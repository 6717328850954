import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { WorkflowService, DirectoryService, CompanyService } from 'services';
import { 
    Breadcrumb, 
    BreadcrumbNavigation,
    List,
    Toast,
    Tooltip,
    Filter, 
    ContextMenu,
    WorkflowArchiveModal,
    WorkflowCreateModal, 
    WorkflowUpdateModal,
    DirectoryCreateModal,
    DirectoryUpdateModal,
    DirectoryDeleteModal,
    DeleteMultipleFilesModal,
    UpdateMultipleFilesModal,
    AuthorityChecker
} from 'components';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { DirectoryAction, LicenseAction } from 'actions';
import StorageKey from 'constants/StorageKey';

const publicUrl = process.env.PUBLIC_URL;

const getFilesInDirectory = async (dispatch, currentDirectory) => {
    try {
        currentDirectory = currentDirectory || "";
        
        const directory = await DirectoryService.getDirectoryList(currentDirectory);
        const workflow = await WorkflowService.getWorkflowList(currentDirectory);

        directory.data.forEach(function (element) {
            element.type = "directory";
            element.droppable = true;
            element.draggable = true;
        });

        workflow.data.forEach(function (element) {
            element.type = "workflow";
            element.draggable = true;
        });
        
        dispatch(DirectoryAction.successDirectoryFileList(directory.data.concat(workflow.data)));
        

    } catch (error) {
        dispatch(DirectoryAction.failureDirectoryFileList(error));
    }
};

const getCompanyList = async (dispatch) => {
    try {
        const { data } = await CompanyService.getCompanyList();
        dispatch(LicenseAction.successCompanyList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureCompanyList(error));
    }
};

const onInitialize = (dispatch, uiState, setRoot, setNavigation) => {
    return () => {
        getFilesInDirectory(dispatch, uiState.currentDirectory);
        getCompanyList(dispatch);
        DirectoryService.getRootDirectory().then(root => {
            setRoot(root.data);
            setNavigation([root.data]);
        })
    };
};

const WorkflowListScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);

    const company = useSelector(state => state.user.company);
    let currentDirectory =  "";

    const isRoot = (currentDirectory == "")? true : false;

    const [ renderedData, setRenderedData ] = useState([]);
    const [ navigation, setNavigation ] = useState([]);
    const [ root, setRoot ] = useState();
    const [ activeRows, setActiveRows ] = useState([]);
    const [ customColumn, setCustomColumn ] = useState(JSON.parse(localStorage.getItem(StorageKey.WORKFLOW_MANAGEMENT_COLUMNS)));
    
    const userAuths = useSelector(state => state.user.auths);
    const directoryFileList = useSelector(state => state.directory.directoryFileList);
    const companyList = useSelector(state => state.license.companyList);

    const onRequestDownloadMultipleFiles = async (data) => {
        try {
            await DirectoryService.downloadMultipleFiles(data, uiState.currentActiveDirectory);
        } catch (error) {
            console.error(error);
        }
    };
    
    const onRequestUpdateFile = (e, file) => {
        if(file.data.length === 1) {
            let item = file.data[0];
            if( item.type === "directory" ) {
                onRequestUpdateDirectory(item.name);
            } else if( item.type === "workflow" ) {
                onRequestUpdateWorkflow(item.id);
            }
        } else {
            onRequestUpdateMultipleFiles(file.data);
        }
    }

    const onRequestDeleteFile = (e, file) => {
        if(file.data.length === 1) {
            let item = file.data[0];
            if( item.type === "directory" ) {
                onRequestDeleteDirectory(item.name);
            } else if( item.type === "workflow" ) {
                onRequestDeleteWorkflow(item.id);
            }
        } else {
            onRequestDeleteMultipleFiles(file.data);
        }
    }

    const onRequestDownloadFile = (e, file) => {
        if(file.data.length === 1) {
            let item = file.data[0];
            if( item.type === "directory" ) {
                onRequestDownloadDirectory(item.name);
            } else if( item.type === "workflow" ) {
                onRequestDownloadWorkflow(item.id);
            }
        } else {
            onRequestDownloadMultipleFiles(file.data);
        }   
    }

    const onCheckAdmin = () => {
        return ((userAuths.includes('ADMIN_ACCESS_LEVEL') && navigation.length < 3) || (userAuths.includes('COMPANY_ACCESS_LEVEL') && navigation.length < 2));
    }

    const [ uiState, setUiState ] = useState({
        showCreateWorkflowModal: false,
        showUpdateWorkflowModal: false,
        showDeleteWorkflowModal: false,
        showCreateDirectoryModal: false,
        showUpdateDirectoryModal: false,
        showDeleteDirectoryModal: false,
        showDeleteMultipleFilesModal: false,
        isRoot: isRoot,
        showUpdateMultipleFilesModal: false,
        editingWorkflow: null,
        editingDirectory: '',
        currentDirectory: currentDirectory,
        currentActiveDirectory: '',
        filter: {
            name: '',
            from: (isRoot) ? null : moment().startOf('day'),
            to: (isRoot) ? null : moment().endOf('day'),
        },
        tenantId: null
    });

    const menuItems = [
        {
            name: t(MessageCode.GENERAL_BUTTON_DOWNLOAD),
            icon: <i className="fa fa-arrow-down"></i>,
            clickHandler: onRequestDownloadFile,
            disabled: !userAuths.includes("DOWNLOAD_WORKFLOW"),
            hidden: false
        },
        {
            name: t(MessageCode.GENERAL_BUTTON_UPDATE),
            icon: <i className="fa fa-edit"></i>,
            clickHandler: onRequestUpdateFile,
            disabled: !userAuths.includes("EDIT_WORKFLOW"),
            hidden: onCheckAdmin()
        },
        {
            name: t(MessageCode.GENERAL_BUTTON_DELETE),
            icon: <i className="fa fa-trash"></i>,
            clickHandler: onRequestDeleteFile,
            disabled: !userAuths.includes("DELETE_WORKFLOW"),
            hidden: onCheckAdmin()
        }
    ]



    const workflowTableColumnList = [{
        field: 'name',
        name: t(MessageCode.TABLE_WORKFLOW_NAME_HEADER),
        headerClasses: 'align-top th-inner',
        formatter: (item) => {
            if (item.name.length > LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) {
                if (item.type === "directory") {
                    return ( 
                        <Tooltip placement="right" value={item.name}>
                            <Link to="#" onClick={() => rowClickHandler(item, 0)}>
                                <i className="fa fa-folder" aria-hidden="true"></i>&nbsp;&nbsp;
                                {item.name.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..."}
                            </Link>
                        </Tooltip>
                    );
                } else if (item.type === "workflow") {
                    return (
                        <Tooltip placement="right" value={item.name}>
                            <span>
                                <img src={ `${publicUrl}/img/02_RaxDocument.ico` }></img>&nbsp;&nbsp;
                                {item.name.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..."}
                            </span>
                        </Tooltip>
                    )
                }
            } else {
                if (item.type === "directory") {
                    return ( 
                        <Link to="#" onClick={() => rowClickHandler(item, 0)}>
                            <i className="fa fa-folder" aria-hidden="true"></i>&nbsp;&nbsp;{item.name}
                        </Link>
                    );
                } else if (item.type === "workflow") {
                    return(
                        <span>
                            <img src={ `${publicUrl}/img/02_RaxDocument.ico` } style={{width: "18px", height: "auto"}}></img>&nbsp;&nbsp;{item.name}
                        </span>
                    )
                }
            }
        },
        sortable: true,
    }, {
        field: 'createDate',
        name: t(MessageCode.TABLE_WORKFLOW_CREATE_DATE_HEADER), 
        headerClasses: 'align-top',
        formatter: (item) => moment(item.createDate).format('MMM DD'),
        sortable: true,
    }, {
        field: 'updateDate',
        name: t(MessageCode.TABLE_WORKFLOW_LAST_MODIFIED_HEADER), 
        headerClasses: 'align-top',
        formatter: (item) => moment(item.updateDate).format('MMM DD, YYYY hh:mm a'),
        sortable: true,
    }, {
        field: 'description',
        name: t(MessageCode.TABLE_WORKFLOW_DESCRIPTION_HEADER),
        headerClasses: 'align-top',
        sortable: true,
    }, {
        field: 'creator',
        name: t(MessageCode.TABLE_WORKFLOW_CREATED_BY_HEADER),
        headerClasses: 'align-top',
        formatter: (item) => item.type === 'workflow' && item.creator ? item.creator.completeName : '-' ,
        sortable: true,
    }, {
        field: 'updatedBy',
        name: t(MessageCode.TABLE_WORKFLOW_UPDATED_BY_HEADER),
        headerClasses: 'align-top',
        formatter: (item) => item.updatedBy ? item.updatedBy.completeName : '-' ,
        sortable: true,
    }];

    const filteredDirectoryFileList = _.filter(directoryFileList, (file) => {
        return (!uiState.filter.name 
                || file.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
                || (file.description && file.description.toLowerCase().includes(uiState.filter.name.toLowerCase())))
                && (!uiState.filter.from || file.createDate >= uiState.filter.from) 
                && (!uiState.filter.to || file.createDate <= uiState.filter.to);
    });

    useEffect(onInitialize(dispatch, uiState, setRoot, setNavigation), []);

    useEffect(() => {
        setRenderedData([...filteredDirectoryFileList]);
    }, [ JSON.stringify(filteredDirectoryFileList) ])

    useEffect(() => {
        if(root) {
            let navigation = uiState.currentDirectory.split("/");
            navigation[0] = root;
            setNavigation(navigation);
        }
    }, [ uiState.currentDirectory ]);

    useEffect(() => {
        menuItemsHeaders.map((item) => {
            if(customColumn && customColumn[item.name]) {
                if(customColumn[item.name].status === "hidden") {
                    item.icon = <i className="fa fa-eye-slash"></i>;
                    item.className = "react-contextmenu-item--disabled cursor-pointer";
                    item.status = "hidden";
                }
            }
        });
        setMenuItemsHeaders(menuItemsHeaders);
        
    }, [JSON.stringify(customColumn)])
    
    const changeDirectory = async (name) => {
        let newParentDir = uiState.currentDirectory;
        let curdir = "";
        let nav = [];
        let tenantId = "";

        if(navigation.length == 1){
            curdir = name;
        }else{
            for(let i = 0; i < navigation.length; i++){
                if(i > 0) nav.push(navigation[i])
            }
            curdir = nav.join('/') + '/' + name;
        }

        let isRootMenu = false;

        if(user.company == null && navigation.length < 2){
            isRootMenu = true;
        }else if(user.company != null && user.department == null && navigation.length < 1){
            isRootMenu = true;
        }

        await getFilesInDirectory(dispatch, curdir);

        setActiveRows([]);
        setUiState({
            ...uiState,
            currentDirectory: newParentDir + "/" + name,
            currentActiveDirectory: curdir,
            tenantId: tenantId,
            filter: {
                name: '',
                from: null,
                to: null,
            }
        }); 
    }

    const navClickHandler = async (index) => {

        let newCurrentDir = "";
        let curdir = "";
        let isRoot = false;
        let filterFrom = uiState.filter.From;
        let filterTo = uiState.filter.To;

        if(index === navigation.length-1) {
            return;
        } 

        let nav = [];
        for (let i = 0; i <= index; i++) {
            newCurrentDir += (i === 0 ? navigation[i]: "/" + navigation[i]);
            if(i > 0)nav.push(navigation[i]);
            if (i === index) {
                isRoot = (newCurrentDir == "") ? true: false;
                curdir = nav.join('/') ; 

                 let isRootMenu = false;

                if(user.company == null && nav.length < 3){
                    isRootMenu = true;
                }else if(user.company != null && user.department == null && nav.length < 2){
                    isRootMenu = true;
                }
                
                setUiState({
                    ...uiState,
                    currentDirectory: newCurrentDir,
                    currentActiveDirectory: curdir,
                    filter: {
                        name: '',
                        from: null,
                        to:null,
                    }
                });

                await getFilesInDirectory(dispatch, curdir);
            }
        }

        setActiveRows([]);
    }

    const rowClickHandler = (item, columnIndex) => {
        if (item.type === "directory" && columnIndex != 3) {
            changeDirectory(item.name);
        }
    }

    const onRequestFilterName = (name) => {
        setActiveRows([]);
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name, 
            },
        });
    };

    const onRequestFilterDateRange = (data) => {
        const [ from, to ] = data || [null, null];
        setActiveRows([]);
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                from,
                to,
            },
        });
    };

    const onRequestUpdateWorkflow = async (id) => {
        try {
            const { data } = await WorkflowService.getSpecificWorkflow(id);
            
            let tenantId;
            if(user.auths.includes('ADMIN_ACCESS_LEVEL')){
                const company = await CompanyService.getCompanyByName(navigation[1]);
                tenantId = company.data.tenantId;
            }else{
                tenantId = user.tenantId
            }

            setUiState({
                ...uiState,
                editingWorkflow: data,
                showUpdateWorkflowModal: true,
                tenantId: tenantId,
            });
        } catch (error) {
            console.error(error);

            await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
            setUiState({
                ...uiState,
                editingWorkflow: null,
                showUpdateWorkflowModal: false,
            });
        }
    };

    const onSuccessUpdateWorkflow = async (data, resetFields) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.WORKFLOW_MODAL_UPDATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            editingWorkflow: null,
            showUpdateWorkflowModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCancelUpdateWorkflow = () => {
        setUiState({
            ...uiState,
            editingWorkflow: null,
            showUpdateWorkflowModal: false,
        });
    };

    const onRequestCreateWorkflow = async() => {
        let tenantId;
        if(user.auths.includes('ADMIN_ACCESS_LEVEL')){
            const company = await CompanyService.getCompanyByName(navigation[1]);
            tenantId = company.data.tenantId;
        }else{
            tenantId = user.tenantId
        }

        setUiState({
            ...uiState,
            showCreateWorkflowModal: true,
            tenantId: tenantId,
        });
    };

    const onSuccessCreateWorkflow = async (data, resetFields) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.WORKFLOW_MODAL_CREATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showCreateWorkflowModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCancelCreateWorkflow = () => {
        setUiState({
            ...uiState,
            showCreateWorkflowModal: false,
        });
    };

    const onRequestDeleteWorkflow = async (id) => {
        try {
            const { data } = await WorkflowService.getSpecificWorkflow(id);

            setUiState({
                ...uiState,
                editingWorkflow: data,
                showDeleteWorkflowModal: true,
            });

        } catch (error) {
            console.error(error);

            await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
            setUiState({
                ...uiState,
                editingWorkflow: null,
                showDeleteWorkflowModal: false,
            });
        }
    };

    const onSuccessDeleteWorkflow = async (data) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.WORKFLOW_MODAL_DELETE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showDeleteWorkflowModal: false,
        });
    };

    const onCancelDeleteWorkflow = (key) => {
        if (key) {
            toast.error(
                <Trans i18nKey={ key } />
            );
        }

        setUiState({
            ...uiState,
            showDeleteWorkflowModal: false,
        });
    };

    const onRequestDownloadWorkflow = async (id) => {
        try {
            await WorkflowService.downloadSpecificWorkflow(id);
        } catch (error) {
            console.error(error);
        }
    };

    const onRequestUpdateDirectory = async (name) => {
        try {

            setUiState({
                ...uiState,
                editingDirectory: {name: name, currentDirectory: uiState.currentActiveDirectory},
                showUpdateDirectoryModal: true,
            });
        } catch (error) {
            console.error(error);

            await getFilesInDirectory(dispatch, uiState.currentDirectory);
            setUiState({
                ...uiState,
                editingDirectory: null,
                showUpdateDirectoryModal: false,
            });
        }
    };

    const onSuccessUpdateDirectory = async (data, resetFields) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.DIRECTORY_MODAL_MESSAGE_UPDATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            editingDirectory: null,
            showUpdateDirectoryModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCancelUpdateDirectory = () => {
        setUiState({
            ...uiState,
            editingDirectory: null,
            showUpdateDirectoryModal: false,
        });
    };

    const onRequestCreateDirectory = () => {
        setUiState({
            ...uiState,
            showCreateDirectoryModal: true,
        });
    };

    const onSuccessCreateDirectory = async (data, resetFields) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);

        setActiveRows([]);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.DIRECTORY_MODAL_MESSAGE_CREATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showCreateDirectoryModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCancelCreateDirectory = () => {
        setUiState({
            ...uiState,
            showCreateDirectoryModal: false,
        });
    };

    const onRequestDeleteDirectory = async (name) => {
        try {

            setUiState({
                ...uiState,
                editingDirectory: {name: name, currentDirectory: uiState.currentActiveDirectory},
                showDeleteDirectoryModal: true,
            });

        } catch (error) {
            console.error(error);

            setUiState({
                ...uiState,
                editingDirectory: null,
                showDeleteDirectoryModal: false,
            });
        }
    };

    const onSuccessDeleteDirectory = async (data) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.DIRECTORY_MODAL_MESSAGE_DELETE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showDeleteDirectoryModal: false,
        });


    };

    const onCancelDeleteDirectory = (key) => {
        if (key) {
            toast.error(
                <Trans i18nKey={ key } />
            );
        }

        setUiState({
            ...uiState,
            showDeleteDirectoryModal: false,
        });
    };

    const onRequestDownloadDirectory = async (name) => {
        try {
            await DirectoryService.downloadSpecificDirectory(name, uiState.currentActiveDirectory);
        } catch (error) {
            console.error(error);
        }
    };

    const onRequestDeleteMultipleFiles = (files) => {
        try {
            setUiState({
                ...uiState,
                editingDirectory: {files: files, currentDirectory: uiState.currentActiveDirectory},
                showDeleteMultipleFilesModal: true,
            });
        } catch (error) {
            console.error(error);

            setUiState({
                ...uiState,
                editingDirectory: null,
                showDeleteMultipleFilesModal: false,
            });
        }
    }

    const onSuccessDeleteMultipleFiles = async () => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast 
                message={MessageCode.DIRECTORY_MODAL_MESSAGE_MULTIPLE_DELETE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showDeleteMultipleFilesModal: false,
        });
    };

    const onCancelDeleteMultipleFiles = (key) => {
        if (key) {
            toast.error(
                <Trans i18nKey={ key } />
            );
        }

        setUiState({
            ...uiState,
            showDeleteMultipleFilesModal: false,
        });
    };

    const onRequestUpdateMultipleFiles = (files) => {
        try {
            setUiState({
                ...uiState,
                editingDirectory: {files: files, currentDirectory: uiState.currentActiveDirectory},
                showUpdateMultipleFilesModal: true,
            });
        } catch (error) {
            console.error(error);

            setUiState({
                ...uiState,
                editingDirectory: null,
                showUpdateMultipleFilesModal: false,
            });
        }
    }

    const onSuccessUpdateMultipleFiles = async (resetFields) => {
        await getFilesInDirectory(dispatch, uiState.currentActiveDirectory);
        setActiveRows([]);
        toast.success(
            <Toast 
                message={MessageCode.DIRECTORY_MODAL_MESSAGE_MULTIPLE_UPDATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showUpdateMultipleFilesModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCancelUpdateMultipleFiles = (key) => {
        if (key) {
            toast.error(
                <Trans i18nKey={ key } />
            );
        }

        setUiState({
            ...uiState,
            showUpdateMultipleFilesModal: false,
        });
    };


    const selectedRows = renderedData.filter((d, index) => activeRows.includes(index))

    const toggleViewHeader = (index) => {
        let data = menuItemsHeaders[index];
        if (data.status === "shown") {
            data.icon = <i className="fa fa-eye-slash"></i>;
            data.className = "react-contextmenu-item--disabled cursor-pointer";
            data.status = "hidden";
        } else {
            data.icon = <i className="fa fa-eye"></i>;
            data.className = "cursor-pointer";
            data.status = "shown";
        }

        let customization = menuItemsHeaders.map((item) => {
            return {
                name: item.name,
                status: item.status
            };
        });

        let res = customization.reduce((ac,{["name"]:x,...rest}) => (ac[x] = rest,ac),{});
        localStorage.setItem(StorageKey.WORKFLOW_MANAGEMENT_COLUMNS, JSON.stringify(res));
        setCustomColumn(res);
        setMenuItemsHeaders(menuItemsHeaders);
    }

    const [menuItemsHeaders, setMenuItemsHeaders] = useState([
        {
            name: t(MessageCode.TABLE_WORKFLOW_NAME_HEADER),
            status: "shown",
            className: "cursor-pointer",
            icon: <i className="fa fa-eye"></i>,
            clickHandler: () => toggleViewHeader(0),
        },
        {
            name: t(MessageCode.TABLE_WORKFLOW_CREATE_DATE_HEADER),
            status: "shown",
            className: "cursor-pointer",
            icon: <i className="fa fa-eye"></i>,
            clickHandler: () => toggleViewHeader(1),
        },
        {
            name: t(MessageCode.TABLE_WORKFLOW_LAST_MODIFIED_HEADER),
            status: "shown",
            className: "cursor-pointer",
            icon: <i className="fa fa-eye"></i>,
            clickHandler: () => toggleViewHeader(2),
        },
        {
            name: t(MessageCode.TABLE_WORKFLOW_DESCRIPTION_HEADER),
            status: "shown",
            className: "cursor-pointer",
            icon: <i className="fa fa-eye"></i>,
            clickHandler: () => toggleViewHeader(3),
        },
        {
            name: t(MessageCode.TABLE_WORKFLOW_CREATED_BY_HEADER),
            status: "shown",
            className: "cursor-pointer",
            icon: <i className="fa fa-eye"></i>,
            clickHandler: () => toggleViewHeader(4),
        },
        {
            name: t(MessageCode.TABLE_WORKFLOW_UPDATED_BY_HEADER),
            status: "shown",
            className: "cursor-pointer",
            icon: <i className="fa fa-eye"></i>,
            clickHandler: () => toggleViewHeader(5),
        }
    ]);



    return (
        <section>
            <AuthorityChecker requiredAuths={["CREATE_WORKFLOW"]}>
                <Link to="#" className="float" id="keys-submenu">
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <ul className='float-submenu'>
                    <li>
                        <AuthorityChecker requiredAuths={["CREATE_WORKFLOW"]}>
                            <Link to="#"
                                onClick={onRequestCreateWorkflow}>
                                <Trans i18nKey={ MessageCode.WORKFLOW_CREATE_BUTTON_TOOLTIP } />
                            </Link>
                        </AuthorityChecker>
                    </li>
                    <li>
                        <AuthorityChecker requiredAuths={["CREATE_WORKFLOW"]}>
                            <Link to="#"
                                onClick={onRequestCreateDirectory}>
                                <Trans i18nKey={ MessageCode.DIRECTORY_CREATE_BUTTON } />
                            </Link>
                        </AuthorityChecker>
                    </li>
                </ul>
            </AuthorityChecker> 

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_WORKFLOW_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_WORKFLOW_LIST_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <WorkflowCreateModal isShown={ uiState.showCreateWorkflowModal } 
                currentDirectory={ uiState.currentActiveDirectory }
                companyList={companyList || []}
                onSuccess={ onSuccessCreateWorkflow }
                onCancel={ onCancelCreateWorkflow }
                tenantId={uiState.tenantId}>
            </WorkflowCreateModal>

            <WorkflowUpdateModal isShown={ uiState.showUpdateWorkflowModal && !!uiState.editingWorkflow } 
                currentDirectory={ uiState.currentActiveDirectory }
                companyList={companyList || []}
                onSuccess={ onSuccessUpdateWorkflow }
                onCancel={ onCancelUpdateWorkflow }
                initialValues={ uiState.editingWorkflow }
                tenantId={uiState.tenantId}>
            </WorkflowUpdateModal>

            <WorkflowArchiveModal isShown={ uiState.showDeleteWorkflowModal && !!uiState.editingWorkflow }
                currentDirectory={ uiState.currentActiveDirectory }
                onSuccess={ onSuccessDeleteWorkflow }
                onCancel={ onCancelDeleteWorkflow }
                initialValues={ uiState.editingWorkflow }>
            </WorkflowArchiveModal>

            <DirectoryCreateModal isShown={ uiState.showCreateDirectoryModal } 
                currentDirectory={uiState.currentActiveDirectory}
                onSuccess={ onSuccessCreateDirectory }
                onCancel={ onCancelCreateDirectory }>
            </DirectoryCreateModal>

            <DirectoryUpdateModal isShown={ uiState.showUpdateDirectoryModal && !!uiState.editingDirectory } 
                onSuccess={ onSuccessUpdateDirectory }
                onCancel={ onCancelUpdateDirectory }
                initialValues={ uiState.editingDirectory }>
            </DirectoryUpdateModal>

            <DirectoryDeleteModal isShown={ uiState.showDeleteDirectoryModal && !!uiState.editingDirectory }
                onSuccess={ onSuccessDeleteDirectory }
                onCancel={ onCancelDeleteDirectory }
                initialValues={ uiState.editingDirectory }>
            </DirectoryDeleteModal>

            <DeleteMultipleFilesModal isShown={ uiState.showDeleteMultipleFilesModal && !!uiState.editingDirectory }
                onSuccess={ onSuccessDeleteMultipleFiles }
                onCancel={ onCancelDeleteMultipleFiles }
                initialValues={ uiState.editingDirectory }>
            </DeleteMultipleFilesModal>

            <UpdateMultipleFilesModal isShown={ uiState.showUpdateMultipleFilesModal && !!uiState.editingDirectory }
                onSuccess={ onSuccessUpdateMultipleFiles }
                onCancel={ onCancelUpdateMultipleFiles }
                initialValues={ uiState.editingDirectory }>
            </UpdateMultipleFilesModal>

            <AuthorityChecker requiredAuths={["VIEW_WORKFLOW"]}>
                 {onCheckAdmin() ? null:
                    <Filter>
                        <Filter.Text label={ 'Name' }
                            onChange={ onRequestFilterName }>
                        </Filter.Text>
                        
                        <Filter.DateRange label={ 'Date' }
                            onChange={ onRequestFilterDateRange }>
                        </Filter.DateRange>
                    </Filter>}

                <BreadcrumbNavigation 
                    navList={navigation}
                    navClickHandler={ navClickHandler }>
                </BreadcrumbNavigation>

                <ContextMenu 
                    menuID={"context-menu"} 
                    options={activeRows[0] !== null ? selectedRows.length ? menuItems : null : menuItemsHeaders}
                    data={{data: selectedRows.length && activeRows[0] !== null  ? selectedRows : null }}>
                    
                    <List theadClass="thead-dark"
                        keyField={ 'id' }
                        data={ renderedData }
                        isSortable={ true }
                        headers={ workflowTableColumnList }
                        rowClickHandler={ rowClickHandler }
                        activeRows={ activeRows }
                        setActiveRows={ setActiveRows }
                        setRenderedData={ setRenderedData }
                        headersSettings={ customColumn }
                        noRecordsPlaceholder={ 
                            <Trans i18nKey={ MessageCode.TABLE_WORKFLOW_EMPTY_PLACEHOLDER } />
                        }>  
                    </List>

                </ContextMenu>
                

            </AuthorityChecker>
        </section>
    );
};

export default WorkflowListScreen;