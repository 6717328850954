import ActionType from 'constants/ActionType';

export default {
    requestLogin: (emailAddress, password) => {
        return {
            type: ActionType.USER_LOGIN_REQUEST,
            payload: {
                emailAddress: emailAddress,
                password: password,
            },
        };
    },

    successLogin: (token) => {
        return {
            type: ActionType.USER_LOGIN_SUCCESS,
            payload: {
                token: token,
            },
        };
    },

    failureLogin: (error) => {
        return {
            type: ActionType.USER_LOGIN_FAILURE,
            payload: {
                error: error,
            },
        };
    },

    requestLogout: () => {
        return {
            type: ActionType.USER_LOGOUT_REQUEST,
        };
    },
    
    successLogout: () => {
        return {
            type: ActionType.USER_LOGOUT_SUCCESS,
        };
    },

    failureLogout: () => {
        return {
            type: ActionType.USER_LOGOUT_FAILURE,
        };
    },

    requestProfile: () => {
        return {
            type: ActionType.USER_PROFILE_REQUEST,
        };
    },

    successProfile: (profile) => {
        return {
            type: ActionType.USER_PROFILE_SUCCESS,
            payload: { 
                fullName: profile.completeName,
                auths: profile.auths,
                company: (profile.company_)? {
                    id: profile.company_.id,
                    name: profile.company_.name,
                    tenantId: profile.company_.tenantId
                }: null,
                department: (profile.department)? profile.department.name: null
            },
        };
    },

    failureProfile: (error) => {
        return {
            type: ActionType.USER_PROFILE_FAILURE,
            payload: { error },
        };
    },

    successVerify: (token) => {
        return {
            type: ActionType.USER_VERIFY_SUCCESS,
            payload: { token },
        };
    },

    failureVerify: () => {
        return {
            type: ActionType.USER_VERIFY_FAILURE,
        };
    },

    requestUserList: () => {
        return {
            type: ActionType.USER_LIST_REQUEST,
        };
    },

    successUserList: (userList) => {
        return {
            type: ActionType.USER_LIST_SUCCESS,
            payload: { userList },
        };
    },

    failureUserList: (error) => {
        return {
            type: ActionType.USER_LIST_FAILURE,
            payload: { error },
        };
    },

    successDepartmentList: (departmentList) => {
        return {
            type: ActionType.DEPARTMENT_LIST_SUCCESS,
            payload: { departmentList },
        };
    },

    failureDepartmentList: (error) => {
        return {
            type: ActionType.DEPARTMENT_LIST_FAILURE,
            payload: { error },
        };
    },

    successUserGroupList: (userGroupList) => {
        return {
            type: ActionType.USER_GROUP_LIST_SUCCESS,
            payload: { userGroupList },
        };
    },

    failureUserGroupList: (error) => {
        return {
            type: ActionType.USER_GROUP_LIST_FAILURE,
            payload: { error },
        };
    },

    successSubordinates: (subordinates) => {
        return {
            type: ActionType.USER_SUBORDINATES_SUCCESS,
            payload: { subordinates },
        };
    },

    failureSubordinates: (error) => {
        return {
            type: ActionType.USER_SUBORDINATES_FAILURE,
            payload: { error },
        };
    },

    successMonitorWidgetsList: (monitorWidgets) => {
        return {
            type: ActionType.MONITOR_WIDGETS_LIST_SUCCESS,
            payload: { monitorWidgets }
        }
    },

    failureMonitorWidgetsList: (error) => {
        return {
            type: ActionType.MONITOR_WIDGETS_LIST_FAILURE,
            payload: { error },
        };
    },

    successUserVisibleWidgets: (visibleWidgets) => {
        return {
            type: ActionType.USER_VISIBLE_WIDGETS_SUCCESS,
            payload: { visibleWidgets }
        }
    },

    failureUserVisibleWidgets: (error) => {
        return {
            type: ActionType.USER_VISIBLE_WIDGETS_FAILURE,
            payload: { error },
        };
    },
};