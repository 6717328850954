import React, { useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { LoginService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Link } from 'react-router-dom';

const emailAddressSelector = (event) => event ? event.target.value : null;
const emailAddressValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_FORGOT_PASSWORD_FORM_EMAIL_REQUIRED;
    }

    return null;
};

const ForgotPasswordForm = ({ onSuccess, onClickLoginLink }) => {
    const [isRequesting, setRequesting] = useState(false);
    const [onChangeEmailAddress, emailAddress] = useValidation(emailAddressSelector, emailAddressValidator);

    const onForgotPassword = async (event) => {
        event.preventDefault();
        setRequesting(true);
        try {
            await LoginService.forgotPassword(emailAddress.value);
            onSuccess();
        }
        catch{
            emailAddress.setError(MessageCode.ERROR_FORGOT_PASSWORD_FORM_INVALID);
        }
        setRequesting(false);
    };

    return (
        <Form>
            <div className="col-sm-8 offset-sm-2 p-4 mt-5">
                <h3 className="mb-5">
                    <Trans i18nKey={MessageCode.FORGOT_PASSWORD_FORM_HEADER} />
                </h3>
                <Form.Group>
                    <Form.Label>
                        <Trans i18nKey={MessageCode.FORGOT_PASSWORD_FORM_EMAIL_LABEL} />
                    </Form.Label>
                    <Form.Control as="input"
                        type="text"
                        id={ "email-forgot-password-field" }
                        tabIndex="1"
                        onChange={onChangeEmailAddress}
                        isInvalid={emailAddress.dirty && emailAddress.error}
                        disabled={isRequesting} >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={emailAddress.error} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <small>
                            <Link to="#" onClick={onClickLoginLink} id={ "back-to-login-btn" }>
                                <Trans i18nKey={MessageCode.FORGOT_PASSWORD_FORM_LOGIN_LINK} />
                            </Link>
                        </small>
                    </Form.Label>
                </Form.Group>

                <Button variant="primary"
                    type="submit"
                    id={ "submit-forgot-password-btn" }
                    className="btn-block btn-lg"
                    tabIndex="2"
                    onClick={e => onForgotPassword(e)}
                    disabled={emailAddress.error || isRequesting} >
                    <Trans i18nKey={MessageCode.FORGOT_PASSWORD_FORM_CONFIRM_BUTTON} />
                </Button>
            </div>
        </Form>
    );

};

export default ForgotPasswordForm;