import React, { useEffect, useState } from 'react';
import _ from 'underscore';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UserService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { AuthorityChecker } from 'components';

const firstNameSelector = (event) => {
    return event ? event.target.value : null;
};

const firstNameValidator = (value) => {
    if (!value) { 
        return MessageCode.ERROR_USER_FIRST_NAME_REQUIRED; 
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_USER_FIRST_NAME_TOO_LONG;
    }

    return null;
};

const lastNameSelector = (event) => {
    return event ? event.target.value : null;
};

const lastNameValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_LAST_NAME_REQUIRED;
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_USER_LAST_NAME_TOO_LONG;
    }

    return null;
};

const emailSelector = (event) => event ? event.target.value : null;
const emailValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_EMAIL_REQUIRED;
    }

    if (value.length > 255) {
        return MessageCode.ERROR_USER_EMAIL_TOO_LONG;
    }

    if ((value.match(/@/g) || []).length !== 1){
        return MessageCode.ERROR_USER_EMAIL_INVALID;
    }

    return null;
};


const passwordSelector = (event) => event ? event.target.value : null;
const passwordValidator = (value) => {

    if (!value) {
        return MessageCode.ERROR_USER_PASSWORD_REQUIRED;
    }

    return null;
};

const confirmPasswordSelector = (event) => event ? event.target.value : null;
const confirmPasswordValidator = (password, confirmPassword) => {
    if (!confirmPassword) {
        return MessageCode.ERROR_USER_CONFIRM_PASSWORD_REQUIRED;
    }
    else if (confirmPassword !== password) {
        return MessageCode.ERROR_USER_PASSWORD_MISMATCH;
    }

    return null;
};

const departmentSelector = (event) => event ? event.target.value : null;
const departmentValidator = (value, role, roleList) => {
    if (role && role != 'default' ) {
        const roleValue = role.split("-")[1];
        const group = roleList.filter(r => r.id === parseInt(roleValue));
        if(group.length > 0  && group[0].authorityNames.includes('DEPARTMENT_ACCESS_LEVEL') && (value == 'default' || !value)){
            return MessageCode.ERROR_USER_DEPARTMENT_REQUIRED;
        }
    }
};

const roleSelector = (event) => event ? event.target.value : null;
const roleValidator = (value, department, roleList) => {

    if (value === 'default' || !value) {
        return MessageCode.ERROR_USER_ROLE_REQUIRED;
    }

    const roleValue = value.split("-")[1];
    const group = roleList.filter(r => r.id === parseInt(roleValue));
    if(group.length > 0  && group[0].authorityNames.includes('DEPARTMENT_ACCESS_LEVEL') && (department.value == 'default' || !department.value)){
        department.setError(MessageCode.ERROR_USER_DEPARTMENT_REQUIRED);
        department.setDirty(true);
    }else{
        department.setError(null);
    }
};

const UserCreateModal = ({ isShown, onSuccess, onCancel, departmentList, roleList, companyList, isAdmin, onCompanyChange }) => {

    const companySelector = (event) =>  getRolesAndDepartmentsByTenant(event) ;
    const companyValidator = (value) => {
        if (value === 'default' || !value) {
            return MessageCode.ERROR_USER_COMPANY_REQUIRED;
        }
    };

    const getRolesAndDepartmentsByTenant = (event) => {
        try{
            const company = JSON.parse(event.target.value);
            onCompanyChange(company.tenant_id);
        }catch(error){
            onCompanyChange("TENANT_X");
        }

        department.reset('default');
        role.reset('default');
        return (event)?event.target.value: null;
    };

    const [ onChangeFirstName, firstName ] = useValidation(firstNameSelector, firstNameValidator);
    const [ onChangeLastName, lastName ] = useValidation(lastNameSelector, lastNameValidator);
    const [ onChangeEmail, email ] = useValidation(emailSelector, emailValidator);
    const [ onChangeDepartment, department] = useValidation(departmentSelector, (value) => departmentValidator(value, role.value, roleList ));
    const [ onChangePassword, password ] = useValidation(passwordSelector, passwordValidator);
    const [ onChangeConfirmPassword, confirmPassword ] = useValidation(confirmPasswordSelector, (value) => confirmPasswordValidator(password.value, value));
    const [ onChangeRole, role ] = useValidation(roleSelector,(value) => roleValidator(value, department, roleList));
    const [ onChangeCompany, company ] = useValidation(companySelector, companyValidator);
    const [ disabled, setDisabled ] = useState(false);
    const [ , general ] = useValidation(null, null, '');
    const { t } = useTranslation();
    const companyValue = useSelector(state => state.user.company);

    const resetFields = () => {
        firstName.reset('');
        lastName.reset('');
        email.reset('');
        department.reset('default');
        role.reset('default');
        company.reset('default');
        password.reset('');
        confirmPassword.reset('');
        general.reset('');
    };

    const onCancelCreate = () => {
        onCancel();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            
            try {
                const emailExist = await UserService.checkIfEmailExists(email.value);
                const departmentValue = (department.value != 'default')? department.value : null;
                const parsedCompany = (company.value)?JSON.parse(company.value):null;
                const cValue = (parsedCompany != null)?parsedCompany.id: companyValue.id;
                const tValue = (parsedCompany != null)?parsedCompany.tenant_id: companyValue.tenantId;
                const roleValue = role.value.split("-")[0];

                if (!emailExist.data) {
                    const { data } = await UserService.addUser(firstName.value, lastName.value, email.value, password.value, departmentValue , roleValue, roleList, cValue, tValue);

                    setTimeout(() => {
                        setDisabled(false);
                    }, 1000);
                    onSuccess(data, resetFields);
                } else {
                    setDisabled(false);
                    email.setError(MessageCode.ERROR_USER_EMAIL_ALREADY_USED);
                }

            } catch ({ data }) {
                setDisabled(false);
                general.setError(MessageCode.ERROR_GENERAL_INTERNAL);
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onCancelCreate }>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.MODAL_USER_CREATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.MODAL_USER_FORM_SUBTEXT } />
                    </small>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.MODAL_USER_FIRST_NAME_LABEL } />
                            </Form.Label>
                            <Form.Control as="input" 
                                type="text"
                                id={ "firstname-create-user-field" }
                                value={ firstName.value || '' }
                                onChange={ onChangeFirstName }
                                isInvalid={ firstName.dirty && firstName.error }>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={ firstName.error } />
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_LAST_NAME_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="text"
                                id={ "lastname-create-user-field" }
                                value={lastName.value || ''}
                                onChange={onChangeLastName}
                                isInvalid={lastName.dirty && lastName.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={lastName.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.MODAL_USER_EMAIL_LABEL} />
                        </Form.Label>
                        <Form.Control as="input"
                            type="email"
                            id={ "email-create-user-field" }
                            value={email.value || ''}
                            onChange={onChangeEmail}
                            isInvalid={email.dirty && email.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={email.error} />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_PASSWORD_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="password"
                                id={ "password-create-user-field" }
                                value={password.value || ''}
                                onChange={(event) => {
                                    onChangePassword(event); 
                                    confirmPassword.validate();
                                }}
                                isInvalid={password.dirty && password.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={password.error} />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_CONFIRM_PASSWORD_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="password"
                                id={ "confirm-password-create-user-field" }
                                value={confirmPassword.value || ''}
                                onChange={onChangeConfirmPassword}
                                isInvalid={confirmPassword.dirty && confirmPassword.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={confirmPassword.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    
                   <AuthorityChecker requiredAuths={["ADMIN_ACCESS_LEVEL"]}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    <Trans i18nKey={MessageCode.MODAL_USER_COMPANY_LABEL} />
                                </Form.Label>
                                <Form.Control as="select"
                                    className='custom-select'
                                    id={ "company-create-user-field" }
                                    value={company.value || 'default'}
                                    onChange={onChangeCompany}
                                    isInvalid={company.dirty && company.error}>
                                    <option value='default'>{t(MessageCode.MODAL_USER_FIELD_COMPANY_DEFAULT)}</option>
                                    {companyList.map(item => <option key={item.id} value={JSON.stringify({'tenant_id':item.tenantId, 'id': item.id})}>{item.name}</option>)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <Trans i18nKey={company.error} />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>  
                    </AuthorityChecker>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_DEPARTMENT_LABEL} />
                            </Form.Label>
                            <Form.Control as="select"
                                className='custom-select'
                                id={ "department-create-user-field" }
                                value={department.value || 'default'}
                                onChange={onChangeDepartment}
                                isInvalid={department.dirty && department.error}>
                                <option value='default'>{t(MessageCode.MODAL_USER_FIELD_DEPARTMENT_DEFAULT)}</option>
                                {departmentList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={department.error} />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_ROLE_LABEL} />
                            </Form.Label>
                            <Form.Control as="select"
                                className='custom-select'
                                id={ "role-create-user-field" }
                                value={role.value || 'default'}
                                onChange={onChangeRole}
                                isInvalid={role.dirty && role.error}>
                                <option value='default'>{t(MessageCode.MODAL_USER_FIELD_ROLE_DEFAULT)}</option>
                                {roleList.map((item, index) => <option key={item.id} value={index + "-" + item.id}>{item.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={role.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid="true">
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ general.error } />
                        </Form.Control.Feedback>
                    </Form.Group>                     
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        id={ "reset-create-user-btn" }
                        onClick={ resetFields }>
                        <Trans i18nKey={ MessageCode.MODAL_USER_CLEAR_BUTTON } />
                    </Button>
                    <Button variant="primary"
                        type="submit"
                        id={ "submit-create-user-btn" }
                        disabled={ disabled || firstName.error || lastName.error || email.error || department.error
                                     || role.error || password.error || confirmPassword.error  || (isAdmin && company.error) }>
                        <Trans i18nKey={ MessageCode.MODAL_USER_REGISTER_BUTTON } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UserCreateModal;
