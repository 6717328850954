import React from 'react';
import { useSelector } from 'react-redux';

import { Redirect, Switch } from 'react-router-dom';
import RoutePath from 'constants/RoutePath';
import { 
    LicenseAccountListScreen, 
    LicenseActivityListScreen, 
    LicenseCompanyListScreen,
    LicenseCompanySpecificScreen,
    LicenseCompanyKeyScreen,
} from 'containers';
import { AuthenticatedRoute } from 'components';
import LicensePremiumActivityListScreen from './LicensePremiumActivityListScreen';

const LicenseScreen = () => {
    const userAuths = useSelector(state => state.user.auths);

    return (
        <Switch>
            <AuthenticatedRoute path={ RoutePath.LICENSE_COMPANIES_LICENSE_KEY_URL } 
                    resolve={ () => userAuths.includes("ACCESS_COMPANY_LICENSE_KEY_PAGE")}
                    resolveTo={ LicenseCompanyKeyScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
            
            <AuthenticatedRoute path={ RoutePath.LICENSE_COMPANIES_LICENSE_URL } 
                    resolve={ () => userAuths.includes("ACCESS_COMPANY_LICENSE_PAGE")}
                    resolveTo={ LicenseCompanySpecificScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.LICENSE_COMPANIES_URL } 
                    resolve={ () => userAuths.includes("ACCESS_COMPANY_TAB")}
                    resolveTo={ LicenseCompanyListScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.LICENSE_ACCOUNTS_URL } 
                    resolve={ () => userAuths.includes("ACCESS_ACCOUNT_TAB")}
                    resolveTo={ LicenseAccountListScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.LICENSE_ACTIVITIES_URL } 
                    resolve={ () => userAuths.includes("ACCESS_ACTIVITY_TAB")}
                    resolveTo={ LicenseActivityListScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.LICENSE_PREMIUM_ACTIVITIES_URL }
                    resolve={ () => userAuths.includes("ACCESS_ACTIVITY_TAB")}
                    resolveTo={ LicensePremiumActivityListScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <Redirect to={ RoutePath.LICENSE_COMPANIES_URL } />
        </Switch>
    );
};

export default LicenseScreen;