import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Breadcrumb, RoleCreateModal, RoleListDisplay, AuthorityChecker   
} from 'components';
import MessageCode from 'constants/MessageCode';
import { RoleService, UserService, CompanyService } from 'services';
import { RoleAction, UserAction, LicenseAction } from 'actions';
import RoleDeleteModal from 'components/user-management/role/RoleDeleteModal';
import RoleUpdateModal from 'components/user-management/role/RoleUpdateModal';

const getAuthoritiesList = async (dispatch) => {
    try{
        const { data } = await RoleService.getAuthoritiesList();
        dispatch(RoleAction.successAuthorityList(data));
    } catch (error) {
        dispatch(RoleAction.failureAuthorityList(error));
    }
};

const getRoleList = async (dispatch) => {
    try{
        const { data } = await RoleService.getRolesList();
        dispatch(RoleAction.successRoleList(data));
    } catch (error) {
        dispatch(RoleAction.failureRoleList(error));
    }
};

const getCompanyList = async (dispatch) => {
    try {
        const { data } = await CompanyService.getCompanyList();
        dispatch(LicenseAction.successCompanyList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureCompanyList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getAuthoritiesList(dispatch);
        getRoleList(dispatch);
        getCompanyList(dispatch);
    };
};

const ManagementRoleScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);
    const authorityList = useSelector(state => state.role.authorityList);
    const roleList = useSelector(state => state.role.roleList);

    const companyList = useSelector(state => state.license.companyList);
    const isAdmin =  (useSelector(state => state.user.company) != null) ? false : true;

    const [ uiState, setUiState ] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        selectedDepartment: null,
        isAdmin: false,
        filter: {
            name: '',
        }
    });

    const onRequestCreateRole = async () => {
        await getRoleList(dispatch);

        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onCancelCreateRole = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onSuccessCreateRole = async () => {
        await getRoleList(dispatch);

        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onRequestUpdateRole = async (id) => {
        try{
            const { data } = await RoleService.getSpecificRole(id);

            setUiState({
                ...uiState,
                showUpdateModal: true,
                selectedRole: data
            });
        } catch (error) {
            console.error(error);
            await getRoleList(dispatch);

            setUiState({
                ...uiState,
                showUpdateModal: false,
                selectedRole: null
            })
        }
    };

    const onCancelUpdateRole = () => {
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedRole: null
        });
    };

    const onSuccessUpdateRole = async () => {
        
        await getRoleList(dispatch);

        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedRole: null,
        });

        await UserService.getCurrentUser().then(({ data }) => {
            dispatch(UserAction.successProfile(data));
        }).catch((error) => {
            dispatch(UserAction.failureProfile(error));
        });
    };

    const onRequestDeleteRole = async (id) => {
        try{
            const { data } = await RoleService.getSpecificRole(id);

            setUiState({
                ...uiState,
                showDeleteModal: true,
                selectedRole: data
            });
        } catch (error) {
            console.error(error);
            await getRoleList(dispatch);

            setUiState({
                ...uiState,
                showDeleteModal: false,
                selectedRole: null
            })
        }
    };

    const onCancelDeleteRole = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedRole: null,
        });
    };

    const onSuccessDeleteRole = async () => {
        await getRoleList(dispatch);
        
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedRole: null,
        });
    };

    return (
        <section>
            <AuthorityChecker requiredAuths={["CREATE_ROLE"]}>
                {/* Add button */}
                <Link to="#" className="float over-accordion" onClick={onRequestCreateRole} id={ "create-role-btn" }>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.ROLE_MODAL_HEADER_CREATE } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_USER_MANAGEMENT_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                <Trans i18nKey={ MessageCode.BREADCRUMB_ROLES_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            {/* Create Role Modal */}
            <RoleCreateModal isShown={uiState.showCreateModal} 
                onClose={onCancelCreateRole} 
                onSuccess={onSuccessCreateRole}
                authorityList={authorityList}
                companyList={companyList || []}
                isAdmin={isAdmin}>
            </RoleCreateModal>

            {/* Delete Role Modal */}
            <RoleDeleteModal isShown={uiState.showDeleteModal && !!uiState.selectedRole } 
                onClose={onCancelDeleteRole} 
                onSuccess={onSuccessDeleteRole}
                initialValues={uiState.selectedRole}>
            </RoleDeleteModal>

            {/* Update Role Modal */}
            <RoleUpdateModal isShown={uiState.showUpdateModal && !!uiState.selectedRole } 
                onClose={onCancelUpdateRole} 
                onSuccess={onSuccessUpdateRole}
                initialValues={uiState.selectedRole}
                authorityList={authorityList}
                companyList={companyList || []}
                isAdmin={isAdmin}>
            </RoleUpdateModal>

            <AuthorityChecker requiredAuths={["VIEW_ROLE"]}>
                {/* Collapsable List */}
                <div className="container-fluid table-responsive">
                    <RoleListDisplay
                        roleList={roleList}
                        authorityList={authorityList}
                        onDelete={onRequestDeleteRole}
                        onUpdate={onRequestUpdateRole}>
                    </RoleListDisplay>
                </div>
            </AuthorityChecker>
        </section>
       
    );
};

export default ManagementRoleScreen;