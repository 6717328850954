import React from 'react';
import _ from 'underscore';

const AgentCardBody = ({ agent = {}, children }) => {
    const getAgentName = (agent) => {
        if (agent) {
            return `${agent.firstName ?? ''} ${agent.lastName ?? ''}`;
        }

        return "Agent User";
    };

    return (
        <div className="mb-1">
            <div className="d-flex w-100 justify-content-between">
                <p className="mr-3 mb-0 text-white font-weight-bolder">
                    { getAgentName(agent) }
                </p>
                <p className="mb-0 text-muted">
                    { agent.macAddress }
                </p>
            </div>

            <div className="clearfix"></div>
            { children }
        </div>
    );
};

export default AgentCardBody;