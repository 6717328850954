import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormControl } from 'components'
import { LicenseService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';

import _ from 'underscore';

const expirationDateSelector = (event) => event ? event : null;
const expirationDateValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_LICENSE_EXPIRATION_DATE_REQUIRED;
    }

    if (typeof value !== 'number'){
        return MessageCode.ERROR_LICENSE_EXPIRATION_DATE_INVALID;
    }

    return null;
};

const allowedKeysSelector = (oldValue, newValue) => {
    if (newValue || parseInt(newValue) < 1) {
        const num = parseInt(newValue,10);
        if (!_.isNaN(num)){
            return num;
        }
        return oldValue;
    }
    return '';
    
};
const allowedKeysValidator = (value) => {
    if (!value && parseInt(value) !== 0) {
        return MessageCode.ERROR_LICENSE_ALLOWED_KEYS_REQUIRED;
    }

    if (parseInt(value) < 1) {
        return MessageCode.ERROR_LICENSE_ALLOWED_KEYS_TOO_LOW;
    }
    else if (parseInt(value) > 100) {
        return MessageCode.ERROR_LICENSE_ALLOWED_KEYS_TOO_HIGH;
    }

    return null;
};

const licenseLabelSelector = (event) => event ? event.target.value: null;
const licenseLabelValidator = (value) => {
    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_LICENSE_LABEL_TOO_LONG;
    }

    return null;
};

const onInitialize = (expirationDate, allowedKeys, licenseLabel, initialValues) => {
    return () => {
        if (initialValues) {
            licenseLabel.setValue(initialValues.label || '');
            expirationDate.setValue(initialValues.expirationDate || '');
            allowedKeys.setValue(initialValues.maxAccounts || '');
        }
    };
};

const LicenseUpdateModal = ({ isShown, onClose, onSuccess, initialValues }) => {
    const { companyId } = useParams();
    const [onChangeExpirationDate, expirationDate] = useValidation(expirationDateSelector, expirationDateValidator);
    const [onChangeAllowedKeys, allowedKeys] = useValidation((event) => allowedKeysSelector(allowedKeys.value, event.target.value), allowedKeysValidator);
    const [onChangeLicenseLabel, licenseLabel] = useValidation(licenseLabelSelector, licenseLabelValidator);
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false); 

    useEffect(onInitialize(expirationDate, allowedKeys, licenseLabel, initialValues), [JSON.stringify(initialValues)]);

    const resetFields = () => {
        expirationDate.reset('');
        allowedKeys.reset('');
        licenseLabel.reset('');
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);
        
            try {
                await LicenseService.updateLicense(companyId, initialValues.id, licenseLabel.value, expirationDate.value, allowedKeys.value);

                toast.success(
                    <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_SUCCESS}
                        values={{name: initialValues.type.name}} />
                );

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                resetFields();
                onSuccess();
            }
            catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_LICENSE_EXPIRATION_DATE_REQUIRED:{
                        expirationDate.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_LICENSE_ALLOWED_KEYS_REQUIRED:
                    case MessageCode.ERROR_LICENSE_ALLOWED_KEYS_TOO_LOW:
                    case MessageCode.ERROR_LICENSE_ALLOWED_KEYS_TOO_HIGH:
                    case MessageCode.ERROR_COMPANY_MORE_LICENSES:{
                        allowedKeys.setError(data.key);
                        break;
                    }

                    default: {
                        console.error(data);
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={isShown} onHide={onClose} centered>
            <Form noValidate onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_HEADER} />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_SUBTEXT} />
                    </small>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.LICENSE_MODAL_UPDATE_LICENSE_LABEL } />
                        </Form.Label>
                        <Form.Control as="input"
                            type="text"
                            id={ "label-update-license-field" }
                            value={licenseLabel.value || ''} 
                            onChange={ onChangeLicenseLabel }
                            isInvalid={ licenseLabel.dirty && licenseLabel.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ licenseLabel.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_EXPIRATION_DATE_LABEL} />
                        </Form.Label>
                        <FormControl.DateTime
                            id={ "expiration-date-update-license-field" }
                            value={expirationDate.value}
                            onChange={onChangeExpirationDate}
                            isInvalid={expirationDate.dirty && expirationDate.error}
                            dateFormat='YYYY MMM DD'
                            timeFormat='hh:mm A'>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={expirationDate.error} />
                            </Form.Control.Feedback>
                        </FormControl.DateTime>

                        {/* <small className='text-white'>
                                License will be <strong className='text-success'>reduced by n day(s).</strong>
                        </small> */}
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_ALLOWED_KEYS_LABEL} />
                        </Form.Label>
                        <Form.Control as='input'
                            type='text'
                            id={ "allowed-keys-update-license-field" }
                            value={allowedKeys.value || ''}
                            maxLength='10'
                            onChange={onChangeAllowedKeys}
                            isInvalid={allowedKeys.dirty && allowedKeys.error} >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={allowedKeys.error} />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={generalError} />
                        </Form.Control.Feedback>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark"
                        type="button"
                        id={ "reset-update-license-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_CLEAR_BUTTON} />
                    </Button>
                    <Button variant="primary"
                        type="submit"
                        id={ "submit-update-license-btn" }
                        disabled={disabled || expirationDate.error || allowedKeys.error}>
                        <Trans i18nKey={MessageCode.LICENSE_MODAL_UPDATE_SAVE_BUTTON} />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LicenseUpdateModal;