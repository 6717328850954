import React, { useState } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { ActivityService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';

const displayNameSelector = event => event ? event.target.value : null;
const displayNameValidator = value => {
    if (!value) { 
        return MessageCode.ERROR_ACTIVITY_NAME_REQUIRED; 
    }

    if (value.length > 255) {
        return MessageCode.ERROR_ACTIVITY_NAME_EXCEEDS_CHARACTER_LIMIT;
    }

    return null;
};

const keySelector = event => event ? event.target.value : null;
const keySelectorValidator = value => {
    if (!value) {
        return MessageCode.ERROR_ACTIVITY_KEY_REQUIRED;
    }

    if (value.length > 255) {
        return MessageCode.ERROR_ACTIVITY_KEY_EXCEEDS_CHARACTER_LIMIT;
    }

    return null;
};

const categorySelector = event => event ? event.target.value : null;
const categorySelectorValidator = value => {

    if (value.length > 255) {
        return MessageCode.ERROR_ACTIVITY_CATEGORY_EXCEEDS_CHARACTER_LIMIT;
    }

    return null;
};

const partialSelector = event => event ? event.target.value : 0;

const ActivityCreateModal = ({ isShown, onSuccess, onCancel }) => {
    const [onChangeDisplayName, name]
        = useValidation(displayNameSelector, displayNameValidator, '');

    const [onChangeKey, key]
        = useValidation(keySelector, keySelectorValidator, '');

    const [onChangeCategory, category]
        = useValidation(categorySelector, categorySelectorValidator, '');

    const [, partial]
        = useValidation(partialSelector, null, 0);

    const [ disabled, setDisabled ] = useState(false); 
    const [, general] = useValidation(null, null, '');

    const onTogglePartial = () => {
        partial.value === 0 ? partial.setValue(1) : partial.setValue(0)
    }

    const resetFields = () => {
        name.reset('');
        key.reset('');
        category.reset('');
        partial.reset(0)
        general.reset('');
    };

    const onCancelCreate = () => {
        onCancel();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                const { data } = await ActivityService.addActivity(
                    name.value, key.value, category.value, partial.value);
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess(data);
                resetFields();

            } catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_ACTIVITY_KEY_REQUIRED: {
                        key.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_ACTIVITY_NAME_REQUIRED: {
                        name.setError(data.key);
                        break;
                    }

                    default: {
                        console.error(data);
                        general.setError(MessageCode.ERROR_GENERAL_INTERNAL);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onCancelCreate }>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={MessageCode.MODAL_ACTIVITY_CREATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={MessageCode.MODAL_ACTIVITY_FORM_SUBTEXT } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.MODAL_ACTIVITY_NAME_LABEL } />
                        </Form.Label>
                        <Form.Control as="input" 
                            id={ "name-create-activity-field" }
                            type="text"
                            value={name.value}
                            onChange={ onChangeDisplayName }
                            isInvalid={ name.dirty && name.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ name.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.MODAL_ACTIVITY_KEY_LABEL} />
                        </Form.Label>
                        <Form.Control as="input"
                            id={ "key-create-activity-field" }
                            type="text"
                            value={key.value}
                            onChange={onChangeKey}
                            isInvalid={key.dirty && key.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={key.error} />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.MODAL_ACTIVITY_CATEGORY_LABEL } />
                        </Form.Label>
                        <Form.Control as="input"
                            type="text"
                            id={ "category-create-activity-field" }
                            value={category.value}
                            onChange={onChangeCategory}
                            isInvalid={category.dirty && category.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={category.error} />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.MODAL_ACTIVITY_PARTIAL_LABEL} />
                        </Form.Label>
                        <Form.Check
                            type="checkbox"
                            id={ "partial-field-activity-field" }
                            checked={partial.value ? 'checked' : ''}
                            onChange={onTogglePartial}>
                        </Form.Check>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid="true">
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ general.error } />
                        </Form.Control.Feedback>
                    </Form.Group>                     
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark"
                        type="button"
                        id={ "reset-create-activity-btn" }
                        onClick={resetFields}
                        disabled={!name.value && !key.value && !category.value && !partial.value}>
                        <Trans i18nKey={MessageCode.MODAL_ACTIVITY_CLEAR_BUTTON} />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-create-activity-btn" }
                        disabled={disabled || name.error || key.error || category.error}>
                        <Trans i18nKey={ MessageCode.MODAL_ACTIVITY_ADD_BUTTON } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ActivityCreateModal;
