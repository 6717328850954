import ActionType from 'constants/ActionType';

export default {    
    successStatusList: (statusList) => {
        return {
            type: ActionType.STATUS_LIST_SUCCESS,
            payload: { statusList },
        };
    },

    failureStatusList: (error) => {
        return {
            type: ActionType.STATUS_LIST_FAILURE,
            payload: { error },
        };
    }
};