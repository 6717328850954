import React, { useState } from 'react';
import _ from 'underscore';

import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LicenseService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';

const licenseTypeSelector = (event) => event ? event.target.value: null;
const licenseTypeValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_LICENSE_TYPE_REQUIRED;
    }

    return null;
};

const licenseLabelSelector = (event) => event ? event.target.value: null;
const licenseLabelValidator = (value) => {
    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_LICENSE_LABEL_TOO_LONG;
    }

    return null;
};

const LicenseCreateModal = ( {isShown, onClose, onSuccess, premiumLicenseTypeList} ) => {
    const { companyId } = useParams();
    const [onChangeLicenseType, licenseType] = useValidation(licenseTypeSelector, licenseTypeValidator, 'pro');
    const [onChangeLicenseLabel, licenseLabel] = useValidation(licenseLabelSelector, licenseLabelValidator);
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false); 
    const {t} = useTranslation();

    const resetFields = () => {
        licenseType.reset('default');
        licenseLabel.reset('');
    };

    const resetToPro = () => {
        licenseType.reset('pro');
        licenseLabel.reset('');
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);
        
            try{
                await LicenseService.addLicense(companyId, licenseType.value, licenseLabel.value);

                toast.success(
                    <Trans i18nKey={ MessageCode.LICENSE_MODAL_CREATE_SUCCESS } 
                            values={ { name: premiumLicenseTypeList.find(x => x.key === licenseType.value).name } } />
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                resetToPro();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch(data.key){
                    case MessageCode.ERROR_LICENSE_TYPE_REQUIRED:
                    case MessageCode.ERROR_LICENSE_TYPE_INVALID:{
                        licenseType.setError(data.key);
                        break;
                    }
                    
                    default:{
                        console.error(data);
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={onClose} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.LICENSE_MODAL_CREATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.LICENSE_MODAL_CREATE_LICENSE_TYPE_LABEL } />
                            </Form.Label>
                            <Form.Control as="select"
                                id={ "type-create-license-field" }
                                className='custom-select'
                                value={licenseType.value}
                                onChange={ onChangeLicenseType }
                                isInvalid={ licenseType.dirty && licenseType.error}>
                                <option value='default' disabled>{t(MessageCode.LICENSE_MODAL_CREATE_LICENSE_TYPE_DEFAULT)}</option>
                                { premiumLicenseTypeList.map(item => <option key={item.key} value={item.key}>{item.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={ licenseType.error } />
                            </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                        <Trans i18nKey={ MessageCode.LICENSE_MODAL_CREATE_LICENSE_LABEL } />
                        </Form.Label>
                        <Form.Control as="input"
                            type="text"
                            id={ "label-create-license-field" }
                            value={licenseLabel.value || ''} 
                            onChange={ onChangeLicenseLabel }
                            isInvalid={ licenseLabel.dirty && licenseLabel.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ licenseLabel.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group>  

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-create-license-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.LICENSE_MODAL_CREATE_CLEAR_BUTTON } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-create-license-btn" }
                        disabled={ disabled || licenseType.error }>
                        <Trans i18nKey={ MessageCode.LICENSE_MODAL_CREATE_SAVE_BUTTON } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LicenseCreateModal;