import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Q from 'q';

import { Link, withRouter } from 'react-router-dom';

const isActive = (currentPath, toPath) => {
    return currentPath.includes(toPath);
};

const userAccessible = async (userAuths, tabAuths) => {
    var deferred = Q.defer();

    if (Array.isArray(tabAuths)) {
        await tabAuths.forEach( auth => {
            if (userAuths.includes(auth)) {
                deferred.resolve(true);
            }
        });
    } else {
        deferred.resolve(true);
    }
    return deferred.promise;

}

const DashboardSidebarItem = ({ to, activeClassName, iconClassName, label, onClick, 
        isCollapsible, collapseSelector, location, children, tabAuth }) => {

    const userAuths = useSelector(state => state.user.auths);
    const [ isAccessible, setAccessible ] = useState(false);

    Promise.resolve(userAccessible(userAuths, tabAuth)).then((isAccessible) => {
        setAccessible(isAccessible || !tabAuth)
    });

    return (
        <>
            {
                isAccessible ?
                    <li className={ isActive(location.pathname, to) ? activeClassName : null }>
                        { !isCollapsible 
                            ? 
                                <Link to={ to } 
                                    onClick={ onClick }>
                                    <i className={ iconClassName }></i>{ label }
                                </Link> 
                            :
                                <a href={ collapseSelector }
                                    data-toggle="collapse"
                                    className="dropdown-toggle">
                                    <i className={ iconClassName }></i>{ label }
                                </a>
                        }
                        { children }
                    </li>
                    : ''
            }
        </>
    );
};

export default withRouter(DashboardSidebarItem);