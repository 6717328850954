import ActionType from 'constants/ActionType';

export default {
    requestAccountList: () => {
        return {
            type: ActionType.ACCOUNT_SOLO_LIST_REQUEST,
        };
    },

    successAccountList: (soloAccountList) => {
        return {
            type: ActionType.ACCOUNT_SOLO_LIST_SUCCESS,
            payload: { soloAccountList },
        };
    },

    failureAccountList: (error) => {
        return {
            type: ActionType.ACCOUNT_SOLO_LIST_FAILURE,
            payload: { error },
        };
    },
};