import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import { CompanyService } from 'services';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { Toast } from 'components';

const onInitialize = (initialValues, setCompanyName) => {
    return () => {

        setCompanyName(initialValues && (() => {
            let displayName = initialValues.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
    };
};

const CompanyDeleteModal = ({ isShown, onClose, onSuccess, initialValues }) => {
    const [ companyName, setCompanyName ] = useState();
    const [ disabled, setDisabled ] = useState(false); 

    useEffect(onInitialize(initialValues, setCompanyName), [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            
            try{
                await CompanyService.deleteCompany(initialValues.id, initialValues.tenantId);

                toast.success(
                    <Toast data={{ name: companyName }}
                        message={MessageCode.COMPANY_MODAL_DELETE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                console.error(data);

                if (data.key === MessageCode.ERROR_COMPANY_HAS_LICENSE){
                    toast.error(
                        <Trans i18nKey={ data.key } />
                    );
                }
                else{
                    toast.error(
                        <Trans i18nKey={ MessageCode.ERROR_GENERAL_INTERNAL } />
                    );
                }
                
                
                onClose();
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onClose } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.COMPANY_MODAL_DELETE_HEADER } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.COMPANY_MODAL_DELETE_TEXT }
                        values={ { name: companyName }}>
                    <p className="wrap-long-text">You are about to delete <span className="brand-yellow"><strong>COMPANY_NAME.</strong></span></p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
         
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-company-btn" }
                    onClick={ () => onClose() }>
                    <Trans i18nKey={ MessageCode.COMPANY_MODAL_DELETE_CANCEL_BUTTON } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-company-btn" }
                    disabled={ disabled }
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.COMPANY_MODAL_DELETE_BUTTON } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CompanyDeleteModal;