import axios from 'axios';
import { saveAs } from 'file-saver';

import HeaderKey from 'constants/HeaderKey';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getWorkflowList: async (directoryParentName) => {
        const url = `${apiUrl}/api/workflow/view`;
        const directory = {
            parent: directoryParentName
        };
        return await axios.post(url, directory);
    },

    getSpecificWorkflow: async (id) => {
        const url = `${apiUrl}/api/workflow/${id}`;
        return await axios.get(url);
    },

    addWorkflow: async (name, file, description = "", currentDirectory, tenantId) => {
        const url = `${apiUrl}/api/workflow/create`;

        const data = new FormData();
        data.set('name', name);
        data.set('description', description);
        data.set('directory',currentDirectory );
        data.append('file', file);
        
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId,
            } 
        };

        return await axios.post(url, data, config);
    },

    updateWorkflow: async (id, name, file, description = "", currentDirectory, tenantId) => {
        const url = `${apiUrl}/api/workflow/${id}`;

        const data = new FormData();
        data.set('name', name);
        data.set('description', description);
        data.set('directory', currentDirectory);
        data.append('file', file);

        const config =  { 
            headers: { 
                'APP-TENANT': tenantId,
            } 
        };

        return await axios.put(url, data, config);
    },

    archiveWorkflow: async (id, currentDirectory) => {
        const data = new FormData();
        data.set('directory', currentDirectory);
        const url = `${apiUrl}/api/workflow/delete/${id}`;

        return await axios.post(url, data);
    },

    downloadSpecificWorkflow: async (id) => {
        const url = `${apiUrl}/api/workflow/download/${id}?rename=true`;

        const response = await axios.get(url, { responseType: 'blob' });

        const contentDisposition = response.headers[HeaderKey.CONTENT_DISPOSITION];
        const filenameRegex = /.*filename\*=(.*)''(.*)/g;

        const match = filenameRegex.exec(contentDisposition);
        if (match) {
            const [ , , filename ] = match;
            saveAs(response.data, decodeURIComponent(filename));
        }
    },
};