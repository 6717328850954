import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import DateTime from 'react-datetime';
import Select from 'react-select';

import { Trans } from 'react-i18next';
import { Button, Form, InputGroup, Card } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import MessageCode from 'constants/MessageCode';
import moment from 'moment';

const placeholder = <Trans i18nKey={MessageCode.FORM_CONTROL_FILE_PLACEHOLDER} />;

const FormControlDateTime = ({ value, id, onChange, children,
    dateFormat = 'MM/DD/YYYY',
    timeFormat = '',
    minDate,
    ...props
}) => {

    const onWrappedChange = (updatedMoment) => {
        if (_.isFunction(onChange)) {
            onChange(_.isString(updatedMoment) ? updatedMoment : updatedMoment.valueOf());
        }
    };



    return (
        <DateTime
            value={ value }
            onChange={ onWrappedChange }
            dateFormat={ dateFormat }
            timeFormat={ timeFormat }
            closeOnSelect={ true }
            minDate={minDate}
            renderInput={
                (datetimeProps, openCalendar) => (
                    <InputGroup onClick={ openCalendar }>
                        <Form.Control as="input"
                            type="text"
                            id={id+"-text-field"}
                            { ...datetimeProps }
                            { ...props }>
                        </Form.Control>
                        <InputGroup.Append style={{zIndex:0}}>
                            <Button variant="outline-secondary" id={id+"-datetime-selector-btn"}>
                                <i className={dateFormat ? "fa fa-calendar" : "fa fa-clock"}></i>
                            </Button>
                        </InputGroup.Append>
                        { children }
                    </InputGroup>
                )
            }>
        </DateTime>
    );
};

const FormControlFile = ({ value, id, onChange, children, isInvalid, ...props }) => {
    let fileInput = null;

    const onWrappedChange = (event) => {
        if (event) {
            const file = event.target.files[0];
            if (file) {
                onChange(_.isFunction(onChange) ? event : null);
            }   
        }
    };

    const onClick = () => {
        fileInput.click();
    };

    return (
        <>
            <InputGroup>
                <Form.Control as="input"
                    type="button"
                    id={id}
                    className="custom-file-input"
                    onClick={ onClick }
                    isInvalid={ isInvalid }>
                </Form.Control>

                <Form.Label className="custom-file-label">
                    { value ? value : placeholder }
                </Form.Label>
                { children }
            </InputGroup>
            <Form.Control as="input" 
                type="file" 
                className="d-none"
                onChange={ onWrappedChange }
                ref={ (fi) => { fileInput = fi; } }
                { ...props }>
            </Form.Control>
        </>
    );
};

const FormControlTypeahead = ({ value, id, onChange, children, isInvalid, errorMessageEmptyTextbox, errorMessageNoneSelected, ...props }) => {
    
    const onWrappedChange = (event) => {
        if (event && event.length > 0) {
            value.setError(null);
            onChange(event);
        }
    };

    const onInputChange = (input) => {
        value.setDirty(true);
        if (input) {
            value.setValue(errorMessageNoneSelected);
        } else {
            value.setValue(errorMessageEmptyTextbox);
        }
    }

    return (
        <>
            <Typeahead
                id={id || 'typeahead'}
                value={value}
                ref={(th) => { value.typeahead = th }}
                onChange={onWrappedChange}
                onInputChange={onInputChange}
                maxResults={5}
                {...props}
            />

            <Form.Control as="input" type="hidden"
                isInvalid={isInvalid}>
            </Form.Control>
            {children}
            
        </>
    );
};

const FormControl = () => {
    return (
        <></>
    );
};

const FormControlWeekdaySelection = ({ value, onChange }) =>{
    const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

    const isSelected = (day) => {
        return value ? value.includes((weekdays.indexOf(day).toString())) : false;
    };

    const handleChange = (e) => {
        value.includes(e.target.value) ? value.splice(value.indexOf(e.target.value),1) : value.push(e.target.value);
        onChange(value.concat());
    }

    return(
        <div>
            {weekdays.map(day =>{
                return (
                    <button type="button"
                        className={ isSelected(day) ? 'btn btn-outline-warning' : 'btn btn-outline-dark' }
                        value={ weekdays.indexOf(day) }
                        key={ day }
                        style={{margin:"3px 3px 3px 3px"}}
                        onClick={ handleChange }>
                        { day }
                    </button>
                );
            })}
        </div>
    );
}

const FormControlMultipleselect = ({value, id, optionList, onChange, isInvalid}) => {
    const onWrappedChange = (event) => {
        if(isInvalid){
            value.setError(MessageCode.ERROR_NO_USERS_SELECTED)
        }

        onChange(event);
    };
    
    
    return(
        <div style={{color: "black"}}>
            <Select
                style={{zIndex: 1}}
                isMulti
                value={value.value}
                options={optionList}
                id={id}
                onChange={onWrappedChange}
                >
            </Select>

            <Form.Control as="input" type="hidden"
                isInvalid={isInvalid}>
            </Form.Control>
        </div>
    )
}

const FormControlWidgetSelection = ({ value, onChange, groupedWidgets }) =>{

    const isSelected = (widget_id) => {
        return value && value.includes(widget_id);
    }

    const widgetsToggle = (widget_id) => {
        value.includes(widget_id) ? value.splice(value.indexOf(widget_id),1) : value.push(widget_id);
        onChange(value.concat());
    }

    return(
        <div className="row text-left">
            { 
                _.map(groupedWidgets, (widgets, index) => {
                    return (
                        <div className="col-sm-3" key={ index }>
                        {
                            _.map(widgets, (widget) => {
                                return (
                                    <Card 
                                        key={ widget.widgetKey } 
                                        className={ isSelected(widget.widgetKey) ? 'bg-success' : 'bg-dark'}
                                        onClick={ () => widgetsToggle(widget.widgetKey) }
                                        value={ widget.widgetKey }
                                    >
                                        <div className="card-body p-2">
                                            <p className="m-0">
                                                <small><i className={isSelected(widget.widgetKey) ? 'fa fa-eye' : 'fa fa-eye-slash'}></i> </small>{widget.widgetTitle}
                                            </p>
                                        </div>
                                    </Card>
                                );
                            })
                        }
                        </div>
                    )
                })
            }
        </div>
    );
}

FormControl.File = FormControlFile;
FormControl.DateTime = FormControlDateTime;
FormControl.WeekdaySelection = FormControlWeekdaySelection;
FormControl.Multipleselect = FormControlMultipleselect;
FormControl.Typeahead = FormControlTypeahead;
FormControl.WidgetSelection = FormControlWidgetSelection;

export default FormControl;