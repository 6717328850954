import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getStatusList: async () => {
        const url = `${apiUrl}/api/status`;
        return await axios.get(url);
    },

    getSpecificStatus: async (id) => {
        const url = `${apiUrl}/api/status/${id}`;
        return await axios.get(url);
    },

    addStatus: async (statusName, statusDepartment, statusThreshold, statusColor, statusIcon, statusTrackIdle, filename) => {
        const url = `${apiUrl}/api/status`;

        const status = {
            name: statusName,
            departmentId: statusDepartment,
            threshold: statusThreshold,
            color: statusColor,
            icon: statusIcon,
            trackIdle: statusTrackIdle,
            fileName: filename
        };

        return await axios.post(url, status);
    },

    updateStatus: async (id, statusName, statusDepartment, statusThreshold, statusColor, statusIcon, statusTrackIdle, filename ) => {
        const url = `${apiUrl}/api/status/${id}`;

        const status = {
            name: statusName,
            departmentId: statusDepartment,
            threshold: statusThreshold,
            color: statusColor,
            icon: statusIcon,
            trackIdle: statusTrackIdle,
            fileName: filename
        };

        return await axios.put(url, status);
    },

    deleteStatus: async (id) => {
        const url = `${apiUrl}/api/status/${id}`;
        return await axios.delete(url);
    }
};