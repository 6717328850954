import React, { useState } from 'react';
import _ from 'underscore';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { ScheduleService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { FormControl } from 'components';

import moment from 'moment';

const dateFromSelector = (event) => {
    return event ? event : null;
};

const shiftSelector = (event) => {
    var dateInMillis = moment(new Date(event))
    return event ? dateInMillis.hour()*3600 + dateInMillis.minutes()*60 : null;
};

const shiftValidator = (value) =>{
    if(!value){
        return MessageCode.ERROR_NO_USERS_SELECTED
    }
    return null
}

const timeStartInitializer = () =>{
    // return moment.hour()*3600 + moment.minutes()*60;
    return moment().valueOf()
}
const timeEndInitializer = () =>{
    return moment().add(9,'hours').valueOf()
}

const ScheduleUpdateModal = ({ isShown, onSuccess, onCancel, schedIdList }) => {
    const [ , general ] = useValidation(null, null, '');
    const [ onChangeDateFrom , dateFrom ] = useValidation(dateFromSelector, null);
    const [ onChangeShiftStart , shiftStart ] = useValidation(shiftSelector, shiftValidator, timeStartInitializer);
    const [ onChangeShiftEnd , shiftEnd ] = useValidation(shiftSelector, shiftValidator, timeEndInitializer);
    const [ disabled, setDisabled ] = useState(false);

    const getSelectedUsers = () =>{
        var userList = []
        schedIdList.forEach(sched=>{
            var fullName = sched.firstName + ' ' +sched.lastName;
            if(!userList.includes(fullName)){
                userList.push(fullName)
            }
        })
        return userList    
    }

    const extractSecondsinDate = (dateInMillis) =>{
        dateInMillis = moment(dateInMillis)
        return dateInMillis.hour()*3600 + dateInMillis.minutes()*60
    }

    const getCoveredDates = () =>{
        var coverageDate = {};
        try{
            var max = schedIdList.reduce((prev, current) => prev = prev > current.scheduleStart ? prev : current.scheduleStart, 0);
            var min = schedIdList.reduce((prev, current) => prev = prev <= current.scheduleStart ? prev : current.scheduleStart);
            coverageDate['max'] = moment(new Date(max)).format('MMM DD YYYY');
            coverageDate['min'] = moment(new Date(min)).format('MMM DD YYYY');

        } catch(e){
            //
        }
        if(moment(coverageDate['min']).isValid()){
            return coverageDate['min'] + " - " +coverageDate['max']
        } 
        return coverageDate['max']
    };
        

    const resetFields = () => {
        dateFrom.reset('');
    };

    const onCancelUpdate = () => {
        onCancel();
    };

    const onSetScheduleDBReadable = () =>{
        const dateStart = moment(new Date(dateFrom.value));
        const userSchedList = [];

        var shiftStartInSeconds = extractSecondsinDate(shiftStart.value);
        var shiftEndInSeconds = extractSecondsinDate(shiftEnd.value);

        schedIdList.forEach(sched=>{
            var mt = moment(sched.scheduleStart).startOf('day').valueOf()
            var userSched = {
                schedId: sched.id,
                schedStart: mt + (shiftStartInSeconds * 1000),
                schedEnd: mt + (shiftEndInSeconds * 1000)
            }
            userSchedList.push(userSched)
        })
        return userSchedList;
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try{
                const scheds = onSetScheduleDBReadable();

                var scheduleList = [];

                scheds.forEach((sched)=>{
                    var schedObj = {
                        id: sched.schedId,
                        scheduleStart: sched.schedStart,
                        scheduleEnd: sched.schedEnd
                    }
                    scheduleList.push(schedObj);
                })

                await ScheduleService.updateSchedule(scheduleList);

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess({name: "Schedules"}, resetFields);

            } catch(error){
                setDisabled(false);
                console.error(error);
                general.setError(MessageCode.ERROR_GENERAL_INTERNAL);
            }
        }
    };

    const errorFeedbackStyle = {
        fontSize: '0.85em',
        color: '#DC3545',
        marginLeft: '5px',
    }

    return (
        <Modal show={ isShown } onHide={ onCancelUpdate }>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.MODAL_SCHEDULE_UPDATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        <p className="wrap-long-text">You are about to update the schedule of:</p>
                    </span>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {/* <Trans i18nKey={MessageCode.MODAL_DATE_FROM_LABEL} /> */}
                                {
                                    getSelectedUsers().map(listitem => (
                                        <Form.Row>
                                            <Form.Label><li>{listitem}</li></Form.Label>
                                        </Form.Row>
                                    ))
                                }
                            </Form.Label>
                        </Form.Group>  
                    </Form.Row>

                    <span>
                        <p className="wrap-long-text">Covered Dates: <span className="brand-yellow"><strong>{getCoveredDates()}</strong></span></p>
                    </span>
                    
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_SCHEDULE_START_LABEL} />
                            </Form.Label>

                            <FormControl.DateTime value={shiftStart.value} dateFormat={false} timeFormat={'h:mm a'} onChange={onChangeShiftStart} id={ "shiftstart-update-schedule-field" }></FormControl.DateTime>
                            <span style={errorFeedbackStyle}>{ shiftStart.error ? "Select shift start" : ""}</span>

                            {/* <FormControl.TimePicker value={shiftStart} onChange={onChangeShiftStart}></FormControl.TimePicker> */}
                            <Form.Control.Feedback type="invalid">
                                {/* <Trans i18nKey={password.error} /> */}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_SCHEDULE_END_LABEL} />
                            </Form.Label>
                            <FormControl.DateTime value={shiftEnd.value} dateFormat={false} timeFormat={'h:mm a'} onChange={onChangeShiftEnd} id={ "shiftend-update-schedule-field" }></FormControl.DateTime>
                            <span style={errorFeedbackStyle}>{ shiftEnd.error ? "Select shift end" : ""}</span>
                            
                            {/* <FormControl.TimePicker value={shiftEnd} onChange={onChangeShiftEnd}></FormControl.TimePicker> */}
                            <Form.Control.Feedback type="invalid">
                                {/* <Trans i18nKey={confirmPassword.error} /> */}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        id={ "reset-update-schedule-btn" }
                        onClick={ resetFields }>
                        <Trans i18nKey={ MessageCode.MODAL_USER_CLEAR_BUTTON } />
                    </Button>
                    <Button variant="primary"
                        type="submit"
                        id={ "submit-update-schedule-btn" }
                        disabled={ disabled || shiftStart.error || shiftEnd.error }>
                        Update
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ScheduleUpdateModal;
