import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode';

const AgentWorkflowList = ({ children }) => {
    return (
        <ListGroup as="ul" variant="flush" className="mt-2 bg-transparent">
        {
            children && children.length > 0 
                ? children
                : (
                    <ListGroup.Item as="li" className="pl-0 pr-0 pt-1 pb-0 bg-transparent">
                        <p className="m-0 text-white">
                            <Trans i18nKey={MessageCode.ERROR_WORKFLOW_EVENTS_WORKFLOW_LIST_EMPTY} />
                        </p>
                    </ListGroup.Item>
                )
        }
        </ListGroup>
    );
};

export default AgentWorkflowList;