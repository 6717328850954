import ActionType from 'constants/ActionType';

const initialState = {
    authorityList: [],
    roleList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.AUTHORITY_LIST_SUCCESS: {
            return {
                ...state,
                authorityList: action.payload.authorityList,
            };
        }

        case ActionType.AUTHORITY_LIST_FAILURE: {
            return {
                ...state,
                authorityList: initialState.authorityList,
            };
        }

        case ActionType.ROLE_LIST_SUCCESS: {
            return {
                ...state,
                roleList: action.payload.roleList,
            };
        }

        case ActionType.ROLE_LIST_FAILURE: {
            return {
                ...state,
                roleList: initialState.roleList,
            };
        }

        default: {
            return state;
        }
    }
};