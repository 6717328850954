import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const WidgetHighcharts = ({ options }) => {
    return (
        <HighchartsReact
            highcharts={ Highcharts }
            options={ options } />
    );
};

export default WidgetHighcharts;