import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getDepartmentList: async (tenantId) => {
        const url = `${apiUrl}/api/department`;
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };
        return await axios.get(url, config);
    },

    getAccessibleDepartmentList: async () => {
        const url = `${apiUrl}/api/department/usersAccessibleDepts`;
        return await axios.get(url);
    },

    getAccessibleDepartmentListForDisplay: async () => {
        const url = `${apiUrl}/api/department/usersAccessibleDeptsForDisplay`;
        return await axios.get(url);
    },

    getSpecificDepartment: async (id) => {
        const url = `${apiUrl}/api/department/${id}`;
        return await axios.get(url);
    },

    addDepartment: async (departmentName, departmentHeadId, tenantId) => {
        const url = `${apiUrl}/api/department`;

        const department = {
            name: departmentName ? departmentName.trim() : '',
            headId: departmentHeadId
        };

        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };
        
        return await axios.post(url, department, config);
    },

    updateDepartment: async (id, departmentName, departmentHeadId, tenantId) => {
        const url = `${apiUrl}/api/department/${id}`;

        const department = {
            name: departmentName ? departmentName.trim() : '',
            headId: departmentHeadId
        };

        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };

        return await axios.put(url, department, config);
    },

    deleteDepartment: async (id, tenantId) => {

        const url = `${apiUrl}/api/department/${id}`;
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };

        return await axios.delete(url, config);
    }
};