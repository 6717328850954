import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getCompanyList: async () => {
        const url = `${apiUrl}/api/company`;
        return await axios.get(url);
    },

    getSpecificCompany: async (id) => {
        const url = `${apiUrl}/api/company/${id}`;
        return await axios.get(url);
    },

    addCompany: async ( companyName, industry, firstName, lastName, email, password ) => {
        const url = `${apiUrl}/api/company`;

        const company = {
            name: companyName ? companyName.trim() : '',
            industryType: industry,
            head : {
                id: 0,
                firstName: firstName,
                lastName: lastName,
                emailAddress: email,
                credential: {
                    username: email,
                    newPassword: password,
                    confirmPassword: password,
                }
            }
        };

        return await axios.post(url, company);
    },

    updateCompany: async (id, companyName, industry, headId ) => {
        const url = `${apiUrl}/api/company/${id}`;

        const company = {
            name: companyName ? companyName.trim() : '',
            industryType: industry,
            headId: headId
        };

        return await axios.put(url, company);
    },

    deleteCompany: async (id, tenantId) => {
        const url = `${apiUrl}/api/company/${id}`;
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId,
            } 
        };

        return await axios.delete(url, config);
    },

    getCompanyByName: async (name) => {
        const url = `${apiUrl}/api/company/find-by-name/${name}`;
        return await axios.get(url);
    },
    
    getIndustryList: async () => {
        const url = `${apiUrl}/api/industry-type`;
        const config = {
            headers: {
                'OT4-APP-SECRET': 'MtC08UZ6E2s5BNUDWb6BfVTDwcyfI3CG',
            },
        };
        return await axios.get(url, config);
    },
};