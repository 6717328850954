import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';

import { Button, Col, Form } from 'react-bootstrap';
import { FormControl } from 'components';

const getDateRangeType = (dateRange) => {
    if (dateRange) {
        if (!dateRange[0] && !dateRange[1]) {
            return 'all';
        }
        else if (
            dateRange[0] === moment().startOf('day').valueOf() &&
            dateRange[1] === moment().startOf('day').add(1, 'day').valueOf()) {
            return "today";
        } 
        else if (
            dateRange[0] === moment().startOf('day').subtract(1, 'day').valueOf() &&
            dateRange[1] === moment().startOf('day').valueOf()) {
            return "yesterday";
        } 
        else if (
            dateRange[0] === moment().startOf('week').valueOf() &&
            dateRange[1] === moment().startOf('week').add(1, 'week').valueOf()) {
            return "this_week";
        }
        else if (
            dateRange[0] === moment().startOf('month').valueOf() &&
            dateRange[1] === moment().startOf('month').add(1, 'month').valueOf()) {
            return "this_month";
        }
    }

    return "custom";
}

const getDateRange = (dateRangeType) => {
    switch (dateRangeType) {
        case 'all': {
            return [ null, null ]
        }
        case 'today': {
            return [ 
                moment().startOf('day').valueOf(), 
                moment().startOf('day').add(1, 'day').valueOf(),
            ];
        }
        case 'yesterday': {
            return [ 
                moment().startOf('day').subtract(1, 'day').valueOf(), 
                moment().startOf('day').valueOf(),
            ];
        }

        case 'this_week': {
            return [ 
                moment().startOf('week').valueOf(), 
                moment().startOf('week').add(1, 'week').valueOf() 
            ];
        }

        case 'this_month': {
            return [ 
                moment().startOf('month').valueOf(), 
                moment().startOf('month').add(1, 'month').valueOf() 
            ];
        }

        default: {
            return [ 
                moment().startOf('day').valueOf(), 
                moment().startOf('day').add(1, 'day').valueOf() 
            ];
        }
    }
};

const TextFilter = ({ label, onChange }) => {
    const onChangeText = (event) => {
        const value = event ? event.target.value : '';
        _.isFunction(onChange) && onChange(value);
    };

    return (
        <Col sm>
            <Form.Group>
                <Form.Label>{ label }</Form.Label>
                <Form.Control as="input" id={ "filter-field" }
                    type="text"
                    onChange={ onChangeText }>
                </Form.Control>
            </Form.Group>
        </Col>
    );
};

const initializeCustomDate = (ui, setUi, customDateRange) => {
    return () => {
        var type = getDateRangeType(customDateRange);
        if ( customDateRange && type === "custom" ) {
            setUi({
                ...ui,
                showCustom: true,
                category: 'custom',
                from: customDateRange[0],
                to: customDateRange[1]
            });
        }
    }
}

const DateRangeFilter = ({ value, onChange, monitorFilter }) => {
    const label = 'Date';
    const fromLabel = 'From';
    const toLabel = 'To';

    const [ ui, setUi ] = useState({
        showCustom: false,
        category: 'today',
        from: null,
        to: null,
    });

    useEffect(initializeCustomDate( ui, setUi, value ), [value]);

    const onChangeDateRange = (event) => {
        const dateRangeType = event.target.value;
        if (dateRangeType === 'all') {
            onChange([ null, null ]);
        }

        if (dateRangeType === 'custom') {
            setUi({
                ...ui,
                showCustom: true,
            });
            onChange([ null, null ])

        } else {
            const [ from, to ] = getDateRange(dateRangeType);

            setUi({
                ...ui, from, to,
                showCustom: false
            });

            _.isFunction(onChange) && onChange([ from, to ]);
        }
    };

    const onChangeFilterFrom = (from) => {
        setUi({ ...ui, from });
        _.isFunction(onChange) && onChange([ from, ui.to ]);
    };

    const onChangeFilterTo = (to) => {
        setUi({ ...ui, to });
        _.isFunction(onChange) && onChange([ ui.from, moment(to).endOf('day').valueOf() ]);
    };

    if (monitorFilter) {
        return (
            <>
                <Form.Group>
                    <Form.Control as="select" custom
                            id={ "date-range-selector" }
                            className="w-100 custom-select"
                            onChange={ onChangeDateRange }>
                            <option value="all">All</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="this_week">This Week</option>
                            <option value="this_month">This Month</option>
                            <option value="custom">Custom</option>
                    </Form.Control>
                </Form.Group>
                
                {
                    ui.showCustom &&
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label className="control-label text-left">{ fromLabel }</Form.Label>
                                <FormControl.DateTime
                                    value={ ui.from }
                                    id={ "date-selector-from" }
                                    onChange={ onChangeFilterFrom }>
                                </FormControl.DateTime>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className="control-label text-left"> { toLabel }</Form.Label>
                                <FormControl.DateTime
                                    value={ ui.to }
                                    id={ "date-selector-to" }
                                    onChange={ onChangeFilterTo }
                                    minDate={ui.from}>
                                </FormControl.DateTime>
                            </Form.Group>
                        </Form.Row>
                }
            </>
        )
    }

    if (!monitorFilter) {
        return (
            <>
                <Col sm>
                    <Form.Group>
                        <Form.Label>{ label }</Form.Label>
                        <Form.Control as="select"
                            id={ "date-range-selector" }
                            className="w-100 custom-select"
                            onChange={ onChangeDateRange }>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="this_week">This Week</option>
                            <option value="this_month">This Month</option>
                            <option value="all">All</option>
                            <option value="custom">Custom</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                {
                    ui.showCustom &&
                        <>
                            <Col sm>
                                <Form.Group>
                                    <Form.Label className="control-label text-left">{ fromLabel }</Form.Label>
                                    <FormControl.DateTime
                                        id={ "date-selector-from" }
                                        value={ ui.from }
                                        onChange={ onChangeFilterFrom }>
                                    </FormControl.DateTime>
                                </Form.Group>
                            </Col>
                            <Col sm>
                                <Form.Group>
                                    <Form.Label className="control-label text-left">{ toLabel }</Form.Label>
                                    <FormControl.DateTime
                                        value={ ui.to }
                                        id={ "date-selector-to" }
                                        onChange={ onChangeFilterTo }>
                                    </FormControl.DateTime>
                                </Form.Group>
                            </Col>
                        </>
                }
            </>
        );
    }
};

const Filter = ({ children }) => {
    return (
        <div className="search-wrap mb-3">
            <div className="card bg-dark p-3 shadow">
                <Form inline>
                    <Form.Row>
                        { children }
                       
                        <Col sm>
                            <Form.Label>&nbsp;</Form.Label>
                            <Button variant="primary">
                                <i className="fa fa-search"></i>
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        </div>
    );
};

Filter.Text = TextFilter;
Filter.DateRange = DateRangeFilter;

export default Filter;