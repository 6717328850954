import React from 'react';
import { Modal, Button, Nav, Tab } from 'react-bootstrap';
import { AgentQueuedWorkflowItem, AgentWorkflowItem, AgentWorkflowList } from 'components';
import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode';
import _ from 'underscore';

const AgentViewAllWorkflowsModal = ({ isShown, agent = {}, onClose, onExecute, onTerminate }) => {

    const getAgentName = (agent) => {
        if (agent) {
            return `${agent.firstName ?? ''} ${agent.lastName ?? ''}`;
        }

        return "Agent User";
    };

    return (
        <Modal show={isShown} onHide={onClose} scrollable={true} centered className="events-wrapper">
            <Modal.Header closeButton>
                <div className="modal-title">
                    <h5 className="m-0">{ getAgentName(agent) }</h5>
                    <p className="m-0"><small>{ agent.macAddress }</small></p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container defaultActiveKey="workflowList">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="workflowList">
                                <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_MODAL_WORKFLOW_LIST_TAB} />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="queuedWorkflowList">
                                <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_MODAL_ACTIVE_LIST_TAB} />
                                { agent.queuedWorkflows && agent.queuedWorkflows.length > 0 
                                    ? <span className="badge badge-primary ml-1">{ agent.queuedWorkflows.length }</span> 
                                    : null}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="workflowList">
                            <p className="pt-2">
                                <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_MODAL_WORKFLOW_LIST_TAB_DESC} />
                            </p>
                            <AgentWorkflowList>
                                { agent.workflows && agent.workflows.map(workflow =>
                                    <AgentWorkflowItem
                                        key={ workflow.id }
                                        workflow={ workflow }
                                        sessionId={ agent.sessionId }
                                        onExecute={ onExecute } />
                                )}
                            </AgentWorkflowList>
                        </Tab.Pane>

                        <Tab.Pane eventKey="queuedWorkflowList">
                            <p className="pt-2">
                                <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_MODAL_ACTIVE_LIST_TAB_DESC} />
                            </p>
                            <AgentWorkflowList>
                                {agent.queuedWorkflows && 
                                    _.sortBy(agent.queuedWorkflows, 'position')
                                    .map((workflow, index) =>
                                        <AgentQueuedWorkflowItem
                                            key={ workflow.id }
                                            workflow={ workflow }
                                            sessionId={ agent.sessionId }
                                            index={ index }
                                            onTerminate={ onTerminate }
                                        />
                                    )}
                            </AgentWorkflowList>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary"
                    type="button"
                    onClick={ onClose }>
                    <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_MODAL_CLOSE_BUTTON_LABEL} />
                </Button>
            </Modal.Footer>
        </Modal>
    );

};

export default AgentViewAllWorkflowsModal;