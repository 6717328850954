import React from 'react';

const Token = ({ label, value }) => {
    return (
        <span className="token"><small>{ label }:</small> <br/>{ value }</span>
    );
};

const MonitorFilterDisplay = ({ date, department, name, role }) => {
    return (
        <>
            Showing 
            <Token label="Date" value={ date } />
            <Token label="Department" value={ department } />
            <Token label="Name" value={ name } />
            <Token label="Role" value={ role } />
        </>
    );
};

export default MonitorFilterDisplay;