import ActionType from 'constants/ActionType';

export default {
    requestWorkflowList: () => {
        return {
            type: ActionType.WORKFLOW_LIST_REQUEST,
        };
    },

    successWorkflowList: (workflowList) => {
        return {
            type: ActionType.WORKFLOW_LIST_SUCCESS,
            payload: { workflowList },
        };
    },

    failureWorkflowList: (error) => {
        return {
            type: ActionType.WORKFLOW_LIST_ERROR,
            payload: { error },
        };
    },

    requestExceptionList: () => {
        return {
            type: ActionType.WORKFLOW_EXCEPTION_LIST_REQUEST,
        };
    },

    successExceptionList: (exceptionList) => {
        return {
            type: ActionType.WORKFLOW_EXCEPTION_LIST_SUCCESS,
            payload: { exceptionList },
        };
    },

    failureExceptionList: (error) => {
        return {
            type: ActionType.WORKFLOW_EXCEPTION_LIST_FAILURE,
            payload: { error },
        };
    },

    requestExecutionLogs: () => {
        return {
            type: ActionType.WORKFLOW_EXECUTION_LOGS_REQUEST,
        };
    },

    successExecutionLogs: (executionLogs) => {
        return {
            type: ActionType.WORKFLOW_EXECUTION_LOGS_SUCCESS,
            payload: { executionLogs },
        }
    },

    failureExecutionLogs: (error) => {
        return {
            type: ActionType.WORKFLOW_EXECUTION_LOGS_FAILURE,
            payload: { error },
        };
    },

    successActiveAgentList: (agentList) => {
        return {
            type: ActionType.WORKFLOW_AGENT_LIST_SUCCESS,
            payload: { agentList },
        };
    },

    failureActiveAgentList: (error) => {
        return {
            type: ActionType.WORKFLOW_AGENT_LIST_FAILURE,
            payload: { error },
        };
    },

};