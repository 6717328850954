export default {
    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
    USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
    USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

    USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
    USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

    USER_VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
    USER_VERIFY_FAILURE: 'USER_VERIFY_FAILURE',

    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_FAILURE: 'USER_LIST_FAILURE',

    USER_SUBORDINATES_SUCCESS: 'USER_SUBORDINATES_SUCCESS',
    USER_SUBORDINATES_FAILURE: 'USER_SUBORDINATES_FAILURE',

    MONITOR_WIDGETS_LIST_SUCCESS: 'MONITOR_WIDGETS_LIST_SUCCESS',
    MONITOR_WIDGETS_LIST_FAILURE: 'MONITOR_WIDGETS_LIST_FAILURE',

    USER_VISIBLE_WIDGETS_SUCCESS: 'USER_VISIBLE_WIDGETS_SUCCESS',
    USER_VISIBLE_WIDGETS_FAILURE: 'USER_VISIBLE_WIDGETS_FAILURE',

    DASHBOARD_TOGGLE_SIDEBAR: 'DASHBOARD_TOGGLE_SIDEBAR',

    AGENT_METRICS_REQUEST: 'AGENT_METRICS_REQUEST',
    AGENT_METRICS_SUCCESS: 'AGENT_METRICS_SUCCESS',
    AGENT_METRICS_FAILURE: 'AGENT_METRICS_FAILURE',

    AGENT_EXCEPTION_LIST_REQUEST: 'AGENT_WORKFLOW_ERRORS_REQUEST',
    AGENT_EXCEPTION_LIST_SUCCESS: 'AGENT_WORKFLOW_ERRORS_SUCCESS',
    AGENT_EXCEPTION_LIST_FAILURE: 'AGENT_WORKFLOW_ERRORS_FAILURE',

    AGENT_USECOUNT_LIST_REQUEST: 'AGENT_WORKFLOW_USE_COUNT_REQUEST',
    AGENT_USECOUNT_LIST_SUCCESS: 'AGENT_WORKFLOW_USE_COUNT_SUCCESS',
    AGENT_USECOUNT_LIST_FAILURE: 'AGENT_WORKFLOW_USE_COUNT_FAILURE',

    AGENT_EXECUTION_TIME_LIST_REQUEST: 'AGENT_EXECUTION_TIME_LIST_REQUEST',
    AGENT_EXECUTION_TIME_LIST_SUCCESS: 'AGENT_EXECUTION_TIME_LIST_SUCCESS',
    AGENT_EXECUTION_TIME_LIST_FAILURE: 'AGENT_EXECUTION_TIME_LIST_FAILURE',

    COMPANY_REQUEST: 'COMPANY_REQUEST',
    COMPANY_SUCCESS: 'COMPANY_SUCCESS',
    COMPANY_FAILURE: 'COMPANY_FAILURE',
    
    COMPANY_LIST_REQUEST: 'COMPANY_LIST_REQUEST',
    COMPANY_LIST_SUCCESS: 'COMPANY_LIST_SUCCESS',
    COMPANY_LIST_FAILURE: 'COMPANY_LIST_FAILURE',

    INDUSTRY_LIST_REQUEST: 'INDUSTRY_LIST_REQUEST',
    INDUSTRY_LIST_SUCCESS: 'INDUSTRY_LIST_SUCCESS',
    INDUSTRY_LIST_FAILURE: 'INDUSTRY_LIST_FAILURE',

    LICENSE_REQUEST: 'LICENSE_REQUEST',
    LICENSE_SUCCESS: 'LICENSE_SUCCESS',
    LICENSE_FAILURE: 'LICENSE_FAILURE',
    
    LICENSE_LIST_REQUEST: 'LICENSE_LIST_REQUEST',
    LICENSE_LIST_SUCCESS: 'LICENSE_LIST_SUCCESS',
    LICENSE_LIST_FAILURE: 'LICENSE_LIST_FAILURE',

    LICENSE_KEY_LIST_REQUEST: 'LICENSE_KEY_LIST_REQUEST',
    LICENSE_KEY_LIST_SUCCESS: 'LICENSE_KEY_LIST_SUCCESS',
    LICENSE_KEY_LIST_FAILURE: 'LICENSE_KEY_LIST_FAILURE',
    
    PREMIUM_LICENSE_LIST_REQUEST: 'PREMIUM_LICENSE_LIST_REQUEST',
    PREMIUM_LICENSE_LIST_SUCCESS: 'PREMIUM_LICENSE_LIST_SUCCESS',
    PREMIUM_LICENSE_LIST_FAILURE: 'PREMIUM_LICENSE_LIST_FAILURE',

    WORKFLOW_LIST_REQUEST: 'WORKFLOW_LIST_REQUEST',
    WORKFLOW_LIST_SUCCESS: 'WORKFLOW_LIST_SUCCESS',
    WORKFLOW_LIST_FAILURE: 'WORKFLOW_LIST_FAILURE',

    ACTIVITY_LIST_REQUEST: 'ACTIVITY_LIST_REQUEST',
    ACTIVITY_LIST_SUCCESS: 'ACTIVITY_LIST_SUCCESS',
    ACTIVITY_LIST_FAILURE: 'ACTIVITY_LIST_FAILURE',

    WORKFLOW_EXCEPTION_LIST_REQUEST: 'WORKFLOW_EXCEPTION_LIST_REQUEST',
    WORKFLOW_EXCEPTION_LIST_SUCCESS: 'WORKFLOW_EXCEPTION_LIST_SUCCESS',
    WORKFLOW_EXCEPTION_LIST_FAILURE: 'WORKFLOW_EXCEPTION_LIST_FAILURE',

    WORKFLOW_EXECUTION_LOGS_REQUEST: 'WORKFLOW_EXECUTION_LOGS_REQUEST',
    WORKFLOW_EXECUTION_LOGS_SUCCESS: 'WORKFLOW_EXECUTION_LOGS_SUCCESS',
    WORKFLOW_EXECUTION_LOGS_FAILURE: 'WORKFLOW_EXECUTION_LOGS_FAILURE',

    WORKFLOW_AGENT_LIST_REQUEST: 'WORKFLOW_AGENT_LIST_REQUEST',
    WORKFLOW_AGENT_LIST_SUCCESS: 'WORKFLOW_AGENT_LIST_SUCCESS',
    WORKFLOW_AGENT_LIST_FAILURE: 'WORKFLOW_AGENT_LIST_FAILURE',

    ACCOUNT_SOLO_LIST_REQUEST: 'ACCOUNT_SOLO_LIST_REQUEST',
    ACCOUNT_SOLO_LIST_SUCCESS: 'ACCOUNT_SOLO_LIST_SUCCESS',
    ACCOUNT_SOLO_LIST_FAILURE: 'ACCOUNT_SOLO_LIST_FAILURE',

    DEPARTMENT_LIST_SUCCESS: 'DEPERTMENT_LIST_SUCCESS',
    DEPARTMENT_LIST_FAILURE: 'DEPERTMENT_LIST_FAILURE',
    DEPARTMENT_LIST_FOR_DISPLAY_SUCCESS: 'DEPARTMENT_LIST_FOR_DISPLAY_SUCCESS',
    DEPARTMENT_LIST_FOR_DISPLAY_FAILURE: 'DEPARTMENT_LIST_FOR_DISPLAY_FAILURE',
    DEPARTMENT_LIST_FOR_EXPANSION_SUCCESS: 'DEPARTMENT_LIST_FOR_EXPANSION_SUCCESS',

    AUTHORITY_LIST_SUCCESS: 'AUTHORITY_LIST_SUCCESS',
    AUTHORITY_LIST_FAILURE: 'AUTHORITY_LIST_FAILURE',

    ROLE_LIST_SUCCESS: 'ROLE_LIST_SUCCESS',
    ROLE_LIST_FAILURE: 'ROLE_LIST_FAILURE',
    
    USER_GROUP_LIST_SUCCESS: 'USER_GROUP_LIST_SUCCESS',
    USER_GROUP_LIST_FAILURE: 'USER_GROUP_LIST_FAILURE',

    STATUS_LIST_SUCCESS: 'STATUS_LIST_SUCCESS',
    STATUS_LIST_FAILURE: 'STATUS_LIST_FAILURE',

    TASK_LIST_SUCCESS: 'TASK_LIST_SUCCESS',
    TASK_LIST_FAILURE: 'TASK_LIST_FAILURE',

    SCHEDULE_LIST_SUCCESS: 'TASK_LIST_SUCCESS',
    SCHEDULE_LIST_FAILURE: 'TASK_LIST_FAILURE',

    DIRECTORY_LIST_SUCCESS: 'DIRECTORY_LIST_SUCCESS',
    DIRECTORY_LIST_FAILURE: 'DIRECTORY_LIST_FAILURE',

    LICENSE_FILE_LIST_SUCCESS: 'LICENSE_FILE_LIST_SUCCESS',
    LICENSE_FILE_LIST_FAILURE: 'LICENSE_FILE_LIST_FAILURE',
    LICENSE_FILE_LIST_CLEAR: 'LICENSE_FILE_LIST_CLEAR',

    ACTIVE_AGENT_SET_SESSION_INFO: 'ACTIVE_AGENT_SET_SESSION_INFO',
    ACTIVE_AGENT_ADD_CONNECTION: 'ACTIVE_AGENT_CONNECT',
    ACTIVE_AGENT_REMOVE_CONNECTION: 'ACTIVE_AGENT_DISCONNECT',
    ACTIVE_AGENT_CLEAR_CONNECTIONS: 'ACTIVE_AGENT_CLEAR_CONNECTIONS',
    ACTIVE_AGENT_ADD_WORKFLOWS: 'ACTIVE_AGENT_ADD_WORKFLOWS',
    ACTIVE_AGENT_UPDATE_WORKFLOWS: 'ACTIVE_AGENT_UPDATE_WORKFLOWS',
    ACTIVE_AGENT_REMOVE_WORKFLOWS: 'ACTIVE_AGENT_REMOVE_WORKFLOWS',
    ACTIVE_AGENT_ADD_QUEUED_WORKFLOWS: 'ACTIVE_AGENT_ADD_QUEUED',
    ACTIVE_AGENT_UPDATE_QUEUED_WORKFLOWS: 'ACTIVE_AGENT_UPDATE_QUEUED',
    ACTIVE_AGENT_REMOVE_QUEUED_WORKFLOWS: 'ACTIVE_AGENT_REMOVE_QUEUED',
};