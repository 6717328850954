import React from 'react';

const MonitorHeader = ({ children }) => {
    return (
        <div className="col-sm-12 mb-3">
            <div className="filter-wrap p-2 shadow">
                <div className="col">
                    { children }
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    );
};

export default MonitorHeader;