import ActionType from 'constants/ActionType';

export default {    
    successDepartmentList: (departmentList) => {
        return {
            type: ActionType.DEPARTMENT_LIST_SUCCESS,
            payload: { departmentList },
        };
    },

    failureDepartmentList: (error) => {
        return {
            type: ActionType.DEPARTMENT_LIST_FAILURE,
            payload: { error },
        };
    },

    successDepartmentListForDisplay: (departmentListForDisplay) => {
        return {
            type: ActionType.DEPARTMENT_LIST_FOR_DISPLAY_SUCCESS,
            payload: { departmentListForDisplay },
        };
    },

    failureDepartmentListForDisplay: (error) => {
        return {
            type: ActionType.DEPARTMENT_LIST_FOR_DISPLAY_FAILURE,
            payload: { error },
        };
    },

    successDepartmentListForExpansion: (departmentListForExpansion) =>{
        return {
            type: ActionType.DEPARTMENT_LIST_FOR_EXPANSION_SUCCESS,
            payload: { departmentListForExpansion }
        }
    },
};