import React, { useState } from 'react';

import { Trans } from 'react-i18next';
import queryString from 'query-string'

import MessageCode from 'constants/MessageCode';
import { LoginForm, ForgotPasswordForm, ForgotPasswordSuccess, ResetPasswordForm } from '../../components';

const LoginScreen = ({ location }) => {

    let initialUserFormState = 'USER_LOGIN_FORM';
    const resetPasswordKey = queryString.parse(location.search).resetPasswordKey;
    if (resetPasswordKey) {
        initialUserFormState = 'USER_RESET_PASSWORD_FORM';
    }

    const [userFormState, setUserFormState] = useState(initialUserFormState);

    const UserForm = () => {
        switch (userFormState) {
            case 'USER_LOGIN_FORM':
                return <LoginForm
                    onForgotPassword={() => setUserFormState('USER_FORGOT_PASSWORD_FORM')} />;

            case 'USER_FORGOT_PASSWORD_FORM':
                return <ForgotPasswordForm
                    onClickLoginLink={() => setUserFormState('USER_LOGIN_FORM')}
                    onSuccess={() => setUserFormState('USER_FORGOT_PASSWORD_SUCCESS')} />;

            case 'USER_FORGOT_PASSWORD_SUCCESS':
                return <ForgotPasswordSuccess headerLabel={MessageCode.FORGOT_PASSWORD_SUCCESS_HEADER}
                    messageLabel={MessageCode.FORGOT_PASSWORD_SUCCESS_MESSAGE}
                    linkLabel={MessageCode.FORGOT_PASSWORD_SUCCESS_LINK}
                    onClickLink={() => setUserFormState('USER_LOGIN_FORM')} />

            case 'USER_RESET_PASSWORD_FORM':
                return <ResetPasswordForm resetPasswordKey={resetPasswordKey}
                    onSuccess={() => setUserFormState('USER_RESET_PASSWORD_SUCCESS')} />;

            case 'USER_RESET_PASSWORD_SUCCESS':
                return <ForgotPasswordSuccess headerLabel={MessageCode.RESET_PASSWORD_SUCCESS_HEADER}
                    messageLabel={MessageCode.RESET_PASSWORD_SUCCESS_MESSAGE}
                    linkLabel={MessageCode.RESET_PASSWORD_SUCCESS_LINK}
                    onClickLink={() => setUserFormState('USER_LOGIN_FORM')} />

            default:
                return null;
        }
    };

    return (
        <div className="container-fluid login-wrap">
            <div className="row justify-content-center">
                <div className="col-sm-6 bg-img" style={ 
                    {
                        'background': `url(${process.env.PUBLIC_URL}/img/login-bg.jpg) no-repeat transparent`,
                        'backgroundSize': 'cover',
                        'backgroundPosition': 'left center',  
                    }
                }>
                    <h1 className="display-4 p-5 mt-5">
                        <Trans i18nKey={MessageCode.LOGIN_HEADER}>
                            <strong>RAX <span className="brand-yellow">Suite</span></strong>
                            Command Center
                        </Trans>
                    </h1>
                    <h2 className="p-5">
                        <Trans i18nKey={MessageCode.LOGIN_SUBHEADER} />
                    </h2>
                    <div className="p-5">
                        <p>
                            <Trans i18nKey={MessageCode.LOGIN_SUPPORT_TEXT} />
                        </p>
                        <p className="lead">
                            <a href="http://support.raxsuite.com/support/home">
                                <i className="fa fa-question-circle"></i>
                                <Trans i18nKey={MessageCode.LOGIN_SUPPORT_LINK} />
                            </a>
                        </p>
                    </div>
                </div>
                <div className="col-sm-6 login">
                    <UserForm />
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    );
};

export default LoginScreen;
