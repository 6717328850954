import ActionType from 'constants/ActionType';

const initialState = {
    taskList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.TASK_LIST_SUCCESS: {
            return {
                ...state,
                taskList: action.payload.taskList,
            };
        }

        case ActionType.TASK_LIST_FAILURE: {
            return {
                ...state,
                taskList: initialState.taskList,
            };
        }

        default: {
            return state;
        }
    }
};