import React from 'react';
import _ from 'underscore';
import LengthLimit from 'constants/LengthLimit';
import { Tooltip } from 'components';

const Item = ({ active, children, index, navClickHandler }) => {
    return (
        <>
            { active
                ? <button className="btn font-weight-bold" onClick={() => navClickHandler(index)}><span className="brand-lt-blue">{ children }</span></button>
                : <button className="btn"><span className="brand-blue" onClick={() => navClickHandler(index)}>{ children }</span></button>
            }
        </>
    );
};

const BreadcrumbNavigation = ({ navList, navClickHandler }) => {
    const navLength = navList.length;
    const NAV_LIMIT = 5;

    const lengthDifference = Math.max(navLength - NAV_LIMIT + 1, 0)
    let renderedData = navList.filter((nav, index) => {
        if(index > navLength - NAV_LIMIT) {
            return nav;
        }
    })
    return(
        <h5 className="text-secondary breadcrumb-nav">
        {
            navLength > NAV_LIMIT-1 &&
                <>
                    <button className="btn" style={{ letterSpacing: "2px"}} onClick={() => navClickHandler(navLength - NAV_LIMIT)}>
                        <Tooltip placement="top" value={navList[lengthDifference-1]}>
                            <span className="brand-blue font-weight-bold">...</span>
                        </Tooltip>
                    </button> >
                </>
        }
        {
            _.map(renderedData, (nav, index) => {
                if (index === renderedData.length - 1){
                    return(
                        <button key={index} className="btn font-weight-bold" onClick={() => navClickHandler(index + lengthDifference)}>
                            {
                                nav.length > LengthLimit.NAVIGATION_DISPLAY_NAME_LIMIT &&
                                    <Tooltip placement="top" value={nav}>
                                        <span className="brand-lt-blue">{nav.substring(0, LengthLimit.NAVIGATION_DISPLAY_NAME_LIMIT) + "..."}</span>
                                    </Tooltip>
                            }

                            {
                                nav.length < LengthLimit.NAVIGATION_DISPLAY_NAME_LIMIT &&
                                    <span className="brand-lt-blue">{nav}</span>
                            }
                        </button>
                    )
                } else {
                    return(
                        <span key={index}>
                            <button className="btn" onClick={() => navClickHandler(index + lengthDifference)}>
                                {
                                    nav.length > LengthLimit.NAVIGATION_DISPLAY_NAME_LIMIT &&
                                        <Tooltip placement="top" value={nav}>
                                            <span className="brand-blue">{nav.substring(0, LengthLimit.NAVIGATION_DISPLAY_NAME_LIMIT) + "..."}</span>
                                        </Tooltip>
                                }

                                {
                                    nav.length < LengthLimit.NAVIGATION_DISPLAY_NAME_LIMIT &&
                                        <span className="brand-blue">{nav}</span>
                                }
                            </button> >
                        </span>
                    )
                }
            })
        }
        </h5>
    )
};

BreadcrumbNavigation.Item = Item;

export default BreadcrumbNavigation;