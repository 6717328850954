import ActionType from 'constants/ActionType';

export default {    
    successTaskList: (taskList) => {
        return {
            type: ActionType.TASK_LIST_SUCCESS,
            payload: { taskList },
        };
    },

    failureTaskList: (error) => {
        return {
            type: ActionType.TASK_LIST_FAILURE,
            payload: { error },
        };
    }
};