/* eslint no-eval: 0 */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';
import Q from 'q';

import { StatusService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Toast, FormControl } from 'components';

const statusNameSelector = (event) => event ? event.target.value : null;
const statusNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_GENERAL_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_GENERAL_NAME_TOO_LONG;
    }

    return null;
};

const statusDepartmentSelector = (event) => event ? event.target.value: null;
const statusDepartmentValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_STATUS_DEPARTMENT_REQUIRED;
    }

    return null;
};

const thresholdSelector = (event) => {
    return event ? event.target.value = +event.target.value : null;
};

const thresholdValidator = (value) => {
    const threshold = eval(value = +value);
    if (threshold < 0 || threshold > 60 || threshold%1 !== 0) {
        return MessageCode.ERROR_STATUS_THRESHOLD_INVALID;
    }

    return null;
};

const colorSelector = (event) => {
    return event ? event.target.value : null;
};

const colorValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_STATUS_COLOR_REQUIRED;
    }

    return null;
};

const iconSelector = (event) => { 
    return event ? event.target.files[0] : null; 
};

const iconValidator = async (value) => {
    
    if (!value) {
        return MessageCode.ERROR_STATUS_ICON_REQUIRED;
    }

    if (!value.name.toLowerCase().endsWith('.png')) {
        return MessageCode.ERROR_STATUS_ICON_UNSUPPORTED;
    }

    var deferred = Q.defer();
    if (value.size) {
        if (value.size > 512000) { // 512KB === 512000B, file size is in bytes
            return MessageCode.ERROR_STATUS_ICON_TOO_BIG;
        }
    
        // checking dimension of image
        var img = new Image();
        img.src = window.URL.createObjectURL(value);
    
        
        img.onload = await function() {
            var width = img.naturalWidth,
                height = img.naturalHeight;
    
            if (!(width === height && (width === 24 || width === 48 || width === 128))) {
                deferred.resolve(MessageCode.ERROR_STATUS_ICON_INVALID_DIMENSION);           
            } else {
                deferred.resolve(null);
            }
        }
        return deferred.promise;
    } 

    deferred.resolve(null);
    return deferred.promise;

};

const onInitialize = (statusName, department, threshold, color, icon, setTrackIdle, initialValues, setGeneralError) => {
    return () => {
        if (initialValues){
            statusName.setValue(initialValues.name || '');
            department.setValue((initialValues.department ? initialValues.department.id : 'default'));
            threshold.setValue(initialValues.threshold || '0');
            color.setValue(initialValues.color || '');
            setTrackIdle(initialValues.trackIdle);
            icon.setValue({ name: initialValues.fileName });
        }
        setGeneralError(null);
    };
 };

const StatusUpdateModal = ( {isShown, onClose, onSuccess, departmentList, initialValues} ) => {
    const [onChangeStatusName, statusName] = useValidation(statusNameSelector, statusNameValidator, '');
    const [onChangeDepartment, department] =  useValidation(statusDepartmentSelector, statusDepartmentValidator, 'default');
    const [onChangeThreshold, threshold] = useValidation(thresholdSelector, thresholdValidator, '0');
    const [colorOnChangeColor, color] = useValidation(colorSelector, colorValidator, '#000000');
    const [onChangeIcon, icon] = useValidation(iconSelector, iconValidator, null);
    const [trackIdle, setTrackIdle] = useState(false);
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false);
    const {t} = useTranslation();

    useEffect(onInitialize(statusName, department,
        threshold, color, icon, setTrackIdle,
        initialValues, setGeneralError), [ JSON.stringify(initialValues) ]);

    const isTrackIdle = () => {
        setTrackIdle(!trackIdle);
    }

    const onInputClick = (event) => {
        event.target.value = null
    }

    const resetFields = () => {
        statusName.reset('');
        department.reset('default');
        threshold.reset(0);
        color.reset('#000000');
        icon.reset(null);
        setTrackIdle(false);
    };

    const onCancel = () => {
        resetFields();
        onClose();
    };

    const getBase64 = async (file) => {
        var deferred = Q.defer();
        if (file.size) {
            var reader  = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = await function () {
                deferred.resolve(reader.result);
            };
            return deferred.promise;
        }

        deferred.resolve(null);
        return deferred.promise;       
    }

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);
     
            Promise.resolve(getBase64(icon.value)).then( async(iconBase64) => {
                try {
                    await StatusService.updateStatus(initialValues.id, statusName.value,
                        department.value, threshold.value, color.value,
                        iconBase64 ? iconBase64 : initialValues.icon,
                        trackIdle, icon.value.name);

                    toast.success(
                        <Toast data={{ name: statusName.value }}
                            message={MessageCode.STATUS_MESSAGE_UPDATE_SUCCESS}>
                        </Toast>
                    );
                    
                    setTimeout(() => {
                        setDisabled(false);
                    }, 1000);

                    resetFields();
                    onSuccess();
                } catch ({data}){
                    setDisabled(false);
                    switch (data.key) {
                        case MessageCode.ERROR_STATUS_INACCESSIBLE :
                            toast.error(
                                <Toast data={{ name: statusName.value }}
                                    message={data.key}>
                                </Toast>
                            );
                            break;
        
                        default: 
                            toast.error(
                                <Trans i18nKey={ MessageCode.ERROR_GENERAL_INTERNAL } />
                            );
                    }
                    onClose();
                }
            });    
        }
    };

    return (
        <Modal show={ isShown } onHide={onCancel} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.STATUS_MODAL_HEADER_UPDATE  } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.GENERAL_SUBTEXT_REQUIRED } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_LABEL_NAME } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-update-status-field" }
                            value={statusName.value}
                            onChange={ onChangeStatusName }
                            isInvalid={ statusName.dirty && statusName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ statusName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.STATUS_MODAL_LABEL_DEPARTMENT } />
                        </Form.Label>
                        <Form.Control as="select"
                            className='custom-select'
                            id={ "department-update-status-field" }
                            value={department.value}
                            onChange={ onChangeDepartment }
                            isInvalid={ department.dirty && department.error}>
                            <option value='default' disabled>{t(MessageCode.DEFAULT_DEPARTMENT)}</option>
                            { departmentList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ department.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.STATUS_MODAL_LABEL_ICON } />
                            <small className="text-muted">
                                <Trans i18nKey={ MessageCode.STATUS_MODAL_SUBTEXT_ICON } />
                            </small>
                        </Form.Label>
                        
                        <FormControl.File
                            accept=".png" onClick={ onInputClick }
                            id={ "icon-update-status-field" }
                            value={ icon.value ? icon.value.name : '' }
                            onChange={ onChangeIcon }
                            isInvalid={ icon.dirty && icon.error }>

                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={ icon.error } />
                            </Form.Control.Feedback>
                        </FormControl.File>
                    </Form.Group>
                
                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group> 

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.STATUS_MODAL_LABEL_THRESHOLD } />
                            </Form.Label>
                            <Form.Control as="input" 
                                id={ "threshold-update-status-field" }
                                type="number" min="0" max="60"
                                value={ threshold.value || '0' }
                                onChange={ onChangeThreshold }
                                isInvalid={ threshold.dirty && threshold.error }>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={ threshold.error } />
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.STATUS_MODAL_LABEL_COLOR} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="color"
                                id={ "color-update-status-field" }
                                value={color.value || '#000000'}
                                onChange={colorOnChangeColor}
                                isInvalid={color.dirty && color.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={color.error} />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm="2">
                            <Form.Label>
                                <Trans i18nKey={MessageCode.STATUS_MODAL_LABEL_TRACK_IDLE} />
                            </Form.Label>
                            <Form.Control 
                                type="checkbox"
                                id={ "idle-update-status-field" }
                                checked={trackIdle || false}
                                onChange={isTrackIdle}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={color.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-update-status-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CLEAR } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-update-status-btn" }
                        disabled={ disabled || statusName.error || department.error || threshold.error || color.error || icon.error }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_UPDATE } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default StatusUpdateModal;