import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { DepartmentAction, UserAction, RoleAction } from 'actions';
import { DepartmentService, UserService, RoleService } from 'services';

import {
    MonitorHeader, 
    MonitorFilterDisplay,
    MonitorFilterModal, 
    MonitorWidgetModal, 
    MonitorWidgetTable,
} from 'components';

import RoutePath from 'constants/RoutePath';

const defaultDepartment = "All";
const defaultRole = "All";
const defaultUser = "All";
const defaultDateRange = "Today";

const getDepartmentList = async (dispatch) => {
    try {
        const { data } = await DepartmentService.getAccessibleDepartmentList();
        dispatch(DepartmentAction.successDepartmentList(data));
    }
    catch (error) {
        dispatch(DepartmentAction.failureDepartmentList(error));
    }
};

const getUserList = async (dispatch) => {
    try {
        const current_user = await UserService.getCurrentUser();
        const { data } = await UserService.getSubordinates(current_user.data.id);
        dispatch(UserAction.successSubordinates(data));
    }
    catch (error) {
        dispatch(UserAction.failureSubordinates(error));
    }
};

const getRoleList = async (dispatch) => {
    try {
        const { data } = await RoleService.getRolesList();
        dispatch(RoleAction.successRoleList(data));

    } catch (error) {
        dispatch(RoleAction.failureRoleList(error));
    }
};

const getMonitorWidgetsList = async (dispatch) => {
    try {
        const { data } = await UserService.getMonitorWidgetList();
        dispatch(UserAction.successMonitorWidgetsList(data));
    } catch (error) {
        dispatch(UserAction.failureMonitorWidgetsList(error));
    }
}

const getVisibleWidgets = async (dispatch) => {
    try {
       const current_user = await UserService.getCurrentUser();
        const { data } = await UserService.getUserVisibleWidgets(current_user.data.id);;
       
        const groupedWidgets = [[], []]
        data.forEach(widget => {
            if(widget.widgetOrder > 0){
                groupedWidgets[widget.widgetColumnNumber-1].push(widget.widgetKey)
            }
        });
        setTimeout(() => {
            dispatch(UserAction.successUserVisibleWidgets(groupedWidgets));
        }, 200);

    } catch (error) {
        dispatch(UserAction.failureUserVisibleWidgets(error));
    }
};

const getDateRangeType = (dateRange) => {
    if (dateRange) {
        if (
            dateRange[0] === moment().startOf('day').valueOf() &&
            dateRange[1] === moment().startOf('day').add(1, 'day').valueOf()) {
            return "Today";
        } 
        else if (
            dateRange[0] === moment().startOf('day').subtract(1, 'day').valueOf() &&
            dateRange[1] === moment().startOf('day').valueOf()) {
            return "Yesterday";
        } 
        else if (
            dateRange[0] === moment().startOf('week').valueOf() &&
            dateRange[1] === moment().startOf('week').add(1, 'week').valueOf()) {
            return "This Week";
        }
        else if (
            dateRange[0] === moment().startOf('month').valueOf() &&
            dateRange[1] === moment().startOf('month').add(1, 'month').valueOf()) {
            return "This Month";
        }
    }

    return "Custom";
}

const findById = (list, item_id) => {
    if(list && item_id) {
        return list.find( ({ id }) => id == item_id );
    } 
    return null;
}

const initializeFilters = (uiState, setUiState, history, departmentFilter, roleFilter, userFilter, dateFrom, dateTo) => {
    return () => {

        if (!dateFrom || !dateTo) {
            let urlQuery = `${RoutePath.REPORTS_MONITOR_URL}?`;
            urlQuery += `dateFrom=${moment().startOf('day').valueOf()}`;
            urlQuery += `&dateTo=${moment().startOf('day').add(1, 'day').valueOf()}`;
            history.push(urlQuery);
        }

        setUiState({
            ...uiState,
            dateFilter: [parseInt(dateFrom), parseInt(dateTo)],
            departmentFilter: departmentFilter,
            roleFilter: roleFilter,
            nameFilter: userFilter
        });
    }
    
}

const onInitialize = (dispatch) => {
    return () => {
        getDepartmentList(dispatch);
        getUserList(dispatch);
        getRoleList(dispatch);
        getMonitorWidgetsList(dispatch);
        getVisibleWidgets(dispatch);
    };
};

const ReportMonitorScreen = () => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [ uiState, setUiState ] = useState({
        showFilterModal: false,
        showWidgetModal: false,
    });

    const widgetsList = useSelector(state => state.user.monitorWidgets);
    const visibleWidgets = useSelector(state => state.user.visibleWidgets);
    const userList = useSelector(state => state.user.subordinates);
    const departmentList = useSelector(state => state.department.departmentList);
    const roleList = useSelector(state => state.role.roleList);

    const params = new URLSearchParams(location.search);
    const initName = findById(userList, params.get("user"));
    const initDepartment = findById(departmentList, params.get("department"));
    const initRole = findById(roleList, params.get("role"));
    const initDateFrom = params.get("dateFrom");
    const initDateTo = params.get("dateTo");

    useEffect(onInitialize(dispatch), []);
    useEffect(initializeFilters(uiState, setUiState, history, initDepartment, initRole, initName, initDateFrom, initDateTo ), 
    [initDepartment, initRole, initName, initDateFrom, initDateTo]);
    
    const closeFilterModal = () => {
        setUiState({
            ...uiState,
            showFilterModal: false,
        });
    };

    const applyFilter = async (data) => {
        const departmentFilter = (data.department !== "default" ? 
                await DepartmentService.getSpecificDepartment(data.department) : null);
        const roleFilter = (data.role !== "default" ? 
                await RoleService.getSpecificRole(data.role) : null);
        const userFilter = (data.user !== null && data.user !== "default"? 
                await UserService.getSpecificUser(data.user) : null);
               
        setUiState({
            ...uiState,
            showFilterModal: false,
            departmentFilter: departmentFilter? departmentFilter.data : null,
            roleFilter: roleFilter ? roleFilter.data : null,
            dateFilter: data.dateRange,
            nameFilter: userFilter ? userFilter.data : null
        });

        let urlQuery = `${RoutePath.REPORTS_MONITOR_URL}?dateFrom=${data.dateRange[0]}&dateTo=${data.dateRange[1]}`;
        urlQuery += (departmentFilter ? `&department=${departmentFilter.data.id}` : '');
        urlQuery += (roleFilter ? `&role=${roleFilter.data.id}` : '');
        urlQuery += (userFilter ? `&user=${userFilter.data.id}` : '');
        history.push(urlQuery);
    };

    const openFilterModal = async () => {

        if (userList && !userList.find( ({ id }) => id === "default" )) {
            let userDefault = { completeName: "All", id: "default" }
            userList.unshift(userDefault);
        }
        
        const data = {
            department: params.get("department"),
            role: params.get("role"),
            dateRange: [parseInt(params.get("dateFrom")), parseInt(params.get("dateTo"))],
            user: params.get("user")
        }

        setUiState({
            ...uiState,
            showFilterModal: true,
            filters: data
        });
    };


    const closeWidgetModal = () => {
        setUiState({
            ...uiState,
            showWidgetModal: false,
        });
    };

    const openWidgetModal = () => {
        setUiState({
            ...uiState,
            showWidgetModal: true,
        });
    };

    const onSuccessUpdateWidgets = async () => {
        getVisibleWidgets(dispatch);
        closeWidgetModal();
    };

    const statusSummaryOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: 'Aug 01-07, 2019'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                showInLegend: true
            }
        },
        credits: {
            enabled: false
        }, 
        series: [{
            name: 'Percentage',
            colorByPoint: true,
            data: [{
                name: 'Active',
                y: 61.41,
                sliced: true,
                selected: true
            }, {
                name: 'Idle',
                y: 11.84
            }, {
                name: 'Inactive',
                y: 10.85
            }, {
                name: 'On Break',
                y: 4.67
            }, {
                name: 'Toilet',
                y: 4.18
            }, {
                name: 'Meeting',
                y: 1.64
            }, {
                name: 'On The Phone',
                y: 1.6
            }, {
                name: 'Lunch/Dinner',
                y: 1.2
            }, {
                name: 'Aux',
                y: 2.61
            }]
        }]
    };

    const employeeLoginHoursOptions = {

        chart: {
            type: 'column'
        },
    
        title: {
            text: ''
        },
        subtitle: {
            text: 'AUG 01-07, 2019'
        },
        xAxis: {
            categories: ['Chris Lee']
        },
    
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: ''
            }
        },
    
        tooltip: {
            formatter: function () {
                return '<b>' + this.x + '</b><br/>' +
                    this.series.name + ': ' + this.y + '<br/>' +
                    'Total: ' + this.point.stackTotal;
            }
        },
    
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Login Hours',
            data: [5],
            stack: 'login'
        }, {
            name: 'Idle Hours',
            data: [3],
            stack: 'login'
        }, {
            name: 'Overtime Hours',
            data: [2],
            stack: 'logout'
        }, {
            name: 'Active Hours',
            data: [3],
            stack: 'logout'
        }]
    };

    const widgetsOptions = {
        'action_timeline' : null,
        'application_frequency' : null,
        'employee_login_hours' : employeeLoginHoursOptions,
        'top_applications' : null,
        'active_users' : null,
        'application_timeline' : null,
        'employee_utilization' : null,
        'top_statuses' : null,
        'alerts' : null,
        'attendance_and_tardiness' : null,
        'hourly_activity' : null,
        'top_tasks' : null,
        'application_duration' : null,
        'break_adherence' : null,
        'task_productivity' : null,
        'status_summary': statusSummaryOptions
    }

    return (
        <>
            <MonitorHeader>
                <MonitorFilterDisplay 
                    name= {uiState.nameFilter? uiState.nameFilter.completeName : defaultUser}
                    department={uiState.departmentFilter? uiState.departmentFilter.name : defaultDepartment}
                    role={uiState.roleFilter ? uiState.roleFilter.name : defaultRole }
                    date={uiState.dateFilter ? getDateRangeType(uiState.dateFilter) : defaultDateRange}>
                </MonitorFilterDisplay>

                <Button variant="primary"
                    onClick={ openFilterModal }>
                    <i className="fa fa-edit"></i>
                </Button>

                <span className="float-right mt-1">
                    <Button variant="secondary" size="sm"
                        onClick={ openWidgetModal }>
                        <i className="fa fa-tasks"></i> Dashboard Settings
                    </Button>
                </span>
            </MonitorHeader>

            <MonitorWidgetTable 
                widgetsList = { widgetsList }
                widgetsOptions = { widgetsOptions }
                visibleWidgets = { visibleWidgets }>
            </MonitorWidgetTable>

            <MonitorFilterModal isShown={ uiState.showFilterModal }
                onSuccess={ applyFilter }
                onCancel={ closeFilterModal }
                departmentList={departmentList}
                userList={userList}
                roleList={roleList}
                filters={uiState.filters}>
            </MonitorFilterModal>

            <MonitorWidgetModal isShown={ uiState.showWidgetModal }
                onSuccess={ onSuccessUpdateWidgets }
                onCancel={ closeWidgetModal }
                currentVisibleWidgets= { visibleWidgets || [] }
                widgetsList = {widgetsList || []}>
            </MonitorWidgetModal>
        </>
    );
};

export default ReportMonitorScreen;