import MessageCode from 'constants/MessageCode';

export default {
    translation: {
        [ MessageCode.GENERAL_LABEL_NAME ]: 'Name',
        [ MessageCode.GENERAL_LABEL_DEPARTMENT ]: 'Department',
        [ MessageCode.GENERAL_LABEL_STATUS ]: 'Status',

        [ MessageCode.GENERAL_BUTTON_ADD ]: 'Add',
        [ MessageCode.GENERAL_BUTTON_UPDATE ]: 'Update',
        [ MessageCode.GENERAL_BUTTON_DELETE ]: 'Delete',
        [ MessageCode.GENERAL_BUTTON_CLEAR ]: 'Clear',
        [ MessageCode.GENERAL_BUTTON_CANCEL ]: 'Cancel',
        [ MessageCode.GENERAL_BUTTON_DOWNLOAD ]: 'Download',

        [ MessageCode.DEPARTMENT_TOOLTIP_DELETE ]: 'Delete this department',
        [ MessageCode.DEPARTMENT_TOOLTIP_EDIT ]: 'Edit this department',        
        [ MessageCode.ROLE_TOOLTIP_DELETE ]: 'Delete this role',
        [ MessageCode.ROLE_TOOLTIP_EDIT ]: 'Edit this role',
        [ MessageCode.STATUS_TOOLTIP_DELETE ]: 'Delete this status',
        [ MessageCode.STATUS_TOOLTIP_EDIT ]: 'Edit this status',
        [ MessageCode.TASK_TOOLTIP_DELETE ]: 'Delete this task',
        [ MessageCode.TASK_TOOLTIP_EDIT ]: 'Edit this task',
        [ MessageCode.DIRECTORY_TOOLTIP_DELETE ]: 'Delete this directory',
        [ MessageCode.DIRECTORY_TOOLTIP_EDIT ]: 'Edit this directory',

        [ MessageCode.STATUS_MODAL_HEADER_CREATE ]: 'Add New Status',
        [ MessageCode.STATUS_MODAL_HEADER_DELETE ]: 'Delete Status',
        [ MessageCode.STATUS_MODAL_HEADER_UPDATE ]: 'Update Status',
        [ MessageCode.TASK_MODAL_HEADER_CREATE ]: 'Add New Task',
        [ MessageCode.TASK_MODAL_HEADER_DELETE ]: 'Delete Task',
        [ MessageCode.TASK_MODAL_HEADER_UPDATE ]: 'Update Task',

        [ MessageCode.STATUS_MESSAGE_DELETE_MODAL_TEXT ]: '<0>You are about to delete <1><0>{{ name }}</0></1> status under <1><0>{{ deptName }}</0></1> department. </0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.STATUS_MESSAGE_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} status.',
        [ MessageCode.STATUS_MESSAGE_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} status.',
        [ MessageCode.TASK_MESSAGE_CREATE_SUCCESS ]: 'Successfully added {{ name }} task.',
        [ MessageCode.TASK_MESSAGE_DELETE_MODAL_TEXT ]: '<0>You are about to delete <1><0>{{ name }}</0></1> task of <1><0>{{ statusName }}</0></1> status under <1><0>{{ deptName }}</0></1> department. </0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.TASK_MESSAGE_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} task.',
        [ MessageCode.TASK_MESSAGE_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} task.',

        [ MessageCode.STATUSES_BREADCRUMB_LABEL ]: "Statuses",
        [ MessageCode.TASKS_BREADCRUMB_LABEL ]: "Tasks",

        [ MessageCode.DEFAULT_DEPARTMENT ]: 'No department',
        [ MessageCode.DEFAULT_STATUS ]: 'No status',

        [ MessageCode.STATUS_DISPLAY_EMPTY ]: 'No statuses.',
        [ MessageCode.TASK_DISPLAY_EMPTY ]: 'No tasks.',

        [ MessageCode.ERROR_DEPARTMENT_DELETE_HAS_CHILDREN ]: "{{ name }} department cannot be deleted. Please ensure it does not have any children.",
        [ MessageCode.ERROR_DEPARTMENT_DELETE_ASSOCIATED_WITH_STATUSES ]: "{{ name }} department cannot be deleted. Please ensure it does not have any status associated with it.",
        [ MessageCode.ERROR_STATUS_DELETE ]: "{{ name }} status cannot be deleted. Please ensure it does not have any tasks.",
        [ MessageCode.ERROR_STATUS_INACCESSIBLE ]: "{{name}} status cannot be modified nor deleted. You can only modify/delete statuses from the departments you head and their descendants.",
        [ MessageCode.ERROR_STATUS_REQUIRED ]: "Status is required.",
        [ MessageCode.ERROR_TASK_INACCESSIBLE ]: "{{name}} task cannot be modified nor deleted. You can only modify/delete tasks from the departments you head and their descendants.",
        [ MessageCode.ERROR_USER_DELETE_ASSOCIATED_WITH_DEPARTMENT ]: "{{name}} user cannot be deleted. Please ensure it does not have any department associated with it.",
        [ MessageCode.ERROR_USER_DELETE_PROTECTED_ACCOUNT ]: "{{name}} is a protected account, it cannot be deleted",

        // TODO: Organize and Generalize transalations below
        [ MessageCode.ACTIVITY_CREATE_BUTTON_TOOLTIP ]: 'Add New Activity',

        [ MessageCode.AGENT_MOST_USED_WORKFLOWS_HEADER ]: 'Most Used Workflows',
        [ MessageCode.AGENT_TOTAL_EXECUTED_HOURS_HEADER ]: 'Total Executed Time',
        [ MessageCode.AGENT_WORKFLOW_EXCEPTIONS_HEADER ]: 'Workflow Errors',
        [ MessageCode.AGENT_WORKFLOW_EVENTS_HEADER ]: 'Events',

        [ MessageCode.AGENT_ACTIVE_AGENT_COUNT_LABEL ]: '# OF ACTIVE AGENTS',
        [ MessageCode.AGENT_FAILED_EXECUTION_LABEL ]: 'FAILED EXECUTION',
        [ MessageCode.AGENT_SCHEDULED_WORKFLOWS_LABEL ]: 'SCHEDULED WORKFLOWS',
        [ MessageCode.AGENT_WORKFLOW_COMPLETED_LABEL ]: 'WORKFLOW COMPLETED',

        [ MessageCode.AGENT_VIEW_ALL_EXCEPTIONS_LINK ]: 'View All Error Logs',
        [ MessageCode.AGENT_VIEW_ALL_WORKFLOWS_LINK ]: 'View All Workflows',
        [ MessageCode.AGENT_VIEW_ALL_EVENTS_LINK ]: 'View All Events',

        [ MessageCode.BREADCRUMB_COMPANY_LICENSE_MANAGEMENT_LABEL ]: 'License Management',
        [ MessageCode.BREADCRUMB_COMPANY_COMPANIES_LABEL ]: 'Companies',
        [ MessageCode.BREADCRUMB_COMPANY_LABEL ]: 'Company',
        [ MessageCode.BREADCRUMB_COMPANY_KEYS_LABEL ]: 'Keys',
        [ MessageCode.BREADCRUMB_ACTIVITY_LABEL ]: 'License Management',
        [ MessageCode.BREADCRUMB_ACTIVITY_LIST_LABEL ]: 'Activities',
        [ MessageCode.BREADCRUMB_PREMIUM_ACTIVITY_LIST_LABEL ]: 'Premium Activities',
        [ MessageCode.BREADCRUMB_USER_LABEL ]: 'User Management',
        [ MessageCode.BREADCRUMB_USER_LIST_LABEL ]: 'Users',

        [ MessageCode.BREADCRUMB_WORKFLOW_LABEL ]: 'Workflow Management',
        [ MessageCode.BREADCRUMB_WORKFLOW_EVENTS_LABEL ]: 'Events',
        [ MessageCode.BREADCRUMB_WORKFLOW_EXCEPTIONS_LABEL ]: 'Error Logs',
        [ MessageCode.BREADCRUMB_WORKFLOW_LIST_LABEL ]: 'Workflows',
        [ MessageCode.BREADCRUMB_WORKFLOW_LOGS_LABEL ]: 'Execution Logs',

        [ MessageCode.COMPANY_CREATE_BUTTON_TOOLTIP ]: 'Add New Company',

        [ MessageCode.COMPANY_MODAL_CREATE_HEADER ]: 'Add New Company',
        [ MessageCode.COMPANY_MODAL_CREATE_ADD_BUTTON ]: 'Add',
        [ MessageCode.COMPANY_MODAL_CREATE_CLEAR_BUTTON ]: 'Clear',
        [ MessageCode.COMPANY_MODAL_CREATE_EMAIL_LABEL ]: 'Email Address *',
        [ MessageCode.COMPANY_MODAL_CREATE_INDUSTRY_LABEL ]: 'Industry *',
        [ MessageCode.COMPANY_MODAL_CREATE_INDUSTRY_DEFAULT ]: 'No industry selected.',
        [ MessageCode.COMPANY_MODAL_CREATE_NAME_LABEL ]: 'Name *',
        [ MessageCode.COMPANY_MODAL_CREATE_SUBTEXT ]: '* All fields are required.',
        [ MessageCode.COMPANY_MODAL_CREATE_SUCCESS ]: 'Successfully added {{ name }} company.',
        [ MessageCode.COMPANY_MODAL_DELETE_BUTTON ]: 'Delete',
        [ MessageCode.COMPANY_MODAL_DELETE_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.COMPANY_MODAL_DELETE_HEADER ]: 'Delete Company',
        [ MessageCode.COMPANY_MODAL_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} company.',
        [ MessageCode.COMPANY_MODAL_DELETE_TEXT ]: '<0>You are about to delete <1><0>{{ name }}.</0></1> This will delete linked department, role, users, directories and workflows.</0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.COMPANY_MODAL_UPDATE_BUTTON ]: 'Update',
        [ MessageCode.COMPANY_MODAL_UPDATE_HEADER ]: 'Update Company',
        [ MessageCode.COMPANY_MODAL_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} company.',
        [ MessageCode.COMPANY_MODAL_UPDATE_COMPANY_HEAD] : 'Company Head: *',
        [ MessageCode.COMPANY_MODAL_UPDATE_COMPANY_HEAD_DEFAULT ]: 'No company head selected.',

        [ MessageCode.ERROR_ACCOUNT_REGFILE_REQUIRED ]: 'Registration File is required.',
        [ MessageCode.ERROR_ACCOUNT_REGFILE_UNSUPPORTED ]: 'Only .dat files are supported.',
        [ MessageCode.ERROR_ACCOUNT_REGFILE_INVALID ]: 'Registration File is invalid',

        [ MessageCode.ERROR_COMPANY_NAME_REQUIRED ]: 'Company Name is required.',
        [ MessageCode.ERROR_COMPANY_NAME_DUPLICATE ]: 'Company Name is already used.',
        [ MessageCode.ERROR_COMPANY_NAME_TOO_LONG ]: 'Company Name is too long. (Max of 255 characters)',
        [ MessageCode.ERROR_COMPANY_EMAIL_REQUIRED ]: 'Company Email is required.',
        [ MessageCode.ERROR_COMPANY_EMAIL_INVALID ]: 'Company Email is invalid.',
        [ MessageCode.ERROR_COMPANY_EMAIL_DUPLICATE ]: 'Company Email is already used.',
        [ MessageCode.ERROR_COMPANY_EMAIL_TOO_LONG ]: 'Company Email is too long. (Max of 255 characters)',
        [ MessageCode.ERROR_COMPANY_INDUSTRY_REQUIRED ]: 'Company Industry is required.',
        [ MessageCode.ERROR_COMPANY_INDUSTRY_INVALID ]: 'Company Industry is invalid.',
        [ MessageCode.ERROR_COMPANY_NAME_INVALID]: 'Company Name is invalid.',
        [ MessageCode.ERROR_COMPANY_NOT_EXIST ]: 'Company with the id {{ id }} does not exist.',
        [ MessageCode.ERROR_COMPANY_COMPANY_HEAD_REQUIRED]: 'Company head is required.',
        [ MessageCode.ERROR_COMPANY_COMPANY_HEAD_IN_ANOTHER_COMPANY]: 'Company head is already assigned to another company.',

        [ MessageCode.ERROR_COMPANY_FULL_LICENSES ]: 'Company has already reached its allowed key count.',
        [ MessageCode.ERROR_COMPANY_HAS_LICENSE ]: 'Company has licenses and cannot be deleted.',
        [ MessageCode.ERROR_COMPANY_MORE_LICENSES ]: 'Company is using more licenses than the new allowed key count.',

        [ MessageCode.ERROR_GENERAL_INTERNAL ]: 'Oops! Something went wrong in processing your request.',
        [ MessageCode.ERROR_GENERAL_PROTECTED ]: '{{ name }} is protected and cannot be deleted.',

        [ MessageCode.ERROR_ACTIVITY_NAME_REQUIRED ]: 'Activity name is required.',
        [ MessageCode.ERROR_ACTIVITY_KEY_REQUIRED ]: 'Activity key is required.',
        [ MessageCode.ERROR_ACTIVITY_ID_REQUIRED ]: 'Activity id is required.',
        [ MessageCode.ERROR_ACTIVITY_NOT_EXIST ]: 'The activity cannot be found or is archived.',
        [ MessageCode.ERROR_ACTIVITY_NAME_EXCEEDS_CHARACTER_LIMIT ]: 'The activity name exceeds the character limit of 255.',
        [ MessageCode.ERROR_ACTIVITY_KEY_EXCEEDS_CHARACTER_LIMIT ]: 'The activity key exceeds the character limit of 255.',
        [ MessageCode.ERROR_ACTIVITY_CATEGORY_EXCEEDS_CHARACTER_LIMIT ]: 'The activity category exceeds the character limit of 255.',

        [ MessageCode.ERROR_DIRECTORY_NAME_REQUIRED ]: 'Directory name is required.',
        [ MessageCode.ERROR_DIRECTORY_NAME_TOO_LONG ]: 'Directory name is too long (max. 255 characters).',
        [ MessageCode.ERROR_DIRECTORY_NAME_EXIST ]: 'Directory name already exists.',
        [ MessageCode.ERROR_DIRECTORY_NAME_INVALID ]: 'Directory name contains invalid characters.',

        [ MessageCode.ERROR_FILE_NAME_REQUIRED ]: 'File name is required.',
        [ MessageCode.ERROR_FILE_NAME_TOO_LONG ]: 'File name is too long (max. 255 characters).',
        [ MessageCode.ERROR_FILE_NAME_EXIST ]: 'File name already exists.',
        [ MessageCode.ERROR_FILE_NAME_INVALID ]: 'File name contains invalid characters.',

        [ MessageCode.ERROR_FORGOT_PASSWORD_FORM_INVALID ]: 'Invalid username.',
        [ MessageCode.ERROR_FORGOT_PASSWORD_FORM_EMAIL_REQUIRED ]: 'Email is required.',

        [ MessageCode.ERROR_LICENSE_ALLOWED_KEYS_REQUIRED ]: 'Allowed Keys is required.',
        [ MessageCode.ERROR_LICENSE_ALLOWED_KEYS_TOO_LOW ]: 'Allowed keys value is too low (min. 1).',
        [ MessageCode.ERROR_LICENSE_ALLOWED_KEYS_TOO_HIGH ]: 'Allowed keys value is too high (max. 100).',
        [ MessageCode.ERROR_LICENSE_EXPIRATION_DATE_INVALID ]: 'Expiration Date is invalid.',
        [ MessageCode.ERROR_LICENSE_EXPIRATION_DATE_REQUIRED ]: 'Expiration Date is required.',

        [ MessageCode.ERROR_LICENSE_EXPIRED_KEY ]: 'The license is already expired.',

        [ MessageCode.ERROR_LICENSE_ID_REQUIRED ]: 'License id is required.',
        [ MessageCode.ERROR_LICENSE_NOT_EXIST ]: 'License does not exist.',
        [ MessageCode.ERROR_LICENSE_NOT_SOLO ]: 'License is associated with another company',

        [ MessageCode.ERROR_LICENSE_TYPE_INVALID ]: 'License type is invalid.',
        [ MessageCode.ERROR_LICENSE_TYPE_REQUIRED ]: 'License type is required.',
        [ MessageCode.ERROR_LICENSE_LABEL_TOO_LONG ]: 'Label is too long (max. 255 characters).',

        [ MessageCode.ERROR_LOGIN_FORM_EMAIL_REQUIRED ]: 'Email is required.',
        [ MessageCode.ERROR_LOGIN_FORM_INCORRECT_CREDENTIALS ]: 'Invalid username/password.',
        [ MessageCode.ERROR_LOGIN_FORM_PASSWORD_REQUIRED ]: 'Password is required.',

        [ MessageCode.ERROR_RESET_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_REQUIRED ]: 'New Password Confirmation is required.',
        [ MessageCode.ERROR_RESET_PASSWORD_FORM_INVALID ]: 'Invalid reset password.',
        [ MessageCode.ERROR_RESET_PASSWORD_FORM_NEW_PASSWORD_REQUIRED ]: 'New Password is required.',
        [ MessageCode.ERROR_RESET_PASSWORD_FORM_NEW_PASSWORD_MISMATCH ]: 'Password does not match.',
        
        [ MessageCode.ERROR_USER_FIRST_NAME_REQUIRED ]: 'First name is required.',
        [ MessageCode.ERROR_USER_LAST_NAME_REQUIRED ]: 'Last name is required.',
        [ MessageCode.ERROR_USER_EMAIL_REQUIRED ]: 'Email is required.',
        [ MessageCode.ERROR_USER_EMAIL_REQUIRED ]: 'Email is required.',
        [ MessageCode.ERROR_USER_EMAIL_INVALID ]: 'Email is invalid.',
        [ MessageCode.ERROR_USER_EMAIL_ALREADY_USED ]: 'Email is already used.',
        [ MessageCode.ERROR_USER_EMAIL_TOO_LONG ]: 'Email is too long (max. 255 characters).',
        [ MessageCode.ERROR_USER_PASSWORD_REQUIRED ]: 'Password is required.',
        [ MessageCode.ERROR_USER_CONFIRM_PASSWORD_REQUIRED ]: 'Password confirmation is required.',
        [ MessageCode.ERROR_USER_DEPARTMENT_REQUIRED ]: 'Department is required.',
        [ MessageCode.ERROR_USER_ROLE_REQUIRED ]: 'Role is required.',
        [ MessageCode.ERROR_USER_COMPANY_REQUIRED ]: 'Company is required.',
        [ MessageCode.ERROR_USER_FIRST_NAME_TOO_LONG ]: 'First name is too long (max. 255 characters).',
        [ MessageCode.ERROR_USER_LAST_NAME_TOO_LONG ]: 'Last name is too long (max. 255 characters).',
        [ MessageCode.ERROR_USER_DEPARTMENT_TOO_LONG ]: 'Department is too long (max. 255 characters).',
        [ MessageCode.ERROR_USER_ROLE_TOO_LONG ]: 'Role is too long (max. 255 characters).',
        [ MessageCode.ERROR_USER_PASSWORD_MISMATCH ]: 'Password does not match.',

        [ MessageCode.ERROR_WORKFLOW_FILE_REQUIRED ]: 'Workflow file is required.',
        [ MessageCode.ERROR_WORKFLOW_FILE_UNSUPPORTED ]: 'Only .rax files are supported.',
        [ MessageCode.ERROR_WORKFLOW_ID_REQUIRED ]: 'Workflow id is required.',
        [ MessageCode.ERROR_WORKFLOW_NOT_EXIST ]: 'The workflow cannot be found or is archived.',
        [ MessageCode.ERROR_WORKFLOW_NAME_REQUIRED ]: 'Workflow name is required.',
        [ MessageCode.ERROR_WORKFLOW_NAME_INVALID ]: 'Workflow name contains invalid characters.',
        [ MessageCode.ERROR_WORKFLOW_NAME_TOO_LONG ]: 'Workflow name is too long (max. 255 characters).',
        [ MessageCode.ERROR_WORKFLOW_DESCRIPTION_TOO_LONG ]: 'Workflow description is too long (max. 1024 characters).',
        [ MessageCode.ERROR_WORKFLOW_FILE_EXIST ]: 'Workflow file already exists.',

        [ MessageCode.FORM_CONTROL_FILE_PLACEHOLDER ]: 'Choose File...',

        [ MessageCode.FORGOT_PASSWORD_FORM_HEADER ]: 'Forgot Password',
        [ MessageCode.FORGOT_PASSWORD_FORM_EMAIL_LABEL ]: 'Email',
        [ MessageCode.FORGOT_PASSWORD_FORM_CONFIRM_BUTTON ]: 'Confirm',
        [ MessageCode.FORGOT_PASSWORD_FORM_LOGIN_LINK ]: 'Go back to Login Page',

        [ MessageCode.FORGOT_PASSWORD_SUCCESS_HEADER ]: 'Email Sent!',
        [ MessageCode.FORGOT_PASSWORD_SUCCESS_MESSAGE ]: 'We sent an email to your email address so you can choose your new password.',
        [ MessageCode.FORGOT_PASSWORD_SUCCESS_LINK ]: 'Go back to Login Page',

        [ MessageCode.KEY_DELETE_MODAL_HEADER ]: 'Delete License Key',
        [ MessageCode.KEY_DELETE_MODAL_TEXT ]: '<0>You are about to delete the license key starting with <1><0>{{ name }}</0></1>.</0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.KEY_DELETE_MODAL_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.KEY_DELETE_MODAL_DELETE_BUTTON ]: 'Delete',
        [ MessageCode.KEY_DELETE_MODAL_SUCCESS ]: 'Successfully deleted the license key starting with {{ name }}.',

        [ MessageCode.KEY_GENERATE_MODAL_HEADER ]: 'Generate a New License Key',
        [ MessageCode.KEY_GENERATE_MODAL_TEXT ]: '<0>You are about to generate a new license key for the selected department.</0> <1>The license key will be sent to <1><0>{{ name }}</0></1>.</1>',
        [ MessageCode.KEY_GENERATE_MODAL_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.KEY_GENERATE_MODAL_GENERATE_BUTTON ]: 'Generate',
        [ MessageCode.KEY_GENERATE_MODAL_SUCCESS ]: 'Successfully generated a new license key.',

        [ MessageCode.KEY_GENERATE_FROM_FILE_MODAL_HEADER ]: 'Generate License Key from File',
        [ MessageCode.KEY_GENERATE_FROM_FILE_MODAL_TEXT ]: '<0>You are about to generate a new license key for the selected department using a registration file.</0><1>The license file will be sent to the <1><0>user\'s email</0></1>.</1>',
        [ MessageCode.KEY_GENERATE_FROM_FILE_MODAL_REGISTRATION_FILE ]: 'Registration File',

        [ MessageCode.LICENSE_CREATE_BUTTON_TOOLTIP ]: 'Add New License',

        [ MessageCode.LICENSE_GENERATE_KEY_LABEL ]: 'Generate New Key',
        [ MessageCode.LICENSE_GENERATE_FROM_FILE_LABEL ]: 'Generate From File',

        [ MessageCode.LICENSE_MODAL_CREATE_CLEAR_BUTTON ]: 'Clear',
        [ MessageCode.LICENSE_MODAL_CREATE_HEADER ]: 'Add New License',
        [ MessageCode.LICENSE_MODAL_CREATE_LICENSE_TYPE_DEFAULT ]: 'No license type selected.',
        [ MessageCode.LICENSE_MODAL_CREATE_LICENSE_TYPE_LABEL ]: 'License Type',
        [ MessageCode.LICENSE_MODAL_CREATE_SAVE_BUTTON ]: 'Save',
        [ MessageCode.LICENSE_MODAL_CREATE_SUCCESS ]: 'Successfully added the {{ name }} license.',
        [ MessageCode.LICENSE_MODAL_CREATE_LICENSE_LABEL ]: 'License Label',
        [ MessageCode.LICENSE_MODAL_DEPARTMENT_LABEL ]: 'Department * ',

        [ MessageCode.LICENSE_MODAL_DELETE_BUTTON ]: 'Delete',
        [ MessageCode.LICENSE_MODAL_DELETE_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.LICENSE_MODAL_DELETE_HEADER ]: 'Delete License',
        [ MessageCode.LICENSE_MODAL_DELETE_SUCCESS ]: 'Successfully deleted the {{ name }} license.',
        [ MessageCode.LICENSE_MODAL_DELETE_TEXT ]: '<0>You are about to delete this <1><0>{{ name }}</0></1> license.</0><1>All license keys linked to this license will be lost.</1><2>Are you sure you want to proceed?</2>',

        [ MessageCode.LICENSE_MODAL_UPDATE_LICENSE_LABEL ]: 'License Label',
        [ MessageCode.LICENSE_MODAL_UPDATE_ALLOWED_KEYS_LABEL ]: 'Allowed Keys *',
        [ MessageCode.LICENSE_MODAL_UPDATE_CLEAR_BUTTON ]: 'Clear',
        [ MessageCode.LICENSE_MODAL_UPDATE_EXPIRATION_DATE_LABEL ]: 'Expiration Date *',
        [ MessageCode.LICENSE_MODAL_UPDATE_HEADER ]: 'Renew License Confirmation',
        [ MessageCode.LICENSE_MODAL_UPDATE_SAVE_BUTTON ]: 'Save',
        [ MessageCode.LICENSE_MODAL_UPDATE_SUBTEXT ]: '* Required fields.',
        [ MessageCode.LICENSE_MODAL_UPDATE_SUCCESS ]: 'Successfully updated the {{ name }} license.',

        [ MessageCode.LOGIN_HEADER ]: '<0>RAX <1>Suite</1></0><br />Command Center',
        [ MessageCode.LOGIN_SUBHEADER ]: 'Evolve your business. <br />Empower your people.',
        [ MessageCode.LOGIN_SUPPORT_TEXT ]: 'Got questions? Check out our Awesome Support Site',
        [ MessageCode.LOGIN_SUPPORT_LINK ]: ' Customer Support',

        [ MessageCode.LOGIN_FORM_HEADER ]: 'Login',
        [ MessageCode.LOGIN_FORM_EMAIL_LABEL ]: 'Email',
        [ MessageCode.LOGIN_FORM_PASSWORD_LABEL ]: 'Password',
        [ MessageCode.LOGIN_FORM_LOGIN_BUTTON ]: 'Login',
        [ MessageCode.LOGIN_FORM_FORGOT_PASSWORD_LINK ]: 'Forgot Password?',

        [ MessageCode.MODAL_ACTIVITY_CREATE_HEADER ]: 'New Activity',
        [ MessageCode.MODAL_ACTIVITY_UPDATE_HEADER ]: 'Update Activity',
        [ MessageCode.MODAL_ACTIVITY_DELETE_HEADER ]: 'Delete Activity',
        [ MessageCode.MODAL_ACTIVITY_FORM_SUBTEXT ]: '* Required fields.',
        [ MessageCode.MODAL_ACTIVITY_ARCHIVE_TEXT ]: '<0>You are about to delete <1><0>{{ name }}.</0></1></0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.MODAL_ACTIVITY_NAME_LABEL ]: 'Name *',
        [ MessageCode.MODAL_ACTIVITY_KEY_LABEL ]: 'Key *',
        [ MessageCode.MODAL_ACTIVITY_CATEGORY_LABEL ]: 'Category',
        [ MessageCode.MODAL_ACTIVITY_PARTIAL_LABEL ]: 'Partial',
        [ MessageCode.MODAL_ACTIVITY_ADD_BUTTON ]: 'Add',
        [ MessageCode.MODAL_ACTIVITY_CLEAR_BUTTON ]: 'Clear',
        [ MessageCode.MODAL_ACTIVITY_UPDATE_BUTTON ]: 'Update',
        [ MessageCode.MODAL_ACTIVITY_ARCHIVE_BUTTON ]: 'Delete',
        [ MessageCode.MODAL_ACTIVITY_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.MODAL_ACTIVITY_CREATE_SUCCESS ]: 'Successfully added {{ name }} activity.',
        [ MessageCode.MODAL_ACTIVITY_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} activity.',
        [ MessageCode.MODAL_ACTIVITY_DELETE_SUCCESS ]: 'Successfully archived {{ name }} activity.',

        [ MessageCode.MODAL_ACCOUNT_DELETE_HEADER ]: 'Delete Account',
        [ MessageCode.MODAL_ACCOUNT_DELETE_TEXT ]: `<0>You are about to delete <1><0>{{ name }}'s {{ licenseType }}</0></1> account.</0><1>Are you sure you want to proceed?</1>`,
        [ MessageCode.MODAL_ACCOUNT_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.MODAL_ACCOUNT_DELETE_BUTTON ]: 'Delete',
        [ MessageCode.MODAL_ACCOUNT_DELETE_SUCCESS ]: `Successfully deleted {{ name }}'s {{ licenseType }} account.`,

        [ MessageCode.MODAL_USER_CREATE_HEADER ]: 'New User',
        [ MessageCode.MODAL_USER_UPDATE_HEADER ]: 'Update User',
        [ MessageCode.MODAL_USER_DELETE_HEADER ]: 'Delete User',
        [ MessageCode.MODAL_USER_DELETE_TEXT ]: `<0>You are about to delete <1><0>{{ name }}</0></1>.</0><1>Are you sure you want to proceed?</1>`,
        [ MessageCode.MODAL_USER_REGISTER_BUTTON ]: 'Register',
        [ MessageCode.MODAL_USER_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.MODAL_USER_CLEAR_BUTTON ]: 'Clear',
        [ MessageCode.MODAL_USER_DELETE_BUTTON ]: 'Delete',
        [ MessageCode.MODAL_USER_UPDATE_BUTTON ]: 'Update',
        [ MessageCode.MODAL_USER_CREATE_SUCCESS ]: 'Successfully added {{ name }}.',
        [ MessageCode.MODAL_USER_UPDATE_SUCCESS ]: 'Successfully updated {{ name }}.',
        [ MessageCode.MODAL_USER_DELETE_SUCCESS ]: 'Successfully archived {{ name }}.',
        [ MessageCode.MODAL_USER_FORM_SUBTEXT ]: '* Required fields.',
        [ MessageCode.MODAL_USER_FIRST_NAME_LABEL ]: 'First Name *',
        [ MessageCode.MODAL_USER_LAST_NAME_LABEL ]: 'Last Name *',
        [ MessageCode.MODAL_USER_EMAIL_LABEL ]: 'Email *',
        [ MessageCode.MODAL_USER_COMPANY_LABEL ]: 'Company *',
        [ MessageCode.MODAL_USER_DEPARTMENT_LABEL ]: 'Department *',
        [ MessageCode.MODAL_USER_ROLE_LABEL ]: 'Role *',
        [ MessageCode.MODAL_USER_PASSWORD_LABEL ]: 'Password *',
        [ MessageCode.MODAL_USER_CONFIRM_PASSWORD_LABEL ]: 'Confirm Password *',
        [ MessageCode.MODAL_USER_FIELD_COMPANY_DEFAULT ]: 'No company',
        [ MessageCode.MODAL_USER_FIELD_DEPARTMENT_DEFAULT ]: 'No department',
        [ MessageCode.MODAL_USER_FIELD_ROLE_DEFAULT ]: 'No role',

        [ MessageCode.RESET_PASSWORD_FORM_HEADER ]: 'Reset Password',
        [ MessageCode.RESET_PASSWORD_FORM_NEW_PASSWORD_LABEL ]: 'New Password',
        [ MessageCode.RESET_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_LABEL ]: 'Confirm New Password',
        [ MessageCode.RESET_PASSWORD_FORM_SUBMIT_BUTTON ]: 'Submit',

        [ MessageCode.RESET_PASSWORD_SUCCESS_HEADER ]: 'Password Changed',
        [ MessageCode.RESET_PASSWORD_SUCCESS_MESSAGE ]: 'Your password has been changed successfully. You can now use your new chosen password to login.',
        [ MessageCode.RESET_PASSWORD_SUCCESS_LINK ]: 'Go back to Login Page',

        [ MessageCode.SIDEBAR_DASHBOARD_LABEL ]: 'Dashboard',
        [ MessageCode.SIDEBAR_WORKFLOW_MANAGEMENT_LABEL ]: 'Workflow<br /><0>Management</0>',
        [ MessageCode.SIDEBAR_LICENSE_MANAGEMENT_LABEL ]: 'License<br /><0>Management</0>',
        [ MessageCode.SIDEBAR_USER_MANAGEMENT_LABEL ]: 'User<br /><0>Management</0>',
        [ MessageCode.SIDEBAR_LOGOUT_LABEL ]: 'Logout',

        [ MessageCode.SIDEBAR_REPORTS_AGENT_LABEL ]: 'Agent',
        [ MessageCode.SIDEBAR_REPORTS_MONITOR_LABEL ]: 'Monitor',

        [ MessageCode.SIDEBAR_WORKFLOW_LIST_LABEL ]: 'Workflows',
        [ MessageCode.SIDEBAR_WORKFLOW_EVENTS_LABEL ]: 'Events',
        [ MessageCode.SIDEBAR_WORKFLOW_ERRORS_LABEL ]: 'Error Logs',
        [ MessageCode.SIDEBAR_WORKFLOW_LOGS_LABEL ]: 'Execution Logs',

        [ MessageCode.SIDEBAR_LICENSE_COMPANY_LABEL ]: 'Companies',
        [ MessageCode.SIDEBAR_LICENSE_LICENSES_LABEL ]: 'Licenses',
        [ MessageCode.SIDEBAR_LICENSE_ACCOUNT_LABEL ]: 'Accounts',
        [ MessageCode.SIDEBAR_LICENSE_ACTIVITY_LABEL ]: 'Activities',
        [ MessageCode.SIDEBAR_LICENSE_PREMIUM_ACTIVITY_LABEL ]: 'Premium Activities',

        [ MessageCode.SIDEBAR_MANAGEMENT_USER_LABEL ]: 'Users',
        [ MessageCode.SIDEBAR_MANAGEMENT_DEPARTMENT_LABEL ]: 'Departments',
        [ MessageCode.SIDEBAR_MANAGEMENT_ROLE_LABEL ]: 'Roles',
        [ MessageCode.SIDEBAR_MANAGEMENT_STATUS_LABEL ]: 'Statuses',
        [ MessageCode.SIDEBAR_MANAGEMENT_TASK_LABEL ]: 'Tasks',
        [ MessageCode.SIDEBAR_MANAGEMENT_SCHEDULE_LABEL ]: 'Schedules',
        [ MessageCode.SIDEBAR_MANAGEMENT_TARGET_LABEL ]: 'Task Targets',
        [ MessageCode.SIDEBAR_MANAGEMENT_TRANSFER_LABEL ]: 'Transfers',
        [ MessageCode.SIDEBAR_MANAGEMENT_DATA_ENTRY_LABEL ]: 'Data Entry',

        [ MessageCode.TABLE_GENERAL_EMPTY_PLACEHOLDER ]: 'No matching records found.',

        [ MessageCode.TABLE_ACTIVITY_CREATE_DATE_HEADER ]: 'Create Date',
        [ MessageCode.TABLE_ACTIVITY_NAME_HEADER ]: 'Activity Name',
        [ MessageCode.TABLE_ACTIVITY_KEY_HEADER ]: 'Key',
        [ MessageCode.TABLE_ACTIVITY_CATEGORY_HEADER ]: 'Category',
        [ MessageCode.TABLE_ACTIVITY_EMPTY_PLACEHOLDER ]: 'No Record Found.',

        [ MessageCode.TABLE_AGENT_WORKFLOW_HEADER ]: 'Workflow',
        [ MessageCode.TABLE_AGENT_SUCCESSFUL_EXECUTION_HEADER ]: 'No. of times successfully executed',
        [ MessageCode.TABLE_AGENT_WORKFLOW_NAME_HEADER ]: 'Workflow Name',
        [ MessageCode.TABLE_AGENT_DATE_EXECUTED_HEADER ]: 'Date Executed',
        [ MessageCode.TABLE_AGENT_MESSAGE_HEADER ]: 'Message',
        [ MessageCode.TABLE_AGENT_EXECUTION_START_HEADER ]: 'Execution Start',
        [ MessageCode.TABLE_AGENT_EXECUTION_END_HEADER ]: 'Execution End',
        [ MessageCode.TABLE_AGENT_EXECUTED_BY_HEADER ]: 'Executed By',
        [ MessageCode.TABLE_AGENT_EXECUTION_STATUS_HEADER ]: 'Status',
        [ MessageCode.TABLE_AGENT_RUNTIME_HEADER ]: 'Runtime',
        
        [ MessageCode.TABLE_COMPANY_NAME_HEADER ]: 'Company Name',
        [ MessageCode.TABLE_COMPANY_EMAIL_HEADER ]: 'Email Address',
        [ MessageCode.TABLE_COMPANY_EMPTY_PLACEHOLDER ]: 'No companies found.',
        [ MessageCode.TABLE_COMPANY_INDUSTRY_HEADER ]: 'Industry',
        [ MessageCode.TABLE_COMPANY_HEAD_HEADER]: 'Company Head',

        [ MessageCode.TABLE_LICENSE_TYPE_HEADER ]: 'Type',
        [ MessageCode.TABLE_LICENSE_LABEL_HEADER ]: 'Label',
        [ MessageCode.TABLE_LICENSE_ISSUE_DATE_HEADER ]: 'Issue Date',
        [ MessageCode.TABLE_LICENSE_EXPIRATION_DATE_HEADER ]: 'Expiration Date',
        [ MessageCode.TABLE_LICENSE_ALLOWED_KEYS_HEADER ]: 'Allowed Keys',
        [ MessageCode.TABLE_LICENSE_REMAINING_KEYS_HEADER ]: 'Remaining Keys',
        [ MessageCode.TABLE_LICENSE_EMPTY_PLACEHOLDER ]: 'No licenses found.',

        [ MessageCode.TABLE_LICENSE_KEY_HEADER ]: 'Key',
        [ MessageCode.TABLE_LICENSE_KEY_NAME_HEADER ]: 'Name',
        [ MessageCode.TABLE_LICENSE_KEY_MACADDRESS_HEADER ]: 'MAC Address',
        [ MessageCode.TABLE_LICENSE_KEY_EMAIL_HEADER ]: 'Email Address',
        [ MessageCode.TABLE_LICENSE_KEY_EMPTY_PLACEHOLDER ]: 'No license keys found.',
        [ MessageCode.TABLE_LICENSE_KEY_DEPARTMENT_HEADER ]: 'Department',

        [ MessageCode.TABLE_USER_EMPTY_PLACEHOLDER ]: 'No Users.',
        [ MessageCode.TABLE_USER_FULL_NAME_HEADER ]: 'Full Name',
        [ MessageCode.TABLE_USER_DEPARTMENT_HEADER ]: 'Department',
        [ MessageCode.TABLE_USER_ROLE_HEADER ]: 'Role',
        [ MessageCode.TABLE_USER_COMPANY_HEADER ]: 'Company',
        [ MessageCode.TABLE_USER_EMAIL_HEADER ]: 'Email Address',

        [ MessageCode.TABLE_WORKFLOW_EMPTY_PLACEHOLDER ]: 'No workflows found.',
        [ MessageCode.TABLE_WORKFLOW_CREATE_DATE_HEADER ]: 'Create Date',
        [ MessageCode.TABLE_WORKFLOW_NAME_HEADER ]: 'Name',
        [ MessageCode.TABLE_WORKFLOW_DESCRIPTION_HEADER ]: 'Description',
        [ MessageCode.TABLE_WORKFLOW_LAST_MODIFIED_HEADER ]: 'Last Modified',
        [ MessageCode.TABLE_WORKFLOW_CREATED_BY_HEADER ]: 'Created By',
        [ MessageCode.TABLE_WORKFLOW_UPDATED_BY_HEADER ]: 'Updated By',
        [ MessageCode.TABLE_WORKFLOW_FILE_HEADER ]: 'File',
        [ MessageCode.TABLE_WORKFLOW_SUCCESSFUL_EXECUTION_HEADER ]: 'No. of times successfully executed',

        [ MessageCode.TABLE_ACCOUNT_EMPTY_PLACEHOLDER ]: 'No accounts found.',
        [ MessageCode.TABLE_ACCOUNT_TYPE_HEADER ]: 'Type',
        [ MessageCode.TABLE_ACCOUNT_NAME_HEADER ]: 'Name',
        [ MessageCode.TABLE_ACCOUNT_EMAIL_ADDRESS_HEADER ]: 'Email Address',
        [ MessageCode.TABLE_ACCOUNT_MAC_ADDRESS_HEADER ]: 'MAC Address',
        [ MessageCode.TABLE_ACCOUNT_EXPIRATION_DATE_HEADER ]: 'Expiration Date',

        [ MessageCode.TABLE_DEPARTMENT_EMPTY_PLACEHOLDER ]: 'No Departments.',
        [ MessageCode.TABLE_DEPARTMENT_NAME_HEADER ]: 'Name',
        [ MessageCode.TABLE_DEPARTMENT_HEAD_HEADER ]: 'Department Head',

        [ MessageCode.TOOLTIP_COMPANY_DELETE_ICON ]: 'Delete this company',
        [ MessageCode.TOOLTIP_COMPANY_EDIT_ICON ]: 'Edit this company',

        [ MessageCode.TOOLTIP_KEY_DELETE_ICON ]: 'Delete this license key',
        [ MessageCode.TOOLTIP_KEY_EXPORT_ICON ]: 'Export this license file',

        [ MessageCode.TOOLTIP_LICENSE_KEY_ICON ]: 'View the license keys',
        [ MessageCode.TOOLTIP_LICENSE_EDIT_ICON ]: 'Edit this license',
        [ MessageCode.TOOLTIP_LICENSE_DELETE_ICON ]: 'Delete this license',
        [ MessageCode.TOOLTIP_ACCOUNT_DELETE_ICON ]: 'Delete this account',
        [ MessageCode.TOOLTIP_ACTIVITY_DELETE_ICON ]: 'Delete this activity',
        [ MessageCode.TOOLTIP_ACTIVITY_EDIT_ICON ]: 'Edit this activity',
        [ MessageCode.TOOLTIP_USER_DELETE_ICON ]: 'Delete this user',
        [ MessageCode.TOOLTIP_USER_EDIT_ICON ]: 'Edit this user',
        [ MessageCode.TOOLTIP_USER_CREATE_BUTTON ]: 'Add New User',

        [ MessageCode.TOOLTIP_WORKFLOW_DELETE_ICON ]: 'Delete this workflow',
        [ MessageCode.TOOLTIP_WORKFLOW_DOWNLOAD_ICON ]: 'Download workflow file',
        [ MessageCode.TOOLTIP_WORKFLOW_EDIT_ICON ]: 'Edit this workflow',

        [ MessageCode.WORKFLOW_CREATE_BUTTON_TOOLTIP ]: 'Add New Workflow',

        [ MessageCode.WORKFLOW_MODAL_CREATE_HEADER ]: 'New Workflow',
        [ MessageCode.WORKFLOW_MODAL_UPDATE_HEADER ]: 'Update Workflow',
        [ MessageCode.WORKFLOW_MODAL_DELETE_HEADER ]: 'Delete Workflow',
        [ MessageCode.WORKFLOW_MODAL_CLEAR_BUTTON ]: 'Clear',
        [ MessageCode.WORKFLOW_MODAL_FORM_SUBTEXT ]: '* Required fields.',
        [ MessageCode.WORKFLOW_MODAL_ARCHIVE_TEXT ]: '<0>You are about to delete <1><0>{{ name }}.</0></1></0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.WORKFLOW_MODAL_NAME_LABEL ]: 'Name *',
        [ MessageCode.WORKFLOW_MODAL_DESCRIPTION_LABEL ]: 'Description',
        [ MessageCode.WORKFLOW_MODAL_FILE_LABEL ]: 'File *',
        [ MessageCode.WORKFLOW_MODAL_ADD_BUTTON ]: 'Add',
        [ MessageCode.WORKFLOW_MODAL_UPDATE_BUTTON ]: 'Update',
        [ MessageCode.WORKFLOW_MODAL_ARCHIVE_BUTTON ]: 'Delete',
        [ MessageCode.WORKFLOW_MODAL_CANCEL_BUTTON ]: 'Cancel',
        [ MessageCode.WORKFLOW_MODAL_CREATE_SUCCESS ]: 'Successfully added {{ name }} workflow.',
        [ MessageCode.WORKFLOW_MODAL_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} workflow.',
        [ MessageCode.WORKFLOW_MODAL_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} workflow.',
        [ MessageCode.WORKFLOW_ROOT_DIRECTORY ]: 'Workflows',

        // Department
        [ MessageCode.BREADCRUMB_USER_MANAGEMENT_LABEL ]: 'User Management',
        [ MessageCode.BREADCRUMB_DEPARTMENTS_LABEL ]: 'Departments',

        [ MessageCode.DEPARTMENT_TABLE_HEADER_NAME ]: 'Department Name',
        [ MessageCode.DEPARTMENT_TABLE_HEADER_PARENT ]: 'Parent',
        [ MessageCode.DEPARTMENT_TABLE_PLACEHOLDER_EMPTY ]: 'No departments found.',

        [ MessageCode.DEPARTMENT_MODAL_HEADER_CREATE]: 'Add New Department',
        [ MessageCode.DEPARTMENT_MODAL_HEADER_UPDATE]: 'Update Department',
        [ MessageCode.DEPARTMENT_MODAL_HEADER_DELETE]: 'Delete Department',
        [ MessageCode.GENERAL_SUBTEXT_REQUIRED ]: '* Required fields',

        [ MessageCode.DEPARTMENT_MODAL_LABEL_NAME ]: 'Name *',
        [ MessageCode.DEPARTMENT_MODAL_LABEL_PARENT ]: 'Parent Department',
        [ MessageCode.DEPARTMENT_MODAL_LABEL_HEAD ]: 'Department Head',

        [ MessageCode.DEPARTMENT_MODAL_FIELD_PARENT_DEFAULT ]: 'No parent',
        [ MessageCode.DEPARTMENT_MODAL_MESSAGE_CREATE_SUCCESS ]: 'Successfully added {{ name }} department.',
        [ MessageCode.DEPARTMENT_MODAL_MESSAGE_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} department.',
        [ MessageCode.DEPARTMENT_MODAL_MESSAGE_DELETE_TEXT ]: '<0>You are about to delete <1><0>{{ name }}.</0></1></0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.DEPARTMENT_MODAL_MESSAGE_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} department.',

        [ MessageCode.DEPARTMENT_DISPLAY_MESSAGE_NO_CHILDREN ]: 'Department has no children.',

        [ MessageCode.ERROR_DEPARTMENT_NAME_REQUIRED ]: "Department name is required.",
        [ MessageCode.ERROR_DEPARTMENT_HEAD_REQUIRED ]: "Department head is required.",
        [ MessageCode.ERROR_DEPARTMENT_HEAD_NOT_SELECTED ]: "Department head is not selected.",
        [ MessageCode.ERROR_DEPARTMENT_NAME_TOO_LONG ]: "Department name is too long (max. 1024 characters).",
        [ MessageCode.ERROR_DEPARTMENT_DOES_NOT_EXIST ]: "Department with id {{ id }} does not exist.",
        [ MessageCode.ERROR_DEPARTMENT_PARENT_INVALID ]: "Department parent is invalid. Parent Department must not be itself or among its descendants.",
        [ MessageCode.ERROR_DEPARTMENT_NAME_INVALID ]: "Department name is invalid.",
        [ MessageCode.ERROR_DEPARTMENT_NAME_DUPLICATE]: "Department name is already used.",

        [ MessageCode.BREADCRUMB_ROLES_LABEL ]: "Roles",
        [ MessageCode.ROLE_DISPLAY_EMPTY ]: 'No roles found.',

        [ MessageCode.ROLE_MODAL_HEADER_CREATE ]: 'Add New Role',
        [ MessageCode.ROLE_MODAL_HEADER_UPDATE ]: 'Update Role',
        [ MessageCode.ROLE_MODAL_HEADER_DELETE ]: 'Delete Role',

        [ MessageCode.ROLE_MODAL_LABEL_TITLE ]: 'Title *',
        [ MessageCode.ROLE_MODAL_HINT_ROLE_NAME ]: 'Role Name',
        [ MessageCode.ROLE_MODAL_SUBTEXT_REQUIRED ]: '* Required fields.',
        [ MessageCode.ROLE_MODAL_LABEL_SELECT_ALL ]: 'Select All',
        [ MessageCode.ROLE_MODAL_LABEL_UNSELECT_ALL ]: 'Unselect All',
        [ MessageCode.ROLE_MODAL_SUBTEXT_AUTHORIZE ]: 'Authorize role with the following:',

        [ MessageCode.ROLE_MODAL_MESSAGE_CREATE_SUCCESS ]: 'Successfully added {{ name }} role.',
        [ MessageCode.ROLE_MODAL_MESSAGE_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} role.',
        [ MessageCode.ROLE_MODAL_MESSAGE_DELETE_TEXT ]: '<0>You are about to delete <1><0>{{ name }}.</0></1></0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.ROLE_MODAL_MESSAGE_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} role.',

        [ MessageCode.ERROR_ROLE_NAME_REQUIRED ]: "Role Name is required.",
        [ MessageCode.ERROR_ROLE_NAME_TOO_LONG ]: "Role Name is too long.",
        [ MessageCode.ERROR_ROLE_DELETE_ASSOCIATED_WITH_USER ]: "{{ name }} role cannot be deleted. Please ensure it does not have any user associated with it.",
        [ MessageCode.ERROR_ROLE_NAME_TOO_LONG ]: "Role Name is too long.",
        
        [ MessageCode.STATUS_MODAL_LABEL_DEPARTMENT ]: 'Department *',
        [ MessageCode.STATUS_MODAL_LABEL_THRESHOLD ]: 'Threshold',
        [ MessageCode.STATUS_MODAL_LABEL_COLOR ]: 'Color *',
        [ MessageCode.STATUS_MODAL_LABEL_ICON ]: 'Icon *',
        [ MessageCode.STATUS_MODAL_SUBTEXT_ICON ]: ' (Only 24x24, 48x48 and 128x128 images with size up to 512KB are allowed.)',
        [ MessageCode.STATUS_MODAL_LABEL_TRACK_IDLE ]: 'Track Idle',

        [ MessageCode.ERROR_GENERAL_NAME_REQUIRED ]: 'Name is required.',
        [ MessageCode.ERROR_GENERAL_NAME_TOO_LONG ]: 'Name is too long.',

        [ MessageCode.ERROR_STATUS_DEPARTMENT_REQUIRED ]: 'Department is required.',
        [ MessageCode.ERROR_STATUS_ICON_REQUIRED ]: 'Icon is required.', 
        [ MessageCode.ERROR_STATUS_COLOR_REQUIRED ]: 'Color is required.', 
        [ MessageCode.ERROR_STATUS_ICON_UNSUPPORTED ]: 'Only .png files are supported.',
        [ MessageCode.ERROR_STATUS_ICON_TOO_BIG ]: 'Icon file size is too big, files allowed are only up to 512KB.',
        [ MessageCode.ERROR_STATUS_ICON_INVALID_DIMENSION ]: 'Icon dimension is invalid.',
        [ MessageCode.ERROR_STATUS_THRESHOLD_INVALID ]: 'Threshold is invalid. Please enter a whole number between 0 to 60.',

        [ MessageCode.STATUS_TABLE_HEADER_DEPARTMENT ]: 'Department',
        [ MessageCode.STATUS_TABLE_HEADER_THRESHOLD ]: 'Threshold',
        [ MessageCode.STATUS_TABLE_HEADER_COLOR ]: 'Color',
        [ MessageCode.STATUS_TABLE_HEADER_ICON ]: 'Icon',
        [ MessageCode.STATUS_TABLE_HEADER_TRACK_IDLE ]: 'Track Idle',

        [ MessageCode.SCHEDULE_TABLE_PLACEHOLDER_EMPTY ]: 'No Schedules',
        [ MessageCode.SCHEDULE_MODAL_HEADER_CREATE ]: 'Add New Schedules',
        [ MessageCode.MODAL_SCHEDULE_CREATE_HEADER ]: 'Create Schedules',
        [ MessageCode.MODAL_USERS_LABEL ]: 'Users *',
        [ MessageCode.MODAL_DATE_FROM_LABEL ]: 'Date From *',
        [ MessageCode.MODAL_DATE_TO_LABEL ]: 'Date To *',
        [ MessageCode.MODAL_SELECTED_DAYS_LABEL ]: 'Select Days *',
        [ MessageCode.MODAL_SCHEDULE_START_LABEL ]: 'Schedule Start *',
        [ MessageCode.MODAL_SCHEDULE_END_LABEL ]: 'Schedule End *',
        [ MessageCode.MODAL_SCHEDULE_UPDATE_HEADER ]: 'Update Selected Schedules',
        [ MessageCode.MODAL_NEW_DATE_LABEL ]: 'New Date *',
        [ MessageCode.MODAL_SCHEDULE_DELETE_HEADER ]: 'Delete Schedules',
        [ MessageCode.ERROR_NO_USERS_SELECTED ]: "No users selected",
        [ MessageCode.WARN_NO_SCHEDULE_SELECTED ]: "Nothing is selected",
        [ MessageCode.MODAL_SCHEDULE_UPDATE_SUCCESS ]: "Successfully updated schedules",
        [ MessageCode.MODAL_SCHEDULE_DELETE_SUCCESS ]: "Successfully deleted schedules",
        [ MessageCode.MODAL_SCHEDULE_CREATE_SUCCESS ]: "Successfully created schedules",
       
        [ MessageCode.DIRECTORY_CREATE_BUTTON ]: 'Add New Directory',
        [ MessageCode.DIRECTORY_MODAL_MESSAGE_CREATE_SUCCESS ]: 'Successfully added {{ name }} directory.',
        [ MessageCode.DIRECTORY_MODAL_MESSAGE_DELETE_SUCCESS ]: 'Successfully deleted {{ name }} directory.',
        [ MessageCode.DIRECTORY_MODAL_MESSAGE_DELETE_TEXT ]: '<0>You are about to delete <1><0>{{ name }}.</0></1></0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.DIRECTORY_MODAL_MESSAGE_UPDATE_SUCCESS ]: 'Successfully updated {{ name }} directory.',
        [ MessageCode.DIRECTORY_MODAL_CREATE_HEADER ]: 'New Directory',
        [ MessageCode.DIRECTORY_MODAL_UPDATE_HEADER ]: 'Update Directory',
        [ MessageCode.DIRECTORY_MODAL_DELETE_HEADER ]: 'Delete Directory',
        [ MessageCode.DIRECTORY_MODAL_FORM_SUBTEXT ]: '* Required fields.',
        [ MessageCode.DIRECTORY_MODAL_NAME_LABEL ]: 'Name *',
        [ MessageCode.DIRECTORY_MODAL_MESSAGE_MULTIPLE_DELETE_SUCCESS ]: 'Successfully deleted selected files.',
        [ MessageCode.DIRECTORY_MODAL_MESSAGE_MULTIPLE_UPDATE_SUCCESS ]: 'Successfully updated selected files.',
    
        [ MessageCode.ERROR_WEBSOCKET_CONNECTION_FAILURE ]: 'Could not connect to the Server. Please try again later.',

        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_EXECUTE_LABEL ]: ' Execute',
        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_TERMINATE_LABEL ]: ' Terminate',
        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_UNQUEUE_LABEL ]: ' Unqueue',
        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_RUNNING_STATUS ]: 'Running',
        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_QUEUED_STATUS ]: 'Queued',
        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_START_EXECUTION_LABEL ]: 'Execution Start: {{ timestamp }}',
        [ MessageCode.WORKFLOW_EVENTS_WORKFLOW_LAST_EXECUTION_LABEL]: 'Last Execution: {{ timestamp }}',
        [ MessageCode.WORKFLOW_EVENTS_QUEUED_WORKFLOW_POSITION ]: '{{ position }} in queue',
        [ MessageCode.WORKFLOW_EVENTS_QUEUED_WORKFLOW_COUNT ]: '{{ count }} item(s) currently in Queue',
        [ MessageCode.WORKFLOW_EVENTS_VIEW_ALL_WORKFLOWS_LABEL ]: 'View all workflows',

        [ MessageCode.ERROR_WORKFLOW_EVENTS_EMPTY ]: 'No active events at the moment.',
        [ MessageCode.ERROR_WORKFLOW_EVENTS_AGENT_LIST_EMPTY ]: 'There are no active agents at the moment.',
        [ MessageCode.ERROR_WORKFLOW_EVENTS_WORKFLOW_LIST_EMPTY ]: 'No workflows at the moment.',

        [ MessageCode.WORKFLOW_EVENTS_MODAL_WORKFLOW_LIST_TAB ]: 'Workflow List',
        [ MessageCode.WORKFLOW_EVENTS_MODAL_ACTIVE_LIST_TAB ]: 'Active',
        [ MessageCode.WORKFLOW_EVENTS_MODAL_WORKFLOW_LIST_TAB_DESC ]: 'Here are the workflows associated with this machine.',
        [ MessageCode.WORKFLOW_EVENTS_MODAL_ACTIVE_LIST_TAB_DESC ]: 'Currently running and queued workflows.',
        [ MessageCode.WORKFLOW_EVENTS_MODAL_CLOSE_BUTTON_LABEL ]: 'Close',
    
        [ MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_TERMINATE_HEADER ]: 'Terminate Workflow',
        [ MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_UNQUEUE_HEADER ]: 'Unqueue Workflow',
        [ MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_BODY_CONTENT ]: '<0>You are about to remove <1><0>{{ name }}</0></1> from the queue.</0><1>Are you sure you want to proceed?</1>',
        [ MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_CONFIRM_BUTTON ]: 'Confirm',
        [ MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_CLOSE_BUTTON ]: 'Cancel',

        [ MessageCode.PREMIUM_ACTIVITY_HEADER_DESCRIPTION ]: 'Drag and drop activities to add or remove them from the premium list.',
        [ MessageCode.PREMIUM_ACTIVITY_ADD_SUCCESS ]: '{{ name }} Activity was added successfully.',
        [ MessageCode.PREMIUM_ACTIVITY_REMOVE_SUCCESS ]: '{{ name }} Activity was removed successfully.',
        [ MessageCode.PREMIUM_ACTIVITY_LIST_LABEL ]: 'List of Activities',
        [ MessageCode.PREMIUM_ACTIVITY_PRO_LIST_LABEL ]: 'Premium Activites for <1>Professional</1>/<3>Enterprise</3> Version', 
    },
};
