import AccountReducer from 'reducers/AccountReducer';
import LicenseReducer from 'reducers/LicenseReducer';
import UserReducer from 'reducers/UserReducer';
import ReportReducer from 'reducers/ReportReducer';
import WorkflowReducer from 'reducers/WorkflowReducer';
import ActivityReducer from 'reducers/ActivityReducer';
import DepartmentReducer from 'reducers/DepartmentReducer';
import RoleReducer from 'reducers/RoleReducer';
import StatusReducer from 'reducers/StatusReducer';
import TaskReducer from 'reducers/TaskReducer';
import ScheduleReducer from 'reducers/ScheduleReducer';
import DirectoryReducer from 'reducers/DirectoryReducer';
import ActiveAgentReducer from 'reducers/ActiveAgentReducer';

import { combineReducers } from 'redux';

export default combineReducers({
    account: AccountReducer,
    license: LicenseReducer,
    user: UserReducer,
    report: ReportReducer,
    workflow: WorkflowReducer,
    activity: ActivityReducer,
    department: DepartmentReducer,
    role: RoleReducer,
    status: StatusReducer,
    task: TaskReducer,
    schedule: ScheduleReducer,
    directory: DirectoryReducer,
    activeAgent: ActiveAgentReducer
});