import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';
import { Trans } from 'react-i18next';
import { AgentService } from 'services';
import { WorkflowAction } from 'actions';
import MessageCode from 'constants/MessageCode';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, Filter, Table, AuthorityChecker } from 'components';

const getExecutionLogs = async (dispatch, from, to) => {
    try {
        const { data } = await AgentService.getWorkflowExecutionLogs(from, to);

        const mappedData = _.map(data, (dataItem, index) => {
            return {
                ...dataItem,
                runtime: moment(dataItem.executionEnd).diff(moment(dataItem.executionStart)),
                index,
            };
        });

        dispatch(WorkflowAction.successExecutionLogs(mappedData));
    } catch (error) {
        dispatch(WorkflowAction.failureExecutionLogs(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getExecutionLogs(dispatch, 
            moment().startOf('day').valueOf(), 
            moment().startOf('day').add(1, 'day').valueOf());
    };
};

const WorkflowLogsScreen = () => {
    const dispatch = useDispatch();

    useEffect(onInitialize(dispatch), []);

    const [ ui, setUi ] = useState({
        filter: {
            text: '',
            from: null,
            to: null,
        },
    });

    const onFilterText = (text) => {
        setUi({
            ...ui,
            filter: {
                ...ui.filter,
                text,
            },
        });
    };

    const onFilterDateRange = ([ from, to ]) => {
        getExecutionLogs(dispatch, from, to);
        setUi({
            ...ui,
            filter: {
                ...ui.filter,
                from, 
                to,
            },
        });
    };

    const executionTableHeaders = [{
        'field': 'executionStart',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_EXECUTION_START_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            const executionDate = moment(record.executionStart);
            return executionDate.format('YYYY/MM/DD HH:mm:ss');
        },
        'sortable': true,
    }, {
        'field': 'executionEnd',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_EXECUTION_END_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            const executionDate = moment(record.executionEnd);
            return executionDate.format('YYYY/MM/DD HH:mm:ss');
        },
        'sortable': true,
    }, {
        'field': 'workflowName',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_WORKFLOW_NAME_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            let companyName = '';
            let deptName = '';
            if (record.executedBy) {
                if (record.executedBy.company_) {
                    companyName = record.executedBy.company_.name;
                }

                if (record.executedBy.department) {
                    deptName = record.executedBy.department.name;
                }
            }

            let subtext = '';
            if (companyName) {
                subtext = `${companyName}`
            }

            if (deptName) {
                subtext = `${subtext} > ${deptName}`;
            }

            return (
                <>
                    <span>{ record.workflowName }</span><br />
                    <small className='text-muted'>{ subtext }</small>
                </>
            );
        },
        'sortable': true,
    }, {
        'field': 'executedBy.id',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_EXECUTED_BY_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            return (
                <>
                    <span>{ `${record.executedBy.firstName} ${record.executedBy.lastName}` }</span><br />
                    <small className="text-muted">{ `${record.emailAddress} (${record.macAddress})` }</small>
                </>
            );
        },
        'sortable': true,
    }, {
        'field': 'statusType',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_EXECUTION_STATUS_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            const statusType = record.statusType|| 'unknown';
            let className = 'text-white';
            if (statusType === 'success') {
                className = 'text-success';
            } else if (statusType === 'faulted') {
                className = 'text-danger';
            }
            return (
            <span className={ className }>{ statusType.toUpperCase() }</span>
            );
        },
        'sortable': true,
    }, {
        'field': 'runtime',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_RUNTIME_HEADER } />,
        'headerClasses': 'align-top text-right',
        'formatter': (record) => {
            const executionStart = moment(record.executionStart);
            const executionEnd = moment(record.executionEnd);

            var ms = executionEnd.diff(executionStart);
            var d = moment.duration(ms);
            var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

            return (
                <>
                    <p className="text-right">{ s }</p>
                </>
            );
        },
        'sortable': true,
    }]

    const executionLogs = useSelector(state => state.workflow.executionLogs);
    const filteredExecutionLogs = _.filter(executionLogs, (executionLog) => {
        let companyName = '';
        let deptName = '';
        let fullName = '';
        let emailAddress = executionLog.emailAddress;
        let macAddress = executionLog.macAddress;
        let workflowName = executionLog.workflowName;
        let statusType = executionLog.statusType;

        if (executionLog.executedBy) {
            fullName = `${executionLog.executedBy.firstName} ${executionLog.executedBy.lastName}`;
            if (executionLog.executedBy.company_) {
                companyName = executionLog.executedBy.company_.name;
            }

            if (executionLog.executedBy.department) {
                deptName = executionLog.executedBy.department.name;
            }
        }
        return ((!ui.filter.text 
                || workflowName.toLowerCase().includes(ui.filter.text.toLowerCase())
                || companyName.toLowerCase().includes(ui.filter.text.toLowerCase())
                || deptName.toLowerCase().includes(ui.filter.text.toLowerCase())
                || fullName.toLowerCase().includes(ui.filter.text.toLowerCase())
                || emailAddress.toLowerCase().includes(ui.filter.text.toLowerCase())
                || macAddress.toLowerCase().includes(ui.filter.text.toLowerCase())
                || statusType.toLowerCase().includes(ui.filter.text.toLowerCase())));
                // && (!ui.filter.from || (executionLog.executionStart >= ui.filter.from && executionLog.executionEnd >= ui.filter.from)) 
                // && (!ui.filter.to || (executionLog.executionStart <= ui.filter.to && executionLog.executionEnd <= ui.filter.to)));
    });

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_WORKFLOW_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_WORKFLOW_LOGS_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_ERROR_LOGS"]}>
                <Filter>
                    <Filter.Text
                        label={ 'Search' }
                        onChange={ onFilterText }>
                    </Filter.Text>
                    <Filter.DateRange 
                        value={[ 
                            moment().startOf('day').valueOf(), 
                            moment().startOf('day').add(1, 'day').valueOf()
                        ]}
                        label={ 'Show by Date' }
                        onChange={ onFilterDateRange }>
                    </Filter.DateRange>
                </Filter>

                <Table keyField={ 'index' } 
                    headers={ executionTableHeaders }
                    data={ filteredExecutionLogs }
                    isSortable={ true }
                    isPaginated={ true }
                    itemPerPage={ 10 }>
                </Table>
            </AuthorityChecker>
        </>
    );
};

export default WorkflowLogsScreen;