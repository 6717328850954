import React from 'react';
import _ from 'underscore';

const Item = ({ active, children }) => {
    return (
        <>
            { active
                ? <span className="brand-yellow">{ children }</span>
                : children
            }
        </>
    );
};

const Breadcrumb = ({ children }) => {
    const appendedChildren = _.reduce(children, (prevChild, currChild) => {
        return <>{ prevChild } > { currChild }</>;
    });

    return (
        <h4 className="text-secondary">{ appendedChildren }</h4>
    );
};

Breadcrumb.Item = Item;

export default Breadcrumb;