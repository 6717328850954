import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { AccountService } from 'services';
import MessageCode from 'constants/MessageCode';

const onInitialize = (ui, setUi, initialValues) => {
    return () => {
        setUi({
            ...ui,
            name: initialValues ? initialValues.fullName : '',
            license: initialValues ? initialValues.license : null,
        });
    };
};
const AccountArchiveModal = ({ isShown, onSuccess, onCancel, initialValues }) => {
    const [ ui, setUi ] = useState({
        name: '',
    });
    const [ disabled, setDisabled ] = useState(false);  

    useEffect(onInitialize(ui, setUi, initialValues), [ JSON.stringify(initialValues) ]);

    
    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                await AccountService.archiveSoloAccount(ui.license.id);
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess({ ...ui });

            } catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_LICENSE_ID_REQUIRED:
                    case MessageCode.ERROR_LICENSE_NOT_EXIST: {
                        onCancel(MessageCode.ERROR_LICENSE_NOT_EXIST);
                        break;
                    }

                    case MessageCode.ERROR_LICENSE_NOT_SOLO: {
                        onCancel(MessageCode.ERROR_LICENSE_NOT_SOLO);
                        break;
                    }

                    default: {
                        console.error(data);
                        onCancel(MessageCode.ERROR_GENERAL_INTERNAL);
                        break;
                    }
                }
            }   
        }
    };

    return (
        <Modal show={ isShown } onHide={ onCancel } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.MODAL_ACCOUNT_DELETE_HEADER } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.MODAL_ACCOUNT_DELETE_TEXT }
                        values={ { 
                            name: ui.name, 
                            licenseType: ui.license ? ui.license.type.name: '',
                        } }>
                    <p className="wrap-long-text">DELETE_PRETEXT <span className="brand-yellow"><strong>ACCOUNT_IDENTIFIER</strong></span> DELETE_POST_TEXT</p>
                    <p className="text-light">CONFIRMATION_TEXT</p>
                </Trans>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-account-btn" }
                    onClick={ () => onCancel() }>
                    <Trans i18nKey={ MessageCode.MODAL_ACCOUNT_CANCEL_BUTTON } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-account-btn" }
                    disabled={ disabled  }
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.MODAL_ACCOUNT_DELETE_BUTTON } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AccountArchiveModal;