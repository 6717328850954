import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { RoleService } from 'services';
import { Toast } from 'components';

const onInitialize = (initialValues, setRoleName) => {
    return () => {
        setRoleName(initialValues && (() => {
            let displayName = initialValues.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
    };
};

const RoleDeleteModal = ({ isShown, onClose, onSuccess, initialValues }) => {
    const [ roleName, setRoleName ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    useEffect(onInitialize(initialValues, setRoleName), [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try{
                await RoleService.deleteRole(initialValues.id);

                toast.success(
                    <Toast data={{ name: roleName }}
                        message={MessageCode.ROLE_MODAL_MESSAGE_DELETE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();
            }
            catch ({ data }) {
                setDisabled(false);
                if (data.key === MessageCode.ERROR_ROLE_DELETE_ASSOCIATED_WITH_USER) {
                    toast.error(
                        <Trans i18nKey={ MessageCode.ERROR_ROLE_DELETE_ASSOCIATED_WITH_USER } 
                                    values={ { name: roleName } } />
                    );
                } else if (data.key === MessageCode.ERROR_GENERAL_PROTECTED) {
                    toast.error(
                        <Trans i18nKey={ MessageCode.ERROR_GENERAL_PROTECTED } 
                                    values={ { name: roleName } } />
                    );
                }else {
                    toast.error(
                        <Trans i18nKey={ MessageCode.ERROR_GENERAL_INTERNAL } />
                    );
                }
                onClose();
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onClose } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.ROLE_MODAL_HEADER_DELETE } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.ROLE_MODAL_MESSAGE_DELETE_TEXT }
                        values={ { name: roleName }}>
                    <p className="wrap-long-text">You are about to delete <span className="brand-yellow"><strong>ROLE_NAME.</strong></span></p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
         
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-role-btn" }
                    onClick={ () => onClose() }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-role-btn" }
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_DELETE } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RoleDeleteModal;