import React, { useState } from 'react';
import _ from 'underscore';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

import { CompanyService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Toast } from 'components';

const companyNameSelector = (event) => event ? event.target.value : null;
const companyNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_COMPANY_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_COMPANY_NAME_TOO_LONG;
    }

    const invalidCharacters = '\\ / : * ? " | < >'.split(" ");

    if (invalidCharacters.some(v => value.includes(v))) {
        return MessageCode.ERROR_COMPANY_NAME_INVALID; 
    }

    if (value.trim().endsWith('.')) {
        return MessageCode.ERROR_COMPANY_NAME_INVALID;
    }

    return null;
};

const industrySelector = (event) => event ? event.target.value: null;
const industryValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_COMPANY_INDUSTRY_REQUIRED;
    }

    return null;
};

const firstNameSelector = (event) => event ? event.target.value : null;
const firstNameValidator = (value) => {
    if (!value) { 
        return MessageCode.ERROR_USER_FIRST_NAME_REQUIRED; 
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_USER_FIRST_NAME_TOO_LONG;
    }

    return null;
};

const lastNameSelector = (event) => event ? event.target.value : null;
const lastNameValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_LAST_NAME_REQUIRED;
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_USER_LAST_NAME_TOO_LONG;
    }

    return null;
};

const emailSelector = (event) => event ? event.target.value : null;
const emailValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_EMAIL_REQUIRED;
    }

    if (value.length > 255) {
        return MessageCode.ERROR_USER_EMAIL_TOO_LONG;
    }

    if ((value.match(/@/g) || []).length !== 1){
        return MessageCode.ERROR_USER_EMAIL_INVALID;
    }

    return null;
};

const passwordSelector = (event) => event ? event.target.value : null;
const passwordValidator = (value) => {

    if (!value) {
        return MessageCode.ERROR_USER_PASSWORD_REQUIRED;
    }

    return null;
};

const confirmPasswordSelector = (event) => event ? event.target.value : null;
const confirmPasswordValidator = (password, confirmPassword) => {
    if (!confirmPassword) {
        return MessageCode.ERROR_USER_CONFIRM_PASSWORD_REQUIRED;
    }
    else if (confirmPassword !== password) {
        return MessageCode.ERROR_USER_PASSWORD_MISMATCH;
    }

    return null;
};

const CompanyCreateModal = ( {isShown, onClose, onSuccess, industryList} ) => {
    const [onChangeCompanyName, companyName] = useValidation(companyNameSelector, companyNameValidator, '');
    const [onChangeIndustry, industry] = useValidation(industrySelector, industryValidator, 'default');
    const [onChangeFirstName, firstName] = useValidation(firstNameSelector, firstNameValidator);
    const [onChangeLastName, lastName] = useValidation(lastNameSelector, lastNameValidator);
    const [onChangeEmail, email] = useValidation(emailSelector, emailValidator);
    const [onChangePassword, password] = useValidation(passwordSelector, passwordValidator);
    const [onChangeConfirmPassword, confirmPassword] = useValidation(confirmPasswordSelector, (value) => confirmPasswordValidator(password.value, value));
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false); 
    const {t} = useTranslation();

    const resetFields = () => {
        companyName.reset('');
        industry.reset('default');
        firstName.reset('');
        lastName.reset('');
        email.reset('');
        password.reset('');
        confirmPassword.reset('');
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);
            
            try{
                await CompanyService.addCompany(companyName.value, industry.value, firstName.value, lastName.value, email.value, password.value,);

                toast.success(
                    <Toast data={{ name: companyName.value }}
                        message={MessageCode.COMPANY_MODAL_CREATE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                resetFields();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch(data.key){
                    case MessageCode.ERROR_COMPANY_NAME_REQUIRED:{
                        companyName.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_COMPANY_NAME_INVALID:
                    case MessageCode.ERROR_COMPANY_NAME_DUPLICATE:{
                        companyName.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_COMPANY_INDUSTRY_REQUIRED:
                    case MessageCode.ERROR_COMPANY_INDUSTRY_INVALID:{
                        industry.setError(data.key);
                        break;
                    }
                    
                    default:{
                        console.error(data);
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={onClose} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_SUBTEXT } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_NAME_LABEL } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-create-company-field" }
                            value={companyName.value}
                            onChange={ onChangeCompanyName }
                            isInvalid={ companyName.dirty && companyName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ companyName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_INDUSTRY_LABEL } />
                        </Form.Label>
                        <Form.Control as="select"
                            id={ "industry-create-company-field" }
                            className='custom-select'
                            value={industry.value}
                            onChange={ onChangeIndustry }
                            isInvalid={ industry.dirty && industry.error}>
                            <option value='default' disabled>{t(MessageCode.COMPANY_MODAL_CREATE_INDUSTRY_DEFAULT)}</option>
                            { industryList.map(item => <option key={item.key} value={item.key}>{item.name}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ industry.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Modal.Header>
                        <span style={{fontSize: "16px", marginLeft: "-15px"}}>Company Head</span>
                        {/* <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_SUBTEXT } /> */}
                    </Modal.Header><br/>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.MODAL_USER_FIRST_NAME_LABEL } />
                            </Form.Label>
                            <Form.Control as="input" 
                                type="text"
                                id={ "firstname-create-user-field" }
                                value={ firstName.value || '' }
                                onChange={ onChangeFirstName }
                                isInvalid={ firstName.dirty && firstName.error }>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={ firstName.error } />
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_LAST_NAME_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="text"
                                id={ "lastname-create-user-field" }
                                value={lastName.value || ''}
                                onChange={onChangeLastName}
                                isInvalid={lastName.dirty && lastName.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={lastName.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.MODAL_USER_EMAIL_LABEL} />
                        </Form.Label>
                        <Form.Control as="input"
                            type="email"
                            id={ "email-create-user-field" }
                            value={email.value || ''}
                            onChange={onChangeEmail}
                            isInvalid={email.dirty && email.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={email.error} />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_PASSWORD_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="password"
                                id={ "password-create-user-field" }
                                value={password.value || ''}
                                onChange={(event) => {
                                    onChangePassword(event);
                                    confirmPassword.validate();
                                }}
                                isInvalid={password.dirty && password.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={password.error} />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_CONFIRM_PASSWORD_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="password"
                                id={ "confirm-password-create-user-field" }
                                value={confirmPassword.value || ''}
                                onChange={onChangeConfirmPassword}
                                isInvalid={confirmPassword.dirty && confirmPassword.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={confirmPassword.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group>  

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-create-company-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_CLEAR_BUTTON } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-create-company-btn" }
                        disabled={ disabled || companyName.error || industry.error || firstName.error || lastName.error 
                            || email.error || password.error || confirmPassword.error }>
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_ADD_BUTTON } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CompanyCreateModal;