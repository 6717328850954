import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'underscore';

import { DepartmentAction, UserAction, LicenseAction } from 'actions';
import { DepartmentService, UserService, CompanyService} from 'services';

import { 
    Breadcrumb,
    Tooltip,
    Table,
    Filter,
    DepartmentCreateModal,
    DepartmentUpdateModal,
    DepartmentDeleteModal,
    DepartmentListDisplay,
    AuthorityChecker
} from 'components';
import MessageCode from 'constants/MessageCode';

const getDepartmentList = async (dispatch) => {
    try {
        const { data } = await DepartmentService.getDepartmentList("");
        dispatch(DepartmentAction.successDepartmentList(data));
    }
    catch (error) {
        dispatch(DepartmentAction.failureDepartmentList(error));
    }
};

const getDepartmentListForDisplay = async (dispatch) => {
    try {
        const { data } = await DepartmentService.getAccessibleDepartmentListForDisplay();
        dispatch(DepartmentAction.successDepartmentListForDisplay(data));
    }
    catch (error) {
        dispatch(DepartmentAction.failureDepartmentListForDisplay(error));
    }
};

const getCompanyHeads = async(dispatch, tenantId, departmentId) => {
     try {
        const { data } = await UserService.getDepartmentHeadList(tenantId, departmentId);
        dispatch(UserAction.successUserList(data));
    }
    catch (error) {
        dispatch(UserAction.failureUserList(error));
    }
}

const getCompanyList = async (dispatch) => {
    try {
        const { data } = await CompanyService.getCompanyList();
        dispatch(LicenseAction.successCompanyList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureCompanyList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getDepartmentList(dispatch);
        getDepartmentListForDisplay(dispatch);
        getCompanyList(dispatch);
    };
};

const traverseDeptTree = (node, nodeList, parent) => {
    node.children.forEach(child => {
        traverseDeptTree(child, nodeList, node)
    });

    var data = {
        name: node.name,
        id: node.id,
        archived: node.archived,
        parent: parent
    }

    if(!data.archived){
        nodeList.push(data)

    }
    
    if(node.children.length == 0){
        return nodeList
    }

    return nodeList
}

const ManagementDepartmentScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const company = useSelector(state => state.user.company);
    const companyList = useSelector(state => state.license.companyList);
    const departmentList = useSelector(state => state.department.departmentList);
    const userList = useSelector(state => state.user.userList);
    const userAuths = useSelector(state => state.user.auths);
    const tenantId =  (!userAuths.includes("ADMIN_ACCESS_LEVEL"))? company.tenantId: null;
    const isAdmin = (company == null)? true: false;

    const [ uiState, setUiState ] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        selectedDepartment: null,
        isAdmin: false,
        filter: {
            name: '',
        }
    });
    
    useEffect(onInitialize(dispatch), []);

    const departmentTableColumnList = [{
        'field': 'name',
        'name': t(MessageCode.TABLE_DEPARTMENT_NAME_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    },{
        'field': 'head.completeName',
        'name': t(MessageCode.TABLE_DEPARTMENT_HEAD_HEADER),
        'headerClasses': 'align-top',
        'formatter': (department) => (department.head)?department.head.completeName : '-',
        'sortable': true,
    },{
        'field': 'actions',
        'name': '',
        'formatter': (department) => {
            return (
                <div className="text-center">
                    <AuthorityChecker requiredAuths={["EDIT_DEPARTMENT"]}>
                        <Tooltip placement="top-start"
                            value={<Trans i18nKey={MessageCode.DEPARTMENT_TOOLTIP_EDIT} />}>
                            <Link to="#"
                                onClick={() => onRequestUpdateDepartment(department.id)}>
                                <i className="fa fa-edit"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    &nbsp;
                    <AuthorityChecker requiredAuths={["DELETE_DEPARTMENT"]}>
                        <Tooltip placement="top-start"
                            value={<Trans i18nKey={MessageCode.DEPARTMENT_TOOLTIP_DELETE} />}>
                            <Link to="#"
                                onClick={() => onRequestDeleteDepartment(department.id)}>
                                <i className="fa fa-trash"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                </div>
            );
        },
    }];

    const filteredDepartmentList = _.filter(departmentList, (department) => {
        return ((department && !department.archived) && 
            (!uiState.filter.name 
                || department.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
                || (department.head && department.head.completeName.toLowerCase().includes(uiState.filter.name.toLowerCase()))));
    });

    const onRequestFilterDepartmentName = (name) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name,
            },
        });
    };

    const onRequestCreateDepartment = async () => {        
        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onRequestUpdateDepartment = async (id) => {
        try{
            const { data } = await DepartmentService.getSpecificDepartment(id);
            await getCompanyHeads(dispatch, data.tenantId, id);
            
            setUiState({
                ...uiState,
                showUpdateModal: true,
                selectedDepartment: data
            });
        } catch (error) {
            await getDepartmentList(dispatch);

            setUiState({
                ...uiState,
                showUpdateModal: false,
                selectedDepartement: null
            })
        }
    };

    const onRequestDeleteDepartment = async (id) => {
        try{
            const { data } = await DepartmentService.getSpecificDepartment(id);

            setUiState({
                ...uiState,
                showDeleteModal: true,
                selectedDepartment: data
            });
        } catch (error) {
            await getDepartmentList(dispatch);

            setUiState({
                ...uiState,
                showDeleteModal: false,
                selectedDepartement: null
            })
        }
    };

    const onCancelCreateDepartment = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onSuccessCreateDepartment = async () => {
        await getDepartmentList(dispatch);
        await getDepartmentListForDisplay(dispatch);
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onCancelUpdateDepartment = () => {
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedDepartment: null
        });
    };

    const onSuccessUpdateDepartment = async () => {
        await getDepartmentList(dispatch);
        await getDepartmentListForDisplay(dispatch);
  
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedDepartment: null,
        });
    };

    const onCancelDeleteDepartment = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedDepartment: null,
        });
    };

    const onSuccessDeleteDepartment = async () => {
        await getDepartmentList(dispatch);
        await getDepartmentListForDisplay(dispatch);
        
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedDepartment: null,
        });
    };

    const onCompanyChange = async (id, departmentId) => {
        await getCompanyHeads(dispatch, id, departmentId);        
    };

    return (
        <section>
            <AuthorityChecker requiredAuths={["CREATE_DEPARTMENT"]}>
                {/* Add button */}
                <Link to="#" className="float over-accordion" onClick={onRequestCreateDepartment} id={ "create-department-btn" }>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_HEADER_CREATE } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            {/* Create Department Modal */}
            <DepartmentCreateModal isShown={uiState.showCreateModal} 
                onClose={onCancelCreateDepartment} 
                onSuccess={onSuccessCreateDepartment}
                departmentList={departmentList || []}
                userList={userList || []}
                companyList={companyList || []}
                isAdmin={isAdmin}>
            </DepartmentCreateModal>

            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_USER_MANAGEMENT_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                <Trans i18nKey={ MessageCode.BREADCRUMB_DEPARTMENTS_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            {/* Update Department Modal */}
            <DepartmentUpdateModal isShown={uiState.showUpdateModal && !!uiState.selectedDepartment} 
                onClose={onCancelUpdateDepartment} 
                onSuccess={onSuccessUpdateDepartment}
                departmentList={departmentList || []}
                userList={userList || []}
                initialValues={uiState.selectedDepartment}
                companyList={companyList || []}
                onCompanyChange={onCompanyChange}
                isAdmin={isAdmin}>
            </DepartmentUpdateModal>

            {/* Delete Department Modal */}
            <DepartmentDeleteModal isShown={uiState.showDeleteModal && !!uiState.selectedDepartment } 
                onClose={onCancelDeleteDepartment} 
                onSuccess={onSuccessDeleteDepartment}
                initialValues={uiState.selectedDepartment}>
            </DepartmentDeleteModal>

            <AuthorityChecker requiredAuths={["VIEW_DEPARTMENT"]}>
                <Filter>
                    <Filter.Text label={'Search'}
                        onChange={onRequestFilterDepartmentName}>
                    </Filter.Text>
                </Filter>
                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={filteredDepartmentList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={departmentTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TABLE_DEPARTMENT_EMPTY_PLACEHOLDER} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>
        </section>
       
    );
};

export default ManagementDepartmentScreen;