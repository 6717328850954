import React from 'react';

const publicUrl = process.env.PUBLIC_URL;

const DashboardSidebar = ({ isToggled, children }) => {
    return (
        <nav id="sidebar" className={ isToggled ? 'active' : '' }>
            <div className="sidebar-header text-center">
                <img className={ `img-fluid sidebar-logo ${ isToggled ? 'hide' : ''}` } 
                    src={ `${publicUrl}/img/rax-logo-white.png` } 
                    alt="RAX Logo" />
                <strong>RAX</strong>
            </div>

            { children }
        </nav>
    );
};


export default DashboardSidebar;