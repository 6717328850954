import ActionType from 'constants/ActionType';

export default {    
    successAuthorityList: (authorityList) => {
        return {
            type: ActionType.AUTHORITY_LIST_SUCCESS,
            payload: { authorityList },
        };
    },

    failureAuthorityList: (error) => {
        return {
            type: ActionType.AUTHORITY_LIST_FAILURE,
            payload: { error },
        };
    },

    successRoleList: (roleList) => {
        return {
            type: ActionType.ROLE_LIST_SUCCESS,
            payload: { roleList },
        };
    },

    failureRoleList: (error) => {
        return {
            type: ActionType.ROLE_LIST_FAILURE,
            payload: { error },
        };
    }
};