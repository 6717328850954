import axios from 'axios';
import { saveAs } from 'file-saver';
import HeaderKey from 'constants/HeaderKey';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getDirectoryList: async (parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/view`;

        const directory = {
            parent: parentDirectoryName ? parentDirectoryName.trim() : '',
        };
        return await axios.post(url, directory);
    },

    addDirectory: async (directoryName, parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/create`;

        const directory = {
            name: directoryName ? directoryName.trim() : '',
            parent: parentDirectoryName ? parentDirectoryName.trim() : '',
        };

        return await axios.post(url, directory);
    },

    updateDirectory: async (currentDirectoryName, newDirectoryName, parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/update`;

        const directory = {
            current: currentDirectoryName ? currentDirectoryName.trim() : '',
            new: newDirectoryName ? newDirectoryName.trim() : '',
            parent: parentDirectoryName ? parentDirectoryName.trim() : '',
        };

        return await axios.post(url, directory);
    },

    updateMultipleFiles: async (files, newFileName, parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/update/multiple`;

        const data = {
            files: files,
            newFileName: newFileName,
            directory: parentDirectoryName
        };

        return await axios.post(url, data);
    },

    deleteDirectory: async (directoryName, parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/delete`;

        const directory = {
            name: directoryName ? directoryName.trim() : '',
            parent: parentDirectoryName ? parentDirectoryName.trim() : '',
        };

        return await axios.post(url, directory);
    },

    deleteMultipleFiles: async (files, parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/delete/multiple`;

        const data = {
            files: files,
            directory: parentDirectoryName
        };
        return await axios.post(url, data);
    },

    downloadSpecificDirectory: async (directoryName, parentDirectoryName) => {
        const url = `${apiUrl}/api/directory/download?name=${directoryName}&parent=${parentDirectoryName}`;
        const encoded = encodeURI(url);
        const directory = {
            name: directoryName,
            parent: parentDirectoryName
        };

        const response = await axios.get(url, { responseType: 'blob' });

        const contentDisposition = response.headers[HeaderKey.CONTENT_DISPOSITION];
        const filenameRegex = /.*filename\*=(.*)''(.*)/g;

        const match = filenameRegex.exec(contentDisposition);
        if (match) {
            const [ , , filename ] = match;
            saveAs(response.data, decodeURIComponent(filename));
        }
    },

    downloadMultipleFiles: async (files, parentDirectoryName) => {
        let url = `${apiUrl}/api/directory/download/multiple?directory=${parentDirectoryName}`;

        files.forEach(file => {
            if (file.type === 'workflow') {
                url += `&fileNames=${file.filename}`
            } else if (file.type === 'directory') {
                url += `&fileNames=${file.name}`
            }
        });

        const response = await axios.get(url, { responseType: 'blob' });

        const contentDisposition = response.headers[HeaderKey.CONTENT_DISPOSITION];
        const filenameRegex = /.*filename\*=(.*)''(.*)/g;

        const match = filenameRegex.exec(contentDisposition);
        if (match) {
            const [ , , filename ] = match;
            saveAs(response.data, decodeURIComponent(filename));
        }
    },

    getRootDirectory: async () => {
        const url = `${apiUrl}/api/directory/root-directory`;
        return await axios.get(url);
    },
}