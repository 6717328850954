import React from 'react';

import { Link } from 'react-router-dom';

import { Card } from 'react-bootstrap';

import {
    WidgetHighcharts 
} from 'components';


const WidgetCard = ({ widgetTitle, widgetKey, options, closeWidget, onDragStart, children }) => {
    
    return (
        <Card className="text-white bg-dark mb-3 shadow" draggable
            onDragStart = {onDragStart}
            >
            <div className="card-header">
                { widgetTitle }
                <span className="float-right">
                    <span className="m-1 brand-ltr-blue"><i className="fa fa-arrows-alt"></i></span>
                    <Link to="#" onClick={() => closeWidget(widgetKey)} id={ "close-widget-"+widgetKey }><i className="fa fa-times"></i></Link>
                </span>
            </div>
            <div className="card-body">
                <WidgetHighcharts options={ options } />
                {children}
            </div>
        </Card>
    );
};

export default WidgetCard;
