import axios from 'axios';
import { saveAs } from 'file-saver';

import HeaderKey from 'constants/HeaderKey';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getSoloAccountList: async () => {
        const url = `${apiUrl}/api/account/solo`;
        return axios.get(url);
    },

    archiveSoloAccount: async (licenseId) => {
        const url = `${apiUrl}/api/license/solo/invalidate`;
        return axios.post(url, { licenseId })
    },

    getAccountList: async (companyId, licenseId) => {
        const url = `${apiUrl}/api/account/list?company=${companyId}&license=${licenseId}`;
        return axios.get(url);
    },

    generateNewKey: async (companyId, licenseId, departmentId) => {
        const url = `${apiUrl}/api/account/generate`;

        const data = new FormData();
        data.set('company', companyId);
        data.set('license', licenseId);
        data.set('department', departmentId);

        const config = {
            headers: {
                'OT4-APP-SECRET': 'MtC08UZ6E2s5BNUDWb6BfVTDwcyfI3CG',
            },
        };
        return axios.post(url, data, config);
    },

    generateNewKeyFromFile: async (companyId, licenseId, registrationFile, departmentId) => {
        const url = `${apiUrl}/api/account/from-file`;

        const data = new FormData();
        data.set('company', companyId);
        data.set('license', licenseId);
        data.set('regFile', registrationFile);
        data.set('department', departmentId);

        const config = {
            headers: {
                'OT4-APP-SECRET': 'MtC08UZ6E2s5BNUDWb6BfVTDwcyfI3CG',
            },
        };
        return axios.post(url, data, config);
    },

    deleteLicenseKey: async (companyId, licenseId, accountId) => {
        const url = `${apiUrl}/api/account/invalidate`;

        const data = new FormData();
        data.set('company', companyId);
        data.set('license', licenseId);
        data.set('account', accountId);

        return axios.post(url, data);
    },

    getLicenseFileList: async (companyId, licenseId, accountId) => {
        const url = `${apiUrl}/api/account/list-license-file`;

        const data = new FormData();
        data.set('company', companyId);
        data.set('license', licenseId);
        data.set('account', accountId);

        return axios.post(url, data);
    },

    exportLicenseFile: async (filename) => {
        const url = `${apiUrl}/api/account/export-license-file/${filename}`;

        const response = await axios.get(url, { responseType: 'blob' });

        const contentDisposition = response.headers[HeaderKey.CONTENT_DISPOSITION];
        const filenameRegex = /.*filename\*=(.*)''(.*)/g;

        const match = filenameRegex.exec(contentDisposition);
        if (match) {
            const [ , , filename ] = match;
            saveAs(response.data, decodeURIComponent(filename));
        }
    }
};