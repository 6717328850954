import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

import { ScheduleService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { FormControl } from 'components';

import moment from 'moment';

const usersSelector = (event) => {
    return event ? event : null;
};

const usersValidator = (value) =>{
    if(!value){
        return MessageCode.ERROR_NO_USERS_SELECTED
    }
    return null
}

const dateSelector = (event) => {
    return event ? event : null;
};

const dateValidator = (value) =>{
    if(!value){
        return MessageCode.ERROR_NO_USERS_SELECTED
    }
    return null
}

const selectedDaysSelector = (event) => {
    return event ? event : [];
};

const selectedDaysValidator = (value) =>{
    if(!value.length){
        return MessageCode.ERROR_NO_USERS_SELECTED
    }
    return null
}

const shiftSelector = (event) => {
    var dateInMillis = moment(new Date(event));
    return event ? event : null;
};

const shiftValidator = (value) =>{
    if(!value){
        return MessageCode.ERROR_NO_USERS_SELECTED
    }
    return null
}

const dateInitializer = () =>{
    return moment(new Date()).valueOf()
}

const timeStartInitializer = () =>{
    // return moment.hour()*3600 + moment.minutes()*60;
    return moment().valueOf()
}
const timeEndInitializer = () =>{
    return moment().add(9,'hours').valueOf()
}

const ScheduleCreateModal = ({ isShown, onSuccess, onCancel, userList }) => {
    const [ , general ] = useValidation(null, null, '');
    const { t } = useTranslation();

    const [ onChangeUsers , users ] = useValidation(usersSelector, usersValidator);
    const [ onChangeDateFrom , dateFrom ] = useValidation(dateSelector, dateValidator, dateInitializer);
    const [ onChangeDateTo , dateTo ] = useValidation(dateSelector, dateValidator, dateInitializer);
    const [ onChangeSelectedDays , selectedDays ] = useValidation(selectedDaysSelector, selectedDaysValidator, []);
    const [ onChangeShiftStart , shiftStart ] = useValidation(shiftSelector, shiftValidator, timeStartInitializer);
    const [ onChangeShiftEnd , shiftEnd ] = useValidation(shiftSelector, shiftValidator, timeEndInitializer);
    const [ isValidDateRange , setValidDateRange ] = useState(true);
    const [ disabled, setDisabled ] = useState(false);

    const validateDateRange = () => {
        if(dateFrom.value > dateTo.value){
            return false
        } 
        return true
    }

    const extractSecondsinDate = (dateInMillis) =>{
        dateInMillis = moment(dateInMillis)
        return dateInMillis.hour()*3600 + dateInMillis.minutes()*60
    }
    

    const userInit =
        userList.map((user)=>{
            return {value: user.id, label: user.completeName}
        });

    const modifiers = {
        'weekend': function(weekday) {
          return weekday == 0 || weekday == 6;
        }
    };


    const resetFields = () => {
        selectedDays.reset([]);
        dateFrom.reset("")
        onChangeUsers(null)
        selectedDays.reset([]);
        dateFrom.reset(moment(new Date()).valueOf())
    };

    const onCancelCreate = () => {
        onCancel();
    };


    const onSetScheduleDBReadable = () =>{
        const dateStart = moment(new Date(dateFrom.value)).startOf('day');
        const dateEnd = moment(new Date(dateTo.value));

        var shiftStartInSeconds = extractSecondsinDate(shiftStart.value);
        var shiftEndInSeconds = extractSecondsinDate(shiftEnd.value);

        const weekdays = selectedDays.value.map((day)=>{
            return parseInt(day)
        })

        const userSchedList = [];
        const dateCurrent = dateStart.clone();

        do{

            if(!weekdays.includes(new Date(dateCurrent).getDay())){
                dateCurrent.add(1,"days");
                continue;
            }

            users.value.forEach((user)=>{
                var userSched = {
                    userId: user.value,
                    schedStart: moment(dateCurrent).startOf('day').add(shiftStartInSeconds,"seconds").valueOf(),
                    schedEnd: moment(dateCurrent).startOf('day').add(shiftEndInSeconds,"seconds").valueOf()
                }
                userSchedList.push(userSched);
            })

            dateCurrent.add(1,"days")
        } while(dateCurrent.isSameOrBefore(dateEnd))

        return userSchedList;
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try{
                const scheds = onSetScheduleDBReadable();

                var scheduleList = [];
                scheds.forEach((sched)=>{
                    var schedObj = {
                        user: {
                            id: sched.userId
                        },
                        scheduleStart: sched.schedStart,
                        scheduleEnd: sched.schedEnd,
                        archived: false
                    }
                    scheduleList.push(schedObj);
                })

                await ScheduleService.addSchedule(scheduleList);

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);

                onSuccess({name: "Schedules"}, resetFields);
                resetFields();

            } catch(e){
                setDisabled(false);
                console.error(e)
                general.setError(MessageCode.ERROR_GENERAL_INTERNAL);
            }
        }
    };

    const errorFeedbackStyle = {
        fontSize: '0.85em',
        color: '#DC3545',
        marginLeft: '5px',
    }

    return (
        <Modal show={ isShown } onHide={ onCancelCreate }>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.MODAL_SCHEDULE_CREATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.MODAL_USER_FORM_SUBTEXT } />
                    </small>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.MODAL_USERS_LABEL } />
                            </Form.Label>
                            <FormControl.Multipleselect 
                                id={ "users-create-schedule-field" }
                                value={users} 
                                optionList={userInit} 
                                onChange={onChangeUsers}
                            />
                            <span style={errorFeedbackStyle}>{ users.error ? "No users selected" : ""}</span>

                            {/* <Form.Control.Feedback type="invalid">
                                users.error
                            </Form.Control.Feedback> */}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_DATE_FROM_LABEL} />
                            </Form.Label>
                            <FormControl.DateTime onChange={onChangeDateFrom} value={dateFrom.value} id={ "datefrom-create-schedule-field" }></FormControl.DateTime>
                            <Form.Control.Feedback type="invalid">
                                {/* <Trans i18nKey={password.error} /> */}
                            </Form.Control.Feedback>
                            <span style={errorFeedbackStyle}>{ dateFrom.error ? "Select start date" : ""}</span>
                            <span style={errorFeedbackStyle}>{ dateFrom.value > dateTo.value ? "Invalid date range" : ""}</span>

                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_DATE_TO_LABEL} />
                            </Form.Label>
                            <FormControl.DateTime onChange={onChangeDateTo} value={dateTo.value} id={ "dateto-create-schedule-field" }></FormControl.DateTime>
                            <span style={errorFeedbackStyle}>{ dateTo.error ? "Select end date" : ""}</span>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.MODAL_SELECTED_DAYS_LABEL } />
                            </Form.Label>
                            <FormControl.WeekdaySelection value={selectedDays.value} onChange={onChangeSelectedDays}></FormControl.WeekdaySelection>
                            <span style={errorFeedbackStyle}>{ selectedDays.error ? "Select weekdays" : ""}</span>
                            
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_SCHEDULE_START_LABEL} />
                            </Form.Label>
                            <FormControl.DateTime value={shiftStart.value} dateFormat={false} timeFormat={'h:mm a'} onChange={onChangeShiftStart} id={ "shiftstart-create-schedule-field" }></FormControl.DateTime>
                            <span style={errorFeedbackStyle}>{ shiftStart.error ? "Select shift start" : ""}</span>

                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_SCHEDULE_END_LABEL} />
                            </Form.Label>
                            <FormControl.DateTime value={shiftEnd.value} dateFormat={false} timeFormat={'h:mm a'} onChange={onChangeShiftEnd} id={ "shiftend-create-schedule-field" }></FormControl.DateTime>
                            <span style={errorFeedbackStyle}>{ shiftEnd.error ? "Select shift end" : ""}</span>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary"
                        onClick={ resetFields }>
                        Reset
                    </Button> */}
                    <Button variant="primary"
                        type="submit"
                        id={ "submit-create-schedule-btn" }
                        disabled={ disabled || users.error || dateFrom.error || dateTo.error || selectedDays.error
                            || (dateFrom.value > dateTo.value) || shiftStart.error || shiftEnd.error}
                            >
                        Add
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ScheduleCreateModal;
