import React from 'react';
import _ from 'underscore';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

const CustomContextMenu = ({ menuID, options, children, data }) => {
    if (data.length == 0) {
        return (
            <>{ children }</>
        )
    }
    return (
        <>
            <ContextMenuTrigger id={menuID}>
                { children }
            </ContextMenuTrigger>
            { options && 
                <ContextMenu id={menuID}>
                {
                    _.map(options, (option, index) => {
                        if(!option.hidden) {
                            return(
                                <MenuItem 
                                    key={ index } 
                                    data={ data } 
                                    onClick={ option.clickHandler }
                                    disabled={option.disabled}
                                    className={option.className} >
                                    {option.icon}{ option.name }
                                </MenuItem>
                            )
                        }                        
                    })
                }
                </ContextMenu>
            }
        </>
    );
};

export default CustomContextMenu;