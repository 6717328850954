import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserAction } from 'actions';
import { LoginService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import StorageKey from 'constants/StorageKey';

const emailAddressSelector = (event) => event ? event.target.value : null;
const emailAddressValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_LOGIN_FORM_EMAIL_REQUIRED;
    }

    return null;
};

const passwordSelector = (event) => event ? event.target.value : null;
const passwordValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_LOGIN_FORM_PASSWORD_REQUIRED;
    }

    return null;
};

const LoginForm = ({ onForgotPassword }) => {
    const dispatch = useDispatch();

    const [isRequesting, setRequesting] = useState(false);
    const [onChangeEmailAddress, emailAddress] = useValidation(emailAddressSelector, emailAddressValidator);
    const [onChangePassword, password] = useValidation(passwordSelector, passwordValidator);
    const [loginError, setLoginError] = useState();

    const onLogin = async (event) => {
        event.preventDefault();
        dispatch(UserAction.requestLogin(emailAddress.value, password.value));
        setRequesting(true);
        try {
            setRequesting(false);
            const { data } = await LoginService.login(emailAddress.value, password.value);
            const token = data.token;

            console.log(data);

            localStorage.setItem(StorageKey.AUTH_KEY, token);
            dispatch(UserAction.successLogin(token));
        }
        catch (error) {
            dispatch(UserAction.failureLogin(error));
            setLoginError(MessageCode.ERROR_LOGIN_FORM_INCORRECT_CREDENTIALS);
        }

    };

    return (
        <Form>
            <div className="col-sm-8 offset-sm-2 p-4 mt-5">
                <h3 className="mb-5">
                    <Trans i18nKey={MessageCode.LOGIN_FORM_HEADER} />
                </h3>

                <Form.Group>
                    <Form.Label>
                        <Trans i18nKey={MessageCode.LOGIN_FORM_EMAIL_LABEL} />
                    </Form.Label>
                    <Form.Control as="input"
                        id={ "email-login-field" }
                        type="text"
                        tabIndex="1"
                        onChange={onChangeEmailAddress}
                        isInvalid={emailAddress.dirty && (emailAddress.error || loginError)}
                        disabled={isRequesting} >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={emailAddress.error} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <Trans i18nKey={MessageCode.LOGIN_FORM_PASSWORD_LABEL} />
                    </Form.Label>
                    <Form.Control as="input"
                        id={ "password-login-field" }
                        type="password"
                        tabIndex="2"
                        onChange={onChangePassword}
                        isInvalid={password.dirty && (password.error || loginError)}
                        disabled={isRequesting} >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={password.error} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Control as="input"
                        type="hidden"
                        isInvalid={loginError}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={loginError} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <small>
                            <Link to="#" onClick={onForgotPassword} id={ "forgot-password-btn" }>
                                <Trans i18nKey={MessageCode.LOGIN_FORM_FORGOT_PASSWORD_LINK} />
                            </Link>
                        </small>
                    </Form.Label>
                </Form.Group>

                <Button variant="primary"
                    type="submit"
                    id={ "submit-login-btn" }
                    className="btn-block btn-lg"
                    tabIndex="3"
                    onClick={e => onLogin(e)}
                    disabled={emailAddress.error || password.error || isRequesting}>
                    <Trans i18nKey={MessageCode.LOGIN_FORM_LOGIN_BUTTON} />
                </Button>
            </div>
        </Form>
    );
};

export default LoginForm;