import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ActiveAgentService } from 'services';
import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode'
import moment from 'moment';

const WorkflowStatus = {
    EXECUTING: 'EXECUTING',
    QUEUED: 'QUEUED',
};

const getOrdinalFormat = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const AgentQueuedWorkflowItem = ({ sessionId, workflow = {}, index, onTerminate }) => {
    return (
        <ListGroup.Item as="li" className="pl-0 pr-0 pt-1 pb-0 bg-transparent">
            <div className="row">
                <div className="col">
                    <p className="m-0 text-white">{workflow.name}</p>
                    {workflow.status === WorkflowStatus.EXECUTING || workflow.position === 0 ?
                        <small className="text-muted">
                            <Trans values={{
                                timestamp: workflow.executionStart ?
                                    moment(workflow.executionStart).format('YYYY/MM/DD hh:mm A') : '-'
                            }}
                                i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_START_EXECUTION_LABEL} />
                        </small>
                        :
                        <small className="text-muted">
                            <Trans values={{ position: getOrdinalFormat(index) }}
                                i18nKey={MessageCode.WORKFLOW_EVENTS_QUEUED_WORKFLOW_POSITION} />
                        </small>
                    }
                </div>
                <div className="col text-right">
                    <p className="mb-0">
                        {
                            workflow.status === WorkflowStatus.EXECUTING ?
                                <span className="badge badge-success">
                                    <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_RUNNING_STATUS} />
                                </span> :
                                <span className="badge badge-warning">
                                    <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_QUEUED_STATUS} />
                                </span> 

                        }
                    </p>
                    <p className="mb-0">
                        {
                            workflow.status === WorkflowStatus.EXECUTING ?
                                <Link to="#" onClick={() => onTerminate(sessionId, workflow)}>
                                    <small>
                                        <i className="far fa-stop-circle"></i>
                                        <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_TERMINATE_LABEL} />
                                    </small>
                                </Link> : 
                                <Link to="#" onClick={() => onTerminate(sessionId, workflow)}>
                                    <small>
                                        <i className="far fa-minus-square"></i>
                                        <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_UNQUEUE_LABEL} />
                                    </small>
                                </Link>
                        }

                    </p>
                </div>
            </div>
        </ListGroup.Item>
    );
};

export default AgentQueuedWorkflowItem;