import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import MessageCode from 'constants/MessageCode';

import { AuthorityChecker, AgentCard, AgentViewAllWorkflowsModal, Breadcrumb } from 'components';
import { Trans } from 'react-i18next';
import { SocketContext } from 'middlewares';

const WorkflowEventsScreen = () => {
    const [ uiState, setUiState ] = useState({
        showViewAllModal: false,
    });

    const socketFunctions = useContext(SocketContext);
    const activeAgents = useSelector(state => state.activeAgent.connections);

    const onOpenWorkflowListModal = (agent) => {
        setUiState({
            ...uiState,
            showViewAllModel: true,
            selectedAgent: agent,
        });
    };

    const onCloseWorkflowListModal = () => {
        setUiState({
            ...uiState,
            showViewAllModel: false,
        });
    };

    const onExecuteWorkflow = (sessionId, workflow) => {
        socketFunctions.sendExecuteRequest(sessionId, workflow);
    };

    const onTerminateWorkflow = (sessionId, workflow) => {
        socketFunctions.sendTerminateRequest(sessionId, workflow);
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={MessageCode.BREADCRUMB_WORKFLOW_LABEL} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={MessageCode.BREADCRUMB_WORKFLOW_EVENTS_LABEL} />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_WORKFLOW_EVENTS"]}>
                <div className="col-md-6">
                {
                    activeAgents.map((activeAgent) => 
                        <AgentCard key={ activeAgent.sessionId } 
                            agent={ activeAgent }
                            onViewAll={ onOpenWorkflowListModal }
                            onTerminate={ onTerminateWorkflow } />
                    )
                }
                </div>
                
                <AgentViewAllWorkflowsModal
                    agent={ uiState.selectedAgent }
                    isShown={ uiState.showViewAllModel }
                    onClose={ onCloseWorkflowListModal }
                    onExecute={ onExecuteWorkflow }
                    onTerminate={ onTerminateWorkflow } /> 
            </AuthorityChecker>
        </>
    )
};

export default WorkflowEventsScreen;