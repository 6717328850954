import React, { useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { LoginService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';

const newPasswordSelector = (event) => event ? event.target.value : null;
const newPasswordValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_RESET_PASSWORD_FORM_NEW_PASSWORD_REQUIRED;
    }

    return null;
};

const confirmNewPasswordSelector = (event) => event ? event.target.value : null;
const confirmNewPasswordValidator = (newPassword, confirmNewPassword) => {
    if (!confirmNewPassword) {
        return MessageCode.ERROR_RESET_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_REQUIRED;
    }
    else if (confirmNewPassword !== newPassword) {
        return MessageCode.ERROR_RESET_PASSWORD_FORM_NEW_PASSWORD_MISMATCH;
    }

    return null;
};

const ResetPasswordForm = ({ resetPasswordKey, onSuccess }) => {
    const [isRequesting, setRequesting] = useState(false);
    const [onChangeNewPassword, newPassword] = useValidation(newPasswordSelector, newPasswordValidator);
    const [onChangeConfirmNewPassword, confirmNewPassword] = useValidation(confirmNewPasswordSelector, (value) => confirmNewPasswordValidator(newPassword.value, value));
    const [resetPasswordError, setResetPasswordError] = useState();

    const onResetPassword = async (event) => {
        event.preventDefault();
        setRequesting(true);
        try {
            await LoginService.resetPassword(newPassword.value, resetPasswordKey);
            onSuccess();
        }
        catch{
            setResetPasswordError(MessageCode.ERROR_RESET_PASSWORD_FORM_INVALID);
        }
        setRequesting(false);
    };

    return (
        <Form>
            <div className="col-sm-8 offset-sm-2 p-4 mt-5">
                <h3 className="mb-5">
                    <Trans i18nKey={MessageCode.RESET_PASSWORD_FORM_HEADER} />
                </h3>

                <Form.Group>
                    <Form.Label>
                        <Trans i18nKey={MessageCode.RESET_PASSWORD_FORM_NEW_PASSWORD_LABEL} />
                    </Form.Label>
                    <Form.Control as="input"
                        id={ "new-password-field" }
                        type="password"
                        tabIndex="1"
                        onChange={onChangeNewPassword}
                        isInvalid={newPassword.dirty && newPassword.error}
                        disabled={isRequesting}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={newPassword.error} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <Trans i18nKey={MessageCode.RESET_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_LABEL} />
                    </Form.Label>
                    <Form.Control as="input"
                        id={ "confirm-password-field" }
                        type="password"
                        tabIndex="2"
                        onChange={onChangeConfirmNewPassword}
                        isInvalid={confirmNewPassword.dirty && confirmNewPassword.error}
                        disabled={isRequesting}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={confirmNewPassword.error} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Control as="input"
                        type="hidden"
                        isInvalid={resetPasswordError}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <Trans i18nKey={resetPasswordError} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary"
                    type="submit"
                    id={ "submit-new-password-btn" }
                    className="btn-block btn-lg"
                    tabIndex="3"
                    onClick={e => onResetPassword(e)}
                    disabled={newPassword.error || confirmNewPassword.error || isRequesting}>
                    <Trans i18nKey={MessageCode.RESET_PASSWORD_FORM_SUBMIT_BUTTON} />
                </Button>
            </div>
        </Form>
    );
};

export default ResetPasswordForm;