import React from 'react';

const AgentMiniWidgetList = ({ children }) => {
    return (
        <div className="row widgets-sm">
            { children }
        </div>
    );
};

export default AgentMiniWidgetList;