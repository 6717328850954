import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LicenseAction } from 'actions';
import MessageCode from 'constants/MessageCode';
import RoutePath from 'constants/RoutePath';
import { CompanyService, LicenseService } from 'services';
import {
    Breadcrumb,
    CompanyHeader,
    LicenseCreateModal,
    LicenseDeleteModal,
    LicenseUpdateModal,
    Table,
    Tooltip,
    AuthorityChecker,
} from 'components';

const getCompany = async (companyId, dispatch, uiState, setUiState) => {
    try {
        const { data } = await CompanyService.getSpecificCompany(companyId);
        dispatch(LicenseAction.successCompany(data));
    }
    catch (error) {
        console.error(error);
        dispatch(LicenseAction.failureCompany(error));
        
        setUiState({
            ...uiState,
            isCompanyExisting: false,
        });
        
        toast.error(
            <Trans i18nKey={ MessageCode.ERROR_COMPANY_NOT_EXIST } 
                        values={ { id: companyId } } />
        );
    }
};

const getLicenseList = async (companyId, dispatch) => {
    try{
        const { data } = await LicenseService.getLicenseList(companyId);
        dispatch(LicenseAction.successLicenseList(data));
    }
    catch (error) {
        console.error(error);
        dispatch(LicenseAction.failureLicenseList(error));
    }
};

const getPremiumLicenseTypeList = async (dispatch) => {
    try{
        const { data } = await LicenseService.getPremiumLicenseTypeList();
        dispatch(LicenseAction.successPremiumLicenseTypeList(data));
    }
    catch (error) {
        console.error(error);
        dispatch(LicenseAction.failurePremiumLicenseTypeList(error));
    }
};

const onInitialize = (companyId, dispatch, uiState, setUiState) => {
    return () => {
        getCompany(companyId, dispatch, uiState, setUiState);
        getLicenseList(companyId, dispatch);
        getPremiumLicenseTypeList(dispatch);
    };
};

const LicenseCompanySpecificScreen = ( ) => {
    const dispatch = useDispatch();
    const { companyId } = useParams();

    const [ uiState, setUiState ] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        isCompanyExisting: true,
        selectedLicense: null,
    });

    const company = useSelector(state => state.license.company);
    const licenseList = useSelector(state => state.license.licenseList);
    const premiumLicenseTypeList = useSelector(state => state.license.premiumLicenseTypeList);

    useEffect(onInitialize(companyId, dispatch, uiState, setUiState), []);

    const licenseTableColumnList = [{
        field: 'type.name',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_TYPE_HEADER } />,
        headerClasses: 'align-top',
        formatter: (license) => {
            if (license.type.key === 'pro'){
                return ( <span className='badge badge-warning'>
                    {license.type.name.toUpperCase()}
                </span> );
            }
            else if (license.type.key === 'enterprise'){
                return ( <span className='badge badge-info'>
                    {license.type.name.toUpperCase()}
                </span>);
            }
        },
        sortable: true,
    },{
        field: 'label',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_LABEL_HEADER } />,
        headerClasses: 'align-top',
        sortable: true,
    },{
        field: 'issueDate',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_ISSUE_DATE_HEADER } />,
        headerClasses: 'align-top',
        formatter: (license) => moment(license.issueDate).format('YYYY MMM DD hh:mm A'),
        sortable: true,
    },{
        field: 'expirationDate',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_EXPIRATION_DATE_HEADER } />,
        headerClasses: 'align-top',
        formatter: (license) => moment(license.expirationDate).format('YYYY MMM DD hh:mm A'),
        sortable: true,
    },{
        field: 'maxAccounts',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_ALLOWED_KEYS_HEADER } />,
        headerClasses: 'align-top',
        sortable: true,
    },{
        field: 'unusedAccounts',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_REMAINING_KEYS_HEADER } />,
        headerClasses: 'align-top',
        sortable: true,
    },{
        field: 'actions',
        name: '',
        formatter: (license) => {
            return (
                <center>
                    <AuthorityChecker requiredAuths={["VIEW_LICENSE_KEY"]}>
                        <Tooltip placement="top-start"
                            value={ <Trans i18nKey={ MessageCode.TOOLTIP_LICENSE_KEY_ICON } /> }>
                            <Link 
                                id={ `view-keys-${license.id}` }
                                to={ `${RoutePath.LICENSE_COMPANIES_URL}/${license.company.id}/license/${license.id}` }>
                                <i className="fa fa-key"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    &nbsp;
                    <AuthorityChecker requiredAuths={["EDIT_LICENSE"]}>
                        <Tooltip placement="top-start"
                            value={ <Trans i18nKey={ MessageCode.TOOLTIP_LICENSE_EDIT_ICON } /> }>
                            <Link to="#"
                                id={ `edit-license-${license.id}` }
                                onClick={ () => onRequestUpdateLicense(license.id) }>
                                <i className="fa fa-edit"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    &nbsp;
                    <AuthorityChecker requiredAuths={["DELETE_LICENSE"]}>
                        <Tooltip placement="top-start"
                            value={ <Trans i18nKey={ MessageCode.TOOLTIP_LICENSE_DELETE_ICON } /> }>
                            <Link to="#"
                                id={ `delete-license-${license.id}` }
                                onClick={ () => onRequestDeleteLicense(license.id) }>
                                <i className="fa fa-trash"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                </center>
            );
        },
    }];

    const onRequestCreateLicense = () => {
        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onRequestUpdateLicense = async (id) => {
        try{
            const { data } = await LicenseService.getSpecificLicense(companyId, id);
            setUiState({
                ...uiState,
                showUpdateModal: true,
                selectedLicense: data,
            });
        }
        catch (error){
            console.error(error);
            await getLicenseList(dispatch);

            setUiState({
                ...uiState,
                showUpdateModal: false,
                selectedLicense: null,
            });
        }
    };

    const onRequestDeleteLicense = async (id) => {
        try{
            const { data } = await LicenseService.getSpecificLicense(companyId, id);
            setUiState({
                ...uiState,
                showDeleteModal: true,
                selectedLicense: data,
            });
        }
        catch (error){
            console.error(error);
            await getLicenseList(dispatch);

            setUiState({
                ...uiState,
                showDeleteModal: false,
                selectedLicense: null,
            });
        }
    };

    const onCancelCreateLicense = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onCancelUpdateLicense = () => {
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedLicense: null,
        });
    };

    const onCancelDeleteLicense = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedLicense: null,
        });
    };

    const onSuccessCreateLicense = async () => {
        await getLicenseList(companyId, dispatch);
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onSuccessUpdateLicense = async () => {
        await getLicenseList(companyId, dispatch);
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedLicense: null,
        });
    };

    const onSuccessDeleteLicense = async () => {
        await getLicenseList(companyId, dispatch);
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedLicense: null,
        });
    };

    return (
        <section>
            {
                (uiState.isCompanyExisting) ? null: <Redirect to = {RoutePath.LICENSE_COMPANIES_URL} />
            }

            <AuthorityChecker requiredAuths={["CREATE_LICENSE"]}>
                <Link to="#" className="float" onClick={onRequestCreateLicense}>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.LICENSE_CREATE_BUTTON_TOOLTIP } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_COMPANIES_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <CompanyHeader>
                <CompanyHeader.Company company = {company}>
                </CompanyHeader.Company>
            </CompanyHeader>

            <AuthorityChecker requiredAuths={["VIEW_COMPANY_LICENSE"]}>
                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={licenseList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={licenseTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TABLE_LICENSE_EMPTY_PLACEHOLDER} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>
            

            <LicenseCreateModal isShown={uiState.showCreateModal}
                onClose={onCancelCreateLicense}
                onSuccess={onSuccessCreateLicense}
                premiumLicenseTypeList={premiumLicenseTypeList}>
            </LicenseCreateModal>

            <LicenseUpdateModal isShown={uiState.showUpdateModal && !!uiState.selectedLicense}
                onClose={onCancelUpdateLicense}
                onSuccess={onSuccessUpdateLicense}
                initialValues={uiState.selectedLicense} >
            </LicenseUpdateModal>

            <LicenseDeleteModal isShown={uiState.showDeleteModal && !!uiState.selectedLicense}
                onClose={onCancelDeleteLicense}
                onSuccess={onSuccessDeleteLicense}
                initialValues={uiState.selectedLicense} >
            </LicenseDeleteModal>
        </section>
    );
};

export default LicenseCompanySpecificScreen;
