import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { 
    AccountArchiveModal, 
    Breadcrumb, 
    Filter, 
    Table, 
    Toast, 
    Tooltip,
    AuthorityChecker
 } from 'components';
import { AccountService } from 'services';
import { AccountAction } from 'actions';
import MessageCode from 'constants/MessageCode';

const getAccountList = async (dispatch) => {
    try {
        const { data } = await AccountService.getSoloAccountList();
        dispatch(AccountAction.successAccountList(data));
    } catch (error) {
        dispatch(AccountAction.failureAccountList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getAccountList(dispatch);
    };
};
const LicenseAccountListScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);

    const [ ui, setUi ] = useState({
        showDeleteModal: false,
        filter: {
            name: '',
            editingAccount: null,
        },
        moreMacAddressVisibility: [],
    });

    const accountList = useSelector(state => state.account.soloAccountList);

    const onFilterText = (name) => {
        setUi({
            ...ui,
            filter: {
                ...ui.filter,
                name,
            },
        });
    };

    const onRequestDeleteAccount = (data) => {
        setUi({
            ...ui,
            showDeleteModal: true,
            editingAccount: data,
        });
    };

    const onSuccessDeleteAccount = async (data) => {
        await getAccountList(dispatch);

        toast.success(
            <Toast data={{
                    name: data.name,
                    licenseType: data.license.type.name
                }}
                message={MessageCode.MODAL_ACCOUNT_DELETE_SUCCESS}>
            </Toast>
        );

        setUi({
            ...ui,
            showDeleteModal: false,
        });
    };

    const onCancelDeleteAccount = (key) => {
        if (key) {
            toast.error(
                <Trans i18nKey={ key } />
            );
        }

        setUi({
            ...ui,
            showDeleteModal: false,
        });
    };

    const accountListHeaders = [{
        'field': 'type.name',
        'name': t(MessageCode.TABLE_ACCOUNT_TYPE_HEADER),
        'formatter': (account) => (
            <span className="badge badge-secondary">
                { account.type.name.toUpperCase() }
            </span>
        ),
        'sortable': true,
    }, {
        'field': 'fullName',
        'name': t(MessageCode.TABLE_ACCOUNT_NAME_HEADER),
        'sortable': true,
    }, {
        'field': 'emailAddress',
        'name': t(MessageCode.TABLE_ACCOUNT_EMAIL_ADDRESS_HEADER),
        'sortable': true,
    }, {
        'field': 'macAddress',
        'name': t(MessageCode.TABLE_ACCOUNT_MAC_ADDRESS_HEADER),
        'formatter': (account) => { 
            if (account.macAddresses && account.macAddresses.length > 0) {
                return (
                    <>
                        {account.macAddresses.slice(0, 3).map((item) => <pre>{item}</pre>)}
                        {account.macAddresses.length > 3 && ui.moreMacAddressVisibility.indexOf(account.id) === -1 ?
                            <Link href="#" onClick={() => ui.moreMacAddressVisibility.push(account.id)}>
                                See More
                            </Link>
                            : ""
                        }
                        
                        <Collapse in={ui.moreMacAddressVisibility.some(macadd => macadd === account.id)}>
                            <div>
                                {account.macAddresses.slice(3, account.macAddresses.length).map((item) => <pre>{item}</pre>)}
                                <Link href="#" onClick={() => ui.moreMacAddressVisibility.splice(ui.moreMacAddressVisibility.indexOf(account.id), 1)}>
                                    See Less
                                </Link>
                            </div>
                        </Collapse>
                    </>
                );
            } else {
                return (<pre>{account.macAddress}</pre>);
            }
        },
        'sortable': true,
    }, {
        'field': 'license.expirationDate',
        'name': t(MessageCode.TABLE_ACCOUNT_EXPIRATION_DATE_HEADER),
        'formatter': (account) => {
            if (account.license && account.license.expirationDate) {
                return moment(account.license.expirationDate).format('YYYY MMM DD hh:mm A');
            }
            return '-';
        },
        'sortable': true,
    }, {
        'field': 'actions',
        'name': '',
        'formatter': (account) => {
            return (
                <>
                    <center>
                        <AuthorityChecker requiredAuths={["DELETE_ACCOUNT"]}>
                            <Tooltip placement="top-start"
                                value={<Trans i18nKey={ MessageCode.TOOLTIP_ACCOUNT_DELETE_ICON } />}>
                                <Link to="#" 
                                    id={ "delete-account-"+account.id }
                                    onClick={ () => onRequestDeleteAccount(account) }>
                                    <i className="fa fa-trash" ></i>
                                </Link>
                            </Tooltip>
                        </AuthorityChecker>
                    </center>
                </>
            );
        },
    }];

    const filteredAccountList = _.filter(accountList, (account) => {
        return (!ui.filter.name 
                || (account.fullName && account.fullName.toLowerCase().includes(ui.filter.name.toLowerCase()))
                || (account.macAddress && account.macAddress.toLowerCase().includes(ui.filter.name.toLowerCase()))
                || (account.emailAddress && account.emailAddress.toLowerCase().includes(ui.filter.name.toLowerCase())));
    });

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>License Management</Breadcrumb.Item>
                <Breadcrumb.Item active>Accounts</Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_ACCOUNT"]}>
                <Filter>
                    <Filter.Text label={ 'Search' }
                        onChange={ onFilterText } />
                </Filter>

                <Table keyField="id"
                    isSortable={ true }
                    isPaginated={ true }
                    itemPerPage={ 10 }
                    noRecordsPlaceholder={ 
                        <Trans i18nKey={ MessageCode.TABLE_ACCOUNT_EMPTY_PLACEHOLDER } />
                    }
                    data={ filteredAccountList } 
                    headers={ accountListHeaders } />
            </AuthorityChecker>

            <AccountArchiveModal
                isShown={ ui.showDeleteModal }
                onSuccess={ onSuccessDeleteAccount }
                onCancel={ onCancelDeleteAccount }
                initialValues={ ui.editingAccount }>
            </AccountArchiveModal>
        </>
        
    );
};

export default LicenseAccountListScreen;