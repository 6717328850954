import React, { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import { AccountService } from 'services';
import MessageCode from 'constants/MessageCode';

const LicenseKeyDeleteModal = ({ isShown, onClose, onSuccess, licenseKey }) => {
    const [ disabled, setDisabled ] = useState(false); 

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                await AccountService.deleteLicenseKey(licenseKey.license.company.id, licenseKey.license.id, licenseKey.id);

                toast.success(
                    <Trans i18nKey={MessageCode.KEY_DELETE_MODAL_SUCCESS}
                        values={{ name: licenseKey.partialKey }} />
                );

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();
            }
            catch ({ data }) {
                setDisabled(false);
                console.error(data);
                toast.error(
                    <Trans i18nKey={MessageCode.ERROR_GENERAL_INTERNAL} />
                );
                onClose();
            }
        }
    };

    return (
        <Modal show={isShown} onHide={onClose} centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={MessageCode.KEY_DELETE_MODAL_HEADER} />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={MessageCode.KEY_DELETE_MODAL_TEXT}
                    values={ licenseKey ? { name: licenseKey.partialKey } : null }>
                    <p>You are about to delete the license key starting with <span className="brand-yellow"><strong>LICENSE_KEY</strong></span></p>

                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-key-btn" }
                    onClick={() => onClose()}>
                    <Trans i18nKey={MessageCode.KEY_DELETE_MODAL_CANCEL_BUTTON} />
                </Button>
                <Button variant="primary"
                    type="button"
                    id={ "confirm-delete-key-btn" }
                    disabled={ disabled }
                    onClick={(e) => onSubmit(e)}>
                    <Trans i18nKey={MessageCode.KEY_DELETE_MODAL_DELETE_BUTTON} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LicenseKeyDeleteModal;