import ActionType from 'constants/ActionType';

const initialState = {
    directoryList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.DIRECTORY_LIST_SUCCESS: {
            return {
                ...state,
                directoryFileList: action.payload.directoryFileList,
            };
        }

        case ActionType.DIRECTORY_LIST_FAILURE: {
            return {
                ...state,
                directoryFileList: initialState.directoryFileList,
            };
        }

        default: {
            return state;
        }
    }
};