import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import {
    Breadcrumb,
    Filter,
    Tooltip,
    Table,
    AuthorityChecker,
    StatusCreateModal,
    StatusDeleteModal,
    StatusUpdateModal
 } from 'components';
import { StatusService, DepartmentService } from 'services';
import { DepartmentAction, StatusAction } from 'actions';

const getDepartmentList = async (dispatch) => {
    try {
        const { data } = await DepartmentService.getAccessibleDepartmentList();
        dispatch(DepartmentAction.successDepartmentList(data));
    }
    catch (error) {
        dispatch(DepartmentAction.failureDepartmentList(error));
    }
};

const getStatusList = async (dispatch) => {
    try {
        const { data } = await StatusService.getStatusList();
        dispatch(StatusAction.successStatusList(data));
    }
    catch (error) {
        dispatch(StatusAction.failureStatusList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getDepartmentList(dispatch);
        getStatusList(dispatch);
    };
};

const ManagementStatusScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);
    const statusList = useSelector(state => state.status.statusList);
    const departmentList = useSelector(state => state.department.departmentList); 

    const [uiState, setUiState] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        selectedStatus: null,
        filter: {
            name: ''
        },
    });

    const filteredStatusList = _.filter(statusList, (status) => {
        return (!uiState.filter.name
            || status.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || status.department.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || status.threshold.toString().includes(uiState.filter.name)
            || (uiState.filter.name === '-' ? status.threshold === 0 : '')
            );
    });

    const statusTableColumnList = [
        {
            'field': 'color',
            'name': t(MessageCode.STATUS_TABLE_HEADER_COLOR),
            'headerClasses': 'align-top',
            'formatter': (status) => {
                return (
                    <>
                        <div className="text-center"><i className="fa fa-square fa-3x" style={{color:status.color}}></i></div>
                    </>
                )
            },
            'sortable': false,
        },
        {
            'field': 'icon',
            'name': t(MessageCode.STATUS_TABLE_HEADER_ICON),
            'headerClasses': 'align-top',
            'formatter': (status) => {
                return (
                    <>
                        <div className="text-center"> <img src={status.icon} width="50px" alt={status.id}/> </div>
                    </>
                )
            },
            'sortable': false,
        },
        {
            'field': 'name',
            'name': t(MessageCode.GENERAL_LABEL_NAME),
            'headerClasses': 'align-top',
            'sortable': true,
        },
        {
            'field': 'department.name',
            'name': t(MessageCode.TABLE_USER_DEPARTMENT_HEADER),
            'headerClasses': 'align-top',
            'formatter': (status) => {
                return (
                    <>
                        <div>
                        { 
                            status.department.name.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                                <Tooltip placement="top" value={status.department.name} key={status.id}>
                                    <span className="wrap-long-text max-width-250px cursor-pointer" key={status.id}>
                                        { status.department.name.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..." }
                                    </span>
                                </Tooltip> : status.department.name
                        }
                        </div>
                    </>
                )
            },
            'sortable': true,
        },
        {
            'field': 'threshold',
            'name': t(MessageCode.STATUS_TABLE_HEADER_THRESHOLD),
            'headerClasses': 'align-top',
            'formatter': (status) => {
                return (
                    <>
                        <div>{ (status.threshold === 0 || status.threshold == null) ? '-' : status.threshold }</div>
                    </>
                )
            },
            'sortable': true,
        },
        {
            'field': 'trackIdle',
            'name': t(MessageCode.STATUS_TABLE_HEADER_TRACK_IDLE),
            'headerClasses': 'align-top',
            'formatter': (status) => {
                return (
                    <>
                        <div className="text-center">
                            {status.trackIdle ? <i className="fa fa-check"></i> : <i className="fa fa-times text-danger"></i>}
                        </div>
                    </>
                )
            },
            'sortable': true,
        },
        {
            'field': 'actions',
            'name': '',
            'formatter': (status) => {
                return ( 
                    <> 
                        {
                            status.editable ? 
                            <div className="text-center">
                                <AuthorityChecker requiredAuths={["EDIT_STATUS"]}>
                                    <Tooltip placement="top-start"
                                        value={<Trans i18nKey={MessageCode.STATUS_TOOLTIP_EDIT} />}>
                                        <Link to="#" onClick={() => onRequestUpdateStatus(status.id)} id={ "edit-status-"+status.id }>
                                            <i className="fa fa-edit"></i>
                                        </Link>
                                    </Tooltip>
                                </AuthorityChecker>
                                &nbsp;
                                <AuthorityChecker requiredAuths={["DELETE_STATUS"]}>
                                    <Tooltip placement="top-start"
                                        value={<Trans i18nKey={MessageCode.STATUS_TOOLTIP_DELETE} />}>
                                        <Link to="#" onClick={() => onRequestDeleteStatus(status.id)} id={ "delete-status-"+status.id }>
                                            <i className="fa fa-trash"></i>
                                        </Link>
                                    </Tooltip>
                                </AuthorityChecker>
                            </div>
                            : ''
                        }
                    </>
                    
                );
            },
        }
    ];

    const onRequestCreateStatus = async () => {
        await getDepartmentList(dispatch);

        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onCancelCreateStatus = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onSuccessCreateStatus = async () => {
        await getStatusList(dispatch);

        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onRequestFilterStatuses = (name) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name,
            },
        });
    };

    const onRequestDeleteStatus = async (id) => {
        try {
            const { data } = await StatusService.getSpecificStatus(id);

            setUiState({
                ...uiState,
                selectedStatus: data,
                showDeleteModal: true,
            });

        } catch (error) {
            console.error(error);

            await getStatusList(dispatch);
            setUiState({
                ...uiState,
                selectedStatus: null,
                showDeleteModal: false,
            });
        }
    };

    const onCancelDeleteStatus = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedStatus: null,
        });
    };

    const onSuccessDeleteStatus = async () => {
        await getStatusList(dispatch);
        
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedStatus: null,
        });
    };

    const onRequestUpdateStatus = async (id) => {
        try {
            const { data } = await StatusService.getSpecificStatus(id);

            setUiState({
                ...uiState,
                selectedStatus: data,
                showUpdateModal: true,
            });

        } catch (error) {
            console.error(error);

            await getStatusList(dispatch);
            setUiState({
                ...uiState,
                selectedStatus: null,
                showUpdateModal: false,
            });
        }
    };

    const onCancelUpdateStatus = () => {
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedStatus: null,
        });
    };

    const onSuccessUpdateStatus = async () => {
        await getStatusList(dispatch);
        
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedStatus: null,
        });
    };

    return (
        <section>
            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_USER_MANAGEMENT_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                <Trans i18nKey={ MessageCode.STATUSES_BREADCRUMB_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["CREATE_STATUS"]}>
                {/* Add button */}
                <Link to="#" className="float over-accordion" onClick={onRequestCreateStatus} id={ "create-status-btn" }>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.STATUS_MODAL_HEADER_CREATE } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            <AuthorityChecker requiredAuths={["VIEW_STATUS"]}>
                {/* Search Filter */}
                <Filter>
                    <Filter.Text label='Search'
                        onChange={onRequestFilterStatuses}>
                    </Filter.Text>
                </Filter>

                {/* List of Status */}
                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={filteredStatusList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={statusTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.STATUS_DISPLAY_EMPTY} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>

            {/* Create Status Modal */}
            <StatusCreateModal isShown={uiState.showCreateModal} 
                onClose={onCancelCreateStatus} 
                onSuccess={onSuccessCreateStatus}
                departmentList={departmentList}>
            </StatusCreateModal>

            {/* Delete Status Modal */}
            <StatusDeleteModal isShown={uiState.showDeleteModal && !!uiState.selectedStatus } 
                onClose={onCancelDeleteStatus} 
                onSuccess={onSuccessDeleteStatus}
                initialValues={uiState.selectedStatus}>
            </StatusDeleteModal>

            {/* Update Status Modal */}
            <StatusUpdateModal isShown={uiState.showUpdateModal && !!uiState.selectedStatus} 
                onClose={onCancelUpdateStatus} 
                onSuccess={onSuccessUpdateStatus}
                departmentList={departmentList}
                initialValues={uiState.selectedStatus}>
            </StatusUpdateModal>
        </section>
    );
};

export default ManagementStatusScreen;