import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LicenseAction, UserAction } from 'actions';
import MessageCode from 'constants/MessageCode';
import RoutePath from 'constants/RoutePath';
import LengthLimit from 'constants/LengthLimit';
import { CompanyService, UserService } from 'services';
import {
    Breadcrumb,
    CompanyCreateModal,
    CompanyUpdateModal,
    CompanyDeleteModal,
    Filter,
    Table,
    Tooltip,
    AuthorityChecker
} from 'components';


const getCompanyList = async (dispatch) => {
    try {
        const { data } = await CompanyService.getCompanyList();
        dispatch(LicenseAction.successCompanyList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureCompanyList(error));
    }
};

const getIndustryList = async (dispatch) => {
    try {
        const { data } = await CompanyService.getIndustryList();
        dispatch(LicenseAction.successIndustryList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureIndustryList(error));
    }
};

const getUsersByCompanyList = async(dispatch, companyId) => {
     try {
        const { data } = await UserService.getUsersByCompany(companyId);
        dispatch(UserAction.successUserList(data));
    }
    catch (error) {
        dispatch(UserAction.failureUserList(error));
    }
}

const onInitialize = (dispatch) => {
    return () => {
        getCompanyList(dispatch);
        getIndustryList(dispatch);
    };
};

const LicenseCompanyListScreen = () => {
    const dispatch = useDispatch();

    const [ uiState, setUiState ] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        selectedCompany: null,
        filter: {
            name: '',
        }
    });

    useEffect(onInitialize(dispatch), []);
    
    const companyList = useSelector(state => state.license.companyList);
    const industryList = useSelector(state => state.license.industryList);
    const userList = useSelector(state => state.user.userList);

    const filteredCompanyList = _.filter(companyList, (company) => {
        return (!uiState.filter.name
            || company.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || company.head.firstName.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || company.head.lastName.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || company.industry.name.toLowerCase().includes(uiState.filter.name.toLowerCase()));
    });

    const companyTableColumnList = [{
        field: 'name',
        name: <Trans i18nKey={MessageCode.TABLE_COMPANY_NAME_HEADER} />,
        headerClasses: 'align-top',
        formatter: (company) => {
            if (company.name.length > LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) {
                return (
                    <Tooltip placement="top"
                        value={company.name}>
                        <Link to={RoutePath.LICENSE_COMPANIES_URL + '/' + company.id}>
                            { company.name.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..." }
                        </Link>
                    </Tooltip>
                );
            } else {
                return (
                    <Link to={RoutePath.LICENSE_COMPANIES_URL + '/' + company.id}>
                        { company.name }
                    </Link>
                );
            }
            
        },
        sortable: true,
    },{
        field: 'head.completeName',
        name: <Trans i18nKey={ MessageCode.TABLE_COMPANY_HEAD_HEADER } />,
        headerClasses: 'align-top',
        formatter: (company) => (company.head)?company.head.completeName: '-',
        sortable: true,
    },{
        field: 'industry.name',
        name: <Trans i18nKey={ MessageCode.TABLE_COMPANY_INDUSTRY_HEADER } />,
        headerClasses: 'align-top',
        formatter: (company) => company.industry.name,
        sortable: true,
    },{
        field: 'actions',
        name: '',
        formatter: (company) => {
            return (
                <center>
                    <AuthorityChecker requiredAuths={["EDIT_COMPANY"]}>
                        <Tooltip placement="top"
                            value={ <Trans i18nKey={ MessageCode.TOOLTIP_COMPANY_EDIT_ICON } /> }>
                            <Link to="#"
                                id={ "edit-company-"+company.id }
                                onClick={ () => onRequestUpdateCompany(company.id) }>
                                <i className="fa fa-edit"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    &nbsp; 
                    <AuthorityChecker requiredAuths={["DELETE_COMPANY"]}>
                        <Tooltip placement="top-start"
                            value={ <Trans i18nKey={ MessageCode.TOOLTIP_COMPANY_DELETE_ICON } /> }>
                            <Link to="#" 
                                id={ "delete-company-"+company.id }
                                onClick={ () => onRequestDeleteCompany(company.id) }>
                                <i className="fa fa-trash"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                </center>
            );
        },
    }];

    const onRequestFilterCompanyName = (companyName) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name: companyName,
            },
        });
    };

    const onRequestCreateCompany = () => {
        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onRequestUpdateCompany = async (id) => {
        try{
            getUsersByCompanyList(dispatch, id);
            const { data } = await CompanyService.getSpecificCompany(id);
            
            setUiState({
                ...uiState,
                showUpdateModal: true,
                selectedCompany: data,
            });
        }
        catch (error){
            console.error(error);
            await getCompanyList(dispatch);

            setUiState({
                ...uiState,
                showUpdateModal: false,
                selectedCompany: null,
                usersByCompany: null
            });
        }
    };

    const onRequestDeleteCompany = async (id) => {
        try{
            const {data} = await CompanyService.getSpecificCompany(id);

            setUiState({
                ...uiState,
                showDeleteModal: true,
                selectedCompany: data,
            });
        }
        catch (error){
            console.error(error);
            await getCompanyList(dispatch);

            setUiState({
                ...uiState,
                showDeleteModal: false,
                selectedCompany: null,
            });
        }
    };

    const onCancelCreateCompany = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onCancelUpdateCompany = () => {
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedCompany: null,
        });
    };

    const onCancelDeleteCompany = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedCompany: null,
        });
    };

    const onSuccessCreateCompany = async () => {
        await getCompanyList(dispatch);
        
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onSuccessUpdateCompany = async () => {
        await getCompanyList(dispatch);
        
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedCompany: null,
        });
    };

    const onSuccessDeleteCompany = async () => {
        await getCompanyList(dispatch);
        
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedCompany: null,
        });
    };

    return (
        <section>
            <AuthorityChecker requiredAuths={["CREATE_COMPANY"]}>
                <Link to="#" className="float" onClick={onRequestCreateCompany}>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.COMPANY_CREATE_BUTTON_TOOLTIP } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_LICENSE_MANAGEMENT_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_COMPANIES_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_COMPANY"]}>
                <Filter>
                    <Filter.Text label='Company Name'
                        onChange={onRequestFilterCompanyName}>
                    </Filter.Text>
                </Filter>

                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={filteredCompanyList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={companyTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TABLE_COMPANY_EMPTY_PLACEHOLDER} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>
            
            <CompanyCreateModal isShown={uiState.showCreateModal} 
                onClose={onCancelCreateCompany} 
                onSuccess={onSuccessCreateCompany}
                industryList={industryList}>
            </CompanyCreateModal>

            <CompanyUpdateModal isShown={uiState.showUpdateModal && !!uiState.selectedCompany } 
                onClose={onCancelUpdateCompany} 
                onSuccess={onSuccessUpdateCompany}
                industryList={industryList}
                initialValues={uiState.selectedCompany}
                userList={userList}
                >
            </CompanyUpdateModal>

            <CompanyDeleteModal isShown={uiState.showDeleteModal && !!uiState.selectedCompany } 
                onClose={onCancelDeleteCompany} 
                onSuccess={onSuccessDeleteCompany}
                initialValues={uiState.selectedCompany}>
            </CompanyDeleteModal>

        </section>
    );
};

export default LicenseCompanyListScreen;