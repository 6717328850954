import React from 'react';
import _ from 'underscore';

import { useDispatch } from 'react-redux';

import { UserAction } from 'actions';
import { UserService } from 'services';

import {
    Droppable,
    WidgetCard
} from 'components';

const MonitorWidgetTable = ({widgetsList, widgetsOptions, visibleWidgets}) => {

    const dispatch = useDispatch();

    const updateUserVisibleWidgets = async (groupedWidgets) => {
        const current_user = await UserService.getCurrentUser();
        await UserService.updateUserVisibleWidgets(current_user.data.id, [], groupedWidgets);
    }

    const onDragStart = (ev, widgetKey, cardIndex, column) => {
        ev.dataTransfer.setData("widgetKey", widgetKey);
        ev.dataTransfer.setData("cardIndex", cardIndex);
        ev.dataTransfer.setData("column", column);
    }

    const onDragOver = (ev, cardIndexDest, columnDest) => {
        ev.preventDefault();
    }

    const onDrop = async (ev, cardIndexDest, columnDest) => {
        ev.preventDefault();
        const widgetKey = ev.dataTransfer.getData("widgetKey");
        const cardIndexSrc = ev.dataTransfer.getData("cardIndex");
        const columnSrc = ev.dataTransfer.getData("column");

        if (columnSrc == columnDest) {
            const columnWidgets = visibleWidgets[columnSrc];
            const columnWidgetCount = columnWidgets.length;
            columnWidgets.splice(cardIndexSrc, 1);
            columnWidgets.splice(cardIndexDest, 0, widgetKey);
            columnWidgets.splice(columnWidgetCount, 1);
            
            const cloned = JSON.parse(JSON.stringify(visibleWidgets));
            dispatch(UserAction.successUserVisibleWidgets(cloned));
            updateUserVisibleWidgets(visibleWidgets);
        } else {
            const columnWidgetsSrc = visibleWidgets[columnSrc];
            const columnWidgetsDest = visibleWidgets[columnDest];
            columnWidgetsSrc.splice(cardIndexSrc, 1);
            columnWidgetsDest.splice(cardIndexDest, 0, widgetKey);

            updateUserVisibleWidgets(visibleWidgets);
            const cloned = JSON.parse(JSON.stringify(visibleWidgets));
            dispatch(UserAction.successUserVisibleWidgets(cloned));
        }
    }

    const getWidgetCardTitle = (widgetKey) => {
        return widgetsList.find(x => x.widgetKey === widgetKey).widgetTitle;
    }

    const closeWidget = (widgetKey, column) => {
        const columnWidgets = visibleWidgets[column];
        columnWidgets.splice(columnWidgets.indexOf(widgetKey),1);

        const cloned = JSON.parse(JSON.stringify(visibleWidgets));
        dispatch(UserAction.successUserVisibleWidgets(cloned));
        updateUserVisibleWidgets(visibleWidgets);
    }
    
    const renderWidgetCard = (widgetKey, column, cardIndex) => {
        return (
            <div 
                key={ column + " - " + cardIndex }
                onDragOver={(e)=>onDragOver(e, cardIndex, column)}
                onDrop={(e)=>{onDrop(e, cardIndex, column)}}
            >
                <WidgetCard
                    key = { widgetKey }
                    widgetTitle = { getWidgetCardTitle(widgetKey) }
                    widgetKey ={ widgetKey }
                    column = { column }
                    index = { cardIndex }
                    options = { widgetsOptions[widgetKey] }
                    closeWidget = { () => closeWidget(widgetKey, column) }
                    onDragStart = {(e) => onDragStart(e, widgetKey, cardIndex, column)}>
                    { !widgetsOptions[widgetKey] ? "Insert Graph Here." : null}
                </WidgetCard>
            </div>
        )
    }

    return (
        <>
            <div className="container-fluid">

                <div className="row">
                    { 
                        _.map(visibleWidgets, (widgets, column) => {
                            return (
                                <div className="col-sm-6" 
                                    key={ column }>
                                {
                                    _.map(widgets, (widget, cardIndex) => 
                                        renderWidgetCard(widget, column, cardIndex)
                                    )
                                }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="clearfix"></div>
            </div>
        </>
    );

}

export default MonitorWidgetTable;