import ActionType from 'constants/ActionType';

const initialState = {
    activityList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.ACTIVITY_LIST_SUCCESS: {
            return {
                ...state,
                activityList: action.payload.activityList,
            };
        }

        case ActionType.ACTIVITY_LIST_FAILURE: {
            return {
                ...state,
                workflowList: initialState.activityList,
            };
        }

        default: {
            return state;
        }
    }
};