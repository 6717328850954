import React, { useEffect, useState } from 'react';
import _ from 'underscore';

import { toast } from 'react-toastify';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UserService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { AuthorityChecker } from 'components';

const firstNameSelector = (event) => {
    return event ? event.target.value : null;
};

const firstNameValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_FIRST_NAME_REQUIRED;
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_USER_FIRST_NAME_TOO_LONG;
    }

    return null;
};

const lastNameSelector = (event) => {
    return event ? event.target.value : null;
};

const lastNameValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_LAST_NAME_REQUIRED;
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_USER_LAST_NAME_TOO_LONG;
    }

    return null;
};

const emailSelector = (event) => event ? event.target.value : null;
const emailValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_USER_EMAIL_REQUIRED;
    }

    return null;
};

const departmentSelector = (event) => event ? event.target.value : null;
const departmentValidator = (value, role, roleList) => {
    const group = roleList.filter(r => r.id === parseInt(role));
    if(group.length > 0  && group[0].authorityNames.includes('DEPARTMENT_ACCESS_LEVEL') && (value == 'default' || !value)){
        return MessageCode.ERROR_USER_DEPARTMENT_REQUIRED;
    }
};

const roleSelector = (event) => event ? event.target.value : null;
const roleValidator = (value, department, roleList) => {
    if (value === 'default' || !value) {
        return MessageCode.ERROR_USER_ROLE_REQUIRED;
    }
    const group = roleList.filter(r => r.id === parseInt(value));
    if(group.length > 0  && group[0].authorityNames.includes('DEPARTMENT_ACCESS_LEVEL') && (department.value == 'default' || !department.value)){
        department.setError(MessageCode.ERROR_USER_DEPARTMENT_REQUIRED);
        department.setDirty(true);
    }else{
        department.setError(null);
    }
};

const onInitialize = (firstName, lastName, email, department, role, company, initialValues, resetFields) => {
    return () => {
        if (initialValues) {
            resetFields();
            if (initialValues.firstName) firstName.setValue(initialValues.firstName);
            if (initialValues.lastName) lastName.setValue(initialValues.lastName);
            if (initialValues.emailAddress) email.setValue(initialValues.emailAddress);
            if (initialValues.departmentId) department.setValue(initialValues.departmentId);
            if (initialValues.displayGroupIds) role.setValue(initialValues.displayGroupIds);
            if (initialValues.companyId) {
                company.setValue(JSON.stringify({'tenant_id':initialValues.tenantId, 'id': initialValues.companyId}) || 'default');
            } else {
                company.reset('default');
            }
                
        }
    };
};

const UserUpdateModal = ({ isShown, onSuccess, onCancel, initialValues, departmentList, roleList, companyList, isAdmin, onCompanyChange }) => {
    const companySelector = (event) =>  getRolesAndDepartmentsByTenant(event) ;
    const companyValidator = (value) => {
        if (value === 'default' || !value) {
            return MessageCode.ERROR_USER_COMPANY_REQUIRED;
        }
    };

    const getRolesAndDepartmentsByTenant = (event) => {
        try{
            const company = JSON.parse(event.target.value);
            onCompanyChange(company.tenant_id);
        }catch(error){
            onCompanyChange("TENANT_X");
        }

        department.reset('default');
        role.reset('default');
        return (event)?event.target.value: null;
    };

    const [onChangeFirstName, firstName] = useValidation(firstNameSelector, firstNameValidator);
    const [onChangeLastName, lastName] = useValidation(lastNameSelector, lastNameValidator);
    const [onChangeEmail, email] = useValidation(emailSelector, emailValidator);
    const [onChangeDepartment, department] = useValidation(departmentSelector, (value) => departmentValidator(value, role.value, roleList ));
    const [onChangeRole, role] = useValidation(roleSelector,(value) => roleValidator(value, department, roleList));
    const [onChangeCompany, company ] = useValidation(companySelector, companyValidator);

    const [ disabled, setDisabled ] = useState(false);
    const [, general] = useValidation(null, null, '');
    const { t } = useTranslation();
const companyValue = useSelector(state => state.user.company);
    const resetFields = () => {
        firstName.reset('');
        lastName.reset('');
        department.reset('default');
        role.reset('default');
        general.reset('');
    };

    useEffect(onInitialize(firstName, lastName, email, department, role, company, initialValues, resetFields),
        [JSON.stringify(initialValues)]);

    const onCancelUpdate = () => {
        onCancel();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                const parsedCompany = (company.value)?JSON.parse(company.value):null;
                const cValue = (parsedCompany != null)?parsedCompany.id: companyValue.id;
                const tValue = (parsedCompany != null)?parsedCompany.tenant_id: companyValue.tenantId;
                const { data } = await UserService.updateUser(initialValues.id, firstName.value, lastName.value, (department.value && department.value != 'default')?department.value:null, role.value, cValue, tValue);

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess(data, resetFields);

            } catch ({ data }) {
                setDisabled(false);
                toast.error(data.message);
                onCancel()
            }
        }
    };

    return (
        <Modal show={isShown} onHide={onCancelUpdate}>
            <Form noValidate onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={MessageCode.MODAL_USER_UPDATE_HEADER} />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={MessageCode.MODAL_USER_FORM_SUBTEXT} />
                    </small>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_FIRST_NAME_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="text"
                                id={ "firstname-update-user-field" }
                                value={firstName.value || ''}
                                onChange={onChangeFirstName}
                                isInvalid={firstName.dirty && firstName.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={firstName.error} />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_LAST_NAME_LABEL} />
                            </Form.Label>
                            <Form.Control as="input"
                                type="text"
                                id={ "lastname-update-user-field" }
                                value={lastName.value || ''}
                                onChange={onChangeLastName}
                                isInvalid={lastName.dirty && lastName.error}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={lastName.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.MODAL_USER_EMAIL_LABEL} />
                        </Form.Label>
                        <Form.Control as="input"
                            disabled={true}
                            type="email"
                            id={ "email-update-user-field" }
                            value={email.value || ''}
                            onChange={onChangeEmail}
                            isInvalid={email.dirty && email.error}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={email.error} />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <AuthorityChecker requiredAuths={["ADMIN_ACCESS_LEVEL"]}>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>
                                    <Trans i18nKey={MessageCode.MODAL_USER_COMPANY_LABEL} />
                                </Form.Label>
                                <Form.Control as="select"
                                    disabled={true}
                                    className='custom-select'
                                    id={ "company-update-user-field" }
                                    value={company.value || 'default'}
                                    onChange={onChangeCompany}
                                    isInvalid={company.dirty && company.error}>
                                    <option value='default'>{t(MessageCode.MODAL_USER_FIELD_COMPANY_DEFAULT)}</option>
                                     {companyList.map(item => <option key={item.id} value={JSON.stringify({'tenant_id':item.tenantId, 'id': item.id})}>{item.name}</option>)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <Trans i18nKey={company.error} />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </AuthorityChecker>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_DEPARTMENT_LABEL} />
                            </Form.Label>
                            <Form.Control as="select"
                                className='custom-select'
                                id={ "department-update-user-field" }
                                value={department.value || 'default'}
                                onChange={onChangeDepartment}
                                isInvalid={department.dirty && department.error}>
                                <option value='default'>{t(MessageCode.MODAL_USER_FIELD_DEPARTMENT_DEFAULT)}</option>
                                {departmentList.map(item => <option key={item.id+"-department"} value={item.id}>{item.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={department.error} />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.MODAL_USER_ROLE_LABEL} />
                            </Form.Label>
                            <Form.Control as="select"
                                className='custom-select'
                                id={ "role-update-user-field" }
                                value={role.value || 'default'}
                                onChange={onChangeRole}
                                isInvalid={role.dirty && role.error}>
                                <option value='default'>{t(MessageCode.MODAL_USER_FIELD_ROLE_DEFAULT)}</option>
                                {roleList.map(item => <option key={item.id + "-role"} value={item.id}>{item.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={role.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid="true">
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={general.error} />
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary"
                        id={ "reset-update-user-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={MessageCode.MODAL_USER_CLEAR_BUTTON} />
                    </Button>
                    <Button variant="primary"
                        type="submit"
                        id={ "submit-update-user-btn" }
                        disabled={ disabled || firstName.error || lastName.error || email.error || department.error
                            || role.error || (isAdmin && company.error) }>
                        <Trans i18nKey={MessageCode.MODAL_USER_UPDATE_BUTTON} />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UserUpdateModal;
