import ActionType from 'constants/ActionType';

export default {
    requestAgentMetrics: () => {
        return {
            type: ActionType.AGENT_METRICS_REQUEST,
        };
    },

    successAgentMetrics: (metrics) => {
        return {
            type: ActionType.AGENT_METRICS_SUCCESS,
            payload: { metrics },
        };
    },

    failureAgentMetrics: (error) => {
        return {
            type: ActionType.AGENT_METRICS_FAILURE,
            payload: { error },
        };
    },

    requestExceptionList: () => {
        return {
            type: ActionType.AGENT_EXCEPTION_LIST_REQUEST,
        };
    },

    successExceptionList: (exceptionList) => {
        return {
            type: ActionType.AGENT_EXCEPTION_LIST_SUCCESS,
            payload: { exceptionList },
        };
    },

    failureExceptionList: (error) => {
        return {
            type: ActionType.AGENT_EXCEPTION_LIST_FAILURE,
            payload: { error },
        };
    },

    requestUseCountList: () => {
        return {
            type: ActionType.AGENT_USECOUNT_LIST_REQUEST,
        };
    },

    successUseCountList: (useCountList) => {
        return {
            type: ActionType.AGENT_USECOUNT_LIST_SUCCESS,
            payload: { useCountList },
        };
    },

    failureUseCountList: (error) => {
        return {
            type: ActionType.AGENT_USECOUNT_LIST_FAILURE,
            payload: { error },
        };
    },

    requestExecutionTimeList: () => {
        return {
            type: ActionType.AGENT_EXECUTION_TIME_LIST_REQUEST,
        };
    },

    successExecutionTimeList: (executionTimeList) => {
        return {
            type: ActionType.AGENT_EXECUTION_TIME_LIST_SUCCESS,
            payload: { executionTimeList },
        };
    },

    failureExecutionTimeList: (error) => {
        return {
            type: ActionType.AGENT_EXECUTION_TIME_LIST_FAILURE,
            payload: { error },
        };
    },
};