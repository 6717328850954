import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { LicenseService } from 'services';
import MessageCode from 'constants/MessageCode';

const onInitialize = (initialValues, setLicenseType) => {
    return () => {
        setLicenseType(initialValues && initialValues.type.name);
    };
};

const LicenseDeleteModal = ({ isShown, onClose, onSuccess, initialValues }) => {
    const { companyId } = useParams();
    const [licenseType, setLicenseType] = useState();
    const [disabled, setDisabled] = useState(false); 
  
    useEffect(onInitialize(initialValues, setLicenseType), [JSON.stringify(initialValues)]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
        
            try {
                await LicenseService.deleteLicense(companyId, initialValues.id);

                toast.success(
                    <Trans i18nKey={MessageCode.LICENSE_MODAL_DELETE_SUCCESS}
                        values={{ name: licenseType }} />
                );

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();
            }
            catch ({ data }) {
                setDisabled(false);
                console.error(data);
                toast.error(
                    <Trans i18nKey={MessageCode.ERROR_GENERAL_INTERNAL} />
                );
                onClose();
            }
        }
    };

    return (
        <Modal show={isShown} onHide={onClose} centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={MessageCode.LICENSE_MODAL_DELETE_HEADER} />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={MessageCode.LICENSE_MODAL_DELETE_TEXT}
                    values={{ name: licenseType }}>
                    <p>You are about to delete this <span className="brand-yellow"><strong>COMPANY_NAME</strong></span> license. </p>
                    <p>All license keys linked to this license will be lost.</p>

                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-license-btn" }
                    onClick={() => onClose()}>
                    <Trans i18nKey={MessageCode.LICENSE_MODAL_DELETE_CANCEL_BUTTON} />
                </Button>
                <Button variant="primary"
                    type="button"
                    id={ "confirm-delete-license-btn" }
                    disabled={ disabled }
                    onClick={(e) => onSubmit(e)}>
                    <Trans i18nKey={MessageCode.LICENSE_MODAL_DELETE_BUTTON} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LicenseDeleteModal;