import React from 'react';

import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const ForgotPasswordSuccess = ({ headerLabel, messageLabel, linkLabel, onClickLink }) => {

    return (
        <Form>
            <div className="col-sm-8 offset-sm-2 p-4 mt-5">
                <h3 className="mb-5">
                    <Trans i18nKey={headerLabel} />
                </h3>

                <Form.Group>
                    <Trans i18nKey={messageLabel} />
                </Form.Group>

                <Form.Group className="text-center">
                    <Form.Label>
                        <Link to="#" onClick={onClickLink}>
                            <Trans i18nKey={linkLabel} />
                        </Link>
                    </Form.Label>
                </Form.Group>
            </div>
        </Form>
    );
};

export default ForgotPasswordSuccess;