import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';

import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import MessageCode from 'constants/MessageCode';
import { AgentService } from 'services';
import { WorkflowAction } from 'actions';
import { Breadcrumb, Filter, Table, AuthorityChecker } from 'components';

const getExceptionList = async (dispatch, from, to) => {
    try {
        const { data } = await AgentService.getWorkflowExceptionList(from, to);

        const mappedData = _.map(data, (dataItem, index) => {
            return {
                ...dataItem,
                index,
            };
        });

        dispatch(WorkflowAction.successExceptionList(mappedData));
    } catch (error) {
        dispatch(WorkflowAction.failureExceptionList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getExceptionList(dispatch,
            moment().startOf('day').valueOf(), 
            moment().startOf('day').add(1, 'day').valueOf());
    };
};

const WorkflowErrorListScreen = () => {
    const dispatch = useDispatch();

    useEffect(onInitialize(dispatch), []);

    const [ ui, setUi ] = useState({
        filter: {
            text: '',
            from: null,
            to: null,
        },
    });

    const onFilterText = (text) => {
        setUi({
            ...ui,
            filter: {
                ...ui.filter,
                text,
            },
        });
    };

    const onFilterDateRange = ([ from, to ]) => {
        getExceptionList(dispatch, from, to);
        setUi({
            ...ui,
            filter: {
                ...ui.filter,
                from, 
                to,
            },
        });
    };

    const exceptionTableHeaders = [{
        'field': 'executionStart',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_DATE_EXECUTED_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            const executionDate = moment(record.executionStart);
            return (
                <>
                    <p className="m-0">{ executionDate.format('MM/DD/YY') }</p>
                    <small className="text-muted">{ executionDate.format('HH:mm A') }</small>
                </>
            );
        },
        'sortable': true,
    }, {
        'field': 'workflowName',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_WORKFLOW_NAME_HEADER } />,
        'headerClasses': 'align-top',
        'sortable': true,
    }, {
        'field': 'executedBy.id',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_EXECUTED_BY_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            return (
                <>
                    <span>{ `${record.executedBy.firstName} ${record.executedBy.lastName}` }</span><br />
                    <small className="text-muted">{ `${record.emailAddress} (${record.macAddress})` }</small>
                </>
            );
        },
        'sortable': true,
    }, {
        'field': 'errorActivity',
        'name': <Trans i18nKey={ MessageCode.TABLE_AGENT_MESSAGE_HEADER } />,
        'headerClasses': 'align-top',
        'formatter': (record) => {
            return (
                <>
                    <p className="text-danger m-0">{ record.errorMessage }</p>
                    <small className="text-muted">{ `Activity: ${record.errorActivity}` }</small>
                </>
            )
        },
        'sortable': true,
    }]

    const exceptionList = useSelector(state => state.workflow.exceptionList);

    const filteredExceptionList = _.filter(exceptionList, (exceptionLog) => {
        let workflowName = exceptionLog.workflowName;
        let errorMessage = exceptionLog.errorMessage;
        let errorActivity = exceptionLog.errorActivity;
        let emailAddress = exceptionLog.emailAddress;
        let macAddress = exceptionLog.macAddress;
        let fullName = '';
        
        if (exceptionLog.executedBy) {
            fullName = `${exceptionLog.executedBy.firstName} ${exceptionLog.executedBy.lastName}`;
        }

        return ((!ui.filter.text 
                || errorMessage.toLowerCase().includes(ui.filter.text.toLowerCase())
                || errorActivity.toLowerCase().includes(ui.filter.text.toLowerCase())
                || workflowName.toLowerCase().includes(ui.filter.text.toLowerCase()))
                || emailAddress.toLowerCase().includes(ui.filter.text.toLowerCase())
                || macAddress.toLowerCase().includes(ui.filter.text.toLowerCase())
                || fullName.toLowerCase().includes(ui.filter.text.toLowerCase()));
                // && (!ui.filter.from || exceptionLog.executionStart >= ui.filter.from) 
                // && (!ui.filter.to || exceptionLog.executionStart <= ui.filter.to));
    });

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_WORKFLOW_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_WORKFLOW_EXCEPTIONS_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_ERROR_LOGS"]}>
                <Filter>
                    <Filter.Text
                        label={ 'Search' }
                        onChange={ onFilterText }>
                    </Filter.Text>
                    <Filter.DateRange 
                        value={[ 
                            moment().startOf('day').valueOf(), 
                            moment().startOf('day').add(1, 'day').valueOf()
                        ]}
                        label={ 'Show by Date' }
                        onChange={ onFilterDateRange }>
                    </Filter.DateRange>
                </Filter>

                <Table keyField={ 'index' } 
                    headers={ exceptionTableHeaders }
                    data={ filteredExceptionList }
                    isSortable={ true }
                    isPaginated={ true }
                    itemPerPage={ 10 }>
                </Table>
            </AuthorityChecker>
        </>
        
    );
};

export default WorkflowErrorListScreen;
