import ActionType from 'constants/ActionType';

const initialState = {
    company: null,
    companyList: [],
    industryList: [],
    license: null,
    licenseList: [], 
    licenseKeyList: [],
    licenseFileList: [],
    premiumLicenseTypeList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.COMPANY_SUCCESS: {
            return {
                ...state,
                company: action.payload.company,
            };
        }

        case ActionType.COMPANY_FAILURE: {
            return {
                ...state,
                company: initialState.company,
            };
        }

        case ActionType.COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                companyList: action.payload.companyList,
            };
        }

        case ActionType.COMPANY_LIST_FAILURE: {
            return {
                ...state,
                companyList: initialState.companyList,
            };
        }

        case ActionType.INDUSTRY_LIST_SUCCESS: {
            return {
                ...state,
                industryList: action.payload.industryList,
            };
        }

        case ActionType.INDUSTRY_LIST_FAILURE: {
            return {
                ...state,
                industryList: initialState.industryList,
            };
        }

        case ActionType.LICENSE_SUCCESS: {
            return {
                ...state,
                license: action.payload.license,
            };
        }

        case ActionType.LICENSE_FAILURE: {
            return {
                ...state,
                license: initialState.license,
            };
        }

        case ActionType.LICENSE_LIST_SUCCESS: {
            return {
                ...state,
                licenseList: action.payload.licenseList,
            };
        }

        case ActionType.LICENSE_LIST_FAILURE: {
            return {
                ...state,
                licenseList: initialState.licenseList,
            };
        }

        case ActionType.LICENSE_KEY_LIST_SUCCESS: {
            return {
                ...state,
                licenseKeyList: action.payload.licenseKeyList,  
            };
        }

        case ActionType.LICENSE_KEY_LIST_FAILURE: {
            return {
                ...state,
                licenseKeyList: initialState.licenseKeyList,
            };
        }

        case ActionType.PREMIUM_LICENSE_LIST_SUCCESS: {
            return {
                ...state,
                premiumLicenseTypeList: action.payload.premiumLicenseTypeList,
            };
        }

        case ActionType.PREMIUM_LICENSE_LIST_FAILURE: {
            return {
                ...state,
                premiumLicenseTypeList: initialState.premiumLicenseTypeList,
            };
        }
        
        case ActionType.LICENSE_FILE_LIST_SUCCESS: {
            return {
                ...state,
                licenseFileList: action.payload.licenseFileList,
            }
        }

        case ActionType.LICENSE_FILE_LIST_FAILURE: {
            return {
                ...state,
                licenseFileList: initialState.licenseFileList,
            };
        }

        case ActionType.LICENSE_FILE_LIST_CLEAR: {
            return {
                ...state,
                licenseFileList: initialState.licenseFileList,
            }
        }

        default: {
            return state;
        }
    }
};