import React from 'react';
import { useSelector } from 'react-redux';

import { Redirect, Switch } from 'react-router-dom';
import RoutePath from 'constants/RoutePath';
import { WorkflowErrorListScreen, WorkflowListScreen, WorkflowLogsScreen, WorkflowEventsScreen, WorkflowEventsScreen2 } from 'containers';
import { AuthenticatedRoute } from 'components';

const WorkflowScreen = () => {
    const userAuths = useSelector(state => state.user.auths);

    return (
        <Switch>
            <AuthenticatedRoute path={ RoutePath.WORKFLOW_LIST_URL } 
                    resolve={ () => userAuths.includes("ACCESS_WORKFLOW_TAB")}
                    resolveTo={ WorkflowListScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.WORKFLOW_EVENTS_URL } 
                    resolve={ () => userAuths.includes("ACCESS_WORKFLOW_EVENTS_TAB")}
                    resolveTo={ WorkflowEventsScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.WORKFLOW_ERRORS_URL } 
                    resolve={ () => userAuths.includes("ACCESS_WORKFLOW_ERROR_LOGS_TAB")}
                    resolveTo={ WorkflowErrorListScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.WORKFLOW_LOGS_URL }
                    resolve={ () => userAuths.includes("ACCESS_WORKFLOW_ERROR_LOGS_TAB") }
                    resolveTo={ WorkflowLogsScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
                    
            <Redirect to={ RoutePath.WORKFLOW_LIST_URL } />
        </Switch>
    );
};

export default WorkflowScreen;