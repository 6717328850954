import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import thunk from 'redux-thunk';

import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import { MainScreen } from 'containers';
import { Logger } from 'middlewares';
import { HttpRequestInterceptor, 
    HttpResponseInterceptor, 
    ServiceWorker, 
    I18nService 
} from 'services';

import rootReducer from 'reducers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Set middlewares
const middlewares = [
    thunk, 
    Logger,
];

// Set primary store
const store = createStore(rootReducer, {}, applyMiddleware(...middlewares));

// Set axios interceptor
axios.interceptors.request.use(
    HttpRequestInterceptor.success, 
    HttpRequestInterceptor.fail,
);

axios.interceptors.response.use(
    HttpResponseInterceptor.success,
    HttpResponseInterceptor.fail,
);

// Set toastify
const toastifyConfig = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    closeButton: <><strong>x</strong></>,
};

// Set routing
ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={ I18nService }>
            <ToastContainer { ...toastifyConfig } />
            <DndProvider backend={HTML5Backend}>
                <BrowserRouter basename={ process.env.PUBLIC_URL }>
                    <MainScreen />
                </BrowserRouter>
            </DndProvider>
        </I18nextProvider>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ServiceWorker.unregister();
