import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';

const onInitialize = (value, setName, setCategory, setPartial) => {
    return () => {
        if (value) {
            setName(value.name);
            setCategory(value.category);
            setPartial(value.isPartial);
        }
    };
};

const PremiumActivityListItem = ({value, isPremium, setPremium}) => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [isPartial, setPartial] = useState(false);

    useEffect(onInitialize(value, setName, setCategory, setPartial), [JSON.stringify(value)]);

    const [{opacity}, drag] = useDrag(() => ({
        type: isPremium ? 'PremiumActivity' : 'Activity',
        item: {name},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                if (dropResult.name === 'BoxActivityList') {
                    setPremium(value, false);
                }
                else if (dropResult.name === 'BoxPremiumList') {
                    setPremium(value, true);
                }
            }
        },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    }));

    const PartialDisplay = () => {
        if (isPremium) {
            if (isPartial) {
                return (
                    <span>
                        <i className="fas fa-star-half" />
                        <small>
                            Limited
                        </small> 
                    </span>
                );
            } else {
                return (
                    <span>
                        <i className="fas fa-star" />
                    </span>
                );
            }
        }
        return (<></>);
    };

    return (
        <li ref={drag} style={{opacity}} className="list-group-item bg-dark">
            <div className="mt-0 mb-0 mr-3 d-flex justify-content-between">
                <div className="name">
                    <div className="line text-secondary">
                        <i className="fa fa-grip-lines-vertical" />
                    </div>
                    <p className="m-0 text-white">{name}</p>
                    <small className="text-secondary">{category}</small>
                </div>
                <PartialDisplay />
            </div>
        </li>
    );
};

export default PremiumActivityListItem;