import ActionType from 'constants/ActionType';

const initialState = {
    metrics: {
        completedWorkflowCount: 0,
        failedWorkflowCount: 0,
        scheduledWorkflowCount: 0,
        activeAgentCount: 0,
    },
    exceptionList: [],
    useCountList: [],
    executionTimeList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.AGENT_EXCEPTION_LIST_SUCCESS: {
            return {
                ...state,
                exceptionList: action.payload.exceptionList,
            };
        }

        case ActionType.AGENT_EXCEPTION_LIST_FAILURE: {
            return {
                ...state,
                exceptionList: initialState.workflowList,
            };
        }

        case ActionType.AGENT_METRICS_SUCCESS: {
            return {
                ...state,
                metrics: action.payload.metrics,
            };
        }

        case ActionType.AGENT_METRICS_FAILURE: {
            return {
                ...state,
                metrics: { ...initialState.metrics },
            };
        }

        case ActionType.AGENT_USECOUNT_LIST_SUCCESS: {
            return {
                ...state,
                useCountList: action.payload.useCountList,
            };
        }

        case ActionType.AGENT_USECOUNT_LIST_FAILURE: {
            return {
                ...state,
                useCountList: initialState.useCountList,
            };
        }

        case ActionType.AGENT_EXECUTION_TIME_LIST_SUCCESS: {
            return {
                ...state,
                executionTimeList: action.payload.executionTimeList,
            };
        }

        case ActionType.AGENT_EXECUTION_TIME_LIST_FAILURE: {
            return {
                ...state,
                executionTimeList: initialState.executionTimeList,
            };
        }

        default: {
            return state;
        }
    }
};