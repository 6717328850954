export default {
    // LABELS {module_LABEL_field}
    GENERAL_LABEL_NAME: 'general.label.name',
    GENERAL_LABEL_DEPARTMENT: 'label.department',
    GENERAL_LABEL_STATUS: 'label.status',

    // BUTTONS {module_BUTTON_function}
    GENERAL_BUTTON_ADD: 'general.button.add',
    GENERAL_BUTTON_UPDATE: 'general.button.update',
    GENERAL_BUTTON_DELETE: 'general.button.delete',
    GENERAL_BUTTON_CLEAR: 'general.button.clear',
    GENERAL_BUTTON_CANCEL: 'general.button.cancel',
    GENERAL_BUTTON_DOWNLOAD: 'general.button.download',

    // TOOLTIPS {module_TOOLTIP_function}
    DEPARTMENT_TOOLTIP_DELETE: 'department.tooltip.delete',
    DEPARTMENT_TOOLTIP_EDIT: 'department.tooltip.edit',
    ROLE_TOOLTIP_DELETE: 'role.tooltip.delete',
    ROLE_TOOLTIP_EDIT: 'role.tooltip.edit',
    STATUS_TOOLTIP_DELETE: 'status.tooltip.delete',
    STATUS_TOOLTIP_EDIT: 'status.tooltip.edit',
    TASK_TOOLTIP_DELETE: 'task.tooltip.delete',
    TASK_TOOLTIP_EDIT: 'task.tooltip.edit',
    DIRECTORY_TOOLTIP_DELETE: 'directory.tooltip.delete',
    DIRECTORY_TOOLTIP_EDIT: 'directory.tooltip.edit',

    // MODAL HEADERS {module_MODAL_HEADER_function}
    STATUS_MODAL_HEADER_CREATE: 'status.modal.header.create',
    STATUS_MODAL_HEADER_DELETE: 'status.modal.header.delete',
    STATUS_MODAL_HEADER_UPDATE: 'status.modal.header.update',
    TASK_MODAL_HEADER_CREATE: 'task.modal.header.create',
    TASK_MODAL_HEADER_DELETE: 'task.modal.header.delete',
    TASK_MODAL_HEADER_UPDATE: 'task.modal.header.update',
    
    // MESSAGES {module_MESSAGE_(event/use)} [modal messages, toast messages]
    STATUS_MESSAGE_DELETE_MODAL_TEXT: 'status.message.delete.modal.text',
    STATUS_MESSAGE_DELETE_SUCCESS: 'status.message.delete.success',
    STATUS_MESSAGE_UPDATE_SUCCESS: 'status.message.update.success',
    TASK_MESSAGE_CREATE_SUCCESS: 'task.message.create.success',
    TASK_MESSAGE_DELETE_MODAL_TEXT: 'task.message.delete.modal.text',
    TASK_MESSAGE_DELETE_SUCCESS: 'task.message.delete.success',
    TASK_MESSAGE_UPDATE_SUCCESS: 'task.message.update.success',

    // BREADCRUMB LABELS {module_BREADCRUMB_LABEL}
    STATUSES_BREADCRUMB_LABEL: 'statuses.breadcrumb.label',
    TASKS_BREADCRUMB_LABEL: 'tasks.breadcrumb.label',

    // DEFAULT VALUES {DEFAULT_field}
    DEFAULT_DEPARTMENT : 'default.department',
    DEFAULT_STATUS : 'default.status',

    // EMPTY VIEW {module_DISPLAY_EMPTY}
    STATUS_DISPLAY_EMPTY: 'status.display.empty',
    TASK_DISPLAY_EMPTY: 'task.display.empty',

    // ERROR MESSAGES {ERROR_module_task}
    ERROR_DEPARTMENT_DELETE_HAS_CHILDREN: "error.department.delete.has.children",
    ERROR_DEPARTMENT_DELETE_ASSOCIATED_WITH_STATUSES: "error.department.delete.associated.with.statuses",
    ERROR_STATUS_DELETE: "error.status.delete",
    ERROR_STATUS_INACCESSIBLE: "error.status.inaccessible",
    ERROR_STATUS_REQUIRED: 'error.status.required',
    ERROR_TASK_INACCESSIBLE: "error.task.inaccessible",
    ERROR_USER_DELETE_ASSOCIATED_WITH_DEPARTMENT: "error.user.delete.associated.with.department",
    ERROR_USER_DELETE_PROTECTED_ACCOUNT: "error.user.delete.protected.account",

    // TODO: Organize and Generalize translations below
    ACTIVITY_CREATE_BUTTON_TOOLTIP: 'activity.create.button.tooltip',

    AGENT_MOST_USED_WORKFLOWS_HEADER: 'agent.most.used.workflows.header',
    AGENT_TOTAL_EXECUTED_HOURS_HEADER: 'agent.total.executed.hours.header',
    AGENT_WORKFLOW_EXCEPTIONS_HEADER: 'agent.workflow.exceptions.header',
    AGENT_WORKFLOW_EVENTS_HEADER: 'agent.workflow.events.header',

    AGENT_ACTIVE_AGENT_COUNT_LABEL: 'agent.active.agent.count.label',
    AGENT_FAILED_EXECUTION_LABEL: 'agent.failed.execution.label',
    AGENT_SCHEDULED_WORKFLOWS_LABEL: 'agent.scheduled.workflows.label',
    AGENT_WORKFLOW_COMPLETED_LABEL: 'agent.workflow.completed.label',

    AGENT_VIEW_ALL_EXCEPTIONS_LINK: 'agent.view.all.exceptions.link',
    AGENT_VIEW_ALL_WORKFLOWS_LINK: 'agent.view.all.workflows.link',
    AGENT_VIEW_ALL_EVENTS_LINK: 'agent.view.all.events.link',

    BREADCRUMB_COMPANY_LICENSE_MANAGEMENT_LABEL: 'breadcrumb.company.license_management.label',
    BREADCRUMB_COMPANY_COMPANIES_LABEL: 'breadcrumb.company.companies.label',
    BREADCRUMB_COMPANY_LABEL: 'breadcrumb.company.label',
    BREADCRUMB_COMPANY_KEYS_LABEL: 'breadcrumb.company.keys.label',

    BREADCRUMB_ACTIVITY_LABEL: 'breadcrumb.activity.label',
    BREADCRUMB_ACTIVITY_LIST_LABEL: 'breadcrumb.activity.list.label',
    BREADCRUMB_PREMIUM_ACTIVITY_LIST_LABEL: 'breadcrumb.premium_activity.list.label',

    BREADCRUMB_USER_LABEL: 'breadcrumb.user.label',
    BREADCRUMB_USER_LIST_LABEL: 'breadcrumb.user.list.label',

    BREADCRUMB_WORKFLOW_LABEL: 'breadcrumb.workflow.label',
    BREADCRUMB_WORKFLOW_EVENTS_LABEL: 'breadcrumb.workflow.events.label',
    BREADCRUMB_WORKFLOW_EXCEPTIONS_LABEL: 'breadcrumb.workflow.exceptions.label',
    BREADCRUMB_WORKFLOW_LIST_LABEL: 'breadcrumb.workflow.list.label',
    BREADCRUMB_WORKFLOW_LOGS_LABEL: 'breadcrumb.workflow.logs.label',

    COMPANY_CREATE_BUTTON_TOOLTIP: 'company.create.button.tooltip',

    COMPANY_MODAL_CREATE_HEADER: 'company.modal.create.header',
    COMPANY_MODAL_CREATE_ADD_BUTTON: 'company.modal.create.add.button',
    COMPANY_MODAL_CREATE_CLEAR_BUTTON: 'company.modal.create.clear.button',
    COMPANY_MODAL_CREATE_EMAIL_LABEL: 'company.modal.create.email.label',
    COMPANY_MODAL_CREATE_INDUSTRY_LABEL: 'company.modal.create.industry.label',
    COMPANY_MODAL_CREATE_INDUSTRY_DEFAULT: 'company.modal.create.industry_default',
    COMPANY_MODAL_CREATE_NAME_LABEL: 'company.modal.create.name.label',
    COMPANY_MODAL_CREATE_SUBTEXT: 'company.modal.create.subtext',
    COMPANY_MODAL_CREATE_SUCCESS: 'company.modal.create.success',
    COMPANY_MODAL_DELETE_BUTTON: 'company.modal.delete.button',
    COMPANY_MODAL_DELETE_CANCEL_BUTTON: 'company.modal.delete.cancel_button',
    COMPANY_MODAL_DELETE_HEADER: 'company.modal.delete.header',
    COMPANY_MODAL_DELETE_SUCCESS: 'company.modal.delete.success',
    COMPANY_MODAL_DELETE_TEXT: 'company.modal.delete.text',
    COMPANY_MODAL_UPDATE_BUTTON: 'company.modal.update.button',
    COMPANY_MODAL_UPDATE_HEADER: 'company.modal.update.header',
    COMPANY_MODAL_UPDATE_SUCCESS: 'company.modal.update.success',
    COMPANY_MODAL_UPDATE_COMPANY_HEAD: 'company.modal.update.company_head',
    COMPANY_MODAL_UPDATE_COMPANY_HEAD_DEFAULT: 'company.modal.update.company_head_default',

    ERROR_ACCOUNT_REGFILE_REQUIRED: 'error.account.registration_file.required',
    ERROR_ACCOUNT_REGFILE_UNSUPPORTED: 'error.account.registration_file.unsupported',
    ERROR_ACCOUNT_REGFILE_INVALID: 'error.account.registration_file.invalid',

    ERROR_COMPANY_NAME_REQUIRED: 'error.company.name.required',
    ERROR_COMPANY_NAME_TOO_LONG: 'error.company.name.too_long',
    ERROR_COMPANY_EMAIL_REQUIRED: 'error.company.email.required',
    ERROR_COMPANY_EMAIL_INVALID: 'error.company.email.invalid',
    ERROR_COMPANY_EMAIL_DUPLICATE: 'error.company.email.duplicate',
    ERROR_COMPANY_NAME_DUPLICATE: 'error.company.name.duplicate',
    ERROR_COMPANY_EMAIL_TOO_LONG: 'error.company.email.too_long',
    ERROR_COMPANY_INDUSTRY_REQUIRED: 'error.company.industry.required',
    ERROR_COMPANY_INDUSTRY_INVALID: 'error.company.industry.invalid',
    ERROR_COMPANY_NAME_INVALID: 'error.company.name.invalid',
    ERROR_COMPANY_NOT_EXIST: 'error.company.not.exist',
    ERROR_COMPANY_COMPANY_HEAD_REQUIRED: 'error.company.head.required',
    ERROR_COMPANY_COMPANY_HEAD_IN_ANOTHER_COMPANY: 'error.company.head.in_different_company',

    ERROR_COMPANY_FULL_LICENSES: 'error.company.full_licenses',
    ERROR_COMPANY_HAS_LICENSE: 'error.company.has_licenses',
    ERROR_COMPANY_MORE_LICENSES: 'error.company.more_licenses',

    ERROR_GENERAL_INTERNAL: 'error.general.internal',
    ERROR_GENERAL_PROTECTED: 'error.general.protected',

    ERROR_ACTIVITY_NAME_REQUIRED: 'error.activity.name.required',
    ERROR_ACTIVITY_KEY_REQUIRED: 'error.activity.key.required',
    ERROR_ACTIVITY_ID_REQUIRED: 'error.activity.id.required',
    ERROR_ACTIVITY_NOT_EXIST: 'error.activity.id.not_exist',
    ERROR_ACTIVITY_NAME_EXCEEDS_CHARACTER_LIMIT: 'error.activity.name.exceeds.character.limit',
    ERROR_ACTIVITY_KEY_EXCEEDS_CHARACTER_LIMIT: 'error.activity.key.exceeds.character.limit',
    ERROR_ACTIVITY_CATEGORY_EXCEEDS_CHARACTER_LIMIT: 'error.activity.category.exceeds.character.limit',

    ERROR_DIRECTORY_NAME_REQUIRED: 'error.directory.name.required',
    ERROR_DIRECTORY_NAME_TOO_LONG: 'error.directory.name.too_long',
    ERROR_DIRECTORY_NAME_EXIST: 'error.directory.name.exist',
    ERROR_DIRECTORY_NAME_INVALID: 'error.directory.name.invalid',
    ERROR_FILE_NAME_REQUIRED: 'error.file.name.required',
    ERROR_FILE_NAME_TOO_LONG: 'error.file.name.too_long',
    ERROR_FILE_NAME_EXIST: 'error.file.name.exist',
    ERROR_FILE_NAME_INVALID: 'error.file.name.invalid',

    ERROR_FORGOT_PASSWORD_FORM_INVALID: 'error.forgot.password.form.invalid',
    ERROR_FORGOT_PASSWORD_FORM_EMAIL_REQUIRED: 'error.forgot.password.form.email.required',

    ERROR_LICENSE_ALLOWED_KEYS_REQUIRED: 'error.license.allowed_keys.required',
    ERROR_LICENSE_ALLOWED_KEYS_TOO_LOW: 'error.license.allowed_keys.too_low',
    ERROR_LICENSE_ALLOWED_KEYS_TOO_HIGH: 'error.license.allowed_keys.too_high',
    ERROR_LICENSE_EXPIRATION_DATE_INVALID: 'error.license.expiration_date.invalid',
    ERROR_LICENSE_EXPIRATION_DATE_REQUIRED: 'error.license.expiration_date.required',

    ERROR_LICENSE_EXPIRED_KEY: 'error.license.expired_key',

    ERROR_LICENSE_ID_REQUIRED: 'error.license.id.required',
    ERROR_LICENSE_NOT_EXIST: 'error.license.id.not_exist',
    ERROR_LICENSE_NOT_SOLO: 'error.license.type.not_solo',

    ERROR_LICENSE_TYPE_INVALID: 'error.license.type.invalid',
    ERROR_LICENSE_TYPE_REQUIRED: 'error.license.type.required',
    ERROR_LICENSE_LABEL_TOO_LONG: 'error.license.label.too_long',

    ERROR_LOGIN_FORM_EMAIL_REQUIRED: 'error.login.form.email.required',
    ERROR_LOGIN_FORM_INCORRECT_CREDENTIALS: 'error.login.form.incorrect.credentials',
    ERROR_LOGIN_FORM_PASSWORD_REQUIRED: 'error.login.form.password.required',

    ERROR_RESET_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_REQUIRED: 'error.reset.password.form.confirm.new.password.required',
    ERROR_RESET_PASSWORD_FORM_INVALID: 'error.reset.password.form.invalid',
    ERROR_RESET_PASSWORD_FORM_NEW_PASSWORD_MISMATCH: 'error.reset.password.form.new.password.mismatch',
    ERROR_RESET_PASSWORD_FORM_NEW_PASSWORD_REQUIRED: 'error.reset.password.form.new.password.required',

    ERROR_USER_FIRST_NAME_REQUIRED: 'error.user.first.name.required',
    ERROR_USER_LAST_NAME_REQUIRED: 'error.user.last.name.required',
    ERROR_USER_EMAIL_REQUIRED: 'error.user.email.required',
    ERROR_USER_EMAIL_INVALID: 'error.user.email.invalid',
    ERROR_USER_EMAIL_ALREADY_USED: 'error.user.email.already.used',
    ERROR_USER_EMAIL_TOO_LONG: 'error.user.email.too_long',
    ERROR_USER_PASSWORD_REQUIRED: 'error.user.password.required',
    ERROR_USER_CONFIRM_PASSWORD_REQUIRED: 'error.user.confirm.password.required',
    ERROR_USER_DEPARTMENT_REQUIRED: 'error.user.department.required',
    ERROR_USER_ROLE_REQUIRED: 'error.user.role.required',
    ERROR_USER_COMPANY_REQUIRED: 'error.user.company.required',
    ERROR_USER_FIRST_NAME_TOO_LONG: 'error.user.first.name.too_long',
    ERROR_USER_LAST_NAME_TOO_LONG: 'error.user.last.name.too_long',
    ERROR_USER_DEPARTMENT_TOO_LONG: 'error.user.department.too_long',
    ERROR_USER_ROLE_TOO_LONG: 'error.user.role.too_long',
    ERROR_USER_PASSWORD_MISMATCH: 'error.user.password.mismatch',

    ERROR_WORKFLOW_FILE_REQUIRED: 'error.workflow.file.required',
    ERROR_WORKFLOW_FILE_UNSUPPORTED: 'error.workflow.file.unsupported',
    ERROR_WORKFLOW_ID_REQUIRED: 'error.workflow.id.required',
    ERROR_WORKFLOW_NOT_EXIST: 'error.workflow.id.not_exist',
    ERROR_WORKFLOW_NAME_REQUIRED: 'error.workflow.name.required',
    ERROR_WORKFLOW_NAME_INVALID: 'error.workflow.name.invalid',
    ERROR_WORKFLOW_NAME_TOO_LONG: 'error.workflow.name.too_long',
    ERROR_WORKFLOW_DESCRIPTION_TOO_LONG: 'error.workflow.description.too_long',
    ERROR_WORKFLOW_FILE_EXIST: "error.workflow.file.exist",

    FORM_CONTROL_FILE_PLACEHOLDER: 'form.control.file.placeholder',

    FORGOT_PASSWORD_FORM_HEADER: 'forgot.password.form.header',
    FORGOT_PASSWORD_FORM_EMAIL_LABEL: 'forgot.password.form.email.label',
    FORGOT_PASSWORD_FORM_CONFIRM_BUTTON: 'forgot.password.form.confirm.button',
    FORGOT_PASSWORD_FORM_LOGIN_LINK: 'forgot.password.form.login.link',

    FORGOT_PASSWORD_SUCCESS_HEADER: 'forgot.password.success.header',
    FORGOT_PASSWORD_SUCCESS_MESSAGE: 'forgot.password.success.message',
    FORGOT_PASSWORD_SUCCESS_LINK: 'forgot.password.success.link',

    KEY_DELETE_MODAL_HEADER: 'key.delete.modal.header',
    KEY_DELETE_MODAL_TEXT: 'key.delete.modal.text',
    KEY_DELETE_MODAL_CANCEL_BUTTON: 'key.delete.modal.cancel_button',
    KEY_DELETE_MODAL_DELETE_BUTTON: 'key.delete.modal.delete_button',
    KEY_DELETE_MODAL_SUCCESS: 'key.delete.modal.success',

    KEY_GENERATE_MODAL_HEADER: 'key.generate.modal.header',
    KEY_GENERATE_MODAL_TEXT: 'key.generate.modal.text',
    KEY_GENERATE_MODAL_CANCEL_BUTTON: 'key.generate.modal.cancel_button',
    KEY_GENERATE_MODAL_GENERATE_BUTTON: 'key.generate.modal.generate_button',
    KEY_GENERATE_MODAL_SUCCESS: 'key.generate.modal.success',

    KEY_GENERATE_FROM_FILE_MODAL_HEADER: 'key.generate_from_file.modal.header',
    KEY_GENERATE_FROM_FILE_MODAL_TEXT: 'key.generate_from_file.modal.text',
    KEY_GENERATE_FROM_FILE_MODAL_REGISTRATION_FILE: 'key.generate_from_file.modal.registration_file',

    LICENSE_CREATE_BUTTON_TOOLTIP: 'license.create.button.tooltip',

    LICENSE_GENERATE_KEY_LABEL: 'license.generate.key.label',
    LICENSE_GENERATE_FROM_FILE_LABEL: 'license.generate_from_file.label',

    LICENSE_MODAL_CREATE_CLEAR_BUTTON: 'license.modal.create.clear_button',
    LICENSE_MODAL_CREATE_HEADER: 'license.modal.create.header',
    LICENSE_MODAL_CREATE_LICENSE_TYPE_DEFAULT: 'license.modal.create.license_type_default',
    LICENSE_MODAL_CREATE_LICENSE_TYPE_LABEL: 'license.modal.create.license_type_label',
    LICENSE_MODAL_CREATE_SAVE_BUTTON: 'license.modal.create.save_button',
    LICENSE_MODAL_CREATE_SUCCESS: 'license.modal.create.success',
    LICENSE_MODAL_CREATE_LICENSE_LABEL: 'license.modal.create.license_label',
    LICENSE_MODAL_DEPARTMENT_LABEL: 'license.modal.department.label',

    LICENSE_MODAL_DELETE_BUTTON: 'license.modal.delete.button',
    LICENSE_MODAL_DELETE_CANCEL_BUTTON: 'license.modal.delete.cancel_button',
    LICENSE_MODAL_DELETE_HEADER: 'license.modal.delete.header',
    LICENSE_MODAL_DELETE_SUCCESS: 'license.modal.delete.success',
    LICENSE_MODAL_DELETE_TEXT: 'license.modal.delete.text',

    LICENSE_MODAL_UPDATE_LICENSE_LABEL: 'license.modal.update.license_label',
    LICENSE_MODAL_UPDATE_ALLOWED_KEYS_LABEL: 'license.modal.update.allowed_keys_label',
    LICENSE_MODAL_UPDATE_CLEAR_BUTTON: 'license.modal.update.clear_button',
    LICENSE_MODAL_UPDATE_EXPIRATION_DATE_LABEL: 'license.modal.update.expiration_date_label',
    LICENSE_MODAL_UPDATE_HEADER: 'license.modal.update.header',
    LICENSE_MODAL_UPDATE_SAVE_BUTTON: 'license.modal.update.save_button',
    LICENSE_MODAL_UPDATE_SUBTEXT: 'license.modal.update.subtext',
    LICENSE_MODAL_UPDATE_SUCCESS: 'license.modal.update.success',

    LOGIN_HEADER: 'login.header',
    LOGIN_SUBHEADER: 'login.subheader',
    LOGIN_SUPPORT_TEXT: 'login.support.text',
    LOGIN_SUPPORT_LINK: 'login.support.link',

    LOGIN_FORM_HEADER: 'login.form.header',
    LOGIN_FORM_EMAIL_LABEL: 'login.form.email.label',
    LOGIN_FORM_PASSWORD_LABEL: 'login.form.password.label',
    LOGIN_FORM_LOGIN_BUTTON: 'login.form.login.button',
    LOGIN_FORM_FORGOT_PASSWORD_LINK: 'login.form.forgot.password.link',

    MODAL_ACTIVITY_CREATE_HEADER: 'modal.activty.create.header',
    MODAL_ACTIVITY_UPDATE_HEADER: 'modal.activty.update.header',
    MODAL_ACTIVITY_DELETE_HEADER: 'modal.activty.delete.header',
    MODAL_ACTIVITY_ADD_BUTTON: 'modal.activty.add.button',
    MODAL_ACTIVITY_CLEAR_BUTTON: 'modal.activty.clear.button',
    MODAL_ACTIVITY_UPDATE_BUTTON: 'modal.activty.update.button',
    MODAL_ACTIVITY_ARCHIVE_BUTTON: 'modal.activty.archive.button',
    MODAL_ACTIVITY_CANCEL_BUTTON: 'modal.activty.cancel.button',
    MODAL_ACTIVITY_NAME_LABEL: 'modal.activty.name.label',
    MODAL_ACTIVITY_KEY_LABEL: 'modal.activty.key.label',
    MODAL_ACTIVITY_CATEGORY_LABEL: 'modal.activty.category.label',
    MODAL_ACTIVITY_PARTIAL_LABEL: 'modal.activty.partial.label',
    MODAL_ACTIVITY_FORM_SUBTEXT: 'modal.activty.form.subtext',
    MODAL_ACTIVITY_ARCHIVE_TEXT: 'modal.activty.archive.text',
    MODAL_ACTIVITY_CREATE_SUCCESS: 'modal.activty.create.success',
    MODAL_ACTIVITY_UPDATE_SUCCESS: 'modal.activty.update.success',
    MODAL_ACTIVITY_DELETE_SUCCESS: 'modal.activty.delete.success',

    MODAL_ACCOUNT_DELETE_HEADER: 'modal.account.delete.header',
    MODAL_ACCOUNT_DELETE_TEXT: 'modal.account.delete.text',
    MODAL_ACCOUNT_CANCEL_BUTTON: 'modal.account.cancel.button',
    MODAL_ACCOUNT_DELETE_BUTTON: 'modal.account.delete.button',
    MODAL_ACCOUNT_DELETE_SUCCESS: 'modal.account.delete.success',

    MODAL_USER_DELETE_HEADER: 'modal.user.delete.header',
    MODAL_USER_CREATE_HEADER: 'modal.user.create.header',
    MODAL_USER_UPDATE_HEADER: 'modal.user.update.header',
    MODAL_USER_DELETE_TEXT: 'modal.user.delete.text',
    MODAL_USER_REGISTER_BUTTON: 'modal.user.add.button',
    MODAL_USER_CANCEL_BUTTON: 'modal.user.cancel.button',
    MODAL_USER_CLEAR_BUTTON: 'modal.user.clear.button',
    MODAL_USER_DELETE_BUTTON: 'modal.user.delete.button',
    MODAL_USER_UPDATE_BUTTON: 'modal.user.update.button',
    MODAL_USER_CREATE_SUCCESS: 'modal.user.create.success',
    MODAL_USER_DELETE_SUCCESS: 'modal.user.delete.success',
    MODAL_USER_UPDATE_SUCCESS: 'modal.user.update.success',
    MODAL_USER_FORM_SUBTEXT: 'modal.user.form.subtext',
    MODAL_USER_FIRST_NAME_LABEL: 'modal.user.first.name.label',
    MODAL_USER_LAST_NAME_LABEL: 'modal.user.last.name.label',
    MODAL_USER_EMAIL_LABEL: 'modal.user.email.label',
    MODAL_USER_COMPANY_LABEL: 'modal.user.company.label',
    MODAL_USER_DEPARTMENT_LABEL: 'modal.user.department.label',
    MODAL_USER_ROLE_LABEL: 'modal.user.role.label',
    MODAL_USER_PASSWORD_LABEL: 'modal.user.password.label',
    MODAL_USER_CONFIRM_PASSWORD_LABEL: 'modal.user.confirm.password.label',
    MODAL_USER_FIELD_COMPANY_DEFAULT: 'modal.user.field.company.default',
    MODAL_USER_FIELD_DEPARTMENT_DEFAULT: 'modal.user.field.department.default',
    MODAL_USER_FIELD_ROLE_DEFAULT: 'modal.user.field.role.default',

    RESET_PASSWORD_FORM_HEADER: 'reset.password.form.header',
    RESET_PASSWORD_FORM_NEW_PASSWORD_LABEL: 'reset.password.form.new.password.label',
    RESET_PASSWORD_FORM_CONFIRM_NEW_PASSWORD_LABEL: 'reset.password.form.confirm.new.password.label',
    RESET_PASSWORD_FORM_SUBMIT_BUTTON: 'reset.password.form.submit.button',

    RESET_PASSWORD_SUCCESS_HEADER: 'reset.password.success.header',
    RESET_PASSWORD_SUCCESS_MESSAGE: 'reset.password.success.message',
    RESET_PASSWORD_SUCCESS_LINK: 'reset.password.success.link',

    SIDEBAR_DASHBOARD_LABEL: 'sidebar.dashboard.label',
    SIDEBAR_WORKFLOW_MANAGEMENT_LABEL: 'sidebar.workflow.management.label',
    SIDEBAR_LICENSE_MANAGEMENT_LABEL: 'sidebar.license.management.label',
    SIDEBAR_USER_MANAGEMENT_LABEL: 'sidebar.user.management.label',
    SIDEBAR_LOGOUT_LABEL: 'sidebar.logout.label',

    SIDEBAR_REPORTS_AGENT_LABEL: 'sidebar.reports.agent.label',
    SIDEBAR_REPORTS_MONITOR_LABEL: 'sidebar.reports.monitor.label',

    SIDEBAR_WORKFLOW_LIST_LABEL: 'sidebar.workflow.list.label',
    SIDEBAR_WORKFLOW_EVENTS_LABEL: 'sidebar.workflow.events.label',
    SIDEBAR_WORKFLOW_ERRORS_LABEL: 'sidebar.workflow.errors.label',
    SIDEBAR_WORKFLOW_LOGS_LABEL: 'sidebar.workflow.logs.label',

    SIDEBAR_LICENSE_COMPANY_LABEL: 'sidebar.license.company.label',
    SIDEBAR_LICENSE_LICENSES_LABEL: 'sidebar.license.licenses.label',
    SIDEBAR_LICENSE_ACCOUNT_LABEL: 'sidebar.license.account.label',
    SIDEBAR_LICENSE_ACTIVITY_LABEL: 'sidebar.license.activity.label',
    SIDEBAR_LICENSE_PREMIUM_ACTIVITY_LABEL: 'sidebar.license.premium_activity.label',

    SIDEBAR_MANAGEMENT_USER_LABEL: 'sidebar.management.user.label',
    SIDEBAR_MANAGEMENT_DEPARTMENT_LABEL: 'sidebar.management.department.label',
    SIDEBAR_MANAGEMENT_ROLE_LABEL: 'sidebar.management.role.label',
    SIDEBAR_MANAGEMENT_STATUS_LABEL: 'sidebar.management.status.label',
    SIDEBAR_MANAGEMENT_TASK_LABEL: 'sidebar.management.task.label',
    SIDEBAR_MANAGEMENT_SCHEDULE_LABEL: 'sidebar.management.schedule.label',
    SIDEBAR_MANAGEMENT_TARGET_LABEL: 'sidebar.management.target.label',
    SIDEBAR_MANAGEMENT_TRANSFER_LABEL: 'sidebar.management.transfer.label',
    SIDEBAR_MANAGEMENT_DATA_ENTRY_LABEL: 'sidebar.management.data.entry.label',

    TABLE_GENERAL_EMPTY_PLACEHOLDER: 'table.general.empty.placeholder',

    TABLE_ACTIVITY_CREATE_DATE_HEADER: 'table.activity.create_date.header',
    TABLE_ACTIVITY_NAME_HEADER: 'table.activity.name.header',
    TABLE_ACTIVITY_KEY_HEADER: 'table.activity.key.header',
    TABLE_ACTIVITY_CATEGORY_HEADER: 'table.activity.category.header',
    TABLE_ACTIVITY_EMPTY_PLACEHOLDER: 'table.activity.empty.placeholder',

    TABLE_ACCOUNT_EMPTY_PLACEHOLDER: 'table.account.empty.placeholder',
    TABLE_ACCOUNT_TYPE_HEADER: 'table.account.type.header',
    TABLE_ACCOUNT_NAME_HEADER: 'table.account.name.header',
    TABLE_ACCOUNT_EMAIL_ADDRESS_HEADER: 'table.account.email_address.header',
    TABLE_ACCOUNT_MAC_ADDRESS_HEADER: 'table.account.mac_address.header',
    TABLE_ACCOUNT_EXPIRATION_DATE_HEADER: 'table.account.expiration_date.header',

    TABLE_AGENT_WORKFLOW_HEADER: 'table.agent.workflow.header',
    TABLE_AGENT_SUCCESSFUL_EXECUTION_HEADER: 'table.agent.successful_execution.header',
    TABLE_AGENT_DATE_EXECUTED_HEADER: 'table.agent.date_executed.header',
    TABLE_AGENT_WORKFLOW_NAME_HEADER: 'table.agent.workflow_name.header',
    TABLE_AGENT_MESSAGE_HEADER: 'table.agent.message.header',
    TABLE_AGENT_EXECUTION_START_HEADER: 'table.agent.execution_start.header',
    TABLE_AGENT_EXECUTION_END_HEADER: 'table.agent.execution_end.header',
    TABLE_AGENT_EXECUTED_BY_HEADER: 'table.agent.executed_by.header',
    TABLE_AGENT_EXECUTION_STATUS_HEADER: 'table.agent.execution_status.header',
    TABLE_AGENT_RUNTIME_HEADER: 'table.agent.runtime.header',

    TABLE_COMPANY_NAME_HEADER: 'table.company.name.header',
    TABLE_COMPANY_EMAIL_HEADER: 'table.company.email.header',
    TABLE_COMPANY_EMPTY_PLACEHOLDER: 'table.company.empty.placeholder',
    TABLE_COMPANY_INDUSTRY_HEADER: 'table.company.industry.header',
    TABLE_COMPANY_HEAD_HEADER: 'table.company.head.header',

    TABLE_LICENSE_TYPE_HEADER: 'table.license.type.header',
    TABLE_LICENSE_LABEL_HEADER: 'table.license.label.header',
    TABLE_LICENSE_ISSUE_DATE_HEADER: 'table.license.issue_date.header',
    TABLE_LICENSE_EXPIRATION_DATE_HEADER: 'table.license.expiration_date.header',
    TABLE_LICENSE_ALLOWED_KEYS_HEADER: 'table.license.allowed_keys.header',
    TABLE_LICENSE_REMAINING_KEYS_HEADER: 'table.license.remaining_keys.header',
    TABLE_LICENSE_EMPTY_PLACEHOLDER: 'table.license.empty.placeholder',

    TABLE_LICENSE_KEY_HEADER: 'table.license_key.header',
    TABLE_LICENSE_KEY_NAME_HEADER: 'table.license_key.name.header',
    TABLE_LICENSE_KEY_MACADDRESS_HEADER: 'table.license_key.macaddress.header',
    TABLE_LICENSE_KEY_EMAIL_HEADER: 'table.license_key.email.header',
    TABLE_LICENSE_KEY_EMPTY_PLACEHOLDER: 'table.license_key.empty.placeholder',
    TABLE_LICENSE_KEY_DEPARTMENT_HEADER: 'table.license_key.department.header',

    TABLE_USER_EMPTY_PLACEHOLDER: 'table.user.empty.placeholder',
    TABLE_USER_FULL_NAME_HEADER: 'table.user.full.name.header',
    TABLE_USER_DEPARTMENT_HEADER: 'table.user.department.header',
    TABLE_USER_ROLE_HEADER: 'table.user.role.header',
    TABLE_USER_COMPANY_HEADER: 'table.user.company.header',
    TABLE_USER_EMAIL_HEADER: 'table.user.email.header',

    TABLE_WORKFLOW_EMPTY_PLACEHOLDER: 'table.workflow.empty.placeholder',
    TABLE_WORKFLOW_CREATE_DATE_HEADER: 'table.workflow.create_date.header',
    TABLE_WORKFLOW_NAME_HEADER: 'table.workflow.name.header',
    TABLE_WORKFLOW_DESCRIPTION_HEADER: 'table.workflow.description.header',
    TABLE_WORKFLOW_LAST_MODIFIED_HEADER: 'table.workflow.last_modified.header',
    TABLE_WORKFLOW_CREATED_BY_HEADER: 'table.workflow.created_by.header',
    TABLE_WORKFLOW_UPDATED_BY_HEADER: 'table.workflow.updated_by.header',
    TABLE_WORKFLOW_FILE_HEADER: 'table.workflow.file.header',
    TABLE_WORKFLOW_SUCCESSFUL_EXECUTION_HEADER: 'table.workflow.successful_execution.header',

    TABLE_DEPARTMENT_EMPTY_PLACEHOLDER: 'table.department.empty.placeholder',
    TABLE_DEPARTMENT_NAME_HEADER: 'table.department.name.header',
    TABLE_DEPARTMENT_HEAD_HEADER: 'table.department.head.header',

    TOOLTIP_COMPANY_DELETE_ICON: 'tooltip.company.delete.icon',
    TOOLTIP_COMPANY_EDIT_ICON: 'tooltip.company.edit.icon',

    TOOLTIP_KEY_DELETE_ICON: 'tooltip.key.delete.icon',
    TOOLTIP_KEY_EXPORT_ICON: 'tooltip.key.export.icon',

    TOOLTIP_LICENSE_KEY_ICON: 'tooltip.license.key.icon',
    TOOLTIP_LICENSE_EDIT_ICON: 'tooltip.license.edit.icon',
    TOOLTIP_LICENSE_DELETE_ICON: 'tooltip.license.delete.icon',
    TOOLTIP_ACCOUNT_DELETE_ICON: 'tooltip.account.delete.icon',
    TOOLTIP_ACTIVITY_EDIT_ICON: 'tooltip.activity.edit.icon',
    TOOLTIP_ACTIVITY_DELETE_ICON: 'tooltip.activity.delete.icon',
    TOOLTIP_USER_EDIT_ICON: 'tooltip.user.edit.icon',
    TOOLTIP_USER_DELETE_ICON: 'tooltip.user.delete.icon',
    TOOLTIP_USER_CREATE_BUTTON: 'tooltip.user.create.button',

    TOOLTIP_WORKFLOW_DOWNLOAD_ICON: 'tooltip.workflow.download.icon',
    TOOLTIP_WORKFLOW_EDIT_ICON: 'tooltip.workflow.edit.icon',
    TOOLTIP_WORKFLOW_DELETE_ICON: 'tooltip.workflow.delete.icon',

    WORKFLOW_CREATE_BUTTON_TOOLTIP: 'workflow.create.button.tooltip',

    WORKFLOW_MODAL_CREATE_HEADER: 'workflow.modal.create.header',
    WORKFLOW_MODAL_UPDATE_HEADER: 'workflow.modal.update.header',
    WORKFLOW_MODAL_DELETE_HEADER: 'workflow.modal.delete.header',
    WORKFLOW_MODAL_CLEAR_BUTTON: 'workflow.modal.clear.button',
    WORKFLOW_MODAL_ADD_BUTTON: 'workflow.modal.add.button',
    WORKFLOW_MODAL_UPDATE_BUTTON: 'workflow.modal.update.button',
    WORKFLOW_MODAL_ARCHIVE_BUTTON: 'workflow.modal.archive.button',
    WORKFLOW_MODAL_CANCEL_BUTTON: 'workflow.modal.cancel.button',
    WORKFLOW_MODAL_NAME_LABEL: 'workflow.modal.name.label',
    WORKFLOW_MODAL_DESCRIPTION_LABEL: 'workflow.modal.description.label',
    WORKFLOW_MODAL_FILE_LABEL: 'workflow.modal.file.label',
    WORKFLOW_MODAL_FORM_SUBTEXT: 'workflow.modal.form.subtext',
    WORKFLOW_MODAL_ARCHIVE_TEXT: 'workflow.modal.archive.text',
    WORKFLOW_MODAL_CREATE_SUCCESS: 'workflow.modal.create.success',
    WORKFLOW_MODAL_UPDATE_SUCCESS: 'workflow.modal.update.success',
    WORKFLOW_MODAL_DELETE_SUCCESS: 'workflow.modal.delete.success',
    WORKFLOW_ROOT_DIRECTORY: 'workflow.root.directory',

    BREADCRUMB_USER_MANAGEMENT_LABEL: 'breadcrumb.user_management.label',
    BREADCRUMB_DEPARTMENTS_LABEL: 'breadcrumb.departments.label',

    DEPARTMENT_TABLE_HEADER_NAME: 'department.table.header.name',
    DEPARTMENT_TABLE_HEADER_PARENT: 'department.table.header.parent',
    DEPARTMENT_TABLE_PLACEHOLDER_EMPTY: 'department.table.placeholder.empty',

    DEPARTMENT_MODAL_HEADER_CREATE: 'department.modal.header.create',
    DEPARTMENT_MODAL_HEADER_UPDATE: 'department.modal.header.update',
    DEPARTMENT_MODAL_HEADER_DELETE: 'department.modal.header.delete',
    GENERAL_SUBTEXT_REQUIRED: 'general.subtext.required',

    DEPARTMENT_MODAL_LABEL_NAME: 'department.modal.label.name',
    DEPARTMENT_MODAL_LABEL_PARENT: 'department.modal.label.parent',
    DEPARTMENT_MODAL_LABEL_HEAD: 'department.modal.label.head',

    DEPARTMENT_MODAL_FIELD_PARENT_DEFAULT: 'department.modal.field.parent.default',
    DEPARTMENT_DISPLAY_MESSAGE_NO_CHILDREN: 'department.display.message.no.children',

    DEPARTMENT_MODAL_MESSAGE_CREATE_SUCCESS: 'department.modal.message.create.success',
    DEPARTMENT_MODAL_MESSAGE_DELETE_SUCCESS: 'department.modal.message.delete.success',
    DEPARTMENT_MODAL_MESSAGE_DELETE_TEXT: 'department.modal.message.delete.text',
    DEPARTMENT_MODAL_MESSAGE_UPDATE_SUCCESS: 'department.modal.message.update.success',

    ERROR_DEPARTMENT_NAME_REQUIRED: "error.department.name.required",
    ERROR_DEPARTMENT_HEAD_REQUIRED: "error.department.head.required",
    ERROR_DEPARTMENT_HEAD_NOT_SELECTED: "error.department.head.not.selected",
	ERROR_DEPARTMENT_NAME_TOO_LONG: "error.department.name.too_long",
	ERROR_DEPARTMENT_DOES_NOT_EXIST: "error.department.does_not_exist",
	ERROR_DEPARTMENT_PARENT_INVALID: "error.department.parent.invalid",
    ERROR_DEPARTMENT_NAME_INVALID: "error.department.name.invalid",
    ERROR_DEPARTMENT_NAME_DUPLICATE: "error.department.name.duplicate",
    
    BREADCRUMB_ROLES_LABEL: 'breadcrumb.roles.label',

    ROLE_DISPLAY_EMPTY: 'role.display.empty',

    ROLE_MODAL_HEADER_CREATE: 'role.modal.header.create',
    ROLE_MODAL_HEADER_UPDATE: 'role.modal.header.update',
    ROLE_MODAL_HEADER_DELETE: 'role.modal.header.delete',

    ROLE_MODAL_LABEL_TITLE: 'role.modal.label.title',
    ROLE_MODAL_HINT_ROLE_NAME: 'role.modal.hint.role_name',
    ROLE_MODAL_SUBTEXT_REQUIRED: 'role.modal.subtext.required',
    ROLE_MODAL_LABEL_SELECT_ALL: 'role.modal.label.select_all',
    ROLE_MODAL_LABEL_UNSELECT_ALL: 'role.modal.label.unselect_all',
    ROLE_MODAL_SUBTEXT_AUTHORIZE: 'role.modal.subtext.authorize',

    ROLE_MODAL_MESSAGE_CREATE_SUCCESS: 'role.modal.message.create.success',
    ROLE_MODAL_MESSAGE_DELETE_SUCCESS: 'role.modal.message.delete.success',
    ROLE_MODAL_MESSAGE_DELETE_TEXT: 'role.modal.message.delete.text',
    ROLE_MODAL_MESSAGE_UPDATE_SUCCESS: 'role.modal.message.update.success',

    ERROR_ROLE_NAME_REQUIRED: "error.role.name.required",
    ERROR_ROLE_NAME_TOO_LONG: "error.role.name.too_long",
    ERROR_ROLE_DELETE_ASSOCIATED_WITH_USER: "error.role.delete.associated.with.user",
    
    STATUS_MODAL_SUBTEXT_REQUIRED: 'status.modal.subtext.required',

    STATUS_MODAL_LABEL_DEPARTMENT: 'status.modal.label.department',
    STATUS_MODAL_LABEL_THRESHOLD: 'status.modal.label.threshold',
    STATUS_MODAL_LABEL_COLOR: 'status.modal.label.color',
    STATUS_MODAL_LABEL_ICON: 'status.modal.label.icon',
    STATUS_MODAL_SUBTEXT_ICON : 'status.modal.subtext.icon',
    STATUS_MODAL_LABEL_TRACK_IDLE: 'status.modal.label.track_idle',
    STATUS_MODAL_FIELD_DEPARTMENT_DEFAULT : 'status.modal.field.department.default',

    ERROR_GENERAL_NAME_REQUIRED: 'error.general.name.required',
    ERROR_GENERAL_NAME_TOO_LONG: 'error.general.name.too_long',

    ERROR_STATUS_DEPARTMENT_REQUIRED: 'error.status.department.required',
    ERROR_STATUS_ICON_REQUIRED: 'error.status.icon.required',
    ERROR_STATUS_COLOR_REQUIRED: 'error.status.color.required',
    ERROR_STATUS_ICON_UNSUPPORTED: 'error.status.icon.unsupported',
    ERROR_STATUS_ICON_TOO_BIG: 'error.status.icon.too_big',
    ERROR_STATUS_ICON_INVALID_DIMENSION: 'error.status.icon.invalid_dimension',
    ERROR_STATUS_THRESHOLD_INVALID: 'error.status.threshold.invalid',

    STATUS_TABLE_HEADER_DEPARTMENT: 'status.table.header.department',
    STATUS_TABLE_HEADER_THRESHOLD: 'status.table.header.threshold',
    STATUS_TABLE_HEADER_COLOR: 'status.table.header.color',
    STATUS_TABLE_HEADER_ICON: 'status.table.header.icon',
    STATUS_TABLE_HEADER_TRACK_IDLE: 'status.table.header.track_idle',

    SCHEDULE_TABLE_PLACEHOLDER_EMPTY: 'schedule.table.placeholder.empty',
    SCHEDULE_MODAL_HEADER_CREATE: 'schedule.modal.header.create',
    MODAL_SCHEDULE_CREATE_HEADER: 'modal.schedule.create.header',
    MODAL_USERS_LABEL: 'modal.users.label',
    MODAL_DATE_FROM_LABEL: 'modal.date.from.label',
    MODAL_DATE_TO_LABEL: 'modal.date.to.label',
    MODAL_SELECTED_DAYS_LABEL: 'modal.selected.days.label',
    MODAL_SCHEDULE_START_LABEL: 'modal.schedule.start.label',
    MODAL_SCHEDULE_END_LABEL: 'modal.schedule.end.label',
    MODAL_SCHEDULE_UPDATE_HEADER: 'modal.schedule.update.header',
    MODAL_NEW_DATE_LABEL: 'modal.new.date.label',
    MODAL_SCHEDULE_DELETE_HEADER: 'modal.schedule.delete.header',
    ERROR_NO_USERS_SELECTED: 'error.no.users.selected',
    WARN_NO_SCHEDULE_SELECTED: 'warn.no.schedule.selected',
    MODAL_SCHEDULE_UPDATE_SUCCESS: 'modal.schedule.update.success',
    MODAL_SCHEDULE_DELETE_SUCCESS: 'modal.schedule.delete.success',
    MODAL_SCHEDULE_CREATE_SUCCESS: 'modal.schedule.create.success',

    DIRECTORY_CREATE_BUTTON: 'directory.create.button',
    DIRECTORY_MODAL_MESSAGE_CREATE_SUCCESS: 'directory.modal.message.create.success',
    DIRECTORY_MODAL_MESSAGE_DELETE_SUCCESS: 'directory.modal.message.delete.success',
    DIRECTORY_MODAL_MESSAGE_DELETE_TEXT: 'directory.modal.message.delete.text',
    DIRECTORY_MODAL_MESSAGE_UPDATE_SUCCESS: 'directory.modal.message.update.success',
    DIRECTORY_MODAL_CREATE_HEADER: 'directory.modal.create.header',
    DIRECTORY_MODAL_UPDATE_HEADER: 'directory.modal.update.header',
    DIRECTORY_MODAL_DELETE_HEADER: 'directory.modal.delete.header',
    DIRECTORY_MODAL_FORM_SUBTEXT: 'directory.modal.form.subtext',
    DIRECTORY_MODAL_NAME_LABEL: 'directory.modal.name.label',
    DIRECTORY_MODAL_MESSAGE_MULTIPLE_DELETE_SUCCESS: 'directory.modal.message.multiple.delete.success',
    DIRECTORY_MODAL_MESSAGE_MULTIPLE_UPDATE_SUCCESS: 'directory.modal.message.multiple.update.success',

    ERROR_WEBSOCKET_CONNECTION_FAILURE: 'error.websocket.connection.failure',

    WORKFLOW_EVENTS_WORKFLOW_EXECUTE_LABEL: 'workflow.events.workflow_execute.label',
    WORKFLOW_EVENTS_WORKFLOW_TERMINATE_LABEL: 'workflow.events.workflow_terminate.label',
    WORKFLOW_EVENTS_WORKFLOW_UNQUEUE_LABEL: 'workflow.events.workflow_unqueue.label',
    WORKFLOW_EVENTS_WORKFLOW_RUNNING_STATUS: 'workflow.events.workflow_running.status',
    WORKFLOW_EVENTS_WORKFLOW_QUEUED_STATUS: 'workflow.events.workflow_queued.status',
    WORKFLOW_EVENTS_WORKFLOW_START_EXECUTION_LABEL: 'workflow.events.workflow_start_execution.label',
    WORKFLOW_EVENTS_WORKFLOW_LAST_EXECUTION_LABEL: 'workflow.events.workflow_last_execution.label',
    WORKFLOW_EVENTS_QUEUED_WORKFLOW_POSITION: 'workflow.events.queued_workflow.position',
    WORKFLOW_EVENTS_QUEUED_WORKFLOW_COUNT: 'workflow.events.queued_workflow.count',
    WORKFLOW_EVENTS_VIEW_ALL_WORKFLOWS_LABEL: 'workflow.events.view_all_workflows.label',

    ERROR_WORKFLOW_EVENTS_EMPTY: 'error.workflow.events.empty',
    ERROR_WORKFLOW_EVENTS_AGENT_LIST_EMPTY: 'error.workflow.events.agent_list_empty',
    ERROR_WORKFLOW_EVENTS_WORKFLOW_LIST_EMPTY: 'error.workflow.events.workflow_list_empty',

    WORKFLOW_EVENTS_MODAL_WORKFLOW_LIST_TAB: 'workflow.events.modal.workflow_list_tab',
    WORKFLOW_EVENTS_MODAL_ACTIVE_LIST_TAB: 'workflow.events.modal.active_list_tab',
    WORKFLOW_EVENTS_MODAL_WORKFLOW_LIST_TAB_DESC: 'workflow.events.modal.workflow_list_tab_desc',
    WORKFLOW_EVENTS_MODAL_ACTIVE_LIST_TAB_DESC: 'workflow.events.modal.active_list_tab_desc',
    WORKFLOW_EVENTS_MODAL_CLOSE_BUTTON_LABEL: 'workflow.events.modal.close_button_label',

    WORKFLOW_EVENTS_TERMINATE_MODAL_TERMINATE_HEADER: 'workflow.events.terminate_modal.terminate_header',
    WORKFLOW_EVENTS_TERMINATE_MODAL_UNQUEUE_HEADER: 'workflow.events.terminate_modal.unqueue_header',
    WORKFLOW_EVENTS_TERMINATE_MODAL_BODY_CONTENT: 'workflow.events.terminate_modal.body_content',
    WORKFLOW_EVENTS_TERMINATE_MODAL_CONFIRM_BUTTON: 'workflow.events.terminate_modal.confirm_button',
    WORKFLOW_EVENTS_TERMINATE_MODAL_CLOSE_BUTTON: 'workflow.events.terminate_modal.close_button',

    PREMIUM_ACTIVITY_HEADER_DESCRIPTION: 'premium.activity.header.description',
    PREMIUM_ACTIVITY_ADD_SUCCESS: 'premium.activity.add.success',
    PREMIUM_ACTIVITY_REMOVE_SUCCESS: 'premium.activity.remove.success',
    PREMIUM_ACTIVITY_LIST_LABEL: 'premium.activity.list.label',
    PREMIUM_ACTIVITY_PRO_LIST_LABEL: 'premium.activity.pro_list.label', 
};
