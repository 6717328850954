import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { DirectoryService } from 'services';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';

const onInitialize = (initialValues, setName) => {
    return () => {
        setName(initialValues && initialValues.name && (() => {
            let displayName = initialValues.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
    };
};

const DirectoryDeleteModal = ({ isShown, onSuccess, onCancel, initialValues }) => {
    const [ name, setName ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    useEffect(onInitialize(initialValues, setName), 
        [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                await DirectoryService.deleteDirectory(initialValues.name, initialValues.currentDirectory);
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess(initialValues);

            } catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_WORKFLOW_ID_REQUIRED: 
                    case MessageCode.ERROR_WORKFLOW_NOT_EXIST: {
                        onCancel(MessageCode.ERROR_WORKFLOW_NOT_EXIST);
                        break;
                    }

                    default: {
                        console.error(data);

                        onCancel(MessageCode.ERROR_WORKFLOW_GENERAL_INTERNAL);
                        break;
                    }
                }

                onCancel();
            }  
        } 
    };

    return (
        <Modal show={ isShown } onHide={ onCancel } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.DIRECTORY_MODAL_DELETE_HEADER } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.DIRECTORY_MODAL_MESSAGE_DELETE_TEXT }
                        values={ { name: name } }>
                    <p className="wrap-long-text">You are about to delete <span className="brand-yellow"><strong>WORKFLOW_NAME.</strong></span></p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-directory-btn" }
                    onClick={ () => onCancel() }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-directory-btn" }
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_DELETE } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DirectoryDeleteModal;