import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';

import translations from 'translations';

i18n.use(languageDetector)
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: translations,
        keySeparator: false,
        react: {
            wait: true,
        },
    });
    
export default i18n;
