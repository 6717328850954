import React, { useState, useEffect } from 'react';

import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Collapse } from 'react-bootstrap';
import { DepartmentAction } from 'actions';
import { AccountService, LicenseService, DepartmentService } from 'services';
import {
    Breadcrumb,
    CompanyHeader,
    LicenseKeyGenerateNewModal,
    LicenseKeyDeleteModal,
    LicenseKeyGenerateFromFileModal,
    Table,
    Tooltip,
    AuthorityChecker,
} from 'components';
import MessageCode from 'constants/MessageCode';
import RoutePath from 'constants/RoutePath';

import { LicenseAction } from 'actions';

const getLicense = async (companyId, licenseId, dispatch, uiState, setUiState) => {
    try{
        const { data } = await LicenseService.getSpecificLicense(companyId, licenseId);
        dispatch(LicenseAction.successLicense(data));
    }
    catch (error) {
        console.error(error);
        dispatch(LicenseAction.failureLicense(error));

        setUiState({
            ...uiState,
            isLicenseExisting: false
        });

        toast.error(
            <Trans i18nKey={ MessageCode.ERROR_LICENSE_NOT_EXIST } />
        );
    }
};

const getLicenseKeyList = async (companyId, licenseId, dispatch) =>{
    try{
        const { data } = await AccountService.getAccountList(companyId, licenseId);
        dispatch(LicenseAction.successLicenseKeyList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureLicenseKeyList(error));
    }
};

const getDepartmentList = async (dispatch, tenantId) => {
    try {
        const { data } = await DepartmentService.getDepartmentList(tenantId);
        dispatch(DepartmentAction.successDepartmentList(data));
    }
    catch (error) {
        dispatch(DepartmentAction.failureDepartmentList(error));
    }
};

const getLicenseFileList = async (dispatch, companyId, licenseId, accountId) => {
    try {
        const { data } = await AccountService.getLicenseFileList(companyId, licenseId, accountId);
        dispatch(LicenseAction.successLicenseFileList([ ...data ]));
    } catch (error) {
        dispatch(LicenseAction.failureLicenseFileList(error));
    }
};

const onInitialize = (companyId, licenseId, dispatch, uiState, setUiState) => {
    return () => {
        getLicense(companyId, licenseId, dispatch, uiState, setUiState);
        getLicenseKeyList(companyId, licenseId, dispatch);
    };
};

const downloadLicenseFileOrDisplayChoicePopup = (dispatch, licenseFileList) => {
    if (licenseFileList && licenseFileList.length > 0) {
        // Download the first file
        let licenseFile = licenseFileList[0];
        AccountService.exportLicenseFile(licenseFile.filename);
        dispatch(LicenseAction.clearLicenseFileList());
    } else {
        dispatch(LicenseAction.clearLicenseFileList());
    }
};

const onChangeLicenseFileList = (dispatch, licenseFileList) => {
    return () => {
        downloadLicenseFileOrDisplayChoicePopup(dispatch, licenseFileList);
    };
};

const LicenseCompanyKeyScreen = () => {
    const dispatch = useDispatch();
    const { companyId, licenseId } = useParams();
    
    const [ uiState, setUiState ] = useState({
        showGenerateKey: false,
        showGenerateFromFile: false,
        showDeleteModal: false,
        showExportModal: false,
        isLicenseExisting: true,
        selectedLicenseKey: null,
        moreMacAddressVisibility: [],
    });

    const license = useSelector(state => state.license.license);
    const licenseKeyList = useSelector(state => state.license.licenseKeyList);
    const licenseFileList = useSelector(state => state.license.licenseFileList);
    const departmentList = useSelector(state => state.department.departmentList);

    useEffect(onInitialize(companyId, licenseId, dispatch, uiState, setUiState), []);
    useEffect(onChangeLicenseFileList(dispatch, licenseFileList), [ JSON.stringify(licenseFileList) ]);

    const licenseKeyTableColumnList = [{
        field: 'partialKey',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_KEY_HEADER } />,
        headerClasses: 'align-top',
        sortable: true,
    },{
        field: 'departmentName',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_KEY_DEPARTMENT_HEADER } />,
        headerClasses: 'align-top',
        sortable: true,
    },{
        field: 'fullName',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_KEY_NAME_HEADER } />,
        headerClases: 'align-top',
        sortable: true, 
    },{
        field: 'macAddress',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_KEY_MACADDRESS_HEADER } />,
        headerClasses: 'align-top',
        'formatter': (licenseKey) => { 
            if (licenseKey.macAddresses && licenseKey.macAddresses.length > 0) {
                return (
                    <>
                        {licenseKey.macAddresses.slice(0, 3).map((item) => <pre>{item}</pre>)}
                        {licenseKey.macAddresses.length > 3 && uiState.moreMacAddressVisibility.indexOf(licenseKey.id) === -1 ?
                            <Link href="#" onClick={() => uiState.moreMacAddressVisibility.push(licenseKey.id)}>
                                See More
                            </Link>
                            : ""
                        }
                        
                        <Collapse in={uiState.moreMacAddressVisibility.some(macadd => macadd === licenseKey.id)}>
                            <div>
                                {licenseKey.macAddresses.slice(3, licenseKey.macAddresses.length).map((item) => <pre>{item}</pre>)}
                                <Link href="#" onClick={() => uiState.moreMacAddressVisibility.splice(uiState.moreMacAddressVisibility.indexOf(licenseKey.id), 1)}>
                                    See Less
                                </Link>
                            </div>
                        </Collapse>
                    </>
                );
            } else {
                return (<pre>{licenseKey.macAddress}</pre>);
            }
        },
        sortable: true,
    },{
        field: 'emailAddress',
        name: <Trans i18nKey={ MessageCode.TABLE_LICENSE_KEY_EMAIL_HEADER } />,
        headerClasses: 'align-top',
        sortable: true,
    },{
        field: 'action',
        name: '',
        formatter: (licenseKey) => {
            return (
                <center>
                    <AuthorityChecker requiredAuths={["DELETE_LICENSE_KEY"]}>
                        <Tooltip placement="top-start"
                            value={ <Trans i18nKey={ MessageCode.TOOLTIP_KEY_DELETE_ICON } /> }>
                            <Link to="#"
                                id={ "delete-key-"+licenseKey.id }
                                onClick={ () => onRequestDeleteLicenseKey(licenseKey) }>
                                <i className="fa fa-trash"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    {
                        licenseKey.hasFile ?
                        <AuthorityChecker requiredAuths={["EXPORT_LICENSE_FILE"]}>
                            <Tooltip placement="top-start"
                                    value={ <Trans i18nKey={ MessageCode.TOOLTIP_KEY_EXPORT_ICON } /> }>
                                <Link to="#"
                                    id={ `export-key-${licenseKey.id}` }
                                    onClick={ () => onRequestLicenseFileList(licenseKey) }>
                                        <i className="fa fa-download"></i>
                                    </Link>
                            </Tooltip>
                        </AuthorityChecker> : null
                    }
                </center>
            );
        },
    }];

    const onRequestGenerateKey = async() => {
        await getDepartmentList(dispatch, license.company.tenantId)
        setUiState({
            ...uiState,
            showGenerateKey: true,
        });
    };

    const onRequestGenerateFromFile = async() => {
        await getDepartmentList(dispatch, license.company.tenantId)
        setUiState({
            ...uiState,
            showGenerateFromFile: true,
        });
    };

    const onRequestDeleteLicenseKey = async (licenseKey) => {
        try{
            setUiState({
                ...uiState,
                showDeleteModal: true,
                selectedLicenseKey: licenseKey,
            });
        }
        catch (error){
            console.error(error);
            await getLicenseKeyList(dispatch);

            setUiState({
                ...uiState,
                showDeleteModal: false,
                selectedLicenseKey: null,
            });
        }
    };

    const onRequestLicenseFileList = async (licenseKey) => {
        await getLicenseFileList(dispatch, 
                licenseKey.license.company.id, licenseKey.license.id, licenseKey.id);
    };

    const onCancelGenerateKey = () => {
        setUiState({
            ...uiState,
            showGenerateKey: false,
        });
    };

    const onCancelGenerateFromFile = () => {
        setUiState({
            ...uiState,
            showGenerateFromFile: false,
        });
    };

    const onCancelDeleteKey = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedLicenseKey: null,
        });
    };

    const onSuccessGenerateNewKey = async () => {
        await getLicenseKeyList(companyId, licenseId, dispatch);
        setUiState({
            ...uiState,
            showGenerateKey: false,
        });
    };

    const onSuccessGenerateFromFile = async () => {
        await getLicenseKeyList(companyId, licenseId, dispatch);
        setUiState({
            ...uiState,
            showGenerateFromFile: false,
        });
    };

    const onSuccessDeleteKey = async () => {
        await getLicenseKeyList(companyId, licenseId, dispatch);
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedLicenseKey: null,
        });
    };

    return (
        <section>
            {
                (uiState.isLicenseExisting) ? null : <Redirect to = {RoutePath.LICENSE_COMPANIES_URL + '/' + companyId} />
            }

            <AuthorityChecker requiredAuths={["GENERATE_LICENSE_KEY"]}>
                <Link to="#" className="float" id="keys-submenu">
                    <i className="fa fa-key my-float"></i>
                </Link>

                <ul className='float-submenu'>
                    <li>
                        <Link to="#"
                            onClick={onRequestGenerateKey}>
                            <Trans i18nKey={ MessageCode.LICENSE_GENERATE_KEY_LABEL } />
                        </Link>
                    </li>
                    <li>
                        <Link to="#"
                            onClick={onRequestGenerateFromFile}>
                            <Trans i18nKey={ MessageCode.LICENSE_GENERATE_FROM_FILE_LABEL } />
                        </Link>
                    </li>
                </ul>
            </AuthorityChecker>
            
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_COMPANIES_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_COMPANY_KEYS_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <CompanyHeader>
                <CompanyHeader.Company company = {license ? license.company : null}>
                </CompanyHeader.Company>
                <CompanyHeader.License license = {license}>
                </CompanyHeader.License>
            </CompanyHeader>

            <AuthorityChecker requiredAuths={["VIEW_LICENSE_KEY"]}>
                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={licenseKeyList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={licenseKeyTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TABLE_LICENSE_KEY_EMPTY_PLACEHOLDER} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>

            <LicenseKeyGenerateNewModal isShown={uiState.showGenerateKey}
                onClose={onCancelGenerateKey}
                onSuccess={onSuccessGenerateNewKey}
                license={license}
                departmentList={departmentList || []}>
            </LicenseKeyGenerateNewModal>

            <LicenseKeyGenerateFromFileModal isShown={uiState.showGenerateFromFile}
                onClose={onCancelGenerateFromFile}
                onSuccess={onSuccessGenerateFromFile}
                license={license}
                departmentList={departmentList || []}>
            </LicenseKeyGenerateFromFileModal>

            <LicenseKeyDeleteModal isShown={uiState.showDeleteModal}
                onClose={onCancelDeleteKey}
                onSuccess={onSuccessDeleteKey}
                licenseKey={uiState.selectedLicenseKey} >    
            </LicenseKeyDeleteModal>
        </section>
    );
};

export default LicenseCompanyKeyScreen;
