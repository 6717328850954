import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { toast } from 'react-toastify';
import { UserService } from 'services';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';

const onInitialize = (initialValues, setName) => {
    return () => {

        setName(initialValues && (() => {
            let displayName = initialValues.firstName + " " + initialValues.lastName;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
    };
};

const UserArchiveModal = ({ isShown, onSuccess, onCancel, initialValues }) => {
    const [ name, setName ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    useEffect(onInitialize(initialValues, setName), 
        [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            
            try {
                const { data } = await UserService.archiveUser(initialValues.id);

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess(data);

            } catch ({ data }) {
                setDisabled(false);
                toast.error(data.message);
                onCancel()
            }   
        }
    };

    return (
        <Modal show={ isShown } onHide={ onCancel } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.MODAL_USER_DELETE_HEADER } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.MODAL_USER_DELETE_TEXT }
                    values={{ name: name } }>
                    <p className="wrap-long-text">You are about to archive <span className="brand-yellow"><strong>USER_NAME.</strong></span></p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-user-btn" }
                    onClick={ onCancel }>
                    <Trans i18nKey={ MessageCode.MODAL_USER_CANCEL_BUTTON } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-user-btn" }
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.MODAL_USER_DELETE_BUTTON } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserArchiveModal;