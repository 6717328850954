import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';

import { DashboardScreen, LoginScreen } from 'containers';

import { AuthenticatedRoute } from 'components';
import RoutePath from 'constants/RoutePath';
import { useLoginStatus } from 'hooks';

const getLastViewedPageOrDefault = (pathname) => {
    return pathname.includes(RoutePath.DASHBOARD_URL) ? pathname : RoutePath.DASHBOARD_URL;
};

const MainScreen = ({ location }) => {
    const isLoggedIn = useLoginStatus();
    
    return (
        <div>
            <Switch>
                <AuthenticatedRoute path={ RoutePath.LOGIN_URL }
                    resolve={ () => !isLoggedIn }
                    resolveTo={ LoginScreen }
                    redirectTo={ getLastViewedPageOrDefault(location.pathname) } />

                <AuthenticatedRoute path={ RoutePath.DASHBOARD_URL } 
                    resolve={ () => isLoggedIn }
                    resolveTo={ DashboardScreen }
                    redirectTo={ RoutePath.LOGIN_URL } />

                <Redirect to={ RoutePath.LOGIN_URL } />
            </Switch>
        </div>
    );
};

export default withRouter(MainScreen);