import React from 'react';
import _ from 'underscore';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AgentCardBody, AgentWorkflowList, AgentQueuedWorkflowItem } from 'components';
import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode';

const noOfWorkflowToShow = 2;
const AgentCard = ({ agent = {}, onViewAll, onTerminate }) => {
    return (
        <Card className="text-white bg-dark mb-3 shadow">
            <Card.Body>
                <AgentCardBody agent={ agent }>
                    <AgentWorkflowList>
                        {agent.queuedWorkflows && 
                            _.sortBy(agent.queuedWorkflows, 'position')
                            .slice(0, noOfWorkflowToShow)
                            .map((workflow, index) =>
                                <AgentQueuedWorkflowItem
                                    key={workflow.id}
                                    workflow={workflow}
                                    sessionId={agent.sessionId}
                                    index={ index }
                                    onTerminate={ onTerminate } >
                                </AgentQueuedWorkflowItem>
                            )}
                    </AgentWorkflowList>
                </AgentCardBody>

                <div className="float-left">
                    <Link to='#' onClick={() => onViewAll(agent)}>
                        <small>
                            <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_VIEW_ALL_WORKFLOWS_LABEL} />
                        </small>
                    </Link>
                </div>
                { agent.queuedWorkflows && agent.queuedWorkflows.length > 1 &&
                    <div className="float-right">
                        <small>
                            <Trans values={{ count: agent.queuedWorkflows.length - 1 }}
                                i18nKey={MessageCode.WORKFLOW_EVENTS_QUEUED_WORKFLOW_COUNT} />
                        </small>
                    </div>
                }
            </Card.Body>
        </Card>
    );
};

export default AgentCard;