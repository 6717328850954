import React from 'react';

const AgentList = ({ children }) => {
    return (
        <div className="col-sm-6">
            {children}
        </div>
    );
};

export default AgentList;