import React, { useState } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'underscore';

import { RoleService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Toast, AuthorityChecker } from 'components';

const roleNameSelector = (event) => event ? event.target.value : null;
const roleNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_ROLE_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_ROLE_NAME_TOO_LONG;
    }

    return null;
};

const companySelector = (event) => event ? event.target.value : null;
const companyValidator = (value) => {
    if (value === 'default' || !value) {
        return MessageCode.ERROR_USER_COMPANY_REQUIRED;
    }
};

const RoleCreateModal = ( {isShown, onClose, onSuccess, authorityList, companyList, isAdmin } ) => {
    const [onChangeRoleName, roleName] = useValidation(roleNameSelector, roleNameValidator, '');
    const [onChangeCompany, company] = useValidation(companySelector, companyValidator);
    const [selectedAuths, setSelectedAuths] = useState([]);
    const [selectAll, setSelectAll] = useState();
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false);

    const {t} = useTranslation();

    const resetFields = () => {
        roleName.reset('');
        setSelectedAuths([]);
        company.reset('default');
    };

    const onAuthToggleChange = (event) => {
        const authKey = event.target.value;
        if (selectedAuths.includes(authKey)) {
            setSelectedAuths(selectedAuths.filter(auth => auth !== authKey));
        } else{
            setSelectedAuths(selectedAuths.concat(authKey));
        }
    };

    const onSelectAll = () => {
        setSelectAll(true);
        setSelectedAuths(_.map( authorityList, "key" ));
    }

    const onUnselectAll = () => {
        setSelectAll(false);
        setSelectedAuths([]);
    }

    const onCancel = () => {
        onClose();
    };

    const hasAuth = (role, authName) => {
        if (role){
            return selectedAuths.includes(authName);
        } else {
            return false
        }
    }

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);

            try{
                await RoleService.addRole(roleName.value, selectedAuths,  company.value );

                toast.success(
                    <Toast data={{ name: roleName.value }}
                        message={MessageCode.ROLE_MODAL_MESSAGE_CREATE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);

                resetFields();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch(data.key){
                    default:{
                        console.error(data);
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={onCancel} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.ROLE_MODAL_HEADER_CREATE } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.ROLE_MODAL_SUBTEXT_REQUIRED } />
                    </small>

                     <AuthorityChecker requiredAuths={["ADMIN_ACCESS_LEVEL"]}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    <Trans i18nKey={MessageCode.MODAL_USER_COMPANY_LABEL} />
                                </Form.Label>
                                <Form.Control as="select"
                                    className='custom-select'
                                    id={ "company-create-role-field" }
                                    value={company.value || 'default'}
                                    onChange={onChangeCompany}
                                    isInvalid={company.dirty && company.error}>
                                    <option value='default'>{t(MessageCode.MODAL_USER_FIELD_COMPANY_DEFAULT)}</option>
                                    {companyList.map(item => <option key={item.id} value={item.tenantId}>{item.name}</option>)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <Trans i18nKey={company.error} />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>  
                    </AuthorityChecker>
                    
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.ROLE_MODAL_LABEL_TITLE } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-create-role-field" }
                            placeholder={t(MessageCode.ROLE_MODAL_HINT_ROLE_NAME)}
                            value={roleName.value}
                            onChange={ onChangeRoleName }
                            isInvalid={ roleName.dirty && roleName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ roleName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <p className="float-left text-muted">
                            <Trans i18nKey={ MessageCode.ROLE_MODAL_SUBTEXT_AUTHORIZE } />
                        </p>
                        {
                            (selectAll || selectedAuths ? (selectedAuths.length === authorityList.length) : false) ? 
                            <Button variant="link" size="sm" className="float-right" onClick={onUnselectAll} id={ "unselect-all-auths-create" }>  
                                <Trans i18nKey={ MessageCode.ROLE_MODAL_LABEL_UNSELECT_ALL } />
                            </Button> : 
                            <Button variant="link" size="sm" className="float-right" onClick={onSelectAll} id={ "select-all-auths-create" }>  
                                <Trans i18nKey={ MessageCode.ROLE_MODAL_LABEL_SELECT_ALL } />
                            </Button>
                        }

                        <div className="clearfix"></div>

                        <ul className="list-group list-group-flush auths-list list-scroll">
                            { authorityList.map(authority =>
                                <li key={authority.key} className="list-group-item d-flex justify-content-between align-items-center bg-transparent"> {authority.title} 
                                    <input type="checkbox" id={authority.id} value={authority.key} checked={ hasAuth(selectedAuths, authority.key) } onChange={onAuthToggleChange}/>
                                </li>
                            )}
                        </ul>
                    </Form.Group>

                
                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group>  

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-create-role-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CLEAR } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-create-role-btn" }
                        disabled={ roleName.error || disabled }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_ADD } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RoleCreateModal;