import React from 'react';

const AgentWidgetList = ({ children }) => {
    return (
        <div className="col-sm-6">
            { children }
        </div>
    );
};

export default AgentWidgetList;