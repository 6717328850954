import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserService, DepartmentService, RoleService, CompanyService } from 'services';
import {
    Breadcrumb,
    Table,
    Toast,
    Tooltip,
    Filter,
    UserArchiveModal,
    UserCreateModal,
    UserUpdateModal,
    AuthorityChecker
} from 'components';
import MessageCode from 'constants/MessageCode';
import { UserAction, DepartmentAction, RoleAction, LicenseAction } from 'actions';

const getUserList = async (dispatch) => {
    try {
        const currentUser = await UserService.getCurrentUser();
        const { data } = await UserService.getUserList();
        dispatch(UserAction.successUserList(data));

    } catch (error) {
        dispatch(UserAction.failureUserList(error));
    }
};

const getCurrentUser = async(dispatch) => {
    try{
        const currentUser = await UserService.getCurrentUser();
        dispatch(UserAction.successProfile(currentUser));
    }catch(error){
        dispatch(UserAction.failureProfile(error));
    }
}

const getDepartmentList = async (dispatch, id) => {
    try {
        const { data } = await DepartmentService.getDepartmentList(id);
        dispatch(DepartmentAction.successDepartmentList(data));

    } catch (error) {
        dispatch(DepartmentAction.failureDepartmentList(error));
    }
};

const getUserGroupList = async (dispatch, id) => {
    try {
        const { data } = await RoleService.getRolesList(id);
        dispatch(RoleAction.successRoleList(data));

    } catch (error) {
        dispatch(RoleAction.failureRoleList(error));
    }
};

const getCompanyList = async (dispatch) => {
    try {
        const { data } = await CompanyService.getCompanyList();
        dispatch(LicenseAction.successCompanyList(data));
    }
    catch (error) {
        dispatch(LicenseAction.failureCompanyList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getUserList(dispatch);
        getCompanyList(dispatch);
    };
};

const ManagementUserScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);

    const isAdmin =  (useSelector(state => state.user.company) != null) ? false : true;
    const [uiState, setUiState] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        editingUser: null,
        isAdmin: false,
        filter: {
            name: ''
        },
    });

    const userList = useSelector(state => state.user.userList);
    const departmentList = useSelector(state => state.department.departmentList);
    const userGroupList = useSelector(state => state.role.roleList);
    const companyList = useSelector(state => state.license.companyList);
    const company = useSelector(state => state.user.company);
     const userAuths = useSelector(state => state.user.auths);

    const tableColumnList = [{
        'field': 'completeName',
        'name': t(MessageCode.TABLE_USER_FULL_NAME_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    },{
        'field': 'emailAddress',
        'name': t(MessageCode.TABLE_USER_EMAIL_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    },{
        'field': 'companyName',
        'name': t(MessageCode.TABLE_USER_COMPANY_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    },{
        'field': 'displayDepartment',
        'name': t(MessageCode.TABLE_USER_DEPARTMENT_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    }, {
        'field': 'displayGroups',
        'name': t(MessageCode.TABLE_USER_ROLE_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    }, {
        'field': 'actions',
        'name': '',
        'formatter': (user) => {
            return (
                <div className="text-center">
                    <AuthorityChecker requiredAuths={["EDIT_USER"]}>
                        <Tooltip placement="top-start"
                            value={<Trans i18nKey={MessageCode.TOOLTIP_USER_EDIT_ICON} />}>
                            <Link to="#" id={ "edit-user-"+user.id }
                                onClick={() => onRequestUpdateUser(user.id)}>
                                <i className="fa fa-edit"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    &nbsp;
                    <AuthorityChecker requiredAuths={["DELETE_USER"]}>
                        <Tooltip placement="top-start"
                            value={<Trans i18nKey={MessageCode.TOOLTIP_USER_DELETE_ICON} />}>
                            <Link to="#" id={ "delete-user-"+user.id }
                                onClick={() => onRequestDeleteUser(user.id)}>
                                <i className="fa fa-trash"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                </div>
            );
        },
    }];

    const userTableColumnList = _.filter(tableColumnList, (col) => {
        return((col.field == "companyName" && userAuths.includes('ADMIN_ACCESS_LEVEL')) || col.field != "companyName");
    });

    const filteredUserList = _.filter(userList, (user) => {
        return ((user && !user.archived) && (!uiState.filter.name
            || (user.completeName && user.completeName.toLowerCase().includes(uiState.filter.name.toLowerCase()))
            || (user.displayDepartment && user.displayDepartment.toLowerCase().includes(uiState.filter.name.toLowerCase()))
            || (user.displayGroups && user.displayGroups.toLowerCase().includes(uiState.filter.name.toLowerCase()))
            || (user.companyName && user.companyName.toLowerCase().includes(uiState.filter.name.toLowerCase()))
            || (user.emailAddress && user.emailAddress.toLowerCase().includes(uiState.filter.name.toLowerCase()))));
    });

    const onRequestFilterName = (name) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name,
            },
        });
    };

    const onRequestUpdateUser = async (id) => {
        try {
            const { data } = await UserService.getSpecificUser(id);
            await getDepartmentList(dispatch, data.tenantId);
            await getUserGroupList(dispatch, data.tenantId);
            
            setUiState({
                ...uiState,
                editingUser: data,
                showUpdateModal: true,
            });
        } catch (error) {
            console.error(error);

            await getUserList(dispatch);
            setUiState({
                ...uiState,
                editingUser: null,
                showUpdateModal: false,
            });
        }
    };

    const onSuccessUpdateUser = async (data, resetFields) => {
        await getUserList(dispatch);
        toast.success(
            <Toast data={{ name: data.firstName + " " + data.lastName }}
                message={MessageCode.MODAL_USER_UPDATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            editingUser: null,
            showUpdateModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCompanyChange = async (id) => {
        await getDepartmentList(dispatch, id);
        await getUserGroupList(dispatch, id);
    };

    const onCancelUpdateUser = () => {
        setUiState({
            ...uiState,
            editingUser: null,
            showUpdateModal: false,
        });
    };

    const onRequestCreateUser = async () => {
        if (company){
            await getDepartmentList(dispatch, company.tenantId);
            await getUserGroupList(dispatch, company.tenantId);
        }

        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onSuccessCreateUser = async (data, resetFields) => {
        await getUserList(dispatch);
        toast.success(
            <Toast data={{ name: data.firstName + " " + data.lastName }}
                message={MessageCode.MODAL_USER_CREATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showCreateModal: false,
        });

        _.isFunction(resetFields) && resetFields();
    };

    const onCancelCreateUser = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onRequestDeleteUser = async (id) => {
        try {
            const { data } = await UserService.getSpecificUser(id);

            setUiState({
                ...uiState,
                editingUser: data,
                showDeleteModal: true,
            });

        } catch (error) {
            console.error(error);

            await getUserList(dispatch);
            setUiState({
                ...uiState,
                editingUser: null,
                showDeleteModal: false,
            });
        }
    };

    const onSuccessDeleteUser = async (data) => {
        await getUserList(dispatch);
        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.MODAL_USER_DELETE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showDeleteModal: false,
        });
    };

    const onCancelDeleteUser = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
        });
    };

    return (
        <section>

            <AuthorityChecker requiredAuths={["CREATE_USER"]}>
                <Link to="#" className="float" onClick={onRequestCreateUser} id={ "create-user-btn" }>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={MessageCode.TOOLTIP_USER_CREATE_BUTTON} />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={MessageCode.BREADCRUMB_USER_LABEL} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={MessageCode.BREADCRUMB_USER_LIST_LABEL} />
                </Breadcrumb.Item>
            </Breadcrumb>
            
            <AuthorityChecker requiredAuths={["VIEW_USER"]}>
                <Filter>
                    <Filter.Text label={'Search'}
                        onChange={onRequestFilterName}>
                    </Filter.Text>
                </Filter>

                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={filteredUserList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={userTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TABLE_USER_EMPTY_PLACEHOLDER} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>

            <UserCreateModal isShown={uiState.showCreateModal}
                onSuccess={onSuccessCreateUser}
                onCancel={onCancelCreateUser}
                departmentList={departmentList || []}
                roleList={userGroupList || []}
                companyList={companyList || []}
                isAdmin={isAdmin}
                onCompanyChange={onCompanyChange}>
            </UserCreateModal>

            <UserUpdateModal isShown={uiState.showUpdateModal && !!uiState.editingUser}
                onSuccess={onSuccessUpdateUser}
                onCancel={onCancelUpdateUser}
                initialValues={uiState.editingUser}
                departmentList={departmentList || []}
                roleList={userGroupList || []}
                companyList={companyList || []}
                isAdmin={isAdmin}
                onCompanyChange={onCompanyChange}>
            </UserUpdateModal>

            <UserArchiveModal isShown={uiState.showDeleteModal && !!uiState.editingUser}
                onSuccess={onSuccessDeleteUser}
                onCancel={onCancelDeleteUser}
                initialValues={uiState.editingUser} />
        </section>
    );
};

export default ManagementUserScreen;