import React, { useEffect, useState } from 'react';

import _ from 'underscore';
import { Trans } from 'react-i18next';

import { Button, Modal, Form } from 'react-bootstrap';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { FormControl } from 'components';
import { UserService } from 'services';

const visibleWidgetsSelector = (event) => event ? event : [];
const visibleWidgetsValidator = (data) => {
    return null;
};

const initializeModal = (visibleWidgets, setCopyVisibleWidgets, currentVisibleWidgets) => {
    const widgetsList = [];
    currentVisibleWidgets.forEach((col, colIndex) => {
        col.forEach((widget, widgetIndex) => {
            widgetsList.push(widget);
            if (colIndex === currentVisibleWidgets.length - 1 && widgetIndex === col.length - 1 ){
                visibleWidgets.setValue(widgetsList);
                setCopyVisibleWidgets([...widgetsList]);
            }
        })
        if(col.length === 0 && colIndex === currentVisibleWidgets.length - 1) {
            visibleWidgets.setValue(widgetsList);
            setCopyVisibleWidgets([...widgetsList]);
        }
    })
}

const getVisibleWidgets = (visibleWidgets, setCopyVisibleWidgets, currentVisibleWidgets) => {
    return () => {  
        initializeModal(visibleWidgets, setCopyVisibleWidgets, currentVisibleWidgets)
    };
}
const MonitorWidgetModal = ({ isShown, onSuccess, onCancel, currentVisibleWidgets, widgetsList }) => {
    const [ onChangeVisibleWidgets , visibleWidgets ] = useValidation(visibleWidgetsSelector, visibleWidgetsValidator, []);
    const [ copyVisibleWidgets, setCopyVisibleWidgets] = useState([]);
    
    const onSubmit = (event) => {
        event.preventDefault();
        try{
            const toAdd = [];
            const toRemove = [];
            visibleWidgets.value.forEach((widget, index) => {
                if(!copyVisibleWidgets.includes(widget)) {
                    toAdd.push(widget);
                }
            });

            copyVisibleWidgets.forEach((widget, index) => {
                if(!visibleWidgets.value.includes(widget)) {
                    toRemove.push(widget);
                }

                if (index === copyVisibleWidgets.length - 1) {
                    toRemove.forEach((widgetKey) => {
                        currentVisibleWidgets.forEach((col) => {
                            if(col.includes(widgetKey)) {
                                col.splice(col.indexOf(widgetKey),1);
                            }
                        })
                    })
                }
            })
            
            setTimeout(async () => {
                const current_user = await UserService.getCurrentUser();
                await UserService.updateUserVisibleWidgets(current_user.data.id, toAdd, currentVisibleWidgets);
                onSuccess();
            }, 500);
            

        } catch(error){
            console.error(error);
        }
    }

    const onCancelSetVisibleWidgets = () => {
        initializeModal(visibleWidgets, setCopyVisibleWidgets, currentVisibleWidgets)
        onCancel();
    }

    const groupedWidgets = [[],[],[],[]];
    if (widgetsList) {
        let column = 0;
        widgetsList.forEach((widget) => {
            groupedWidgets[column].push(widget)
            column = (column + 1) % 4;
        });
    }

    useEffect(getVisibleWidgets(visibleWidgets, setCopyVisibleWidgets, currentVisibleWidgets), [currentVisibleWidgets]);

    return (
        <Modal show={ isShown } onHide={ onCancel } 
                centered size="xl" id="dashboardModal">
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header>
                    <h5 className="modal-title">
                        <i className="fa fa-tasks"></i> Dashboard Settings
                    </h5>
                </Modal.Header>
                <Modal.Body className="text-center">
                <p className="m-1">Here are the list of widgets on your dashboard. Click the widgets you would like to show or hide.</p>
                    <p className="m-1 text-light"> <span className="badge badge-warning">{visibleWidgets.value.length} Widgets</span> visible on your dashboard </p>
                    <hr />

                    <FormControl.WidgetSelection 
                        value={visibleWidgets.value}
                        toRemove={[]}
                        toAdd={[]}
                        onChange={onChangeVisibleWidgets}
                        groupedWidgets={groupedWidgets} >
                    </FormControl.WidgetSelection>

                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary"
                        onClick={ onCancelSetVisibleWidgets }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                    </Button>
                    <Button 
                        variant="primary"
                        type="submit">
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_UPDATE } />
                    </Button>
                    
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default MonitorWidgetModal;