import React, { useEffect } from 'react';

import { Button, Form, Modal, Col } from 'react-bootstrap';

import { useValidation } from 'hooks';
import { FormControl, Filter } from 'components';

const userSelector = (value) => value ? value: null;
const userValidator = () => {
    return null;
};

const departmentSelector = (event) => {
    return event ? event.target.value: null
};
const departmentValidator = () => {
    return null;
};

const roleSelector = (event) => {
    return event ? event.target.value: null
};
const roleValidator = () => {
    return null;
};

const dateRangeSelector = (value) => value;
const dateRangeValidator = (value) => {
    return value && value.length === 2 && value[0] && value[1];
};

const onInitialize = (department, role, user, dateRange, filters, userFilter) => {
    return () => {
        if (filters) {
            department.setValue(filters.department || 'default');
            role.setValue(filters.role || 'default');
            user.setValue( userFilter ? [userFilter] : []);
            dateRange.setValue( filters.dateRange || null);
        }
    };
};


const MonitorFilterModal = ({ isShown, onSuccess, onCancel, departmentList, userList, roleList, filters }) => {

    const [onChangeDepartment, department] = useValidation(departmentSelector, departmentValidator, null);
    const [onChangeRole, role] = useValidation(roleSelector, roleValidator, null);
    const [onChangeUser, user] = useValidation(userSelector, userValidator, null);
    const [onChangeDateRange, dateRange] = useValidation(dateRangeSelector, dateRangeValidator, null);

    let userFilter = null

    if (filters && userList) {
        userFilter = userList.find( ({ id }) => id === filters.user ); 
    }

    useEffect(onInitialize(department, role, user, dateRange, filters, userFilter), 
        [ JSON.stringify(filters) ]);

    const onSubmit = async (event) => {
        event.preventDefault();
        
        onSuccess({
            department: department.value, 
            role: role.value, 
            user: user.value && user.value.length ? user.value[0].id : null, 
            dateRange: dateRange.value});
    }

    return (
        <Modal show={ isShown } onHide={ onCancel } centered size="sm">
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">Filter by</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Filter.DateRange
                            value={dateRange.value}
                            onChange={ onChangeDateRange }
                            monitorFilter= {true}>
                        </Filter.DateRange>
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>Department</Form.Label>
                        <Form.Control as="select"
                            className='custom-select'
                            value={department.value || 'default'}
                            onChange={ onChangeDepartment }>
                            <option value='default'>All</option>
                            { departmentList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Role</Form.Label>
                        <Form.Control as="select"
                            className='custom-select'
                            value={role.value || 'default'}
                            onChange={ onChangeRole }>
                            <option value='default'>All</option>
                            { roleList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </Form.Control>
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <FormControl.Typeahead
                            value={user}
                            onChange={onChangeUser}
                            placeholder="Select user"
                            labelKey={option => `${option.completeName}`}
                            options={userList}
                            defaultSelected={userFilter ? [userFilter] : []}>
                        </FormControl.Typeahead>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" >Apply Filter</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default MonitorFilterModal;