import { useState, useEffect } from 'react';
import _ from 'underscore';

const customSetError = (setError) => {
    return (value) => {
        setError(value);
    };
};

const setErrorMessage = (valueValidator, initialValue, setError) => {
    const errorMessage = _.isFunction(valueValidator) ? valueValidator(initialValue) : null;
    
    Promise.resolve(errorMessage).then((actualErrorMessage) => {
        setError(actualErrorMessage);
    });
}

const useValidation = (valueSelector, valueValidator, initialValue) => {
    const [ dirty, setDirty ] = useState(false);
    const [ value, setValueWithoutValidation ] = useState(initialValue);

    const [ error, setError ] = useState();
    const [ isValidated, setValidated] = useState(false);

    useEffect(() => {
        setErrorMessage(valueValidator, value, setError);
        setValidated(true);
    }, [value, isValidated]);

   
    const setValue = (value) => {
        setValueWithoutValidation(value);
        setDirty(true);
    };

    const wrappedCallback = (...args) => {
        const currentValue = _.isFunction(valueSelector) ? valueSelector(...args) : null;
        setValue(currentValue);
    };

    const reset = (resetValue) => {
        setValue(resetValue);
        setDirty(false);
        setValidated(false);
    };

    const validate = () => {
        setValidated(false);
    };

    return [ wrappedCallback, {
        value, error, dirty, reset, setValue, setError, setDirty, validate
    }];
};

export default useValidation;