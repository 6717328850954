import React from 'react';

const AgentMiniWidget = ({ label, value, textClassName, iconClassName }) => {
    return (
        <div className="col-sm text-center">
            <div className="widgets p-2 shadow">
                <small className="m-0">{ label }</small>
                <h3 className={ textClassName }>
                    { value } <i className={ iconClassName }></i>
                </h3>
            </div>
        </div>
    );
};

export default AgentMiniWidget;