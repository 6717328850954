import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { TaskService } from 'services';
import { Toast } from 'components';

const onInitialize = (initialValues, setTaskName, setTaskStatusName, setTaskDeptName) => {
    return () => {
        setTaskName(initialValues && (() => {
            let displayName = initialValues.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
        setTaskStatusName(initialValues && (() => {
            let displayName = initialValues.status.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
        setTaskDeptName(initialValues && (() => {
            let displayName = initialValues.department.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
    };
};

const TaskDeleteModal = ({ isShown, onClose, onSuccess, initialValues }) => {
    const [ taskName, setTaskName ] = useState();
    const [ taskStatusName, setTaskStatusName ] = useState();
    const [ taskDeptName, setTaskDeptName ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    useEffect(onInitialize(initialValues, setTaskName, setTaskStatusName, setTaskDeptName), [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try{
                await TaskService.deleteTask(initialValues.id);

                toast.success(
                    <Toast data={{ name: taskName }}
                        message={MessageCode.TASK_MESSAGE_DELETE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_TASK_INACCESSIBLE :
                        toast.error(
                            <Toast data={{ name: taskName }}
                                message={data.key}>
                            </Toast>
                        );
                        break;

                    default: 
                        toast.error(
                            <Trans i18nKey={ MessageCode.ERROR_GENERAL_INTERNAL } />
                        );
                }
                onClose();
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onClose } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.TASK_MODAL_HEADER_DELETE } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.TASK_MESSAGE_DELETE_MODAL_TEXT }
                        values={ { name: taskName, statusName: taskStatusName, deptName: taskDeptName }}>
                    <p className="wrap-long-text">You are about to delete <span className="brand-yellow"><strong>TASK_NAME</strong></span> task of 
                        <span className="brand-yellow"><strong>STATUS_NAME</strong></span>
                        under <span className="brand-yellow"><strong>DEPT_NAME</strong></span>.</p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
         
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-task-btn" }
                    onClick={ () => onClose() }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-task-btn" }
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_DELETE } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskDeleteModal;