import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserService, ScheduleService } from 'services';

import { Modal, Button, Form } from 'react-bootstrap';

import {
    Breadcrumb,
    Table,
    Filter,
    ScheduleCreateModal,
    ScheduleArchiveModal,
    ScheduleUpdateModal,
    Toast,
    AuthorityChecker
} from 'components';

import MessageCode from 'constants/MessageCode';
import { UserAction, ScheduleAction } from 'actions';

const onInitialize = (dispatch) => {
    return () => {
        getScheduleList(dispatch);
        getUserList(dispatch);
    };
};

const getScheduleList = async (dispatch) => {
    try {
        const { data } = await ScheduleService.getAllSchedules();
        dispatch(ScheduleAction.successScheduleList(data));

    } catch (error) {
        // dispatch(ScheduleAction.failureScheduleList(error));
    }
};

const getUserList = async (dispatch) => {
    try {
        const { data } = await UserService.getUserList();
        dispatch(UserAction.successUserList(data));

    } catch (error) {
        dispatch(UserAction.failureUserList(error));
    }
};

const ManagementScheduleScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);
    const userList = useSelector(state => state.user.userList);
    const scheduleList = useSelector(state => state.schedule.scheduleList) || [];


    const [ uiState, setUiState ] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        editingWorkflow: null,
        filter: {
            name: '',
            from: moment().startOf('day'),
            to: moment().endOf('day'),
        },
    });

    const scheduleTableColumnList = [{
        'field': 'displayName',
        'name': 'User',
        'headerClasses': 'align-top',
        'sortable': true,
    }, {
        'field': 'date',
        'name': 'Date',
        'headerClasses': 'align-top',
        'formatter': (sched) => moment(new Date(sched.scheduleStart)).format('MMM DD YYYY'),
        'sortable': true,
    }, {
        'field': 'shift',
        'name': 'Shift',
        'headerClasses': 'align-top',
        'sortable': true,
    }];

    var selectedSchedules = [];
    const scheduleTableUpdatableList = JSON.parse(JSON.stringify(scheduleTableColumnList)).concat({
        'field': '',
        'name': 'Action',
        'headerClasses': 'align-top',
        'sortable':true,
        'formatter': (s) => {
            var isUpdatable = moment(s.scheduleStart).isAfter(moment().add(14,"days"));
            return (
                <>
                    <input type="checkbox" checked={isUpdatable ? selectedScheds.includes(s.schedId) : false} key={s.schedId} value={s.schedId} disabled={ !isUpdatable } onChange={onSchedToggleChange}/>
                </>
            )
        },
    })

    const scheduleTableDeletableList = JSON.parse(JSON.stringify(scheduleTableColumnList)).concat({
        'field': '',
        'name': 'Action',
        'headerClasses': 'align-top',
        'sortable':true,
        'formatter': (s) => {
            var isDeletable = moment(s.scheduleStart).isAfter(moment());
            return (
                <input type="checkbox" checked={isDeletable ? selectedScheds.includes(s.schedId) : false} key={s.schedId} value={s.schedId} disabled={ !isDeletable } onChange={onSchedToggleChange}/>
            )
        },
    })

    const onSelectSchedule = (schedId) =>{
        selectedSchedules.includes(schedId) ? selectedSchedules.splice(selectedSchedules.indexOf(schedId,1)) : selectedSchedules.push(schedId);
    }

    const scheduleDisplayList = scheduleList.map((sched)=>{
        var schedObj = {}
        const dateInitStart = new Date(sched.scheduleStart);
        const dateInitEnd = new Date(sched.scheduleEnd);

        const dateStr = dateInitStart.toLocaleDateString();
        const startShift = new Date(dateInitStart.getTime()).toLocaleTimeString();
        const endShift = new Date(dateInitEnd.getTime()).toLocaleTimeString();

        const shiftDisplay = startShift + ' - ' + endShift;
        const fullName = sched.firstName + ' ' + sched.lastName;

        schedObj = {
            schedId: sched.id,
            userId: sched.userId,
            displayName: fullName,
            date: moment(dateStr).format('MMM DD YYYY'),
            shift: shiftDisplay,
            scheduleStart: sched.scheduleStart,
        }

        return schedObj
    })

    const filteredScheduleList = _.filter(scheduleDisplayList, (schedule) => {
        const filterFrom = moment(uiState.filter.from).valueOf();
        const filterTo = moment(uiState.filter.to).valueOf();

        return (
                (!uiState.filter.name || schedule.displayName.toLowerCase().includes(uiState.filter.name.toLowerCase())) &&
                ((!uiState.filter.from || schedule.scheduleStart >= filterFrom) && (!uiState.filter.to || schedule.scheduleStart <= filterTo)));
    });

    const onRequestFilterName = (name) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name,
            },
        });
    };

    const onRequestFilterDateRange = ([ from, to ]) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                from,
                to,
            },
        });
    };

    const onRequestCreateSchedule = () => {
        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onSuccessCreateSchedules = async (data, resetFields) => {
        await getScheduleList(dispatch);
        toast.success(
            <Toast data={data}
                message={MessageCode.MODAL_SCHEDULE_CREATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onCancelCreateSchedules = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onRequestArchiveSchedule = (idList) =>{
        var schedToUpdate = [];
        idList.forEach(id=>{
            schedToUpdate.push(scheduleList.filter(sched=> sched.id == id)[0]);
        })
        setUiState({
            ...uiState,
            archivingSchedule: schedToUpdate,
            showDeleteModal: true,
        });
    }

    const onSuccessArchiveSchedules = async (data) => {
        await getScheduleList(dispatch);
        selectedScheds.length = 0;
        toast.success(
            <Toast data={{name:"schedules"}}
                message={MessageCode.MODAL_SCHEDULE_DELETE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showDeleteModal: false,
        });
    };

    const onCancelArchiveSchedules = (key) => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
        });
    };

    const onRequestUpdateSchedules = (idList) =>{
        var schedToUpdate = [];
        idList.forEach(id=>{
            schedToUpdate.push(scheduleList.filter(sched=> sched.id == id)[0]);
        })
        setUiState({
            ...uiState,
            updatingSchedule: schedToUpdate,
            showUpdateModal: true,
        });
    }

    const onSuccessUpdateSchedules = async (data) =>{
        await getScheduleList(dispatch);
        selectedScheds.length = 0;
        toast.success(
            <Toast data={{name:"schedules"}}
                message={MessageCode.MODAL_SCHEDULE_UPDATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showUpdateModal: false,
        });
    }

    const onCancelUpdateSchedules = () =>{
        setUiState({
            ...uiState,
            showUpdateModal: false,
        });
    }

    const onEmptySelect = () =>{
        toast.error(
            <Toast data={{name:"schedules"}}
                message={MessageCode.WARN_NO_SCHEDULE_SELECTED}>
            </Toast>
        );
    }

    const [dropdownOpen, setOpen] = useState(false);
    const [updateLinkOpen, showUpdateLink] = useState(false);
    const [deleteLinkOpen, showDeleteLink] = useState(false);
    const [isAllSchedsSelected, toggleSelectAllSchedules] = useState(false);
    const [selectedScheds, setSelectedScheds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const onSelectAll = () => {
        setSelectAll(true);
        setSelectedScheds(filteredScheduleList.map((sched)=>{ return sched.schedId}));
    }

    const onUnselectAll = () => {
        setSelectAll(false);
        setSelectedScheds([]);
    }

    const hasSched = (sched, schedId) => {
        // No implementation
    }

    const onSchedToggleChange = (event) => {
        const schedKey = parseInt(event.target.value);
        if(selectedScheds.includes(schedKey)){
            setSelectedScheds(selectedScheds.filter(sched=>sched !== schedKey));
        } else{
            setSelectedScheds(selectedScheds.concat(schedKey));
        }
    };

    const toggle = () => setOpen(!dropdownOpen);

    const updateChange = () =>{
        showDeleteLink(false);
        showUpdateLink(!updateLinkOpen);
    }

    const deleteChange = () =>{
        showUpdateLink(false);
        showDeleteLink(!deleteLinkOpen);
    }

    const cancelUpdateDelete = () =>{
        showUpdateLink(false);
        showDeleteLink(false);
        onUnselectAll();
    }

    const onSelectAllSchedules = () =>{
        //implement here
    }

    return (

        <section>

            <AuthorityChecker requiredAuths={["CREATE_SCHEDULE"]}>
                {/* Add button */}
                <Link to="#" className="float over-accordion" onClick={onRequestCreateSchedule} id={ "create-schedule-btn" }>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.SCHEDULE_MODAL_HEADER_CREATE } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>
            

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={MessageCode.BREADCRUMB_USER_LABEL} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {/* <Trans i18nKey={MessageCode.BREADCRUMB_SCHEDULE_LABEL} /> */}
                    Schedules
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_SCHEDULE"]}>
                {/* Search Filter */}
                <Filter>
                    <Filter.Text label={ 'User' }
                        onChange={ onRequestFilterName }
                    >
                    </Filter.Text>

                    <Filter.DateRange label={ 'Date' }
                        onChange={ onRequestFilterDateRange }
                        >
                    </Filter.DateRange>
                </Filter>

                <div className="container-fluid table-responsive">
                    <div>
                        <div style={{float: 'left', marginTop:'5px'}}>
                            {
                                updateLinkOpen || deleteLinkOpen ?
                                    (
                                        <Button variant="link" size="sm" className="float-right" onClick={!selectAll ? onSelectAll : onUnselectAll} id={ "select-toggle-btn" }>
                                            {!selectAll ? 'Select All' : 'Unselect All'}
                                        </Button>
                                    ) :''
                            }

                        </div>
                        <div style={{float: 'right', marginBottom: '5px'}}>
                            {
                                updateLinkOpen || deleteLinkOpen ?
                                    (
                                        <span>
                                            <Link to="#" 
                                                id={ "manage-multiple-sched-btn" }
                                                onClick={() => selectedScheds.length < 1 ? onEmptySelect() : updateLinkOpen ? onRequestUpdateSchedules(selectedScheds) : onRequestArchiveSchedule(selectedScheds)} 
                                                disabled={true}>
                                                {updateLinkOpen ? 'UPDATE' : 'DELETE'}
                                            </Link>
                                            <span style={{margin: '5px 5px 5px 5px'}}></span>
                                            <Link to="#" id={ "cancel-update-delete-btn" } onClick={cancelUpdateDelete}>CANCEL</Link>
                                        </span>
                                    ) : ''
                            }

                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} style={{marginLeft: '5px'}}>
                                <DropdownToggle color="primary" style={{borderRadius: '5px'}}>
                                    <i className="fa fa-cog"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <AuthorityChecker requiredAuths={["EDIT_SCHEDULE"]}>
                                        <DropdownItem onClick={updateChange} disabled={updateLinkOpen} id={ "edit-schedule-btn" }>
                                            <span className="fa fa-edit"></span> Update
                                        </DropdownItem>
                                        <AuthorityChecker requiredAuths={["DELETE_SCHEDULE"]}>
                                            <DropdownItem divider />
                                        </AuthorityChecker>
                                    </AuthorityChecker>
                                    

                                    <AuthorityChecker requiredAuths={["DELETE_SCHEDULE"]}>
                                        <DropdownItem onClick={deleteChange} id={ "delete-schedule-btn" } disabled={deleteLinkOpen}>
                                            <span className="fa fa-trash"></span> Delete
                                        </DropdownItem>
                                    </AuthorityChecker>
                                    
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                    <Table theadClass="thead-dark"
                        keyField={'schedId'}
                        data={filteredScheduleList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={!(updateLinkOpen || deleteLinkOpen) ? scheduleTableColumnList : updateLinkOpen ? scheduleTableUpdatableList : scheduleTableDeletableList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.SCHEDULE_TABLE_PLACEHOLDER_EMPTY} />
                        }>
                    </Table>
                </div>

            </AuthorityChecker>
            



            

            <ScheduleCreateModal isShown={uiState.showCreateModal}
                onSuccess={onSuccessCreateSchedules}
                onCancel={onCancelCreateSchedules}
                userList={userList || []}
            />

            <ScheduleArchiveModal isShown={uiState.showDeleteModal}
                onSuccess={onSuccessArchiveSchedules}
                onCancel={onCancelArchiveSchedules}
                initialValues={uiState.archivingSchedule}
            />

            <ScheduleUpdateModal isShown={uiState.showUpdateModal}
                onSuccess={onSuccessUpdateSchedules}
                onCancel={onCancelUpdateSchedules}
                schedIdList={uiState.updatingSchedule || []}
            />
        </section>
    );
};

export default ManagementScheduleScreen;
