import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getTaskList: async () => {
        const url = `${apiUrl}/api/task`;
        return await axios.get(url);
    },

    getSpecificTask: async (id) => {
        const url = `${apiUrl}/api/task/${id}`;
        return await axios.get(url);
    },

    addTask: async (taskName, taskStatus, taskDepartment) => {
        const url = `${apiUrl}/api/task`;

        const task = {
            name: taskName,
            statusId: taskStatus,
            departmentId: taskDepartment
        };

        return await axios.post(url, task);
    },

    updateTask: async (id, taskName, taskStatus, taskDepartment) => {
        const url = `${apiUrl}/api/task/${id}`;

        const task = {
            name: taskName,
            statusId: taskStatus,
            departmentId: taskDepartment
        };

        return await axios.put(url, task);
    },

    deleteTask: async (id) => {
        const url = `${apiUrl}/api/task/${id}`;
        return await axios.delete(url);
    }
};