import React from 'react';
import axios from 'axios';
import HeaderKey from 'constants/HeaderKey';
import StorageKey from 'constants/StorageKey';
import SockJsClient from 'react-stomp';

const apiUrl = process.env.REACT_APP_API_URL;
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
const appSecret = process.env.REACT_APP_APP_SECRET;
let stompClient = null;

export default {
    getActiveAgentListHttp: async () => {
        const url = `${apiUrl}/api/active-agents`;
        return await axios.get(url);
    },

    connectToWebSocketPartial: (onMessageReceived) => {
        const headers = {
            [HeaderKey.APP_KEY]: appSecret,
            [HeaderKey.AUTH_KEY]: localStorage.getItem(StorageKey.AUTH_KEY),
            'platform': 'command-center',
        };

        const activeAgentSubscriptionTopic = 
            ['/topic/active-agents', '/user/topic/active-agents'];

            return (
                <SockJsClient
                    url={websocketUrl}
                    headers={headers}
                    subscribeHeaders={headers}
                    topics={activeAgentSubscriptionTopic}
                    onMessage={onMessageReceived}
                    autoReconnect={false}
                    ref={(client) => stompClient = client} />
            );
    },

    connectToWebSocket: (onConnectionSuccess, onConnectionFailure, onMessageReceived, onDisconnect) => {
        const headers = {
            [HeaderKey.APP_KEY]: appSecret,
            [HeaderKey.AUTH_KEY]: localStorage.getItem(StorageKey.AUTH_KEY),
            'platform': 'command-center',
        };

        const activeAgentSubscriptionTopic = 
            ['/topic/active-agents', '/user/topic/active-agents'];
        
        return (
            <SockJsClient
                url={websocketUrl}
                headers={headers}
                subscribeHeaders={headers}
                topics={activeAgentSubscriptionTopic}
                onConnect={onConnectionSuccess}
                onMessage={onMessageReceived}
                onConnectFailure={onConnectionFailure}
                onDisconnect={onDisconnect}
                autoReconnect={false}
                ref={(client) => stompClient = client} />
        );
    },

    isWebSocketConnected: () => {
        return stompClient != null;
    },

    getActiveAgentListWebSocket: async () => {
        const headers = {
            [HeaderKey.APP_KEY]: appSecret,
            [HeaderKey.AUTH_KEY]: localStorage.getItem(StorageKey.AUTH_KEY),
            'platform': 'command-center',
        };

        if (stompClient) {
            return stompClient.sendMessage('/request/active-agents','', headers);
        }
    },

    sendExecuteWorkflowCommand: async (sessionId, workflow) => {
        const headers = {
            [HeaderKey.APP_KEY]: appSecret,
            [HeaderKey.AUTH_KEY]: localStorage.getItem(StorageKey.AUTH_KEY),
            'platform': 'command-center',
        };
        
        const executeCommand = {
            sessionId: sessionId,
            workflowId: workflow.workflowId,
        };

        if (stompClient) {
            return stompClient.sendMessage('/direct/agent-execute-command',
                JSON.stringify(executeCommand), headers);
        }
    },

    sendTerminateWorkflowCommand: async (sessionId, workflow) => {
        const headers = {
            [HeaderKey.APP_KEY]: appSecret,
            [HeaderKey.AUTH_KEY]: localStorage.getItem(StorageKey.AUTH_KEY),
            'platform': 'command-center',
        };
        
        const terminateCommand = {
            sessionId: sessionId,
            workflowId: workflow.workflowId,
            queueId: workflow.queueId,
        };

        if (stompClient) {
            return stompClient.sendMessage('/direct/agent-terminate-command',
                JSON.stringify(terminateCommand), headers);
        }
    },
};
