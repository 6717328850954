import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { 
    Accordion,
    Card,
    Button
} from 'react-bootstrap';

import { 
    Tooltip, 
    AuthorityChecker
} from 'components';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';

const RoleListDisplay = ({ roleList, authorityList, onDelete, onUpdate }) => {

    const deleteRole = async (roleId) => {
        onDelete(roleId);
    };

    const updateRole = async(roleId) => {
        onUpdate(roleId);
    };

    const hasAuth = (role, authName) => {
        return role.authorityNames.includes(authName);
    }

    let displayName = (name) =>  { return name.length > LengthLimit.TOAST_DISPLAY_NAME_LIMIT ?
        name.substring(0, LengthLimit.TOAST_DISPLAY_NAME_LIMIT) + "..." : name }

    return (        
        <Accordion>
            { roleList.map( role => 
                <Card bg="dark" key={role.id}>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={ role.id }>
                            {
                                role.name.length > LengthLimit.TABLE_COLUMN_CONTENT_LIMIT ?
                                    <Tooltip key={role.id} placement="auto" value={ role.name }>
                                        <nobr className="text-truncate wrap-long-text max-width-250px cursor-pointer"> { displayName(role.name) } </nobr>
                                    </Tooltip> : role.name
                            }
                        </Accordion.Toggle> 
                        
                        <AuthorityChecker requiredAuths={["DELETE_ROLE"]}>
                            {
                                !role.isProtected ? 
                                    <Button className="float-right" variant="link" onClick={ () => deleteRole(role.id) } id={ "delete-role-"+role.id }>
                                        <Tooltip placement="top-start"
                                        value={ <Trans i18nKey={ MessageCode.ROLE_TOOLTIP_DELETE } /> }>
                                            <Link to="#">
                                                <i className="fa fa-trash"></i>
                                            </Link>
                                        </Tooltip>
                                    </Button> : null
                            }
                        </AuthorityChecker>
                        
                        <AuthorityChecker requiredAuths={["EDIT_ROLE"]}>
                            <Button className="float-right" variant="link" onClick={ () => updateRole(role.id) } id={ "edit-role-"+role.id }>
                                <Tooltip placement="top-start"
                                value={ <Trans i18nKey={ MessageCode.ROLE_TOOLTIP_EDIT } /> }>
                                    <Link to="#">
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </Tooltip>
                            </Button>
                        </AuthorityChecker>
                       
                    </Card.Header>
                    
                    <Accordion.Collapse eventKey={ role.id }>
                        <Card.Body> 
                            <small className="text-muted">Here are the authorized access to this role</small>

                            <ul className="list-inline mb-4">
                                { authorityList.map( (auth, index) =>
                                 <Tooltip key={auth.key} placement="auto" value={ auth.title }>
                                    <li  className={(index === authorityList.length-1 ? 'mb-3 ' : '') + "float-left list-group-item col-sm-4 bg-transparent d-flex justify-content-between align-items-center"}>
                                    <nobr className="text-truncate"> {auth.title} </nobr>
                                        { hasAuth(role, auth.key) ?   <i className="fa fa-check"></i> : <i className="fa fa-times text-danger"></i> }
                                    </li>
                                </Tooltip>
                                )}
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )}
        </Accordion>
    );

};

export default RoleListDisplay;