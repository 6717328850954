import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { DepartmentService } from 'services';
import { Toast } from 'components';

const onInitialize = (initialValues, setDepartmentName) => {
    return () => {
        setDepartmentName(initialValues && (() => {
            let displayName = initialValues.name;
            displayName = displayName.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                displayName.substring(0, LengthLimit.MODAL_DISPLAY_NAME_LIMIT) + "..." : displayName;
            return displayName;
        }));
    };
};

const DepartmentDeleteModal = ({ isShown, onClose, onSuccess, initialValues }) => {
    const [ departmentName, setDepartmentName ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    useEffect(onInitialize(initialValues, setDepartmentName), [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try{
                await DepartmentService.deleteDepartment(initialValues.id, initialValues.tenantId);
                toast.success(
                    <Toast data={{ name: departmentName }}
                        message={MessageCode.DEPARTMENT_MODAL_MESSAGE_DELETE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                console.error(data);
                
                if (data.message === MessageCode.ERROR_DEPARTMENT_DELETE_HAS_CHILDREN){
                    toast.error(
                        <Toast data={{ name: departmentName }}
                            message={data.message}>
                        </Toast>
                    );
                } else if (data.message === MessageCode.ERROR_DEPARTMENT_DELETE_ASSOCIATED_WITH_STATUSES){
                    toast.error(
                        <Toast data={{ name: departmentName }}
                            message={data.message}>
                        </Toast>
                    );
                }
                else{
                    toast.error(
                        <Trans i18nKey={ MessageCode.ERROR_GENERAL_INTERNAL } />
                    );
                }
                
                onClose();
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onClose } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_HEADER_DELETE } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_MESSAGE_DELETE_TEXT }
                        values={ { name: departmentName }}>
                    <p className="wrap-long-text">You are about to delete <span className="brand-yellow"><strong>DEPARTMENT_NAME.</strong></span></p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
         
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-department-btn" }
                    onClick={ () => onClose() }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-department-btn" }
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_DELETE } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DepartmentDeleteModal;