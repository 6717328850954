import React from 'react';
import moment from 'moment';

import { Tooltip } from 'components'
import LengthLimit from 'constants/LengthLimit';

const CompanyDetails = ({company}) => {
    return (
        <div className='col-sm'>
            {(company && company.name.length > LengthLimit.COMPANY_DISPLAY_NAME_LIMIT) &&
                <Tooltip value={(company ? company.name : null)}>
                    <h2 className='m-0 wrap-long-text'>
                        { company.name.substring(0, LengthLimit.COMPANY_DISPLAY_NAME_LIMIT) + "..." }
                    </h2>
                </Tooltip>
            }

            {(company && company.name.length <= LengthLimit.COMPANY_DISPLAY_NAME_LIMIT) &&
                <h2 className='m-0 wrap-long-text'>
                    { company.name }
                </h2>
            }

            <p className="m-0">
                <i className="far fa-building"></i>
                { company ? ' '+ company.industry.name : ''}
            </p>

            <p className="m-0">
                <i className="far fa-envelope"></i>
                <a href=  { company && company.head ? ' mailto:' +  company.head.emailAddress : null}>
                    { company && company.head ? ' ' + company.head.emailAddress : '-'}
                </a>
            </p>
        </div>
    );
};

const LicenseDetails = ({license}) => {

    const LicenseType = () => {
        if (license.type.key === 'pro'){
            return (<span className='badge badge-warning'>{license.type.name.toUpperCase()}</span>);
        }
        else if (license.type.key === 'enterprise'){
            return (<span className='badge badge-info'>{license.type.name.toUpperCase()}</span>);
        }
    };

    return (
        <div className='col-sm'>
            <h2 className='m-0'>
                { license ? (<LicenseType />) : null }
                
            </h2>

            <p className="m-0">
                <i className="fa fa-rocket"></i>
                { license ? ' Issued at ' + moment(license.issueDate).format('YYYY MMM DD hh:mm A') : null }
            </p>

            <p className="m-0">
                <i className="fa fa-flag-checkered"></i>
                { license ? ' Expires at ' + moment(license.expirationDate).format('YYYY MMM DD hh:mm A') : null }
            </p>
        </div>
    );
};

const CompanyHeader = ({children}) => {
    return (
        <div className="card bg-dark mb-3">
            <div className="card text-white bg-dark shadow">
                <div className="card-body row">
                    {children}
                </div>
            </div>
        </div>
    );
};

CompanyHeader.Company = CompanyDetails;
CompanyHeader.License = LicenseDetails;

export default CompanyHeader;