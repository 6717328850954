import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getAllSchedules: async () => {
        const url = `${apiUrl}/api/schedule`;
        return await axios.get(url);
    },

    addSchedule: async (scheduleList) => {
        const url = `${apiUrl}/api/schedule`;
        return await axios.post(url, scheduleList);
    },

    archiveSchedules: async (scheduleIds) =>{
        const url = `${apiUrl}/api/schedule`;
        return await axios.patch(url,scheduleIds);
    },

    updateSchedule: async (scheduleList) => {
        const url = `${apiUrl}/api/schedule`;
        return await axios.put(url,scheduleList)
    }

};
