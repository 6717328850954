import ActionType from 'constants/ActionType';

export default {
    setSessionInfo: (sessionInfo) => {
        return { 
            type: ActionType.ACTIVE_AGENT_SET_SESSION_INFO,
            payload: { sessionInfo }
        };
    },

    addConnection: (connection) => {
        return {
            type: ActionType.ACTIVE_AGENT_ADD_CONNECTION,
            payload: { connection },
        };
    },

    removeConnection: (connection) => {
        return {
            type: ActionType.ACTIVE_AGENT_REMOVE_CONNECTION,
            payload: { connection },
        };
    },

    clearConnections: () => {
        return {
            type: ActionType.ACTIVE_AGENT_CLEAR_CONNECTIONS,
        };
    },

    addWorkflows: (sessionId, workflows = []) => {
        return {
            type: ActionType.ACTIVE_AGENT_ADD_WORKFLOWS,
            payload: {
                sessionId, workflows,
            },
        };
    },

    updateWorkflows: (sessionId, workflows = []) => {
        return {
            type: ActionType.ACTIVE_AGENT_UPDATE_WORKFLOWS,
            payload: {
                sessionId, workflows,
            },
        };
    },

    removeWorkflows: (sessionId, workflows = []) => {
        return {
            type: ActionType.ACTIVE_AGENT_REMOVE_WORKFLOWS,
            payload: {
                sessionId, workflows,
            },
        };
    },

    addQueuedWorkflows: (sessionId, queuedWorkflows = []) => {
        return {
            type: ActionType.ACTIVE_AGENT_ADD_QUEUED_WORKFLOWS,
            payload: {
                sessionId, queuedWorkflows,
            },
        };
    },

    updateQueuedWorkflows: (sessionId, queuedWorkflows = []) => {
        return {
            type: ActionType.ACTIVE_AGENT_UPDATE_QUEUED_WORKFLOWS,
            payload: {
                sessionId, queuedWorkflows,
            },
        };
    },

    removeQueuedWorkflows: (sessionId, queuedWorkflows = []) => {
        return {
            type: ActionType.ACTIVE_AGENT_REMOVE_QUEUED_WORKFLOWS,
            payload: {
                sessionId, queuedWorkflows,
            },
        };
    },
};