import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    login: (username, password) => {
        const encodedUsername = encodeURIComponent(username);
        const url = `${apiUrl}/api/login?username=${encodedUsername}&password=${password}`;
        return axios.post(url);
    },

    logout: () => {
        const url = `${apiUrl}/api/logout`;
        return axios.get(url);
    },

    isLoggedIn: () => {
        const url = `${apiUrl}/api/test`;
        return axios.get(url);
    },

    forgotPassword: (username) => {
        const encodedUsername = encodeURIComponent(username);
        const url = `${apiUrl}/api/user/reset-password?u=${encodedUsername}`;
        return axios.get(url);
    },

    resetPassword: (newPassword, resetPasswordKey) => {
        const url = `${apiUrl}/api/user/reset-password?newPassword=${newPassword}&resetPasswordKey=${resetPasswordKey}`;
        return axios.post(url);  
    },
};