import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode';
import { ActiveAgentService } from 'services';
import { toast } from 'react-toastify';

const onInitialize = (uiState, setUiState, workflow) => {
    return () => {
        if (workflow) {
            setUiState({
                ...uiState,
                workflowStatus: workflow.status,
                workflowName: workflow.workflowName,
            });
        }
    };
};

const AgentTerminateWorkflowConfirmationModal = ({ isShown, onClose, agentSessionId, workflow }) => {
    const [uiState, setUiState] = useState({
        workflowStatus: '',
        workflowName: '',
    });

    useEffect(onInitialize(uiState, setUiState, workflow), [JSON.stringify(workflow)]);

    const onTerminate = () => {
        if (ActiveAgentService.isWebSocketConnected()) {
            ActiveAgentService.sendTerminateWorkflowCommand(agentSessionId, workflow);
        } else {
            toast.error(
                <Trans i18nKey={MessageCode.ERROR_WEBSOCKET_CONNECTION_FAILURE} />
            );
        }
        onClose();
    };

    return (
        <Modal show={isShown} onHide={onClose} centered>
            <Modal.Header closeButton>
                {uiState.workflowStatus === 'EXECUTING' ?
                    <h5 className="modal-title">
                        <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_TERMINATE_HEADER} />
                    </h5>
                    :
                    <h5 className="modal-title">
                        <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_UNQUEUE_HEADER} />
                    </h5>
                }
            </Modal.Header >
            <Modal.Body>
                <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_BODY_CONTENT}
                    values={{ name: uiState.workflowName }}>
                    <p className="wrap-long-text">You are about to remove <span className="brand-yellow"><strong>WORKFLOW_NAME</strong></span> from the queue.</p>
                    <p className="text-light">Are you sure you want to proceed?</p>
                </Trans>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"
                    type="button"
                    onClick={() => onTerminate()}>
                    <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_CONFIRM_BUTTON} />
                </Button>
                <Button variant="dark"
                    type="button"
                    onClick={() => onClose()}>
                    <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_TERMINATE_MODAL_CLOSE_BUTTON} />
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default AgentTerminateWorkflowConfirmationModal;