import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { ScheduleService } from 'services';
import MessageCode from 'constants/MessageCode';

import moment from 'moment';

const onInitialize = (initialValues, setName) => {
    return () => {

        setName(initialValues && (() => {
            return "SCHEDULES";
        }));
    };
};

const ScheduleArchiveModal = ({ isShown, onSuccess, onCancel, initialValues }) => {
    const [ name, setName ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    const isEmpty = false;
    useEffect(onInitialize(initialValues, setName),
        [ JSON.stringify(initialValues) ]);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            var schedIds = []
            try {
                initialValues.forEach(sched=>{
                    schedIds.push(sched.id)
                })

                await ScheduleService.archiveSchedules(schedIds);

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess({name: "Schedules"});
            } catch ({ data }) {
                setDisabled(false);
                onCancel(MessageCode.ERROR_GENERAL_INTERNAL);
            }
        }
    };

    const getSelectedUsers = () =>{
        var userList = []
        try{
            initialValues.forEach(sched=>{
                var fullName = sched.firstName + ' ' +sched.lastName;
                if(!userList.includes(fullName)){
                    userList.push(fullName)
                }
            })
        } catch(e){
            //
        }
        
        return userList    
    }

    const getCoveredDates = () =>{
        var coverageDate = {};
        try{
            var max = initialValues.reduce((prev, current) => prev = prev > current.scheduleStart ? prev : current.scheduleStart, 0);
            var min = initialValues.reduce((prev, current) => prev = prev <= current.scheduleStart ? prev : current.scheduleStart);
            coverageDate['max'] = moment(new Date(max)).format('MMM DD YYYY');
            coverageDate['min'] = moment(new Date(min)).format('MMM DD YYYY');

        } catch(e){
            //
        }
         if(moment(coverageDate['min']).isValid()){
             return coverageDate['min'] + " - " +coverageDate['max']
         } 
         return coverageDate['max']
    }

    return (
        <Modal show={ isShown } onHide={ onCancel } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <Trans i18nKey={ MessageCode.MODAL_SCHEDULE_DELETE_HEADER } />
                </h5>
            </Modal.Header>
            <Modal.Body>
                {
                    isEmpty ?
                        (<p className="text-light">You have not selected any schedules.</p>) :
                        (<span><p className="wrap-long-text">You are about to delete the schedules of: </p></span>)
                }
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>
                            {
                                getSelectedUsers().map(listitem => (
                                    <Form.Row>
                                        <Form.Label><li>{listitem}</li></Form.Label>
                                    </Form.Row>
                                ))
                            }
                        </Form.Label>
                    </Form.Group>  
                </Form.Row>

                <span>
                    <p className="wrap-long-text">Covered Dates: <span className="brand-yellow"><strong>{getCoveredDates()}</strong></span></p>
                </span>

                <p className="text-light">Are you sure you want to proceed?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-schedule-btn" }
                    onClick={ onCancel }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                </Button>
                <Button variant="primary"
                    type="button"
                    id={ "confirm-delete-schedule-btn" }
                    hidden={isEmpty}
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                   <Trans i18nKey={ MessageCode.GENERAL_BUTTON_DELETE } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScheduleArchiveModal;
