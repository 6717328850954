import React from 'react';
import { ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode';

const AgentWorkflowItem = ({ sessionId, workflow = {}, onExecute }) => {

    return (
        <ListGroup.Item as="li" className="pl-0 pr-0 pt-1 pb-0 bg-transparent">
            <div className="row">
                <div className="col">
                    <p className="m-0 text-white">{workflow.name || "Workflow name" }</p>
                    <small className="text-muted">
                        <Trans values={{timestamp: workflow.lastExecution ?
                            moment(workflow.lastExecution).format('YYYY/MM/DD hh:mm A') : '-'}}
                            i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_LAST_EXECUTION_LABEL} />
                    </small>
                </div>
                <div className="col text-right">
                    <p className="mb-0">
                        <Link to="#" onClick={() => onExecute && onExecute(sessionId, workflow) }>
                            <small>
                                <i className="far fa-play-circle"></i> 
                                <Trans i18nKey={MessageCode.WORKFLOW_EVENTS_WORKFLOW_EXECUTE_LABEL} />
                            </small>
                        </Link>
                    </p>
                </div>
            </div>
        </ListGroup.Item>
    );
};

export default AgentWorkflowItem;