import React, { useState, useEffect }  from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import { 
    Breadcrumb,
    Filter,
    Table,
    Tooltip,
    AuthorityChecker,
    TaskCreateModal,
    TaskDeleteModal,
    TaskUpdateModal
} from 'components';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { StatusService, TaskService, DepartmentService } from 'services';
import { StatusAction, TaskAction, DepartmentAction } from 'actions';

const getDepartmentList = async (dispatch) => {
    try {
        const { data } = await DepartmentService.getAccessibleDepartmentList();
        dispatch(DepartmentAction.successDepartmentList(data));
    }
    catch (error) {
        dispatch(DepartmentAction.failureDepartmentList(error));
    }
};

const getStatusList = async (dispatch) => {
    try {
        const { data } = await StatusService.getStatusList();
        dispatch(StatusAction.successStatusList(data));
    }
    catch (error) {
        dispatch(StatusAction.failureStatusList(error));
    }
};


const getTaskList = async (dispatch) => {
    try {
        const { data } = await TaskService.getTaskList();
        dispatch(TaskAction.successTaskList(data));
    }
    catch (error) {
        dispatch(TaskAction.failureTaskList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getStatusList(dispatch);
        getTaskList(dispatch);
        getDepartmentList(dispatch);
    };
};

const ManagementTaskScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);
    const statusList = useSelector(state => state.status.statusList);
    const taskList = useSelector(state => state.task.taskList);
    const departmentList = useSelector(state => state.department.departmentList);

    const [uiState, setUiState] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        selectedTask: null,
        filter: {
            name: ''
        },
    });

    const filteredTaskList = _.filter(taskList, (task) => {
        return (!uiState.filter.name
            || task.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || task.department.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || task.status.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            );
    });

    const taskTableColumnList = [
        {
            'field': 'name',
            'name': t(MessageCode.GENERAL_LABEL_NAME),
            'headerClasses': 'align-top',
            'sortable': true,
        },
        {
            'field': 'status.name',
            'name': t(MessageCode.GENERAL_LABEL_STATUS),
            'headerClasses': 'align-top',
            'formatter': (task) => {
                return (
                    <>
                        <div>
                        { 
                            task.status.name.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                                <Tooltip placement="top" value={task.status.name} key={task.id}>
                                    <span className="wrap-long-text max-width-250px cursor-pointer" key={task.id}>
                                        { task.status.name.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..." }
                                    </span>
                                </Tooltip> : task.status.name
                        }

                        </div>
                    </>
                )
            },
            'sortable': true,
        },
        {
            'field': 'department.name',
            'name': t(MessageCode.GENERAL_LABEL_DEPARTMENT),
            'headerClasses': 'align-top',
            'formatter': (task) => {
                return (
                    <>
                        <div>
                        { 
                            task.department.name.length > LengthLimit.MODAL_DISPLAY_NAME_LIMIT ?
                                <Tooltip placement="top" value={task.department.name} key={task.id}>
                                    <span className="wrap-long-text max-width-250px cursor-pointer" key={task.id}>
                                        { task.department.name.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..." }
                                    </span>
                                </Tooltip> : task.department.name
                        }
                        </div>
                    </>
                )
            },
            'sortable': true,
        },
        {
            'field': 'actions',
            'name': '',
            'formatter': (task) => {
                return (
                    <div className="text-center">
                        <AuthorityChecker requiredAuths={["EDIT_TASK"]}>
                            <Tooltip placement="top-start"
                                value={<Trans i18nKey={MessageCode.TASK_TOOLTIP_EDIT} />}>
                                <Link to="#" onClick={() => onRequestUpdateTask(task.id)} id={ "edit-task-"+task.id }>
                                    <i className="fa fa-edit"></i>
                                </Link>
                            </Tooltip>
                        </AuthorityChecker>
                        &nbsp;
                        <AuthorityChecker requiredAuths={["DELETE_TASK"]}>
                            <Tooltip placement="top-start"
                                value={<Trans i18nKey={MessageCode.TASK_TOOLTIP_DELETE} />}>
                                <Link to="#" onClick={() => onRequestDeleteTask(task.id)} id={ "delete-task-"+task.id }>
                                    <i className="fa fa-trash"></i>
                                </Link>
                            </Tooltip>
                        </AuthorityChecker>
                    </div>
                );
            },
        }
    ];

    const onRequestFilterTasks = async (name) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name,
            },
        });
    };

    const onRequestCreateTask = async () => {
       await getStatusList(dispatch);

       setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onRequestDeleteTask = async (id) => {
        try {
            const { data } = await TaskService.getSpecificTask(id);

            setUiState({
                ...uiState,
                selectedTask: data,
                showDeleteModal: true,
            });

        } catch (error) {
            console.error(error);

            await getStatusList(dispatch);
            setUiState({
                ...uiState,
                selectedTask: null,
                showDeleteModal: false,
            });
        }
    };

    const onRequestUpdateTask = async (id) => {
        try {
            const { data } = await TaskService.getSpecificTask(id);

            setUiState({
                ...uiState,
                selectedTask: data,
                showUpdateModal: true,
            });

        } catch (error) {
            console.error(error);

            await getTaskList(dispatch);
            setUiState({
                ...uiState,
                selectedTask: null,
                showUpdateModal: false,
            });
        }
    };

    const onCancelCreateTask = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onSuccessCreateTask = async () => {
        await getTaskList(dispatch);

        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onCancelDeleteTask = () => {
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedTask: null,
        });
    };

    const onSuccessDeleteTask = async () => {
        await getTaskList(dispatch);
        
        setUiState({
            ...uiState,
            showDeleteModal: false,
            selectedTask: null,
        });
    };

    const onCancelUpdateTask = () => {
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedTask: null,
        });
    };

    const onSuccessUpdateTask = async () => {
        await getTaskList(dispatch);
        
        setUiState({
            ...uiState,
            showUpdateModal: false,
            selectedTask: null,
        });
    };

    return (
        <section>
            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={ MessageCode.BREADCRUMB_USER_MANAGEMENT_LABEL } />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                <Trans i18nKey={ MessageCode.TASKS_BREADCRUMB_LABEL } />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["CREATE_TASK"]}>
                {/* Add button */}
                <Link to="#" className="float over-accordion" onClick={onRequestCreateTask} id={ "create-task-btn" }>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={ MessageCode.TASK_MODAL_HEADER_CREATE } />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            <AuthorityChecker requiredAuths={["VIEW_TASK"]}>
                {/* Search Filter */}
                <Filter>
                    <Filter.Text label='Search'
                        onChange={onRequestFilterTasks}>
                    </Filter.Text>
                </Filter>

                {/* List of Task */}
                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={filteredTaskList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={taskTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TASK_DISPLAY_EMPTY} />
                        }>
                    </Table>
                </div>
                
            </AuthorityChecker>

            {/* Create Task Modal */}
            <TaskCreateModal isShown={uiState.showCreateModal} 
                onClose={onCancelCreateTask} 
                onSuccess={onSuccessCreateTask}
                departmentList={departmentList}
                statusList={statusList}>
            </TaskCreateModal>

            {/* Delete Task Modal */}
            <TaskDeleteModal isShown={uiState.showDeleteModal && !!uiState.selectedTask } 
                onClose={onCancelDeleteTask} 
                onSuccess={onSuccessDeleteTask}
                initialValues={uiState.selectedTask}>
            </TaskDeleteModal>

            {/* Update Task Modal */}
            <TaskUpdateModal isShown={uiState.showUpdateModal && !!uiState.selectedTask} 
                onClose={onCancelUpdateTask} 
                onSuccess={onSuccessUpdateTask}
                initialValues={uiState.selectedTask}
                departmentList={departmentList}
                statusList={statusList}>
            </TaskUpdateModal>
        </section>
    );
};

export default ManagementTaskScreen;