import React from 'react';

const DashboardHeader = ({ fullName, onClick }) => {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="col p-0">
            <button type="button" 
                id="sidebarCollapse" 
                className="btn btn-light"
                onClick={ onClick }>
                <i className="fas fa-align-left"></i>
            </button>
            </div>
            <div className="col text-right">
                <span>{ fullName }</span> 
            </div>
        </nav>
    );
};

export default DashboardHeader;