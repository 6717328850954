import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { Route, Redirect, Switch } from 'react-router-dom';

import { UserService, LoginService } from 'services';
import { ActiveAgentAction } from 'actions';
import { SocketContext } from 'middlewares';

import RoutePath from 'constants/RoutePath';
import StorageKey from 'constants/StorageKey';
import MessageCode from 'constants/MessageCode'
import HeaderKey from 'constants/HeaderKey';
import { UserAction } from 'actions';
import SockJsClient from 'react-stomp';

import { 
    DashboardHeader, 
    DashboardSidebar,
    DashboardSidebarItem, 
    AuthorityChecker
} from 'components';

import {
    LicenseScreen,
    ManagementScreen,
    ReportScreen,
    WorkflowScreen,
    ForbiddenPage,
} from 'containers';

const dashboardAuths = [
    "ACCESS_AGENT_REPORT_TAB", 
    "ACCESS_MONITOR_REPORT_TAB",
];

const workflowManagementAuths = [
    "ACCESS_WORKFLOW_TAB", 
    "ACCESS_WORKFLOW_ERROR_LOGS_TAB",
];

const licenseManagementAuths = [
    "ACCESS_COMPANY_TAB", 
    "ACCESS_ACCOUNT_TAB", 
    "ACCESS_ACTIVITY_TAB",
    "MANAGE_LICENSING"
];

const userManagementAuths = [
    "ACCESS_USER_TAB", 
    "ACCESS_DEPARTMENT_TAB", 
    "ACCESS_ROLE_TAB",
    "ACCESS_STATUS_TAB", 
    "ACCESS_TASK_TAB", 
    "ACCESS_SCHEDULE_TAB",
    "ACCESS_TASK_TARGET_TAB", 
    "ACCESS_TRANSFER_TAB", 
    "ACCESS_DATA_ENTRY_TAB"
];

const onFullNameChange = (dispatch) => {
    return () => {
        dispatch(UserAction.requestProfile());

        UserService.getCurrentUser().then(({ data }) => {
            if (!data.tenantId) {
                localStorage.setItem(StorageKey.TENANT_ID, '');
            } else {
                localStorage.setItem(StorageKey.TENANT_ID, data.tenantId);
            }

            dispatch(UserAction.successProfile(data));
        }).catch((error) => {
            dispatch(UserAction.failureProfile(error));
        });
    };
};

const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
const appSecret = process.env.REACT_APP_APP_SECRET;

let stompClient;


const DashboardScreen = () => {
    const dispatch = useDispatch();
    const fullName = useSelector(state => state.user.fullName);
    const isLoading = useSelector(state => state.user.isLoading || state.user.auths.length === 0);
    const user = useSelector(state => state.user);
    const activeAgents = useSelector(state => state.activeAgent.connections);

    const headers = {
        [HeaderKey.APP_KEY]: appSecret,
        [HeaderKey.AUTH_KEY]: user.token,
        platform: 'command-center',
    };

    useEffect(onFullNameChange(dispatch), []);

    const logout = () => {
        dispatch(UserAction.requestLogout());

        LoginService.logout().then(() => {
            localStorage.setItem(StorageKey.AUTH_KEY, '');
            dispatch(UserAction.successLogout());
        }).catch((error) => {
            localStorage.setItem(StorageKey.AUTH_KEY, '');
            dispatch(UserAction.failureLogout(error));
        });
    };

    // Websocket handlers
    const onConnectionSuccess = (e) => {
        stompClient.sendMessage('/all/identity', '', headers);
    };

    const onMessageReceived = (message) => {
        if ('CONNECT' === message.type) {
            if (!activeAgents.find((activeAgent) => {
                return activeAgent.sessionId === message.sessionId
            })) {
                dispatch(
                    ActiveAgentAction.addConnection({
                        firstName: message.firstName,
                        lastName: message.lastName,
                        sessionId: message.sessionId,
                        workflows: message.workflows || [],
                        queuedWorkflows: message.queuedWorkflows || [],
                }));

                sendSessionInfoToAgents();
            }
        } else if ('DISCONNECT' === message.type) {
            dispatch(
                ActiveAgentAction.removeConnection({
                    sessionId: message.sessionId,
            }));
        } else if ('IDENTITY' === message.type) {
            sessionInfo = {
                tenantId: message.tenantId,
                sessionId: message.sessionId,
                firstName: message.firstName,
                lastName: message.lastName,
            };

            sendSessionInfoToAgents();
        } else if ('WORKFLOW' === message.type) {
            if (message.added) {
                dispatch(
                    ActiveAgentAction.addWorkflows(message.sessionId, message.added)
                );
            }

            if (message.updated) {
                dispatch(
                    ActiveAgentAction.updateWorkflows(message.sessionId, message.updated)
                );
            }

            if (message.removed) {
                dispatch(
                    ActiveAgentAction.removeWorkflows(message.sessionId, message.removed)
                );
            }
        } else if ('QUEUE' === message.type) {
            if (message.added) {
                dispatch(
                    ActiveAgentAction.addQueuedWorkflows(message.sessionId, message.added)
                );
            }

            if (message.updated) {
                dispatch(
                    ActiveAgentAction.updateQueuedWorkflows(message.sessionId, message.updated)
                );
            }

            if (message.removed) {
                dispatch(
                    ActiveAgentAction.removeQueuedWorkflows(message.sessionId, message.removed)
                );
            }
        }
    };

    const onConnectionFailure = (v1) => {
        console.error(v1);
    };

    const onDisconnect = () => {
        dispatch(
            ActiveAgentAction.clearConnections()
        );
    };

    const sendSessionInfoToAgents = () => {
        
        if (stompClient) {
            const serialized = JSON.stringify({
                ...sessionInfo,
                type: 'CONNECT',
                platform: 'command-center',
            });

            stompClient.sendMessage('/all/connect', serialized, headers);
        }
    };

    const sendExecuteRequest = (sessionId, workflow) => {
        if (stompClient) {
            const serialized = JSON.stringify(workflow);
            stompClient.sendMessage(`/all/${sessionId}/execute`, serialized, headers);
        }
    };

    const sendTerminateRequest = (sessionId, workflow) => {
        if (stompClient) {
            const serialized = JSON.stringify(workflow);
            stompClient.sendMessage(`/all/${sessionId}/terminate`, serialized, headers);
        }
    };

    // UI state
    const [ isSidebarToggled, toggleSidebar ] = useState(false);
    const collapseSidebar = () => {
        toggleSidebar(!isSidebarToggled);
    };

    const getAuthenticatedRedirect = () => {
        if (user.auths.some(auth => dashboardAuths.includes(auth))){
            return RoutePath.REPORTS_URL;
        } else if (user.auths.some(auth => workflowManagementAuths.includes(auth))){
            return RoutePath.WORKFLOW_URL;
        } else if (user.auths.some(auth => licenseManagementAuths.includes(auth))){
            return RoutePath.LICENSE_URL;
        } else if (user.auths.some(auth => userManagementAuths.includes(auth))){
            return RoutePath.MANAGEMENT_URL;
        } else {
            return RoutePath.FORBIDDEN_PAGE_URL;
        }
    };

    // Websocket
    let sessionInfo = {
        tenantId: localStorage.getItem(StorageKey.TENANT_ID),
    };

    const subscriptions = [
        '/user/all/identity',
        '/all/disconnect',
    ];

    if (sessionInfo.tenantId === '') {
        subscriptions.push('/center/connect');
        subscriptions.push('/center/workflows');
        subscriptions.push('/center/queue');
    } else {
        subscriptions.push(`/center/${sessionInfo.tenantId}/connect`);
        subscriptions.push(`/center/${sessionInfo.tenantId}/workflows`);
        subscriptions.push(`/center/${sessionInfo.tenantId}/queue`);
    }

    return (
        <div className="wrapper">
            <SocketContext.Provider value={
                {
                    sendExecuteRequest,
                    sendTerminateRequest,
                    
                }
            }>
                <AuthorityChecker requiredAuths={["VIEW_WORKFLOW_EVENTS"]}>
                    <SockJsClient
                        url={websocketUrl}
                        headers={headers}
                        subscribeHeaders={headers}
                        topics={subscriptions}
                        onConnect={onConnectionSuccess}
                        onMessage={onMessageReceived}
                        onConnectFailure={onConnectionFailure}
                        onDisconnect={onDisconnect}
                        autoReconnect={true}
                        ref={(client) => stompClient = client} />
                </AuthorityChecker>

                <DashboardSidebar isToggled={ isSidebarToggled }>
                    <ul className="list-unstyled components">
                        <AuthorityChecker requiredAuths={dashboardAuths}>
                            <DashboardSidebarItem
                                to={ RoutePath.REPORTS_URL }
                                isCollapsible="true"
                                collapseSelector="#dashboardSubMenu"
                                activeClassName="active"
                                iconClassName="fas fa-chart-area"
                                label={ <Trans i18nKey={MessageCode.SIDEBAR_DASHBOARD_LABEL} /> }>

                                <ul className="collapse list-unstyled" id="dashboardSubMenu">
                                    <AuthorityChecker requiredAuths={["ACCESS_AGENT_REPORT_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.REPORTS_AGENT_URL}
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_REPORTS_AGENT_LABEL} /> } /> 
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_MONITOR_REPORT_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.REPORTS_MONITOR_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_REPORTS_MONITOR_LABEL} /> } />
                                    </AuthorityChecker>
                                </ul>
                            </DashboardSidebarItem>
                        </AuthorityChecker>
                        
                        <AuthorityChecker requiredAuths={workflowManagementAuths}>
                            <DashboardSidebarItem
                                to={ RoutePath.WORKFLOW_URL }
                                isCollapsible="true"
                                collapseSelector="#workflowSubMenu"
                                activeClassName="active"
                                iconClassName="fas fa-window-restore"
                                label={ 
                                    <Trans i18nKey={MessageCode.SIDEBAR_WORKFLOW_MANAGEMENT_LABEL}>
                                        <span className="pl-4">Workflow<br />Management</span>
                                    </Trans> 
                                }>

                                <ul className="collapse list-unstyled" id="workflowSubMenu">
                                    <AuthorityChecker requiredAuths={["ACCESS_WORKFLOW_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.WORKFLOW_LIST_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_WORKFLOW_LIST_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_WORKFLOW_EVENTS_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.WORKFLOW_EVENTS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_WORKFLOW_EVENTS_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_WORKFLOW_ERROR_LOGS_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.WORKFLOW_ERRORS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_WORKFLOW_ERRORS_LABEL} /> } />
                                    </AuthorityChecker>

                                    <AuthorityChecker requiredAuths={["ACCESS_WORKFLOW_ERROR_LOGS_TAB"]}>
                                        <DashboardSidebarItem
                                            to={ RoutePath.WORKFLOW_LOGS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_WORKFLOW_LOGS_LABEL} /> } />
                                    </AuthorityChecker>
                                </ul>
                            </DashboardSidebarItem>
                        </AuthorityChecker>
                        
                        <AuthorityChecker requiredAuths={licenseManagementAuths}>
                            <DashboardSidebarItem
                                to={ RoutePath.LICENSE_URL }
                                isCollapsible="true"
                                collapseSelector="#licenseSubMenu"
                                iconClassName="fas fa-certificate"
                                activeClassName="active"
                                label={ 
                                    <Trans i18nKey={MessageCode.SIDEBAR_LICENSE_MANAGEMENT_LABEL}>
                                        <span className="pl-4">License<br />Management</span>
                                    </Trans> 
                                }>

                                <ul className="collapse list-unstyled" id="licenseSubMenu">
                                
                                    <AuthorityChecker requiredAuths={["ACCESS_COMPANY_TAB"]}>
                                        <DashboardSidebarItem 
                                                to={ RoutePath.LICENSE_COMPANIES_URL   }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_LICENSE_COMPANY_LABEL} /> } />
                                    </AuthorityChecker>
                                    { (user.company)?
                                        <AuthorityChecker requiredAuths={["MANAGE_LICENSING"]}>
                                            <DashboardSidebarItem 
                                                to={ (user.company)? RoutePath.LICENSE_COMPANIES_URL  + "/" + user.company.id :""  }
                                                activeClassName="active"
                                                label={ <Trans i18nKey={MessageCode.SIDEBAR_LICENSE_LICENSES_LABEL} /> } />
                                        </AuthorityChecker>
                                        :null
                                    }
                                        
                                    <AuthorityChecker requiredAuths={["ACCESS_ACCOUNT_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.LICENSE_ACCOUNTS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_LICENSE_ACCOUNT_LABEL} /> } />
                                    </AuthorityChecker>

                                    <AuthorityChecker requiredAuths={["ACCESS_ACTIVITY_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.LICENSE_ACTIVITIES_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_LICENSE_ACTIVITY_LABEL} /> } />
                                    </AuthorityChecker>

                                    <AuthorityChecker requiredAuths={["ACCESS_ACTIVITY_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.LICENSE_PREMIUM_ACTIVITIES_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_LICENSE_PREMIUM_ACTIVITY_LABEL} /> } />
                                    </AuthorityChecker>
                                </ul>
                            </DashboardSidebarItem>
                        </AuthorityChecker>
                        
                        <AuthorityChecker requiredAuths={userManagementAuths}>
                            <DashboardSidebarItem
                                to={ RoutePath.MANAGEMENT_URL }
                                isCollapsible="true"
                                collapseSelector="#usersSubMenu"
                                iconClassName="fas fa-user"
                                activeClassName="active"
                                label={ 
                                    <Trans i18nKey={MessageCode.SIDEBAR_USER_MANAGEMENT_LABEL}>
                                        <span className="pl-4">User<br />Management</span>
                                    </Trans> 
                                }>

                                <ul className="collapse list-unstyled" id="usersSubMenu">
                                    <AuthorityChecker requiredAuths={["ACCESS_USER_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_USERS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_USER_LABEL} /> } />
                                    </AuthorityChecker>

                                    <AuthorityChecker requiredAuths={["ACCESS_DEPARTMENT_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_DEPARTMENTS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_DEPARTMENT_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_ROLE_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_ROLES_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_ROLE_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_STATUS_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_STATUSES_URL }
                                            activeClassName="active"
                                            tabAuth=""
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_STATUS_LABEL} /> } />
                                    </AuthorityChecker>

                                    <AuthorityChecker requiredAuths={["ACCESS_TASK_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_TASKS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_TASK_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_SCHEDULE_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_SCHEDULES_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_SCHEDULE_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_TASK_TARGET_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_TARGETS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_TARGET_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_TRANSFER_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_TRANSFERS_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_TRANSFER_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                    <AuthorityChecker requiredAuths={["ACCESS_DATA_ENTRY_TAB"]}>
                                        <DashboardSidebarItem 
                                            to={ RoutePath.MANAGEMENT_DATA_ENTRY_URL }
                                            activeClassName="active"
                                            label={ <Trans i18nKey={MessageCode.SIDEBAR_MANAGEMENT_DATA_ENTRY_LABEL} /> } />
                                    </AuthorityChecker>
                                    
                                </ul>
                            </DashboardSidebarItem>
                        </AuthorityChecker>

                        <DashboardSidebarItem to="#logout"
                            activeClassName="active"
                            iconClassName="fas fa-power-off"
                            label={ <Trans i18nKey={MessageCode.SIDEBAR_LOGOUT_LABEL} /> }
                            onClick={ logout } />
                    </ul>
                </DashboardSidebar>
        
                <div id="content" className="container-fluid">
                    <DashboardHeader fullName={ fullName }
                        onClick={ collapseSidebar } />

                    {
                        !isLoading ? 
                        <Switch>
                            <Route path={ RoutePath.REPORTS_URL }
                                component={ ReportScreen } />
                            <Route path={ RoutePath.WORKFLOW_URL } 
                                component={ WorkflowScreen } />
                            <Route path={ RoutePath.LICENSE_URL }
                                component={ LicenseScreen } />
                            <Route path={ RoutePath.MANAGEMENT_URL } 
                                component={ ManagementScreen } />
                            <Route path={ RoutePath.FORBIDDEN_PAGE_URL } 
                                component={ ForbiddenPage } />
                            <Redirect to={ getAuthenticatedRedirect() } />
                            
                        </Switch>
                        : null
                    }
                </div>
            </SocketContext.Provider>
        </div>
    );
};

export default DashboardScreen;