import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getWorkflowMetrics: async (from, to) => {
        const url = `${apiUrl}/api/report/agent/metrics?from=${from}&to=${to}`;
        return await axios.get(url);
    },

    getWorkflowExceptionList: async (from, to) => {
        const url = `${apiUrl}/api/report/agent/exceptions?from=${from ? from : ''}&to=${to ? to : ''}`;
        return await axios.get(url);
    },

    getWorkflowUseCountList: async (from, to) => {
        const url = `${apiUrl}/api/report/agent/use-counts?from=${from}&to=${to}`;
        return await axios.get(url);
    },

    getAgentTotalExecutionTimeList: async (from, to) => {
        const url = `${apiUrl}/api/report/agent/total-hours?from=${from}&to=${to}`;
        return await axios.get(url);
    },

    getWorkflowExecutionLogs: async (from, to) => {
        const url =`${apiUrl}/api/report/agent/logs?from=${from ? from : ''}&to=${to ? to : ''}`;
        return await axios.get(url);
    },
};