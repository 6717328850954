import ActionType from 'constants/ActionType';

export default {
    requestActivityList: () => {
        return {
            type: ActionType.ACTIVITY_LIST_REQUEST,
        };
    },

    successActivityList: (activityList) => {
        return {
            type: ActionType.ACTIVITY_LIST_SUCCESS,
            payload: { activityList },
        };
    },

    failureActivityList: (error) => {
        return {
            type: ActionType.ACTIVITY_LIST_FAILURE,
            payload: { error },
        };
    },
};