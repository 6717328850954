import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

import { CompanyService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Toast } from 'components';

const companyNameSelector = (event) => event ? event.target.value : null;
const companyNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_COMPANY_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_COMPANY_NAME_TOO_LONG;
    }

    const invalidCharacters = '\\ / : * ? " | < >'.split(" ");

    if (invalidCharacters.some(v => value.includes(v))) {
        return MessageCode.ERROR_COMPANY_NAME_INVALID; 
    }

    if (value.trim().endsWith('.')) {
        return MessageCode.ERROR_COMPANY_NAME_INVALID;
    }

    return null;
};

const industrySelector = (event) => event ? event.target.value: null;
const industryValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_COMPANY_INDUSTRY_REQUIRED;
    }

    return null;
};

const companyHeadSelector = (event) => event ? event.target.value: null;
const companyHeadValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_COMPANY_COMPANY_HEAD_REQUIRED;
    }

    return null;
};

const onInitialize = (companyName, industry, companyHead, initialValues) => {
    return () => {
        
        if (initialValues){
            companyName.setValue(initialValues.name || '');
            industry.setValue(initialValues.industry.key || 'default');
            if(initialValues.head)companyHead.setValue(initialValues.head.id || 'default')
                else companyHead.setValue('default')
        }else{
            companyName.setValue('');
            industry.setValue('default');
            companyHead.setValue('default');
        }
    };
 };

const CompanyUpdateModal = ( {isShown, onClose, onSuccess, industryList, initialValues, userList} ) => {
    const [onChangeCompanyName, companyName] = useValidation(companyNameSelector, companyNameValidator);
    const [onChangeIndustry, industry] = useValidation(industrySelector, industryValidator);
    const [onChangeCompanyHead, companyHead] = useValidation(companyHeadSelector, companyHeadValidator);

    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false); 
    const {t} = useTranslation();

    useEffect(onInitialize(companyName, industry, companyHead, initialValues), [ JSON.stringify(initialValues) ]);

    const resetFields = () => {
        companyName.reset('');
        companyHead.reset('default')
        industry.reset('default');
    };

    const onCancel = () => {
        resetFields();
        onClose();
    };

    const onSubmit = async (event) => {

        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);
        
            try{
                await CompanyService.updateCompany(initialValues.id, companyName.value, industry.value, companyHead.value);

                toast.success(
                    <Toast data={{ name: companyName.value }}
                        message={MessageCode.COMPANY_MODAL_UPDATE_SUCCESS}>
                    </Toast>
                );

                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                resetFields();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch(data.key){
                    case MessageCode.ERROR_COMPANY_NAME_REQUIRED:{
                        companyName.setError(data.key);
                        break;
                    }
    
                    case MessageCode.ERROR_COMPANY_NAME_INVALID:
                    case MessageCode.ERROR_COMPANY_NAME_DUPLICATE:{
                        companyName.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_COMPANY_INDUSTRY_REQUIRED:
                    case MessageCode.ERROR_COMPANY_INDUSTRY_INVALID:{
                        industry.setError(data.key);
                        break;
                    }
                    
                    default:{
                        console.error(data);
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
      
        <Modal show={ isShown } onHide={onCancel} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_UPDATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_SUBTEXT } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_NAME_LABEL } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-update-company-field" }
                            value={companyName.value}
                            onChange={ onChangeCompanyName }
                            isInvalid={ companyName.dirty && companyName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ companyName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_INDUSTRY_LABEL } />
                        </Form.Label>
                        <Form.Control as="select"
                            id={ "industry-update-company-field" }
                            className='custom-select'
                            value={industry.value}
                            onChange={ onChangeIndustry }
                            isInvalid={ industry.dirty && industry.error}>
                            <option value='default' disabled>{t(MessageCode.COMPANY_MODAL_CREATE_INDUSTRY_DEFAULT)}</option>
                            { industryList.map(item => <option key={item.key} value={item.key}>{item.name}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ industry.error } />
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.COMPANY_MODAL_UPDATE_COMPANY_HEAD } />
                        </Form.Label>
                        <Form.Control as="select"
                            className='custom-select'
                            value={companyHead.value}
                            onChange={ onChangeCompanyHead }
                            isInvalid={ companyHead.dirty && companyHead.error}>
                            <option value='default' disabled>{t(MessageCode.COMPANY_MODAL_UPDATE_COMPANY_HEAD_DEFAULT)}</option>
                            { (userList != null) ? userList.map(item => <option key={item.id} value={item.id}>{item.completeName}</option>) : null}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ companyHead.error } />
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group>  

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-update-company-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_CREATE_CLEAR_BUTTON } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-update-company-btn" }
                        disabled={ disabled || companyName.error || industry.error || companyHead.error}>
                        <Trans i18nKey={ MessageCode.COMPANY_MODAL_UPDATE_BUTTON } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CompanyUpdateModal;