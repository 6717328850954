import React from 'react';

const AgentWidget = ({ title, children, className }) => {
    return (
        <div className={`card text-white bg-dark mb-3 shadow ${className}`}>
            <div className="card-header">{ title }</div>
            <div className="card-body">
                { children }
            </div>
        </div>
    );
};

export default AgentWidget;