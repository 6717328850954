import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import _ from 'underscore';
import { SocketContext } from 'middlewares';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReportAction, WorkflowAction } from 'actions';
import MessageCode from 'constants/MessageCode';
import RoutePath from 'constants/RoutePath';
import { AgentService, ActiveAgentService } from 'services';
import {
    AgentMiniWidget,
    AgentMiniWidgetList,
    AgentWidget,
    AgentWidgetList,
    Table,
    WidgetHighcharts,
    AgentCardBody,
    AgentWorkflowList,
    AgentQueuedWorkflowItem,
    AgentTerminateWorkflowConfirmationModal,
    AuthorityChecker,
} from 'components';
import { useState } from 'react';

const useCountTableHeaders = [{
    'field': 'workflowName',
    'name': <Trans i18nKey={MessageCode.TABLE_AGENT_WORKFLOW_HEADER} />,
    'headerClasses': 'align-top',
}, {
    'field': 'useCount',
    'name': <Trans i18nKey={MessageCode.TABLE_AGENT_SUCCESSFUL_EXECUTION_HEADER} />,
    'headerClasses': 'align-top',
    'formatter': (record) => <span className="text-success">{record.useCount}</span>,
}];

const exceptionTableHeaders = [{
    'field': 'executionStart',
    'name': <Trans i18nKey={MessageCode.TABLE_AGENT_DATE_EXECUTED_HEADER} />,
    'headerClasses': 'align-top',
    'formatter': (record) => {
        const executionDate = moment(record.executionStart);
        return (
            <>
                <p className="m-0">{executionDate.format('MM/DD/YY')}</p>
                <small className="text-muted">{executionDate.format('hh:mm A')}</small>
            </>
        );
    },
}, {
    'field': 'workflowName',
    'name': <Trans i18nKey={MessageCode.TABLE_AGENT_WORKFLOW_NAME_HEADER} />,
    'headerClasses': 'align-top',
}, {
    'field': 'errorActivity',
    'name': <Trans i18nKey={MessageCode.TABLE_AGENT_MESSAGE_HEADER} />,
    'headerClasses': 'align-top',
    'formatter': (record) => {
        return (
            <>
                <p className="text-danger m-0">{record.errorMessage}</p>
                <small className="text-muted">{`Activity: ${record.errorActivity}`}</small>
            </>
        )
    },
}]

const createExecutionTimeOptions = (executionTimeList) => {
    const executionTimeOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: [],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Total Minutes (mins)'
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} mins</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        series: [],
    };

    if (executionTimeList.length === 0) {
        return executionTimeOptions;
    }

    const xAxisCategoryList = [];
    const seriesDataList = [];

    executionTimeList.forEach((executionTimeEntry) => {
        const currentDate = moment(executionTimeEntry.date);
        const executionTime = executionTimeEntry.executionTime || 0;

        xAxisCategoryList.push(currentDate.format('MMM DD'));
        seriesDataList.push(executionTime / (60 * 1000));
    });

    const [lastExecutionTimeEntry] = executionTimeList.slice(-1);
    const lastExecutionDate = moment(lastExecutionTimeEntry.date);

    executionTimeOptions.subtitle.text = `Month of ${lastExecutionDate.format('MMMM')}`;
    executionTimeOptions.xAxis.categories = xAxisCategoryList;
    executionTimeOptions.series.push({
        name: 'Executed Minutes',
        data: seriesDataList,
    });

    return executionTimeOptions;
};

const getWorkflowMetrics = async (dispatch) => {
    try {
        const monthStart = moment().startOf('month').valueOf();
        const monthEnd = moment().endOf('month').valueOf();

        const { data } = await AgentService.getWorkflowMetrics(monthStart, monthEnd);
        dispatch(ReportAction.successAgentMetrics(data));
    } catch (error) {
        dispatch(ReportAction.failureAgentMetrics(error));
    }
};

const getExceptionList = async (dispatch) => {
    try {
        const monthStart = moment().startOf('month').valueOf();
        const monthEnd = moment().endOf('month').valueOf();

        const { data } = await AgentService.getWorkflowExceptionList(monthStart, monthEnd);

        if (_.isArray(data)) {
            const mappedData = _.map(data.slice(0, 5), (dataItem, index) => {
                return {
                    ...dataItem,
                    index: index,
                };
            });

            dispatch(ReportAction.successExceptionList(mappedData));
        } else {
            dispatch(ReportAction.failureExceptionList(data));
        }

    } catch (error) {
        dispatch(ReportAction.failureExceptionList(error));
    }
};

const getUseCountList = async (dispatch) => {
    try {
        const monthStart = moment().startOf('month').valueOf();
        const monthEnd = moment().endOf('month').valueOf();

        const { data } = await AgentService.getWorkflowUseCountList(monthStart, monthEnd);

        dispatch(ReportAction.successUseCountList(data));
    } catch (error) {
        dispatch(ReportAction.failureUseCountList(error));
    }
};

const getExecutionTimeList = async (dispatch) => {
    try {
        const monthStart = moment().startOf('month').valueOf();
        const monthEnd = moment().endOf('month').valueOf();

        const { data } = await AgentService.getAgentTotalExecutionTimeList(monthStart, monthEnd);
        dispatch(ReportAction.successExecutionTimeList(data));
    } catch (error) {
        dispatch(ReportAction.failureExecutionTimeList(error));
    }
}

const onInitialize = (dispatch) => {
    return () => {
        getWorkflowMetrics(dispatch);
        getExceptionList(dispatch);
        getUseCountList(dispatch);
        getExecutionTimeList(dispatch);
    };
};

const ReportAgentScreen = () => {
    const dispatch = useDispatch();
    const socketFunctions = useContext(SocketContext);

    const [uiState, setUiState] = useState({
        eventTerminateModalShown: false,
        eventAgentSessionId: '',
        eventWorkflow: null,
    });

    const metrics = useSelector(state => state.report.metrics);
    const exceptionList = useSelector(state => state.report.exceptionList);
    const useCountList = useSelector(state => state.report.useCountList);
    const executionTimeList = useSelector(state => state.report.executionTimeList);
    const activeAgents = useSelector(state => state.activeAgent.connections);

    useEffect(onInitialize(dispatch), []);

    const executionTimeOptions = createExecutionTimeOptions(executionTimeList);

    const onEventTerminateModalOpen = (eventAgentSessionId, eventWorkflow) => {
        setUiState({
            ...uiState,
            eventTerminateModalShown: true,
            eventAgentSessionId: eventAgentSessionId,
            eventWorkflow: eventWorkflow,
        });
    };

    const onEventTerminateModalClose = () => {
        setUiState({
            ...uiState,
            eventTerminateModalShown: false,
            eventAgentSessionId: '',
            eventWorkflow: null,
        });
    };

    const onTerminateWorkflow = (sessionId, workflow) => {
        socketFunctions.sendTerminateRequest(sessionId, workflow);
    };

    return (
        <div>
            <div className="col-sm-12 mb-3">
                <AgentMiniWidgetList>
                    <AgentMiniWidget
                        label={<Trans i18nKey={MessageCode.AGENT_WORKFLOW_COMPLETED_LABEL} />}
                        value={metrics.completedWorkflowCount}
                        textClassName="m-0 text-success"
                        iconClassName="fa fa-flag-checkered">
                    </AgentMiniWidget>

                    <AgentMiniWidget
                        label={<Trans i18nKey={MessageCode.AGENT_FAILED_EXECUTION_LABEL} />}
                        value={metrics.failedWorkflowCount}
                        textClassName="m-0 text-danger"
                        iconClassName="fa fa-bomb">
                    </AgentMiniWidget>

                    <AgentMiniWidget
                        label={<Trans i18nKey={MessageCode.AGENT_SCHEDULED_WORKFLOWS_LABEL} />}
                        value={metrics.scheduledWorkflowCount}
                        textClassName="m-0 text-warning"
                        iconClassName="fa fa-calendar-check">
                    </AgentMiniWidget>

                    <AgentMiniWidget
                        label={<Trans i18nKey={MessageCode.AGENT_ACTIVE_AGENT_COUNT_LABEL} />}
                        value={activeAgents.length}
                        textClassName="m-0 text-primary"
                        iconClassName="fa fa-robot">
                    </AgentMiniWidget>
                </AgentMiniWidgetList>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <AgentWidgetList>
                        <AgentWidget title={<Trans i18nKey={MessageCode.AGENT_TOTAL_EXECUTED_HOURS_HEADER} />}>
                            <WidgetHighcharts
                                options={executionTimeOptions}>
                            </WidgetHighcharts>
                        </AgentWidget>

                        <AgentWidget title={<Trans i18nKey={MessageCode.AGENT_MOST_USED_WORKFLOWS_HEADER} />}>
                            <Table keyField={'workflowName'}
                                headers={useCountTableHeaders}
                                data={useCountList}>
                            </Table>

                            <Link to={RoutePath.WORKFLOW_LIST_URL}>
                                <small><Trans i18nKey={MessageCode.AGENT_VIEW_ALL_WORKFLOWS_LINK} /></small>
                            </Link>
                        </AgentWidget>
                    </AgentWidgetList>
                    <AgentWidgetList>
                        <AuthorityChecker requiredAuths={["VIEW_WORKFLOW_EVENTS"]}>
                            <AgentWidget title={<Trans i18nKey={MessageCode.AGENT_WORKFLOW_EVENTS_HEADER} />} className="active-events" >
                                {activeAgents.filter(agent => agent.queuedWorkflows.length > 0).length > 0 ?
                                    activeAgents
                                        .filter(agent => agent.queuedWorkflows.length > 0)
                                        .slice(0, 2)
                                        .map(agent =>
                                        <div className="mb-3" key={agent.sessionId}>
                                            <AgentCardBody
                                                agent={agent}>
                                                <AgentWorkflowList>
                                                    {agent.queuedWorkflows && 
                                                        _.sortBy(agent.queuedWorkflows, 'position')
                                                        .slice(0, 1)
                                                        .map((workflow, index) =>
                                                            <AgentQueuedWorkflowItem
                                                                key={workflow.id}
                                                                workflow={workflow}
                                                                sessionId={agent.sessionId}
                                                                index={ index }
                                                                onTerminate={ onTerminateWorkflow } >
                                                            </AgentQueuedWorkflowItem>
                                                        )}
                                                </AgentWorkflowList>
                                            </AgentCardBody>
                                        </div>
                                    )
                                    :
                                    <p className='mb-2 text-center text-white'>
                                        <Trans i18nKey={MessageCode.ERROR_WORKFLOW_EVENTS_EMPTY} />
                                    </p>
                                }

                                <AgentTerminateWorkflowConfirmationModal
                                    isShown={ uiState.eventTerminateModalShown }
                                    onClose={onEventTerminateModalClose}
                                    agentSessionId={uiState.eventAgentSessionId}
                                    workflow={uiState.eventWorkflow}>
                                </AgentTerminateWorkflowConfirmationModal>

                                <Link to={RoutePath.WORKFLOW_EVENTS_URL}>
                                    <small>
                                        <Trans i18nKey={MessageCode.AGENT_VIEW_ALL_EVENTS_LINK} />
                                    </small>
                                </Link>
                            </AgentWidget>
                        </AuthorityChecker>


                        <AgentWidget title={<Trans i18nKey={MessageCode.AGENT_WORKFLOW_EXCEPTIONS_HEADER} />}>
                            <Table keyField={'index'}
                                headers={exceptionTableHeaders}
                                data={exceptionList}>
                            </Table>

                            <Link to={RoutePath.WORKFLOW_ERRORS_URL}>
                                <small>
                                    <Trans i18nKey={MessageCode.AGENT_VIEW_ALL_EXCEPTIONS_LINK} />
                                </small>
                            </Link>
                        </AgentWidget>
                    </AgentWidgetList>

                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    );
};

export default ReportAgentScreen;