import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getCurrentUser: () => {
        const url = `${apiUrl}/api/user/profile/current-user`;
        return axios.get(url);
    },

    getUserList: async (tenantId) => {
        const url = `${apiUrl}/api/user/get-user-list`;
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };
        
        if(tenantId){
            return await axios.get(url, config);
        }else{
            return await axios.get(url);
        }
    },

    getDepartmentHeadList: async (tenantId, departmentId) => {
        const url = `${apiUrl}/api/user/get-department-head-list?departmentId=${departmentId}`;
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };
        
        if(tenantId){
            return await axios.get(url, config);
        }else{
            return await axios.get(url);
        }
    },

    getActiveUsers: async (simple=false) => {
        const url = `${apiUrl}/api/user/getActiveUsers/${simple}`;
        return await axios.get(url);
    },

    getSpecificUser: async (id) => {
        const url = `${apiUrl}/api/user/${id}`;
        return await axios.get(url);
    },

    addUser: async (firstName, lastName, email, password, departmentId, roleIdx, roleList, companyId, tenantId) => {
        const url = `${apiUrl}/api/user/register`;
        const data = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            credential: {
                username: email,
                newPassword: password,
                confirmPassword: password
            },
            departmentId: departmentId,
            groups: [roleList[roleIdx]],
            companyId: companyId
        }

        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };
        
        return await axios.post(url, data, config);
    },

    updateUser: async (id, firstName, lastName, department, role, company, tenantId) => {
        const url = `${apiUrl}/api/user/updateUser/${id}`;

        const data = {
            firstName: firstName,
            lastName: lastName,
            department: (department)?{"id": department}:null,
            company_: {"id": company},
            groups: [{"id": role}]
        }

        return await axios.put(url, data);
    },

    archiveUser: async (id) => {
        const url = `${apiUrl}/api/user/archive/${id}`;
        return await axios.delete(url);
    },

    checkIfEmailExists: async(email) => {
        const url = `${apiUrl}/api/user/checkEmailExist/${email}`;
        return await axios.get(url);
    },

    getSubordinates: async(id) => {
        const url = `${apiUrl}/api/user/getSubordinates`;
        return await axios.get(url);
    },

    getUsersByCompany: async(id) => {
        const url = `${apiUrl}/api/user/find-by-company/${id}`;
        return await axios.get(url);
    },

    getUserVisibleWidgets: async(id) => {
        const url = `${apiUrl}/api/user/getVisibleWidgets/${id}`;
        return await axios.get(url);
    },

    updateUserVisibleWidgets: async (id, toAdd, currentVisibleWidgets) => {
        const url = `${apiUrl}/api/user/updateVisibleWidgets/${id}`;

        const data = {
            toAdd: [toAdd],
            currentVisibleWidgets: currentVisibleWidgets
        }
        return await axios.post(url, data);
    },

    getMonitorWidgetList: async () => {
        const url = `${apiUrl}/api/widget`;
        return await axios.get(url);
    },
    
};