import ActionType from 'constants/ActionType';

const initialState = {
    connections: [],
    sessionInfo: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.ACTIVE_AGENT_SET_SESSION_INFO: {
            return {
                ...state,
                sessionInfo: action.payload.sessionInfo,
            };
        }

        case ActionType.ACTIVE_AGENT_ADD_CONNECTION: {
            return {
                ...state,
                connections: [ ...state.connections.filter((activeAgent) => 
                        action.payload.connection.sessionId !== activeAgent.sessionId), action.payload.connection ],
            };
        }

        case ActionType.ACTIVE_AGENT_REMOVE_CONNECTION: {
            return {
                ...state,
                connections: state.connections.filter((activeAgent) => 
                        action.payload.connection.sessionId !== activeAgent.sessionId)
            };
        }

        case ActionType.ACTIVE_AGENT_CLEAR_CONNECTIONS: {
            return {
                ...state,
                connections: initialState.connections,
            };
        }

        case ActionType.ACTIVE_AGENT_ADD_WORKFLOWS: {
            return {
                ...state,
                connections: state.connections.map((connection) => {
                    if (connection.sessionId === action.payload.sessionId) {
                        connection.workflows = [
                            ...connection.workflows,
                            ...action.payload.workflows,
                        ];
                    }

                    return connection;
                }),
            };
        }

        case ActionType.ACTIVE_AGENT_UPDATE_WORKFLOWS: {
            return {
                ...state,
                connections: state.connections.map((connection) => {
                    if (connection.sessionId === action.payload.sessionId) {
                        connection.workflows = [
                            ...connection.workflows.filter((oldWorkflow) => 
                                !action.payload.workflows.find((newWorkflow) => 
                                    newWorkflow.id === oldWorkflow.id)),
                            ...action.payload.workflows,
                        ];
                    }

                    return connection;
                }),
            }
        }

        case ActionType.ACTIVE_AGENT_REMOVE_WORKFLOWS: {
            return {
                ...state,
                connections: state.connections.map((connection) => {
                    if (connection.sessionId === action.payload.sessionId) {
                        connection.workflows = connection.workflows.filter((oldWorkflow) => 
                            !action.payload.workflows.find((newWorkflow) => 
                                    newWorkflow.id === oldWorkflow.id)
                        );
                    }

                    return connection;
                }),
            }
        }

        case ActionType.ACTIVE_AGENT_ADD_QUEUED_WORKFLOWS: {
            return {
                ...state,
                connections: state.connections.map((connection) => {
                    if (connection.sessionId === action.payload.sessionId) {
                        connection.queuedWorkflows = [
                            ...connection.queuedWorkflows,
                            ...action.payload.queuedWorkflows,
                        ];
                    }

                    return connection;
                }),
            };
        }

        case ActionType.ACTIVE_AGENT_UPDATE_QUEUED_WORKFLOWS: {
            return {
                ...state,
                connections: state.connections.map((connection) => {
                    if (connection.sessionId === action.payload.sessionId) {
                        connection.queuedWorkflows = [
                            ...connection.queuedWorkflows.filter((oldWorkflow) => 
                                !action.payload.queuedWorkflows.find((newWorkflow) => 
                                    newWorkflow.id === oldWorkflow.id)),
                            ...action.payload.queuedWorkflows,
                        ];
                    }

                    return connection;
                }),
            }
        }

        case ActionType.ACTIVE_AGENT_REMOVE_QUEUED_WORKFLOWS: {
            return {
                ...state,
                connections: state.connections.map((connection) => {
                    if (connection.sessionId === action.payload.sessionId) {
                        connection.queuedWorkflows = connection.queuedWorkflows.filter((oldWorkflow) => 
                            !action.payload.queuedWorkflows.find((newWorkflow) => 
                                    newWorkflow.id === oldWorkflow.id)
                        );
                    }

                    return connection;
                }),
            }
        }

        default: {
            return state;
        }
    }
};