import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getActivityList: async () => {
        const url = `${apiUrl}/api/activity`;
        return await axios.get(url);
    },

    getPremiumActivityList: async () => {
        const url = `${apiUrl}/api/license-type/activity?key=pro`;
        return await axios.get(url);
    },

    getSpecificActivity: async (id) => {
        const url = `${apiUrl}/api/activity/${id}`;
        return await axios.get(url);
    },

    addActivity: async (name, key, category = "", partial = 0) => {
        const url = `${apiUrl}/api/activity`;

        const data = {
            name: name,
            key: key,
            category: category,
            partial: partial
        }

        return await axios.post(url, data);
    },

    updateActivity: async (id, name, key, category = "", partial = 0) => {
        const url = `${apiUrl}/api/activity/${id}`;

        const data = {
            name: name,
            key: key,
            category: category,
            partial: partial
        }

        return await axios.put(url, data);
    },

    archiveActivity: async (id) => {
        const url = `${apiUrl}/api/activity/${id}`;

        return await axios.delete(url);
    },

    addPremiumActivity: async (id) => {
        const url = `${apiUrl}/api/license-type/add-activity`;
        const data = {
            licenseType: 'pro',
            activities: [{id}],
        };
        return await axios.post(url, data);
    },

    removePremiumActivity: async (id) => {
        const url = `${apiUrl}/api/license-type/remove-activity`;
        const data = {
            licenseType: 'pro',
            activities: [{id}],
        };
        return await axios.post(url, data);
    },
};