import ActionType from 'constants/ActionType';

const initialState = {
    soloAccountList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.ACCOUNT_SOLO_LIST_SUCCESS: {
            return {
                ...state,
                soloAccountList: action.payload.soloAccountList,
            };
        }

        case ActionType.ACCOUNT_SOLO_LIST_FAILURE: {
            return {
                ...state,
                soloAccountList: initialState.soloAccountList,
            };
        }

        default: {
            return state;
        }
    }
};