import React from 'react';
import { useSelector } from 'react-redux';

import { Switch, Redirect } from 'react-router-dom';
import RoutePath from 'constants/RoutePath';
import { ReportAgentScreen, ReportMonitorScreen } from 'containers';
import { AuthenticatedRoute } from 'components';

const ReportScreen = () => {
    const userAuths = useSelector(state => state.user.auths);

    return (
        <Switch>
            <AuthenticatedRoute path={ RoutePath.REPORTS_AGENT_URL } 
                    resolve={ () => userAuths.includes("ACCESS_AGENT_REPORT_TAB")}
                    resolveTo={ ReportAgentScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
                    
            <AuthenticatedRoute path={ RoutePath.REPORTS_MONITOR_URL } 
                    resolve={ () => userAuths.includes("ACCESS_MONITOR_REPORT_TAB")}
                    resolveTo={ ReportMonitorScreen }
                    redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <Redirect to={ RoutePath.REPORTS_AGENT_URL } />
        </Switch>
    );
};

export default ReportScreen;