import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UserAction from 'actions/UserAction';
import StorageKey from 'constants/StorageKey';

const checkLoginStatus = (dispatch) => {
    return () => {
        const cachedToken = localStorage.getItem(StorageKey.AUTH_KEY);
        if (cachedToken) {
            dispatch(UserAction.successVerify(cachedToken));
        } else {
            dispatch(UserAction.failureVerify());
        }
    };
};

const useLoginStatus = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    useEffect(checkLoginStatus(dispatch), []);

    return !!token;
};

export default useLoginStatus;
