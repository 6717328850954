import ActionType from 'constants/ActionType';

const initialState = {
    departmentList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.DEPARTMENT_LIST_SUCCESS: {
            return {
                ...state,
                departmentList: action.payload.departmentList,
            };
        }

        case ActionType.DEPARTMENT_LIST_FAILURE: {
            return {
                ...state,
                departmentList: initialState.departmentList,
            };
        }

        case ActionType.DEPARTMENT_LIST_FOR_DISPLAY_SUCCESS: {
            return {
                ...state,
                departmentListForDisplay: action.payload.departmentListForDisplay,
            };
        }

        case ActionType.DEPARTMENT_LIST_FOR_DISPLAY_FAILURE: {
            return {
                ...state,
                departmentListForDisplay: initialState.departmentListForDisplay,
            };
        }

        case ActionType.DEPARTMENT_LIST_FOR_EXPANSION_SUCCESS: {
            return {
                ...state,
                departmentListForExpansion: action.payload.departmentListForExpansion
            }
        }
        
        default: {
            return state;
        }
    }
};