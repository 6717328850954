import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { Trans } from 'react-i18next';
import { Pagination, Table } from 'react-bootstrap';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { Tooltip } from 'components';

const generateDefaultFormatter = (field) => {
    return (item) => getValue(item, field);
};

const getValue = (item, field) => {
    return item && field && item[field] ? item[field] : '-';
};

const generateHeaderKey = (column) => {
    return column.field;
};

const generateRowKey = (item, keyField) => {
    return getValue(item, keyField);
};

const generateCellKey = (item, keyField, index) => {
    return `${getValue(item, keyField)}-${index}`;
};

const generatePageNumbers = (startPage, currentPage, endPage) => {
    const startPages = _.range(startPage, Math.min(startPage + 3, endPage));
    const endPages = _.range(Math.max(endPage - 2, startPage), endPage + 1);
    const middlePages = _.range(Math.max(currentPage - 2, startPage), Math.min(currentPage + 3, endPage));

    const pageNumbers = _.union(startPages, middlePages, endPages);

    const processedPageNumbers = [];
    _.reduce(pageNumbers, (prevPage, currPage) => {
        if (prevPage + 1 !== currPage) {
            processedPageNumbers.push('...');
        }
        processedPageNumbers.push(currPage);

        return currPage;
    }, null, startPage - 1);

    return processedPageNumbers;
};

const accessValue = (rootObject, key) => {
    const keyArray = key.split('.');
    return _.reduce(keyArray, (currentObject, keyItem) => currentObject ? currentObject[keyItem] : null, rootObject);
};

const toggleSortOrder = (order) => {
    return order === 'asc' ? 'desc': 'asc';
};

const sortData = (data, sortOrder) => {
    const partitionedData = _.partition(data, (dataItem) => {
        const value = accessValue(dataItem, sortOrder.field);
        return value;
    });

    const sortPartitionedData = _.sortBy(partitionedData[0], (dataItem) => {
        const value = accessValue(dataItem, sortOrder.field);
        return _.isString(value) ? value.toLowerCase() : value;
    });
    
    const sortedData = sortPartitionedData.concat(partitionedData[1]);

    return sortOrder.order === 'asc' ? sortedData : sortedData.reverse();
};

const paginateData = (data, page, itemPerPage) => {
    const index = Math.max(0, page - 1);
    const paginatedData = data.slice((index * itemPerPage), (index * itemPerPage) + itemPerPage);

    return paginatedData;
};

const onInitialize = (setPageCount, setCurrentPage, dataCount, itemPerPage) => {
    return () => {
        setPageCount(Math.ceil(dataCount / itemPerPage));
        setCurrentPage(1);
    };
};

const defaultNoRecordsPlaceholder = (
    <Trans i18nKey={ MessageCode.TABLE_GENERAL_EMPTY_PLACEHOLDER } />
);

const CustomTable = ({ data, headers, keyField,
        isPaginated = false,
        isSortable = false,
        itemPerPage = 10,
        theadClass = '', 
        tbodyClass = '',
        noRecordsPlaceholder = ''}) => {
    

    const [ sortOrder, setSortOrder ] = useState({ field: 'name', order: 'asc' });
    const [ pageCount, setPageCount ] = useState(1);
    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(onInitialize(setPageCount, setCurrentPage, _.isArray(data) ? data.length : 0, itemPerPage), 
            [ JSON.stringify(data) ]);

    const onSortField = (header) => {
        const newSortOrder = {
            ...sortOrder
        };

        if (sortOrder.field === header.field) {
            newSortOrder.order = toggleSortOrder(sortOrder.order);
        } else {
            newSortOrder.field = header.field;
            newSortOrder.order = 'asc';
        };

        setSortOrder(newSortOrder);
    };

    const onChangePage = (page) => {
        setCurrentPage(page);
    };
    
    let renderedData = [ ...data ];
    if (isSortable) {
        renderedData = sortData(renderedData, sortOrder);
    }
    
    if (isPaginated) {
        renderedData = paginateData(renderedData, currentPage, itemPerPage);
    }
    
    return (
        <>
        <Table bordered hover responsive size="sm" variant="dark" >
            <thead className={ theadClass }>
                <tr>
                    { 
                        _.map(headers, (header) => {
                            const headerKey = generateHeaderKey(header);
                            return (
                                <th key={ headerKey }
                                    onClick={ () => onSortField(header) }
                                    className={ `${header.headerClasses} ${isSortable && header.sortable && 'sortable'}` }>
                                    { header.name }
                                    
                                    {
                                        isSortable && header.sortable && (
                                            <span className="float-right">
                                                { sortOrder.field !== header.field && <i className="fa fa-sort"></i> }
                                                { sortOrder.field === header.field && sortOrder.order === 'asc' && <i className="fa fa-sort-up"></i> }
                                                { sortOrder.field === header.field && sortOrder.order === 'desc' && <i className="fa fa-sort-down"></i> }
                                            </span>
                                        )
                                    }
                                </th>
                            );
                        })
                    }
                </tr>
            </thead>
            <tbody className={ tbodyClass }>
                {
                    renderedData.length > 0
                        ?
                            _.map(renderedData, (dataItem) => {
                                const rowKey = generateRowKey(dataItem, keyField);
                                return (
                                    <tr key={rowKey}>
                                        { 
                                            _.map(headers, (header, columnIndex) => {
                                                const cellKey = generateCellKey(dataItem, keyField, columnIndex);
                                                const formatter = _.isFunction(header.formatter) 
                                                    ? header.formatter : generateDefaultFormatter(header.field);

                                                const content = formatter(dataItem);

                                                if (content != null && content.length > LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) {

                                                    return <Tooltip placement="top" value={content} key={cellKey}>
                                                        <td className="wrap-long-text max-width-250px cursor-pointer" key={cellKey}>
                                                            { content.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..." }
                                                        </td>
                                                    </Tooltip>;
                                                    
                                                } else {
                                                    return <td className="wrap-long-text max-width-250px" key={cellKey}> {content} </td>;
                                                }
                                            })
                                        }
                                    </tr>
                                );
                            })
                        :
                            <tr className="no-records-found">
                                <td colSpan={ headers.length } className="text-center">
                                    { noRecordsPlaceholder || defaultNoRecordsPlaceholder }
                                </td>
                            </tr>
                }
            </tbody>
        </Table>

        {
            isPaginated &&
                (
                    <nav className="text-center">
                        <Pagination className="justify-content-center">
                            { currentPage !== 1 
                                && <Pagination.Prev onClick={ () => onChangePage(currentPage - 1) } /> }
                            {
                                _.map(generatePageNumbers(1, currentPage, pageCount), (page, pageIndex) => {
                                    return  page !== '...' 
                                        ? 
                                            <Pagination.Item key={ pageIndex }
                                                active={ page === currentPage }
                                                onClick={ () => onChangePage(page) }>{ page }
                                            </Pagination.Item>
                                        :   <Pagination.Ellipsis key={ pageIndex } />;
                                })
                            }
                            { currentPage < pageCount 
                                && <Pagination.Next onClick={ () => onChangePage(currentPage + 1) } /> }
                        </Pagination>
                    </nav>
                )
        }
        
        </>
    );  
};

export default CustomTable;