import ActionType from 'constants/ActionType';

export default {
    successCompany: (company) => {
        return {
            type: ActionType.COMPANY_SUCCESS,
            payload: { company },
        };
    },

    failureCompany: (error) => {
        return {
            type: ActionType.COMPANY_FAILURE,
            payload: { error },
        };
    },

    successCompanyList: (companyList) => {
        return {
            type: ActionType.COMPANY_LIST_SUCCESS,
            payload: { companyList },
        };
    },

    failureCompanyList: (error) => {
        return {
            type: ActionType.COMPANY_LIST_FAILURE,
            payload: { error },
        };
    },

    successIndustryList: (industryList) => {
        return {
            type: ActionType.INDUSTRY_LIST_SUCCESS,
            payload: {industryList},
        };
    },

    failureIndustryList: (error) => {
        return {
            type: ActionType.INDUSTRY_LIST_FAILURE,
            payload: {error},
        };
    },

    successLicense: (license) => {
        return {
            type: ActionType.LICENSE_SUCCESS,
            payload: { license },
        };
    },

    failureLicense: (error) => {
        return {
            type: ActionType.LICENSE_FAILURE,
            payload: { error }, 
        };
    },

    successLicenseList: (licenseList) => {
        return {
            type: ActionType.LICENSE_LIST_SUCCESS,
            payload: { licenseList },
        };
    },

    failureLicenseList: (error) => {
        return {
            type: ActionType.LICENSE_LIST_FAILURE,
            payload: { error },
        };
    },

    successLicenseKeyList: (licenseKeyList) => {
        return {
            type: ActionType.LICENSE_KEY_LIST_SUCCESS,
            payload: { licenseKeyList },
        };
    },

    failureLicenseKeyList: (error) => {
        return {
            type: ActionType.LICENSE_KEY_LIST_FAILURE,
            payload: { error },
        };
    },

    successPremiumLicenseTypeList: (premiumLicenseTypeList) => {
        return {
            type: ActionType.PREMIUM_LICENSE_LIST_SUCCESS,
            payload: { premiumLicenseTypeList },
        };
    },

    failurePremiumLicenseTypeList: (error) => {
        return {
            type: ActionType.PREMIUM_LICENSE_LIST_FAILURE,
            payload: { error },
        };
    },

    successLicenseFileList: (licenseFileList) => {
        return {
            type: ActionType.LICENSE_FILE_LIST_SUCCESS,
            payload: { licenseFileList },
        };
    },

    failureLicenseFileList: (error) => {
        return {
            type: ActionType.LICENSE_FILE_LIST_FAILURE,
            payload: { error },
        };
    },

    clearLicenseFileList: () => {
        return {
            type: ActionType.LICENSE_FILE_LIST_CLEAR,
        };
    },
};