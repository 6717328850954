import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { DirectoryService } from 'services';
import MessageCode from 'constants/MessageCode';


const DeleteMultipleFilesModal = ({ isShown, onSuccess, onCancel, initialValues }) => {

    const [ disabled, setDisabled ] = useState(false);

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                await DirectoryService.deleteMultipleFiles(initialValues.files, initialValues.currentDirectory);
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess();

            } catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_WORKFLOW_ID_REQUIRED: 
                    case MessageCode.ERROR_WORKFLOW_NOT_EXIST: {
                        onCancel(MessageCode.ERROR_WORKFLOW_NOT_EXIST);
                        break;
                    }

                    default: {
                        console.error(data);

                        onCancel(MessageCode.ERROR_WORKFLOW_GENERAL_INTERNAL);
                        break;
                    }
                }

                onCancel();
            } 
        }  
    };

    return (
        <Modal show={ isShown } onHide={ onCancel } centered>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    Delete Multiple Files
                </h5>
            </Modal.Header>
            <Modal.Body>
                <p className="wrap-long-text">You are about to delete multiple files.</p>
                <p className="text-light">Are you sure you want to proceed?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark"
                    type="button"
                    id={ "cancel-delete-multiple-files-btn" }
                    onClick={ () => onCancel() }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CANCEL } />
                </Button>
                <Button variant="primary" 
                    type="button"
                    id={ "confirm-delete-multiple-files-btn" }
                    disabled={disabled}
                    onClick={ (e) => onSubmit(e) }>
                    <Trans i18nKey={ MessageCode.GENERAL_BUTTON_DELETE } />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteMultipleFilesModal;