import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

import { DepartmentService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Toast, FormControl, AuthorityChecker } from 'components';

const departmentNameSelector = (event) => event ? event.target.value : null;
const departmentNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_DEPARTMENT_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_DEPARTMENT_NAME_TOO_LONG;
    }

    const invalidCharacters = '\\ / : * ? " | < >'.split(" ");

    if (invalidCharacters.some(v => value.includes(v))) {
        return MessageCode.ERROR_DEPARTMENT_NAME_INVALID; 
    }
    
    return null;
};

const departmentHeadSelector = (value) => {
    return value ? value : null
};
const departmentHeadValidator = (value) => {
    return null;
};

const onInitialize = (departmentName, departmentHead, initialValues, setGeneralError, company) => {
    return () => {

        if (initialValues){
            departmentName.setValue(initialValues.name || '');
            departmentHead.setValue(initialValues.head ? [initialValues.head] : []);
            if(company)company.setValue(initialValues.tenantId || '');
        }
        setGeneralError(null);
    };
 };

const DepartmentUpdateModal = ({ isShown, onClose, onSuccess, departmentList, userList, initialValues, companyList, onCompanyChange, isAdmin} ) => {

    const getUserListByTenant = (event) => {
        onCompanyChange(event.target.value);
        return (event)?event.target.value: null;
    };

    const companySelector = (event) => getUserListByTenant(event);
    const companyValidator = (value) => {
        if (value === 'default' || !value) {
            return MessageCode.ERROR_USER_COMPANY_REQUIRED;
        }
    };

    const [onChangeDepartmentName, departmentName] = useValidation(departmentNameSelector, departmentNameValidator, '');
    const [onChangeDepartmentHead, departmentHead] = useValidation(departmentHeadSelector, departmentHeadValidator, []);
    const [onChangeCompany, company ] = useValidation(companySelector, companyValidator);
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false);
    const {t} = useTranslation();

    useEffect(onInitialize(departmentName, departmentHead, initialValues, setGeneralError, company), 
        [ JSON.stringify(initialValues) ]);

    let head = null
    
    if (initialValues) {
        head = userList.find(({ id }) => {
            return initialValues 
                    && initialValues.head 
                    && id === initialValues.head.id;
        });
    }

    const resetFields = () => {
        departmentName.reset('');
        departmentHead.reset([]);
        if (departmentHead && departmentHead.typeahead) {
            departmentHead.typeahead.getInstance().clear();
        }
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);

            const departmentValue = (departmentHead.value != '')? departmentHead.value[0].id: null;
            try{
                await DepartmentService.updateDepartment(initialValues.id, departmentName.value, departmentValue, (company.value)?company.value:initialValues.tenantId);
                toast.success(
                    <Toast data={{ name: departmentName.value }}
                        message={MessageCode.DEPARTMENT_MODAL_MESSAGE_UPDATE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                
                resetFields();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch(data.key){
                    case MessageCode.ERROR_DEPARTMENT_NAME_REQUIRED:
                    case MessageCode.ERROR_DEPARTMENT_NAME_DUPLICATE: {
                        departmentName.setError(data.key);
                        break;
                    }
                
                    default:{
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={onClose} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_HEADER_UPDATE } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.GENERAL_SUBTEXT_REQUIRED } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_LABEL_NAME } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-update-department-field" }
                            value={departmentName.value}
                            onChange={ onChangeDepartmentName }
                            isInvalid={ departmentName.dirty && departmentName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ departmentName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <AuthorityChecker requiredAuths={["ADMIN_ACCESS_LEVEL"]}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    <Trans i18nKey={MessageCode.MODAL_USER_COMPANY_LABEL} />
                                </Form.Label>
                                <Form.Control as="select"
                                    disabled={true}
                                    className='custom-select'
                                    id={ "company-update-department-field" }
                                    value={company.value || 'default'}
                                    onChange={onChangeCompany}
                                    isInvalid={company.dirty && company.error}>
                                    <option value='default'>{t(MessageCode.MODAL_USER_FIELD_COMPANY_DEFAULT)}</option>
                                    {companyList.map(item => <option key={item.id} value={item.tenantId}>{item.name}</option>)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <Trans i18nKey={company.error} />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>  
                    </AuthorityChecker>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group>  

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.DEPARTMENT_MODAL_LABEL_HEAD} />
                        </Form.Label>
                        <FormControl.Typeahead
                            value={departmentHead}
                            id={ "head-update-department-field" }
                            onChange={onChangeDepartmentHead}
                            isInvalid={departmentHead.dirty && departmentHead.error}
                            errorMessageEmptyTextbox={MessageCode.ERROR_DEPARTMENT_HEAD_REQUIRED}
                            errorMessageNoneSelected={MessageCode.ERROR_DEPARTMENT_HEAD_NOT_SELECTED}
                            placeholder="Select department head"
                            filterBy={['firstName', 'lastName']}
                            labelKey={option => `${option.firstName}` + ' ' + `${option.lastName}` }
                            options={userList}
                            defaultSelected={head ? [head] : []}>

                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={departmentHead.error} />
                            </Form.Control.Feedback>
                        </FormControl.Typeahead>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-update-department-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CLEAR } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-update-department-btn" }
                        disabled={ disabled || company.error || departmentName.error || departmentHead.error }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_UPDATE } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DepartmentUpdateModal;