import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

import { DepartmentService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { Toast, FormControl, AuthorityChecker } from 'components';

const departmentNameSelector = (event) => event ? event.target.value : null;
const departmentNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_DEPARTMENT_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_DEPARTMENT_NAME_TOO_LONG;
    }

    const invalidCharacters = '\\ / : * ? " | < >'.split(" ");

    if (invalidCharacters.some(v => value.includes(v))) {
        return MessageCode.ERROR_DEPARTMENT_NAME_INVALID; 
    }

    return null;
};

const departmentHeadSelector = (value) => {
    return value ? value : null
};
const departmentHeadValidator = (value) => {
    return null;
};

const DepartmentCreateModal = ( {isShown, onClose, onSuccess, departmentList, userList, companyList, isAdmin} ) => {

    const getUserListByTenant = (event) => {
        return (event)?event.target.value: null;
    };

    const companySelector = (event) => getUserListByTenant(event);
    const companyValidator = (value) => {
        if (value === 'default' || !value) {
            return MessageCode.ERROR_USER_COMPANY_REQUIRED;
        }
    };

    const [onChangeDepartmentName, departmentName] = useValidation(departmentNameSelector, departmentNameValidator, '');
    const [onChangeDepartmentHead, departmentHead] = useValidation(departmentHeadSelector, departmentHeadValidator, []);
    const [onChangeCompany, company] = useValidation(companySelector, companyValidator);

    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        // Set default value to first entry in companyList
        if (companyList && companyList.length > 0) {
            company.setValue(companyList[0].tenantId);
        }
    }, [ JSON.stringify(companyList) ]);

    const resetFields = () => {
        departmentName.reset('');

        if (companyList && companyList.length > 0) {
            company.reset(companyList[0].tenantId);
        }
        
        departmentHead.reset([]);
        if (departmentHead && departmentHead.typeahead) {
            departmentHead.typeahead.getInstance().clear();
        }
        else if (departmentHead && departmentHead.value[0] && departmentHead.value[0].typeahead) {
            departmentHead.value[0].typeahead.getInstance().clear();
        }
    };

    const onCancel = () => {
        onClose();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);
            try{
                await DepartmentService.addDepartment(departmentName.value, (departmentHead.value[0])? departmentHead.value[0].id : null, (company.value)?company.value:null );
            
                toast.success(
                    <Toast data={{ name: departmentName.value }}
                        message={MessageCode.DEPARTMENT_MODAL_MESSAGE_CREATE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                
                resetFields();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                
                switch(data.key){
                    case MessageCode.ERROR_DEPARTMENT_NAME_REQUIRED:
                    case MessageCode.ERROR_DEPARTMENT_NAME_DUPLICATE: {
                        departmentName.setError(data.key);
                        break;
                    }
                
                    default:{
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={onCancel} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_HEADER_CREATE } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.GENERAL_SUBTEXT_REQUIRED } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.DEPARTMENT_MODAL_LABEL_NAME } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-create-department-field" }
                            value={departmentName.value}
                            onChange={ onChangeDepartmentName }
                            isInvalid={ departmentName.dirty && departmentName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ departmentName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <AuthorityChecker requiredAuths={["ADMIN_ACCESS_LEVEL"]}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    <Trans i18nKey={MessageCode.MODAL_USER_COMPANY_LABEL} />
                                </Form.Label>
                                <Form.Control as="select"
                                    className='custom-select'
                                    id={ "company-create-department-field" }
                                    value={company.value || 'default'}
                                    onChange={onChangeCompany}
                                    isInvalid={company.dirty && company.error}>
                                    <option value='default' key='default'>{t(MessageCode.MODAL_USER_FIELD_COMPANY_DEFAULT)}</option>
                                    {companyList.map((item, i) => <option key={item.id} value={item.tenantId}>{item.name}</option>)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <Trans i18nKey={company.error} />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>  
                    </AuthorityChecker>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group>  

                    {/* <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={MessageCode.DEPARTMENT_MODAL_LABEL_HEAD} />
                        </Form.Label>
                        <FormControl.Typeahead
                            value={departmentHead}
                            id={ "head-create-department-field" }
                            onChange={onChangeDepartmentHead}
                            isInvalid={departmentHead.dirty && departmentHead.error}
                            errorMessageEmptyTextbox={MessageCode.ERROR_DEPARTMENT_HEAD_REQUIRED}
                            errorMessageNoneSelected={MessageCode.ERROR_DEPARTMENT_HEAD_NOT_SELECTED}
                            placeholder="Select department head"
                            filterBy={['firstName', 'lastName']}
                            labelKey={option => `${option.firstName}` + ' ' + `${option.lastName}` }
                            options={userList}>

                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={departmentHead.error} />
                            </Form.Control.Feedback>
                        </FormControl.Typeahead>
                    </Form.Group> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-create-department-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CLEAR } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-create-department-btn" }
                        disabled={ disabled || company.error || departmentName.error || departmentHead.error }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_ADD } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DepartmentCreateModal;