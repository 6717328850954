import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { Trans } from 'react-i18next';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { Tooltip } from 'components';

const generateDefaultFormatter = (field) => {
    return (item) => getValue(item, field);
};

const getValue = (item, field) => {
    return item && field && item[field] ? item[field] : '-';
};

const generateHeaderKey = (column) => {
    return column.field;
};

const generateRowKey = (item, keyField, index) => {
    return `${getValue(item, keyField)}-${index}`;
};

const generateCellKey = (item, keyField, index) => {
    return `${getValue(item, keyField)}-${index}`;
};

const accessValue = (rootObject, key) => {
    const keyArray = key.split('.');
    return _.reduce(keyArray, (currentObject, keyItem) => currentObject ? currentObject[keyItem] : currentObject, rootObject);
};

const toggleSortOrder = (order) => {
    return order === 'asc' ? 'desc': 'asc';
};

const sortData = (data, sortOrder) => {
    const sortedData = _.sortBy(data, (dataItem) => {
        const value = accessValue(dataItem, sortOrder.field);
        return  dataItem.type + (_.isString(value) ? value.toLowerCase() : value);
    });

    return sortOrder.order === 'asc' ? sortedData : sortedData.reverse();
};

const defaultNoRecordsPlaceholder = (
    <Trans i18nKey={ MessageCode.TABLE_GENERAL_EMPTY_PLACEHOLDER } />
);

const CustomList = ({ data, headers, keyField,
        activeRows, 
        setActiveRows,
        setRenderedData,
        headersSettings = null,
        rowClickHandler = null,
        isSortable = false,
        theadClass = '', 
        tbodyClass = '',
        noRecordsPlaceholder = ''}) => {

    const [ sortOrder, setSortOrder ] = useState({ field: 'name', order: 'asc' });
    const [ showMouseTooltip, setShowMouseTooltip ] = useState(false);
    const onSortField = (header) => {
        const newSortOrder = {
            ...sortOrder
        };

        if (sortOrder.field === header.field) {
            newSortOrder.order = toggleSortOrder(sortOrder.order);
        } else {
            newSortOrder.field = header.field;
            newSortOrder.order = 'asc';
        };

        setActiveRows([]);
        setSortOrder(newSortOrder);
    };

    const handleRowDoubleClick = (dataItem, columnIndex, e) => {
        rowClickHandler(dataItem, columnIndex, e);
        setActiveRows([]);
    };

    const handleRowSingleClick = (e, itemIndex) => {
        if(e.ctrlKey){
            if(activeRows.includes(itemIndex)) {
                activeRows.splice(activeRows.indexOf(itemIndex), 1);
                setActiveRows(activeRows.concat());
            } else {
                activeRows.push(itemIndex);
                setActiveRows(activeRows.concat());
            }
        } else {
            setActiveRows([itemIndex]);
        }
    };

    let renderedData = [...data];

    useEffect(() => {
        if (isSortable) {
            renderedData = sortData(renderedData, sortOrder);
        }
        setRenderedData(renderedData);
    }, [JSON.stringify(sortOrder)])

    const mousedownHandler = (e, itemIndex) => {
        if(e.button === 2 && !showMouseTooltip) {
            if (itemIndex == null) {
                setActiveRows([null]);
            } else if(!activeRows.includes(itemIndex)) {
                setActiveRows([itemIndex]);
            }
        }
    }

    return (
        
        <div className="container-fluid table-responsive table-fix-head list-scroll">
            <table className="table-hover" size="sm" variant="dark" >
                <thead className={ theadClass }>
                    <tr>
                        { 
                            _.map(headers, (header) => {
                                const headerKey = generateHeaderKey(header);
                                if(!headersSettings || !headersSettings[header.name] || headersSettings[header.name].status === "shown") {
                                    return (
                                        <th key={ headerKey }
                                            onClick={ () => onSortField(header) }
                                            onMouseDown={(e) => mousedownHandler(e, null)}
                                            className={ `${header.headerClasses} ${isSortable && header.sortable && 'sortable'}` }>
                                            { header.name }
                                            
                                            {
                                                isSortable && header.sortable && (
                                                    <span className="float-right">
                                                        { sortOrder.field !== header.field && <i className="fa fa-sort"></i> }
                                                        { sortOrder.field === header.field && sortOrder.order === 'asc' && <i className="fa fa-sort-up"></i> }
                                                        { sortOrder.field === header.field && sortOrder.order === 'desc' && <i className="fa fa-sort-down"></i> }
                                                    </span>
                                                )
                                            }
                                        </th>
                                    );
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody className={ tbodyClass }>
                    {
                        renderedData.length > 0
                            ?
                            _.map(renderedData, (dataItem, itemIndex) => {
                                const rowKey = generateRowKey(dataItem, keyField, itemIndex);
                                return (
                                    <tr
                                        key={rowKey} 
                                        className={ activeRows.indexOf(itemIndex) >= 0? 'active-row' : 'not-active-row'}>
                                        { 
                                            _.map(headers, (header, columnIndex) => {
                                                const cellKey = (itemIndex+"-"+columnIndex);
                                                const formatter = _.isFunction(header.formatter) 
                                                    ? header.formatter : generateDefaultFormatter(header.field);

                                                const content = formatter(dataItem);
                                                
                                                if(!headersSettings || !headersSettings[header.name] || headersSettings[header.name].status === "shown"){
                                                    if ( content != null && content.length > LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) {

                                                        return <Tooltip placement="top-start" value={content} key={cellKey}>
                                                            
                                                            <td className="wrap-long-text max-width-250px cursor-pointer" 
                                                                key={cellKey} 
                                                                onDoubleClick={(e) => handleRowDoubleClick(dataItem, columnIndex, e)}
                                                                onClick={(e) => handleRowSingleClick(e, itemIndex)}>
                                                                    { content.substring(0, LengthLimit.TABLE_COLUMN_CONTENT_LIMIT) + "..." }
                                                            </td>
                                                            
                                                        </Tooltip>;
                                                        
                                                    } else {
                                                        return (
                                                            <td className="wrap-long-text max-width-250px" 
                                                                key={cellKey} 
                                                                onDoubleClick={(e) => handleRowDoubleClick(dataItem, columnIndex, e)}
                                                                onMouseDown={(e) => mousedownHandler(e, itemIndex)}
                                                                onClick={(e) => handleRowSingleClick(e, itemIndex)}> 
                                                                    { content }  
                                                            </td>
                                                        )
                                                    }
                                                }
                                            })
                                        }   
                                    </tr>
                                );
                            })
                            :
                            <tr className="no-records-found">
                                <td colSpan={ headers.length } className="text-center">
                                    { noRecordsPlaceholder || defaultNoRecordsPlaceholder }
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
            
        </div>
    );  
};

export default CustomList;