import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getLicenseList: async (companyId) => {
        const url = `${apiUrl}/api/license/company?id=${companyId}`;
        return await axios.get(url);
    },

    getSpecificLicense: async (companyId, licenseId) => {
        const url = `${apiUrl}/api/license/company/license?company=${companyId}&license=${licenseId}`;
        return await axios.get(url);
    },

    addLicense: async (companyId, licenseType, label) => {
        const url = `${apiUrl}/api/license/company/add`;
        const data = {
            companyId,
            licenseType,
            label,
        }
        return await axios.post(url, data);
    },

    updateLicense: async (companyId, licenseId, label, expirationDate, maxAccounts) => {
        const url = `${apiUrl}/api/license/company/update`;
        const data = {
            companyId, 
            licenseId,
            expirationDate,
            maxAccounts,
            label,
        }
        return await axios.post(url, data);
    },

    deleteLicense: async (companyId, licenseId) => {
        const url = `${apiUrl}/api/license/company/invalidate`;
        const data = {
            companyId,
            licenseId,
        }
        return await axios.post(url, data);
    },

    getPremiumLicenseTypeList: async () => {
        const url = `${apiUrl}/api/license-type/premium`;
        return await axios.get(url);
    },
};