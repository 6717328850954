import ActionType from 'constants/ActionType';

const initialState = {
    workflowList: [],
    exceptionList: [],
    executionLogs: [],
    agentList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.WORKFLOW_LIST_SUCCESS: {
            return {
                ...state,
                workflowList: action.payload.workflowList,
            };
        }

        case ActionType.WORKFLOW_LIST_FAILURE: {
            return {
                ...state,
                workflowList: initialState.workflowList,
            };
        }

        case ActionType.WORKFLOW_EXCEPTION_LIST_SUCCESS: {
            return {
                ...state,
                exceptionList: action.payload.exceptionList,
            };
        }

        case ActionType.WORKFLOW_EXCEPTION_LIST_FAILURE: {
            return {
                ...state,
                exceptionList: initialState.exceptionList,
            };
        }

        case ActionType.WORKFLOW_EXECUTION_LOGS_SUCCESS: {
            return {
                ...state,
                executionLogs: action.payload.executionLogs,
            };
        }

        case ActionType.WORKFLOW_EXCEPTION_LIST_FAILURE: {
            return {
                ...state,
                executionLogs: initialState.executionLogs,
            };
        }

        case ActionType.WORKFLOW_AGENT_LIST_SUCCESS: {
            return {
                ...state,
                agentList: action.payload.agentList,
            };
        };

        case ActionType.WORKFLOW_AGENT_LIST_FAILURE : {
            return {
                ...state,
                agentList: initialState.agentList,
            };
        }

        default: {
            return state;
        }
    }
};