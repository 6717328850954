import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import { ActivityService } from 'services';

import {
    Breadcrumb,
    Filter,
    PremiumActivityListItem,
    Toast,
} from 'components';

import MessageCode from 'constants/MessageCode';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useDrop } from 'react-dnd';

const getActivityList = async (setActivityList, setPremiumList) => {
    try {
        const act = await ActivityService.getActivityList();
        const premAct = await ActivityService.getPremiumActivityList();
        let data = act.data;
        let data2 = premAct.data;
        data = data.filter(val => !data2.some(val2 => val.id === val2.id));
        setActivityList(data);
        setPremiumList(data2);
    } catch (error) {
        setActivityList([]);
        setPremiumList([]);
    }
};

const onInitialize = (setActivityList, setPremiumList) => {
    return () => {
        getActivityList(setActivityList, setPremiumList);
    };
};

const LicensePremiumActivityListScreen = () => {
    const [activityList, setActivityList] = useState([]);
    const [premiumList, setPremiumList] = useState([]);
    const [filter, setFilter] = useState('');
    useEffect(onInitialize(setActivityList, setPremiumList), []);

    const [{}, BoxActivityList] = useDrop(() => ({
        accept: 'PremiumActivity',
        drop: () => ({name: 'BoxActivityList'}),
    }));

    const [{}, BoxPremiumList] = useDrop(() => ({
        accept: 'Activity',
        drop: () => ({name: 'BoxPremiumList'}),
    }));

    const setPremium = async (value, shouldSetToPremium) => {
        try {
            if (shouldSetToPremium) {
                await ActivityService.addPremiumActivity(value.id);
                toast.success(
                    <Toast data={{ name: value.name }}
                        message={MessageCode.PREMIUM_ACTIVITY_ADD_SUCCESS}>
                    </Toast>
                );
            } else {
                await ActivityService.removePremiumActivity(value.id);
                toast.success(
                    <Toast data={{ name: value.name }}
                        message={MessageCode.PREMIUM_ACTIVITY_REMOVE_SUCCESS}>
                    </Toast>
                );
            }
            getActivityList(setActivityList, setPremiumList);
        }
        catch (error) {
            toast.error(
                <Trans i18nKey={ MessageCode.ERROR_GENERAL_INTERNAL } />
            );
        }
    };

    const onFilterText = (text) => {
        setFilter(text);
    };

    const filteredActivityList = _.filter(activityList, (activity) => {
        return (!filter
                || (activity.name && activity.name.toLowerCase().includes(filter.toLowerCase()))
                || (activity.category && activity.category.toLowerCase().includes(filter.toLowerCase())));
    });

    const filteredPremiumList = _.filter(premiumList, (activity) => {
        return (!filter
                || (activity.name && activity.name.toLowerCase().includes(filter.toLowerCase()))
                || (activity.category && activity.category.toLowerCase().includes(filter.toLowerCase())));
    });

    return (
        <section className="premium-activity">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={MessageCode.BREADCRUMB_ACTIVITY_LABEL} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={MessageCode.BREADCRUMB_PREMIUM_ACTIVITY_LIST_LABEL} />
                </Breadcrumb.Item>
            </Breadcrumb>

            <Alert variant="dark" className="mt-3 mb-3">
                <Trans i18nKey={MessageCode.PREMIUM_ACTIVITY_HEADER_DESCRIPTION} />
            </Alert>

            <Filter>
                <Filter.Text label={ 'Search' }
                    onChange={ onFilterText } />
            </Filter>

            <Container>
                <Row className="mt-4">
                    <Col ref={BoxActivityList} sm={6}>
                        <h6 className="push-text">
                            <Trans i18nKey={MessageCode.PREMIUM_ACTIVITY_LIST_LABEL} />
                        </h6>
                        <div className="list-wrapper">
                            <ul className="list-group">
                                {filteredActivityList.map(item => 
                                    <PremiumActivityListItem
                                        key={item.id}
                                        value={item}
                                        isPremium={false}
                                        setPremium={setPremium} />
                                )}
                            </ul>
                        </div>
                    </Col>

                    <Col ref={BoxPremiumList} sm={6}>
                        <h6 className="push-text">
                            <Trans i18nKey={MessageCode.PREMIUM_ACTIVITY_PRO_LIST_LABEL}>
                                a<span className="brand-yellow">b</span>/<span className="text-info">c</span>d
                            </Trans>
                        </h6>
                        <div className="list-wrapper">
                            <ul className="list-group">
                                {filteredPremiumList.map(item => 
                                    <PremiumActivityListItem
                                        key={item.id}
                                        value={item}
                                        isPremium={true}
                                        setPremium={setPremium} />
                                )}
                            </ul>
                        </div>
                    </Col>
                </Row>  
            </Container>    
        </section>
    );
};

export default LicensePremiumActivityListScreen;