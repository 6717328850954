import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

import { WorkflowService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { FormControl } from 'components';

const displayNameSelector = (event) => {
    return event ? event.target.value : null;
};

const displayNameValidator = (value) => {
    value = value ? value.trim() : null;
    const invalidCharacters = '\\ / : * ? " | < >'.split(" ");

    if (!value) { 
        return MessageCode.ERROR_WORKFLOW_NAME_REQUIRED; 
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_WORKFLOW_NAME_TOO_LONG;
    }

    if (invalidCharacters.some(v => value.includes(v))) {
        return MessageCode.ERROR_WORKFLOW_NAME_INVALID; 
    }

    return null;
};

const fileSelector = (event) => {
    return event ? event.target.files[0] : null;
};

const fileValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_WORKFLOW_FILE_REQUIRED;
    }

    if (!value.name.endsWith('.rax')) {
        return MessageCode.ERROR_WORKFLOW_FILE_UNSUPPORTED;
    }

    return null;
};

const descriptionSelector = (event) => {
    return event ? event.target.value : null;
};

const descriptionValidator = (value) => {
    if (_.isString(value) && value.length > 1024) {
        return MessageCode.ERROR_WORKFLOW_DESCRIPTION_TOO_LONG;
    }
};

const onInitialize = (name, description, file, initialValues) => {
    return () => {
        if (initialValues) {
            name.setValue(initialValues.name || '');
            description.setValue(initialValues.description || '');
            file.setValue({ name: initialValues.originalFilename });
        }
    };
};

const WorkflowUpdateModal = ({ isShown, currentDirectory, onSuccess, onCancel, initialValues, tenantId }) => {
    const [ onChangeDisplayName, name ] 
        = useValidation(displayNameSelector, displayNameValidator, '');

    const [ onChangeFile, file ]
        = useValidation(fileSelector, fileValidator, null);

    const [ onChangeDescription, description ]
        = useValidation(descriptionSelector, descriptionValidator, '');

    const [ disabled, setDisabled ] = useState(false);    
    const [ , general ] = useValidation(null, null, '');
    const { t } = useTranslation();

    const onInputClick = (event) => {
        event.target.value = null
    }

    const resetFields = () => {
        name.reset('');
        file.reset(null);
        description.reset('');
        general.reset('');
    };

    useEffect(onInitialize(name, description, file, initialValues), 
        [ JSON.stringify(initialValues) ]);

    const onCancelUpdate = () => {
        onCancel();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            
            try {
                const { data } = await WorkflowService.updateWorkflow(
                    initialValues.id, name.value, file.value, description.value, currentDirectory, tenantId);
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess(data, resetFields);

            } catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_WORKFLOW_FILE_REQUIRED: 
                    case MessageCode.ERROR_WORKFLOW_FILE_EXIST: 
                    case MessageCode.ERROR_WORKFLOW_FILE_UNSUPPORTED: {
                        file.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_WORKFLOW_NAME_REQUIRED: 
                    case MessageCode.ERROR_WORKFLOW_NAME_INVALID: 
                    case MessageCode.ERROR_WORKFLOW_NAME_TOO_LONG: {
                        name.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_WORKFLOW_DESCRIPTION_TOO_LONG: {
                        description.setError(data.key);
                        break;
                    }

                    case MessageCode.ERROR_WORKFLOW_ID_REQUIRED:
                    case MessageCode.ERROR_WORKFLOW_NOT_EXIST:
                        general.setError(MessageCode.ERROR_WORKFLOW_NOT_EXIST);
                        break;

                    default: {
                        console.error(data);
                        general.setError(MessageCode.ERROR_GENERAL_INTERNAL);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onCancelUpdate }>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_UPDATE_HEADER } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_FORM_SUBTEXT } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_NAME_LABEL } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-update-workflow-field" }
                            value={ name.value }
                            onChange={ onChangeDisplayName }
                            isInvalid={ name.dirty && name.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ name.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_DESCRIPTION_LABEL } />
                        </Form.Label>
                        <Form.Control as="textarea"
                            id={ "description-update-workflow-field" }
                            value={ description.value }
                            onChange={ onChangeDescription }
                            isInvalid={ description.dirty && description.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ description.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_FILE_LABEL } />
                        </Form.Label>
                        <FormControl.File
                            id={ "file-update-workflow-field" }
                            accept=".rax" onClick={ onInputClick }
                            value={ file.value ? file.value.name : '' }
                            onChange={ onChangeFile }
                            isInvalid={ file.dirty && file.error }>

                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={ file.error } />
                            </Form.Control.Feedback>
                        </FormControl.File>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid="true">
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ general.error } />
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        id={ "reset-update-workflow-btn" }
                        onClick={ resetFields }>
                        <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_CLEAR_BUTTON } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-update-workflow-btn" }
                        disabled={ disabled || name.error || description.error || file.error }>
                        <Trans i18nKey={ MessageCode.WORKFLOW_MODAL_UPDATE_BUTTON } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default WorkflowUpdateModal;
