import React, { useState } from 'react';

import { Modal, Button, Form, Col} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

import { AccountService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import { FormControl } from 'components';


const fileSelector = (event) => { 
    return event ? event.target.files[0] : null; 
};

const fileValidator = (value) => {
    if (!value) {
        return MessageCode.ERROR_ACCOUNT_REGFILE_REQUIRED;
    }

    if (!value.name.endsWith('.dat')) {
        return MessageCode.ERROR_ACCOUNT_REGFILE_UNSUPPORTED;
    }

    return null;
};

const departmentSelector = (event) => event ? event.target.value : null;
const departmentValidator = (value) => {
    if (value === 'default' || !value) {
        return MessageCode.ERROR_STATUS_DEPARTMENT_REQUIRED;
    }
    return null;
};

const LicenseKeyGenerateFromFileModal = ({ isShown, onClose, onSuccess, license, departmentList }) => {
    const { t } = useTranslation();

    const [ onChangeDepartment, department ] = useValidation(departmentSelector, departmentValidator);
    const [ onChangeFile, file ] = useValidation(fileSelector, fileValidator, null);
    const [ disabled, setDisabled ] = useState(false); 

    const resetFields = () =>{
        file.reset('');
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                await AccountService.generateNewKeyFromFile(license.company.id, license.id, file.value, department.value);
                
                toast.success(
                    <Trans i18nKey={MessageCode.KEY_GENERATE_MODAL_SUCCESS} />
                );
                  
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                resetFields();
                onSuccess();
            }
            catch ({ data }) {
                setDisabled(false);
                console.error(data);

                switch(data.key){
                    case MessageCode.ERROR_LICENSE_EXPIRED_KEY:
                    case MessageCode.ERROR_COMPANY_FULL_LICENSES:
                    case MessageCode.ERROR_ACCOUNT_REGFILE_INVALID:
                    case MessageCode.ERROR_ACCOUNT_REGFILE_REQUIRED:
                    case MessageCode.ERROR_ACCOUNT_REGFILE_UNSUPPORTED:{
                        toast.error(
                            <Trans i18nKey={data.key} />
                        );
                        break;
                    }

                    default:{
                        toast.error(
                            <Trans i18nKey={MessageCode.ERROR_GENERAL_INTERNAL} />
                        );
                        break;
                    }
                }

                onClose();
            }
        }
    };

    return (
        <Modal show={isShown} onHide={onClose} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={MessageCode.KEY_GENERATE_FROM_FILE_MODAL_HEADER} />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <Trans i18nKey={MessageCode.KEY_GENERATE_FROM_FILE_MODAL_TEXT}
                        values={{ name: license ? license.company.emailAddress : null }}>
                        <p className='m-0'>You are about to generate a new license key using a registration file.</p> 
                        <p>The license file will be sent to <span className="brand-yellow"><strong>EMAIL</strong></span>.</p>
                    </Trans>
                        <Form.Group>
                            <Form.Label>
                                <Trans i18nKey={MessageCode.LICENSE_MODAL_DEPARTMENT_LABEL} />
                            </Form.Label>
                            <Form.Control as="select"
                                className='custom-select'
                                id={ "department-create-user-field" }
                                value={department.value || 'default'}
                                onChange={onChangeDepartment}
                                isInvalid={department.dirty && department.error}>
                                <option value='default'>{t(MessageCode.MODAL_USER_FIELD_DEPARTMENT_DEFAULT)}</option>
                                {departmentList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                <Trans i18nKey={department.error} />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <Trans i18nKey={ MessageCode.KEY_GENERATE_FROM_FILE_MODAL_REGISTRATION_FILE } />
                            </Form.Label>
                            <FormControl.File
                                accept=".dat"
                                id={ "file-generate-key-field" }
                                value={ file.value ? file.value.name : '' }
                                onChange={ onChangeFile }
                                isInvalid={ file.dirty && file.error }>

                                <Form.Control.Feedback type="invalid">
                                    <Trans i18nKey={ file.error } />
                                </Form.Control.Feedback>
                            </FormControl.File>
                        </Form.Group>
 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark"
                        type="button"
                        id={ "cancel-generate-key-btn" }
                        onClick={onClose}>
                        <Trans i18nKey={MessageCode.KEY_GENERATE_MODAL_CANCEL_BUTTON} />
                    </Button>
                    <Button variant="primary"
                        type="submit"
                        id={ "submit-generate-key-btn" }
                        disabled={disabled || file.error || department.error}>
                        <Trans i18nKey={MessageCode.KEY_GENERATE_MODAL_GENERATE_BUTTON} />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LicenseKeyGenerateFromFileModal;