/* eslint no-eval: 0 */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';

import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';
import LengthLimit from 'constants/LengthLimit';
import { Toast } from 'components';
import { TaskService } from 'services';


const taskNameSelector = (event) => event ? event.target.value : null;
const taskNameValidator = (value) => {
    if (!value){
        return MessageCode.ERROR_GENERAL_NAME_REQUIRED;
    }

    if (value.length > 255){
        return MessageCode.ERROR_GENERAL_NAME_TOO_LONG;
    }

    return null;
};

const taskStatusSelector = (event) => event ? event.target.value: null; 
const taskStatusValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_STATUS_REQUIRED;
    }

    return null;
};

const statusDepartmentSelector = (event) => event ? event.target.value: null;
const statusDepartmentValidator = (value) => {
    if (!value || value === 'default'){
        return MessageCode.ERROR_STATUS_REQUIRED;
    }

    return null;
};

const onInitialize = (taskName, status, department, initialValues, setGeneralError) => {
    return () => {
        if (initialValues){
            taskName.setValue(initialValues.name || '');
            department.setValue((initialValues.department ? initialValues.department.id : 'default'));
            status.setValue((initialValues.status ? initialValues.status.id : 'default'));
        }
        setGeneralError(null);
    };
 };

const TaskUpdateModal = ( {isShown, onClose, onSuccess, initialValues, departmentList, statusList} ) => {
    const [onChangeTaskName, taskName] = useValidation(taskNameSelector, taskNameValidator, '');
    const [onChangeDepartment, department] =  useValidation(statusDepartmentSelector, statusDepartmentValidator, 'default');
    const [onChangeStatus, status] =  useValidation(taskStatusSelector, taskStatusValidator, 'default');
    const [generalError, setGeneralError] = useState();
    const [disabled, setDisabled] = useState(false);
    const {t} = useTranslation();

    useEffect(onInitialize(taskName, status, department, initialValues, setGeneralError), [ JSON.stringify(initialValues) ]);

    const resetFields = () => {
        taskName.reset('');
        department.reset('default');
        status.reset('default');
    };

    const onCancel = () => {
        onClose();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();
            setGeneralError(null);

            try {
                
                await TaskService.updateTask(initialValues.id, taskName.value, status.value, department.value);

                toast.success(
                    <Toast data={{ name: taskName.value }}
                        message={MessageCode.TASK_MESSAGE_UPDATE_SUCCESS}>
                    </Toast>
                );
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);

                resetFields();
                onSuccess();
            }
            catch ({data}){
                setDisabled(false);
                switch(data.key){
                    case MessageCode.ERROR_DEPARTMENT_NAME_REQUIRED:{
                        taskName.setError(data.key);
                        break;
                    }
                
                    default:{
                        console.error(data);
                        setGeneralError(data.key);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={onCancel} centered>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        <Trans i18nKey={ MessageCode.TASK_MODAL_HEADER_UPDATE  } />
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.GENERAL_SUBTEXT_REQUIRED } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.GENERAL_LABEL_NAME } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-update-task-field" }
                            value={taskName.value}
                            onChange={ onChangeTaskName }
                            isInvalid={ taskName.dirty && taskName.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ taskName.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.GENERAL_LABEL_STATUS } />
                        </Form.Label>
                        <Form.Control as="select"
                            className='custom-select'
                            id={ "status-update-task-field" }
                            value={status.value}
                            onChange={ onChangeStatus }
                            isInvalid={ status.dirty && status.error}>
                            <option value='default' disabled>{t(MessageCode.DEFAULT_STATUS)}</option>
                            { statusList.map(item => <option key={item.id} value={item.id}>
                                { item.name.length > LengthLimit.DROPDOWN_DISPLAY_NAME_LIMIT ? item.name.substring(0, LengthLimit.DROPDOWN_DISPLAY_NAME_LIMIT) + "..." : item.name }
                            </option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ status.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.GENERAL_LABEL_DEPARTMENT } />
                        </Form.Label>
                        <Form.Control as="select"
                            className='custom-select'
                            id={ "department-update-task-field" }
                            value={department.value}
                            onChange={ onChangeDepartment }
                            isInvalid={ department.dirty && department.error}>
                            <option value='default' disabled>{t(MessageCode.DEFAULT_DEPARTMENT)}</option>
                            { departmentList.map(item => <option key={item.id} value={item.id}>
                                { item.name.length > LengthLimit.DROPDOWN_DISPLAY_NAME_LIMIT ? item.name.substring(0, LengthLimit.DROPDOWN_DISPLAY_NAME_LIMIT) + "..." : item.name }
                            </option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ department.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid={generalError}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ generalError } />
                        </Form.Control.Feedback>
                    </Form.Group> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" 
                        type="button"
                        id={ "reset-update-task-btn" }
                        onClick={resetFields}>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CLEAR } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-update-task-btn" }
                        disabled={ disabled || taskName.error || status.error || department.error }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_UPDATE } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TaskUpdateModal;