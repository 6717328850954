import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomTooltip = ({ placement, value, children }) => {
    return (
        <OverlayTrigger 
            key={ placement } placement={ placement }
            overlay={ 
                <Tooltip id={ `tooltip-${placement}` }>
                    { value }
                </Tooltip> 
            }>
            { children }
        </OverlayTrigger>
    );

};

export default CustomTooltip;