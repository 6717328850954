export default {
    BASE_URL: '/',
    LOGIN_URL: '/login',
    DASHBOARD_URL: '/dashboard',

    REPORTS_URL: '/dashboard/report',
    REPORTS_AGENT_URL: '/dashboard/report/agent',
    REPORTS_MONITOR_URL: '/dashboard/report/monitor',

    WORKFLOW_URL: '/dashboard/workflow',
    WORKFLOW_LIST_URL: '/dashboard/workflow/list',
    WORKFLOW_EVENTS_URL: '/dashboard/workflow/events',
    WORKFLOW_ERRORS_URL: '/dashboard/workflow/errors',
    WORKFLOW_LOGS_URL: '/dashboard/workflow/logs',

    LICENSE_URL: '/dashboard/license',
    LICENSE_COMPANIES_URL: '/dashboard/license/companies',
    LICENSE_COMPANIES_LICENSE_URL: '/dashboard/license/companies/:companyId',
    LICENSE_COMPANIES_LICENSE_KEY_URL: '/dashboard/license/companies/:companyId/license/:licenseId',
    LICENSE_ACCOUNTS_URL: '/dashboard/license/accounts',
    LICENSE_ACTIVITIES_URL: '/dashboard/license/activities',
    LICENSE_PREMIUM_ACTIVITIES_URL: '/dashboard/license/premium-activities',

    MANAGEMENT_URL: '/dashboard/management',
    MANAGEMENT_USERS_URL: '/dashboard/management/users',
    MANAGEMENT_DEPARTMENTS_URL: '/dashboard/management/departments',
    MANAGEMENT_ROLES_URL: '/dashboard/management/roles',
    MANAGEMENT_STATUSES_URL: '/dashboard/management/statuses',
    MANAGEMENT_TASKS_URL: '/dashboard/management/tasks',
    MANAGEMENT_SCHEDULES_URL: '/dashboard/management/schedules',
    MANAGEMENT_TARGETS_URL: '/dashboard/management/targets',
    MANAGEMENT_TRANSFERS_URL: '/dashboard/management/transfers',
    MANAGEMENT_DATA_ENTRY_URL: '/dashboard/management/dataentry',

    FORBIDDEN_PAGE_URL: '/dashboard/forbidden',
}