import ActionType from 'constants/ActionType';

export default {    
    successDirectoryFileList: (directoryFileList) => {
        return {
            type: ActionType.DIRECTORY_LIST_SUCCESS,
            payload: { directoryFileList },
        };
    },

    failureDirectoryFileList: (error) => {
        return {
            type: ActionType.DIRECTORY_LIST_FAILURE,
            payload: { error },
        };
    },

};