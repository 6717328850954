import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default {
    getAuthoritiesList: async () => {
        const url = `${apiUrl}/api/authority/findAll`;
        return await axios.get(url);
    },

    getRolesList: async (tenantId) => {
        const url = `${apiUrl}/api/usergroup/findAllActive`;

        if(tenantId){
            const config =  { 
                headers: { 
                    'APP-TENANT': tenantId 
                } 
            };
            return await axios.get(url, config);
        }else{
            return await axios.get(url);
        }        
    },

    getSpecificRole: async (id) => {
        const url = `${apiUrl}/api/usergroup/${id}`;
        return await axios.get(url);
    },

    addRole: async ( roleName, roleAuths, tenantId ) => {
        const url = `${apiUrl}/api/usergroup`;

        const role = {
            name: roleName,
            authorityNames: roleAuths
        };

        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };

        return await axios.post(url, role, config);
    },

    updateRole: async (id, roleName, roleAuths, tenantId ) => {
        const url = `${apiUrl}/api/usergroup/${id}`;

        const role = {
            name: roleName,
            newAuthorityName: roleAuths
        };
        
        const config =  { 
            headers: { 
                'APP-TENANT': tenantId 
            } 
        };

        return await axios.put(url, role, config);
    },

    deleteRole: async (id) => {
        const url = `${apiUrl}/api/usergroup/archiveRole/${id}`;
        return await axios.delete(url);
    },
};