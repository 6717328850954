import React from 'react';

const publicUrl = process.env.PUBLIC_URL;

const ForbiddenPage = () => {
    return (
        <div className="forbidden-page">
            <img src={ `${publicUrl}/img/warning.png` }  width="180px" alt="Unauthorize Access"/>
            <div className="error-401">401</div>
            <p className="error-message brand-yellow">Unauthorized Access</p>
        </div>
    )
};

export default ForbiddenPage;