import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ActivityService } from 'services';
import { ActivityAction } from 'actions';

import {
    Breadcrumb,
    Table,
    Filter,
    Toast,
    Tooltip,
    AuthorityChecker,
    ActivityArchiveModal,
    ActivityCreateModal,
    ActivityUpdateModal
} from 'components';

import MessageCode from 'constants/MessageCode';

const getActivityList = async (dispatch) => {
    try {
        const { data } = await ActivityService.getActivityList();
        dispatch(ActivityAction.successActivityList(data));

    } catch (error) {
        dispatch(ActivityAction.failureActivityList(error));
    }
};

const onInitialize = (dispatch) => {
    return () => {
        getActivityList(dispatch);
    };
};
const LicenseActivityListScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(onInitialize(dispatch), []);

    const [uiState, setUiState] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        showDeleteModal: false,
        editingActivity: null,
        filter: {
            name: ''
        },
    });

    const activityList = useSelector(state => state.activity.activityList);

    const activityTableColumnList = [{
        'field': 'createDate',
        'name': t(MessageCode.TABLE_ACTIVITY_CREATE_DATE_HEADER),
        'headerClasses': 'align-top',
        'formatter': (activity) => moment(activity.createDate).format('MMM DD'),
        'sortable': true,
    }, {
        'field': 'name',
        'name': t(MessageCode.TABLE_ACTIVITY_NAME_HEADER),
        'headerClasses': 'align-top th-inner',
        'sortable': true,
    }, {
        'field': 'key',
        'name': t(MessageCode.TABLE_ACTIVITY_KEY_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    }, {
        'field': 'category',
        'name': t(MessageCode.TABLE_ACTIVITY_CATEGORY_HEADER),
        'headerClasses': 'align-top',
        'sortable': true,
    }, {
        'field': 'actions',
        'name': '',
            'formatter': (activity) => {
            return (
                <div className="text-center">
                    <AuthorityChecker requiredAuths={["EDIT_ACTIVITY"]}>
                        <Tooltip placement="top-start"
                            value={<Trans i18nKey={MessageCode.TOOLTIP_ACTIVITY_EDIT_ICON} />}>
                            <Link to="#"
                                id={ "edit-activity-"+activity.id }
                                onClick={() => onRequestUpdateActivity(activity.id)}>
                                <i className="fa fa-edit"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                    &nbsp;
                    <AuthorityChecker requiredAuths={["DELETE_ACTIVITY"]}>
                        <Tooltip placement="top-start"
                            value={<Trans i18nKey={MessageCode.TOOLTIP_ACTIVITY_DELETE_ICON} />}>
                            <Link to="#"
                                id={ "delete-activity-"+activity.id }
                                onClick={() => onRequestDeleteActivity(activity.id)}>
                                <i className="fa fa-trash"></i>
                            </Link>
                        </Tooltip>
                    </AuthorityChecker>
                </div>
            );
        },
    }];

    const filteredActivityList = _.filter(activityList, (activity) => {
        return (!uiState.filter.name
            || activity.name.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || activity.key.toLowerCase().includes(uiState.filter.name.toLowerCase())
            || activity.category.toLowerCase().includes(uiState.filter.name.toLowerCase()));
    });

    const onRequestFilterName = (name) => {
        setUiState({
            ...uiState,
            filter: {
                ...uiState.filter,
                name,
            },
        });
    };

    const onRequestUpdateActivity = async (id) => {
        try {
            const { data } = await ActivityService.getSpecificActivity(id);
            setUiState({
                ...uiState,
                editingActivity: data,
                showUpdateModal: true,
            });
        } catch (error) {
            console.error(error);

            await getActivityList(dispatch);
            setUiState({
                ...uiState,
                editingActivity: null,
                showUpdateModal: false,
            });
        }
    };

    const onSuccessUpdateActivity = async (data) => {
        await getActivityList(dispatch);

        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.MODAL_ACTIVITY_UPDATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            editingActivity: null,
            showUpdateModal: false,
        });
    };

    const onCancelUpdateActivity = () => {
        setUiState({
            ...uiState,
            editingActivity: null,
            showUpdateModal: false,
        });
    };

    const onRequestCreateActivity = () => {
        setUiState({
            ...uiState,
            showCreateModal: true,
        });
    };

    const onSuccessCreateActivity = async (data) => {
        await getActivityList(dispatch);

        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.MODAL_ACTIVITY_CREATE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onCancelCreateActivity = () => {
        setUiState({
            ...uiState,
            showCreateModal: false,
        });
    };

    const onRequestDeleteActivity = async (id) => {
        try {
            const { data } = await ActivityService.getSpecificActivity(id);

            setUiState({
                ...uiState,
                editingActivity: data,
                showDeleteModal: true,
            });

        } catch (error) {
            console.error(error);

            await getActivityList(dispatch);
            setUiState({
                ...uiState,
                editingActivity: null,
                showDeleteModal: false,
            });
        }
    };

    const onSuccessDeleteActivity = async (data) => {
        await getActivityList(dispatch);

        toast.success(
            <Toast data={{ name: data.name }}
                message={MessageCode.MODAL_ACTIVITY_DELETE_SUCCESS}>
            </Toast>
        );

        setUiState({
            ...uiState,
            showDeleteModal: false,
        });
    };

    const onCancelDeleteActivity = () => {
       
        setUiState({
            ...uiState,
            showDeleteModal: false,
        });
    };

    return (
        <section>
            <AuthorityChecker requiredAuths={["CREATE_ACTIVITY"]}>
                <Link to="#" className="float" onClick={onRequestCreateActivity}>
                    <i className="fa fa-plus my-float"></i>
                </Link>

                <div className="label-container">
                    <div className="label-text">
                        <Trans i18nKey={MessageCode.ACTIVITY_CREATE_BUTTON_TOOLTIP} />
                    </div>
                    <i className="fa fa-play label-arrow"></i>
                </div>
            </AuthorityChecker>

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Trans i18nKey={MessageCode.BREADCRUMB_ACTIVITY_LABEL} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    <Trans i18nKey={MessageCode.BREADCRUMB_ACTIVITY_LIST_LABEL} />
                </Breadcrumb.Item>
            </Breadcrumb>

            <AuthorityChecker requiredAuths={["VIEW_ACTIVITY"]}>
                <Filter>
                    <Filter.Text label={'Search'}
                        onChange={onRequestFilterName}>
                    </Filter.Text>
                </Filter>

                <div className="container-fluid table-responsive">
                    <Table theadClass="thead-dark"
                        keyField={'id'}
                        data={filteredActivityList}
                        isPaginated={true}
                        isSortable={true}
                        itemPerPage={10}
                        headers={activityTableColumnList}
                        noRecordsPlaceholder={
                            <Trans i18nKey={MessageCode.TABLE_ACTIVITY_EMPTY_PLACEHOLDER} />
                        }>
                    </Table>
                </div>
            </AuthorityChecker>

            <ActivityCreateModal isShown={uiState.showCreateModal}
                onSuccess={onSuccessCreateActivity}
                onCancel={onCancelCreateActivity}>
            </ActivityCreateModal>

            <ActivityUpdateModal isShown={uiState.showUpdateModal && !!uiState.editingActivity}
                onSuccess={onSuccessUpdateActivity}
                onCancel={onCancelUpdateActivity}
                initialValues={uiState.editingActivity}>
            </ActivityUpdateModal>

            <ActivityArchiveModal isShown={uiState.showDeleteModal && !!uiState.editingActivity}
                onSuccess={onSuccessDeleteActivity}
                onCancel={onCancelDeleteActivity}
                initialValues={uiState.editingActivity}>
            </ActivityArchiveModal>
        </section>
    );
};

export default LicenseActivityListScreen;