import React from 'react';
import { useSelector } from 'react-redux';

import { Redirect, Switch } from 'react-router-dom';
import RoutePath from 'constants/RoutePath';
import { 
    ManagementUserScreen, 
    ManagementDepartmentScreen, 
    ManagementRoleScreen,
    ManagementStatusScreen,
    ManagementTaskScreen,
    ManagementScheduleScreen,
    ManagementTargetScreen,
    ManagementTransferScreen,
    ManagementDataEntryScreen,
} from 'containers';
import { AuthenticatedRoute } from 'components';

const ManagementScreen = () => {
    const userAuths = useSelector(state => state.user.auths);

    return (
        <Switch>
            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_USERS_URL } 
                resolve={ () => userAuths.includes("ACCESS_USER_TAB")}
                resolveTo={ ManagementUserScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
            
            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_DEPARTMENTS_URL } 
                resolve={ () => userAuths.includes("ACCESS_DEPARTMENT_TAB")}
                resolveTo={ ManagementDepartmentScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
                    
            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_ROLES_URL } 
                resolve={ () => userAuths.includes("ACCESS_ROLE_TAB")}
                resolveTo={ ManagementRoleScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_STATUSES_URL } 
                resolve={ () => userAuths.includes("ACCESS_STATUS_TAB")}
                resolveTo={ ManagementStatusScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_TASKS_URL } 
                resolve={ () => userAuths.includes("ACCESS_TASK_TAB")}
                resolveTo={ ManagementTaskScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
            
            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_SCHEDULES_URL } 
                resolve={ () => userAuths.includes("ACCESS_SCHEDULE_TAB")}
                resolveTo={ ManagementScheduleScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
            
            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_TARGETS_URL } 
                resolve={ () => userAuths.includes("ACCESS_TASK_TARGET_TAB")}
                resolveTo={ ManagementTargetScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />

            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_TRANSFERS_URL } 
                resolve={ () => userAuths.includes("ACCESS_TRANSFER_TAB")}
                resolveTo={ ManagementTransferScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
            
            <AuthenticatedRoute path={ RoutePath.MANAGEMENT_DATA_ENTRY_URL } 
                resolve={ () => userAuths.includes("ACCESS_DATA_ENTRY_TAB")}
                resolveTo={ ManagementDataEntryScreen }
                redirectTo={ RoutePath.FORBIDDEN_PAGE_URL } />
            
            <Redirect to={ RoutePath.MANAGEMENT_USERS_URL } />
        </Switch>
    );
};

export default ManagementScreen;