import React, { useState } from 'react';
import _ from 'underscore';

import { Modal, Button, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { DirectoryService } from 'services';
import { useValidation } from 'hooks';
import MessageCode from 'constants/MessageCode';

const displayNameSelector = (event) => {
    return event ? event.target.value : null;
};

const displayNameValidator = (value) => {
    value = value ? value.trim() : null;
    const invalidCharacters = '\\ / : * ? " | < >'.split(" ");

    if (!value) { 
        return MessageCode.ERROR_FILE_NAME_REQUIRED; 
    }

    if (_.isString(value) && value.length > 255) {
        return MessageCode.ERROR_FILE_NAME_TOO_LONG;
    }

    if (invalidCharacters.some(v => value.includes(v))) {
        return MessageCode.ERROR_FILE_NAME_INVALID; 
    }

    return null;
};

const UpdateMultipleFilesModal = ({ isShown, onSuccess, onCancel, initialValues }) => {
    const [ onChangeDisplayName, name ] 
        = useValidation(displayNameSelector, displayNameValidator);

    const [ disabled, setDisabled ] = useState(false);
    const [ , general ] = useValidation(null, null, '');

    const resetFields = () => {
        name.reset('');
        general.reset('');
    };

    const onCancelCreate = () => {
        onCancel();
    };

    const onSubmit = async (event) => {
        if(!disabled) {
            setDisabled(true);
            event.preventDefault();

            try {
                await DirectoryService.updateMultipleFiles(
                    initialValues.files, name.value.trim(), initialValues.currentDirectory);
                
                setTimeout(() => {
                    setDisabled(false);
                }, 1000);
                onSuccess(resetFields);

            } catch ({ data }) {
                setDisabled(false);
                switch (data.key) {
                    case MessageCode.ERROR_FILE_NAME_INVALID:
                    case MessageCode.ERROR_FILE_NAME_TOO_LONG:
                    case MessageCode.ERROR_FILE_NAME_EXIST:
                    case MessageCode.ERROR_FILE_NAME_REQUIRED: 
                    case MessageCode.ERROR_WORKFLOW_NAME_INVALID: 
                    case MessageCode.ERROR_DIRECTORY_NAME_INVALID: {
                        name.setError(data.key);
                        break;
                    }

                    default: {
                        general.setError(MessageCode.ERROR_GENERAL_INTERNAL);
                        break;
                    }
                }
            }
        }
    };

    return (
        <Modal show={ isShown } onHide={ onCancelCreate }>
            <Form noValidate onSubmit={ onSubmit }>
                <Modal.Header closeButton>
                    <h5 className="modal-title">
                        Update Multiple Files
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-muted">
                        <Trans i18nKey={ MessageCode.DIRECTORY_MODAL_FORM_SUBTEXT } />
                    </small>
                    <Form.Group>
                        <Form.Label>
                            <Trans i18nKey={ MessageCode.DIRECTORY_MODAL_NAME_LABEL } />
                        </Form.Label>
                        <Form.Control as="input" 
                            type="text"
                            id={ "name-update-multiple-field" }
                            value={ name.value || '' }
                            onChange={ onChangeDisplayName }
                            isInvalid={ name.dirty && name.error }>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ name.error } />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control as="input"
                            type="hidden"
                            isInvalid="true">
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            <Trans i18nKey={ general.error } />
                        </Form.Control.Feedback>
                    </Form.Group>                     
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        id={ "reset-update-multiple-btn" }
                        onClick={ resetFields }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_CLEAR } />
                    </Button>
                    <Button variant="primary" 
                        type="submit"
                        id={ "submit-update-multiple-btn" }
                        disabled={ disabled || name.error }>
                        <Trans i18nKey={ MessageCode.GENERAL_BUTTON_UPDATE } />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UpdateMultipleFilesModal;
