import React,  { useState } from 'react';
import { useSelector } from 'react-redux';
import Q from 'q';

const userAccessible = async (userAuths, requiredAuths) => {
    var deferred = Q.defer();
    
    await requiredAuths.forEach( auth => {
        if (userAuths.includes(auth)) {
            deferred.resolve(true);
        }
    });
    
    return deferred.promise;

}

const AuthorityChecker = ({ requiredAuths, children }) => {
    const userAuths = useSelector(state => state.user.auths);
    const [ isAccessible, setAccessible ] = useState(false);

    Promise.resolve(userAccessible(userAuths, requiredAuths)).then((isAccessible) => {
        setAccessible(isAccessible)
    });

    return (
            isAccessible ? <> {children} </> : null
    );
};

export default AuthorityChecker;