import HeaderKey from 'constants/HeaderKey';
import StorageKey from 'constants/StorageKey';

const appSecret = process.env.REACT_APP_APP_SECRET;

export default {
    success: (config) => {
        config.headers = {
            ...config.headers,
            [HeaderKey.APP_KEY]: appSecret,
            [HeaderKey.AUTH_KEY]: localStorage.getItem(StorageKey.AUTH_KEY),
            'Access-Control-Allow-Origin': '*',
        };
    
        return config;
    }, 
    
    fail: (error) => {
        return Promise.reject(error);
    },
};