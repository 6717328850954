import ActionType from 'constants/ActionType';

export default {
    successScheduleList: (scheduleList) => {
        return {
            type: ActionType.SCHEDULE_LIST_SUCCESS,
            payload: { scheduleList },
        };
    },
    failureScheduleList: (error) => {
        return {
            type: ActionType.SCHEDULE_LIST_FAILURE,
            payload: { error },
        };
    }
};
